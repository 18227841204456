// @mui
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, Grid, Card } from '@mui/material';
// components
import { Image } from '../../../components';
import { Box } from '@mui/system';
import WorkInDesk from '../../../assets/images/WorkInDesk.png';
import ArrowRigth from '../../../assets/images/ArrowRigth.png';
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const VISIONS = [
  {
    name: 'Erstellst du deinen Workspace',
    description: 'I am a description',
  },
  {
    name: 'Find your Workplace and destination',
    description: 'I am a description',
  },
  {
    name: 'Make new social network by working abroad',
    description: 'I am a description'
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

// ----------------------------------------------------------------------

export default function HaveSpaceSteps() {
  const { t } = useTranslation();
  return (
    <RootStyle>
      <Container>
        <Stack
          spacing={3}
          sx={{
            maxWidth: 466,
            mb: { xs: 8, md: 5 },
            mx: { xs: 'auto', md: 'unset' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography fontSize={14} color={"#FB5A55"} fontWeight={600}>Onboarding</Typography>
          <Typography variant="h2">{t("HaveSpace_HowItWorks")}</Typography>
          <Typography fontSize={18} sx={{ color: '#777E91' }}>
            {t("HaveSpace_HowItWorks_Subline")}
          </Typography>
        </Stack>

        <Grid
          container
          spacing={{ xs: 8, md: 3 }}
          justifyContent="space-between"
          alignItems={{ md: 'center' }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ padding: "20px", borderLeft: "3px solid #F2F4F7" }}>
              <Box sx={{ color: "#FB5A55", display: "flex", alignItems: "center", gap: 1 }}>
                <Box>01</Box>
                <img src={ArrowRigth} alt="ArrowRigth" />
              </Box>
              <Box>
                <Typography variant="h4">{t("Onboarding_Step1_Headline")}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: '#777E91' }}>
                  {t("Onboarding_Step1_Text")}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: "20px", borderLeft: "3px solid #F2F4F7" }}>
              <Box sx={{ color: "#FB5A55", display: "flex", alignItems: "center", gap: 1 }}>
                <Box>02</Box>
                <img src={ArrowRigth} alt="ArrowRigth" />
              </Box>
              <Box>
                <Typography variant="h4">{t("Onboarding_Step2_Headline")}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: '#777E91' }}>
                  {t("Onboarding_Step2_Text")}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: "20px", borderLeft: "3px solid #F2F4F7" }}>
              <Box sx={{ color: "#FB5A55", display: "flex", alignItems: "center", gap: 1 }}>
                <Box>03</Box>
                <img src={ArrowRigth} alt="ArrowRigth" />
              </Box>
              <Box>
                <Typography variant="h4">
                  {t("Onboarding_Step3_Headline")}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: '#777E91' }}>
                  {t("Onboarding_Step3_Text")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Image
              alt="vision"
              src={WorkInDesk}
            />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
