import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Autocomplete, InputAdornment, InputLabel, Typography, FormControl, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Input, Modal, Button } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
// components
import { Image, Iconify } from '../../../components';
import { InputStyle } from './TravelTourBarFilters';
import { useTranslation } from 'react-i18next';
import user from '../../../assets/images/user.svg';
import { tag_list } from '../../../mocks/tag_list';
import { useEffect, useState } from 'react';
import { tag_user } from '../../../mocks/tag_user';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  width: '100%',
  '& .MuiAutocomplete-root': {
    '& .MuiFilledInput-root': {
      padding: '0 12px',
    },
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height: 500,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ariaLabel = { 'aria-label': 'description' };


// ----------------------------------------------------------------------

export default function TravelTourFilterEssen({ tags_user, handleChangeTagsUser, tags_workspace, handleChangeTagsWorkplace }) {
  const [tagSelectedUser, setTagSelectedUser] = useState([]);
  const [nameSelected, setNameSelected] = useState([]);
  const [tagSelectedWorkspace, setTagSelectedWorkspace] = useState([]);

  const { t } = useTranslation();

  const handleChangeUser = (option) => {
    console.log(option);
    //push id to array if not exist
    if (tagSelectedUser.indexOf(option.id) === -1) {
      setTagSelectedUser([...tagSelectedUser, option.id]);
      setNameSelected([...nameSelected, option.name]);
      console.log([...tagSelectedUser, option.id].join(','))
      handleChangeTagsUser([...tagSelectedUser, option.id].join(','));
    }
    //remove id from array if exist
    else {
      setTagSelectedUser(tagSelectedUser.filter(item => item !== option.id));
      setNameSelected(nameSelected.filter(item => item !== option.name));
      handleChangeTagsUser(tagSelectedUser.filter(item => item !== option.id).join(','));
    }
  };

  const handleChangeWorkspace = (option) => {
    console.log(option);
    //push id to array if not exist
    if (tagSelectedWorkspace.indexOf(option.id) === -1) {
      setTagSelectedWorkspace([...tagSelectedWorkspace, option.id]);
      setNameSelected([...nameSelected, option.name]);
      console.log([...tagSelectedWorkspace, option.id].join(','))
      handleChangeTagsWorkplace([...tagSelectedWorkspace, option.id].join(','));
    }
    //remove id from array if exist
    else {
      setTagSelectedWorkspace(tagSelectedWorkspace.filter(item => item !== option.id));
      setNameSelected(nameSelected.filter(item => item !== option.name));
      handleChangeTagsWorkplace(tagSelectedWorkspace.filter(item => item !== option.id).join(','));
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log("tags", tags_user)
    console.log("tags", tags_workspace)
    let name = [];
    if (tags_user) {
      console.log("entro")
      setTagSelectedUser(tags_user.split(','));
      //search name of tag
      tags_user.split(',').map((item) => {
        tag_user.map((tag) => {
          if (tag.id === item) {
            console.log("tag: " + tag.name);
            name.push(t(tag.name));
          }
        });
      });
      setNameSelected(name);
    }

    if (tags_workspace) {
      console.log("entro2")
      setTagSelectedWorkspace(tags_workspace.split(','));
      tags_workspace.split(',').map((item) => {
        tag_list.map((tag) => {
          if (tag.id === item) {
            console.log("tag: " + tag.name);
            name.push(t(tag.name));
          }
        });
      });
      setNameSelected(name);
    }
  }, [tags_user, tags_workspace]);

  return (
    <RootStyle>
      <Box display="flex" alignItems="flex-start" justifyContent="center" gap={1} sx={{ width: "100% !important" }}>
        <Box>
          <Image alt={"user"} src={user} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            fontSize={{ xs: "0.9rem", lg: "16px", xxl: "2rem" }}
            sx={{ fontWeight: 800, lineHeight: '1.1', px: "8px" }} >
            {t("MainSearch_What")}
          </Typography>
          <Box
            onClick={handleOpen}
            fontSize={{ xs: "0.9rem", lg: "14px", xxl: "1.6rem" }}
            sx={{
              color: "#777E91",
              lineHeight: '1.1',
              px: "8px",
              py: "16px",
              width: "100%",
              cursor: "pointer"
            }}>
            {nameSelected.length > 0 ? nameSelected.join(", ").slice(0, 15) + (nameSelected.join(", ").length > 15 ? " ..." : "") : t("MainSearch_What2")}
          </Box>
        </Box>
      </Box>
      <ModalTagsUser handleChangeUser={handleChangeUser} tagSelectedUser={tagSelectedUser} handleChangeWorkspace={handleChangeWorkspace} tagSelectedWorkspace={tagSelectedWorkspace} open={open} handleClose={handleClose} />
    </RootStyle>
  );
}

function ModalTagsUser({ handleChangeUser, tagSelectedUser, handleChangeWorkspace, tagSelectedWorkspace, open, handleClose }) {
  const { t } = useTranslation();

  const [categoriesUser, setCategoriesUser] = useState([]);
  const [tags_users, setTagsUsers] = useState([]);

  const [categoriesWorkspace, setCategoriesWorkspace] = useState([]);
  const [tags_workspace, setTagsWorkspace] = useState([]);

  const [changeTags, setChangeTags] = useState(false);

  useEffect(() => {
    tag_user.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
    let tags_user = tag_user.filter((item) => item.category);
    setTagsUsers(tags_user);
    let categories_user = tag_user.map((item) => {
      return item.category;
    });
    categories_user = categories_user.filter(
      (item, index) => categories_user.indexOf(item) === index
    );

    setCategoriesUser(categories_user);

    tag_list.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
    let tag_workspace = tag_list.filter((item) => item.category);
    setTagsWorkspace(tag_workspace);
    let categories_workspace = tag_list.map((item) => {
      return item.category;
    });
    categories_workspace = categories_workspace.filter(
      (item, index) => categories_workspace.indexOf(item) === index
    );

    setCategoriesWorkspace(categories_workspace);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Button variant="contained" sx={{ my: 2, background: "#F5465D" }}
          onClick={() => {
            setChangeTags(prev => !prev);
          }}>
          {changeTags ? t('FilterInterest') : t('FilterWorkspaces')}
        </Button>
        {!changeTags ? (
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              columnGap: 15,
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
              },
            }}
          >
            {categoriesUser.map((category, index) =>
              <Typography key={index} variant="h6" paragraph>
                {category.charAt(0).toUpperCase() + category.slice(1)}
                <div>
                  {tags_users.filter(item => item.category === category).map((option) => {
                    return (
                      <MenuItem onClick={() => handleChangeUser(option)} key={option.id} value={option.id}>
                        <Checkbox checked={tagSelectedUser.indexOf(option.id) > -1} />
                        <ListItemText primary={t(option.name)} />
                      </MenuItem>
                    );
                  })}
                </div>
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              columnGap: 15,
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
              },
            }}
          >
            {categoriesWorkspace.map((category, index) =>
              <Typography key={index} variant="h6" paragraph>
                {category.charAt(0).toUpperCase() + category.slice(1)}
                <div>
                  {tags_workspace.filter(item => item.category === category).map((option) => {
                    return (
                      <MenuItem onClick={() => handleChangeWorkspace(option)} key={option.id} value={option.id}>
                        <Checkbox checked={tagSelectedWorkspace.indexOf(option.id) > -1} />
                        <ListItemText primary={t(option.name)} />
                      </MenuItem>
                    );
                  })}
                </div>
              </Typography>
            )}
          </Box>
        )}
        {/* Button to close the modal */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Box
            component="button"
            onClick={handleClose}
            sx={{
              fontSize: 16,
              py: 1,
              px: 2.5,
              borderRadius: 1,
              border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
              color: 'text.secondary',
              bgcolor: 'background.neutral',
              cursor: 'pointer',
              transition: (theme) => theme.transitions.create(['all'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter
              }),
              '&:hover': {
                opacity: 0.48
              }
            }}
          >
            {t('SaveButton')}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}