import useSWR from 'swr';
import { useGlobalContext } from '../stores/global';
// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------


export default function useRequest(request, { fallbackData, ...config } = {}) {
  const { user } = useGlobalContext();

  if (user) {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
  }
  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR(request && JSON.stringify(request), () => axios.request(request), {
    ...config,
    fallbackData: fallbackData && {
      status: 200,
      statusText: 'InitialData',

      config: request,
      headers: {},
      data: fallbackData,
    },
  });

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate,
  };
}
