/* import PropTypes from 'prop-types'; */
// icons
import eventsIcon from '@iconify/icons-carbon/events';
import calendarIcon from '@iconify/icons-carbon/calendar';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Divider, Typography, Avatar } from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import { Image, RatingLabel, Iconify, TextMaxLine } from '../../../components';
//
/* import { TravelTourFilterGuests, TravelTourFilterTime } from '../filters'; */
import config from '../../../config';
import moment from 'moment';
import locationIcon from '@iconify/icons-carbon/location';
import AvailableDays from '../tours/AvailableDays';
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

/* const resetInputStyles = {
  '& .MuiFilledInput-root': {
    padding: 0,
  },
  '& .MuiFilledInput-input': {
    height: '26px !important',
  },
  '& .MuiInputAdornment-root': {
    display: 'block',
  },
}; */

// ----------------------------------------------------------------------


export default function TravelCheckOutSummary({
  workspace,
  guests,
  /*   setGuests,
    setTotalPrice,
    changeDates, */
  days,
  priceSale,
  fromDate,
  toDate,
  isSubmitting,
  setSubmit,
  isLoading
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <Stack sx={{ p: 4, pb: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Image alt={"Cover img"} src={config.api.host + workspace.imageList[0].data} ratio="1/1" sx={{ borderRadius: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <TextMaxLine variant="h5">{workspace.data.title}</TextMaxLine>

            <RatingLabel ratings={workspace?.data?.rating} reviews={workspace?.data?.allReviews.length || 0} sx={{ mt: 2 }} />

            <Divider sx={{ borderStyle: 'dashed', my: 2.5 }} />

            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Avatar src={workspace.data.user.profilepicture} />
              <div>
                <Typography variant="body3" sx={{ color: 'text.disabled' }}>
                  {t("Workspace_created")}
                </Typography>
                <Typography variant="subtitle2">{workspace.data.user.first_name} {workspace.data.user.last_name}</Typography>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <Stack sx={{ p: 4, pb: 3 }}>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', sm: 'column' }}
          gap={2}
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderStyle: 'dashed', display: { xs: 'none', sm: 'block' } }}
            />
          }
          sx={{
            p: 3.5,
            borderRadius: 2,
            color: 'text.disabled',
            bgcolor: 'background.neutral',
          }}
        >
          <Stack direction="row" spacing={1.5} sx={{ width: 1 }}>
            <Iconify icon={calendarIcon} sx={{ width: 24, height: 24, flexShrink: 0 }} />
            <Stack spacing={0.5}>
              <Typography>Von: {moment(fromDate).format("DD.MM.YYYY")} - Bis: {moment(toDate).format("DD.MM.YYYY")}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ width: 1 }}>
            <Iconify icon={eventsIcon} sx={{ width: 24, height: 24, flexShrink: 0 }} />
            <Stack spacing={0.5}>
              <Typography >{t('Guests')}: {guests.adults}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ width: 1 }}>
            <Iconify icon={locationIcon} sx={{ width: 24, height: 24, flexShrink: 0 }} />
            <Stack spacing={0.5}>
              <Typography>{t('Address')}: {workspace.data.address}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          spacing={2.5}
          direction={{ xs: 'column', sm: 'column' }}
          gap={2}
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderStyle: 'dashed', display: { xs: 'none', sm: 'block' } }}
            />
          }
          sx={{
            p: 3.5,
            marginTop: 3,
            borderRadius: 2,
            color: 'text.disabled',
            bgcolor: 'background.neutral',
          }}
        >
          <Stack direction="row" >
            <AvailableDays notShowCalendar={true} workplace={workspace} />
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="h4">
          {t('Booking_PriceDetails')}
        </Typography>

        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color={'text.disabled'}>
            €{fCurrency(workspace.data.price)} /Tag x {days} days
          </Typography>
          <Typography variant="body1" color={'text.disabled'}>
            €{fCurrency(priceSale)}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color={'text.disabled'}>
            {t('Workspace_ServiceFee')}
          </Typography>
          <Typography variant="body1" color={'text.disabled'}>
            €{fCurrency(priceSale * 0.15)}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between" backgroundColor={"#F4F5F6"} paddingY={3} paddingX={2} borderRadius={"5px"}>
          <Typography variant="h5">
            Total <span style={{ color: "#777E91" }}>
              (EUR)</span>
          </Typography>
          <Typography variant="h5">
            €{fCurrency(priceSale)}
          </Typography>
        </Stack>
        {/*center message*/}
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center" paddingY={3}>
          <Typography variant="body1" color={'text.disabled'}>
            {t('Booking_Cancellation')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
