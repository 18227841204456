import PropTypes from 'prop-types';
// @mui
import { Box, Pagination } from '@mui/material';
//
import ReviewTravelTourItem from './ReviewUserTourItem';

// ----------------------------------------------------------------------

ReviewUserTourList.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default function ReviewUserTourList({ reviews }) {
  return (
    <>
      {reviews.map((review) => {
        return (
          <Box key={review.id}>
            <ReviewTravelTourItem
              name={review.user_id.first_name}
              avatarUrl={review.user_id.profilepicture}
              postedAt={review.review_date}
              message={review.review_text}
              rating={review.review_rating}
            />
          </Box>
        );
      })}

      {/* <Pagination
        count={10}
        color="primary"
        size="large"
        sx={{
          pt: 5,
          pb: { xs: 10, md: 15 },
          '& .MuiPagination-ul': {
            justifyContent: 'center',
          },
        }}
      /> */}
    </>
  );
}
