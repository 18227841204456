import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { getUser } from './helpers/storage';
import { Provider as GlobalProvider } from './stores/global';
// import i18n (needs to be bundled ;)) 
import './i18n';
const initialState = {
  isLoading: false,
  user: getUser(),
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalProvider initialState={initialState}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GlobalProvider>
);

