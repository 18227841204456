import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, FilledInput, FormHelperText, Grid, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Iconify } from '../../components';
// icons
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ErrorMsg from '../../components/errors/ErrorMsg';
import config from '../../config';
// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  newPassword: Yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  repeatPassword: Yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8")
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export default function VerifyCodeForm() {
  const { t } = useTranslation();
  let params = useParams();
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      newPassword: "",
      repeatPassword: "",
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    let body = { hash: params.hash, password: data.newPassword }
    axios.put(`${config.api.host}/auth/reset_password`, body)
      .then(res => {
        if (res.data.success) {
          navigate("/login")
        } else {
          setOpen(true);
          setMsg("Something went wrong");
        }
      })
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ mt: 5, mb: 3 }}>
          <Grid item xs={12}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              {t('NewPassword')}
              {/* New password */}
            </Typography>
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? viewIcon : viewOff} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              {t('RepeatPassword')}
              {/* Repeat password */}
            </Typography>
            <Controller
              name="repeatPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Repeat Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? viewIcon : viewOff} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Passwort ändern
          </LoadingButton>
        </Stack>
      </form>
      <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
    </>
  );
}
