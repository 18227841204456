import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import ErrorMsg from '../../components/errors/ErrorMsg';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('That is not an email'),
});

ResetPasswordForm.propTypes = {
  onGetEmail: PropTypes.func,
  onSent: PropTypes.func,
};

export default function ResetPasswordForm({ onSent, onGetEmail }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data) => {
    let body = { mail: data.email };
    axios.post(`${config.api.host}/auth/forgot_password`, body)
      .then(res => {
        if (res.data.success) {
          onSent();
          onGetEmail(data.email);
        } else {
          setOpen(true);
          setMsg("Die Post ist nicht registriert");
        }
      })
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Email_Information")}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset Password
          </LoadingButton>
        </Stack>
      </form>
      <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
    </>
  );
}
