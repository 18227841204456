/* import PropTypes from 'prop-types'; */
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  /* Card, */ Button,
  Tabs,
  Tab,
} from "@mui/material";
// hooks
import { useBoundingClientRect, useRequest } from "../../../hooks";
// components
import {
  CarouselArrows /*  Image, Iconify, SvgIconStyle  */,
  Image,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
/* import config from '../../../config'; */
import TravelTourItem from "../tours/TravelTourItem";

import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import howItWorks1 from "../../../assets/images/howItWorks1.png";
import howItWorks2 from "../../../assets/images/howItWorks2.png";
import howItWorks3 from "../../../assets/images/howItWorks3.png";
import dashLine from "../../../assets/images/dashLine.png";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5, 0),
  },
}));

const CarouselArrowsStyle = styled(CarouselArrows)(({ theme }) => ({
  position: "unset",
  justifyContent: "center",
  "& button": {
    borderRadius: "50%",
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

// ----------------------------------------------------------------------

export default function HowItWorks() {
  const { t } = useTranslation();
  // ----------------------------------------------------------------------

  const theme = useTheme();

  const [types, setType] = useState(
    Array.from({ length: 10 }, (_, i) => i + 1)
  );
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const container = useBoundingClientRect(containerRef);


  useEffect(() => {
    //setWorkplaces();
  }, []);

  /* const offsetLeft = container?.left; */

  const carouselSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: {
              xs: "center",
              md: "center",
            },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">{t('Headline_How_it_works')}</Typography>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t('Subline_How_it_works')}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ position: "relative", marginTop: "40px", }}>
          <Box sx={{ position: "absolute", width: "100%", zIndex: 0 }}>
            <img src={dashLine} alt="dashLine" width="100%" />
          </Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            textAlign="center"
            justifyContent={{ md: "space-between" }}
            gap={10}
            sx={{
              zIndex: 1000,
            }}
          >
            <Box sx={{
              padding: "30px 0px",
              width: {xs:"100%", lg:"60"},
            }}>
              <Box
                component={LazyLoadImage}
                width={"55%"}
                height={"50%"}
                src={howItWorks1}
                alt="..."
                sx={{
                  borderRadius: "15px",
                }}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("HowItWorks_1")}
              </Typography>
              <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                {t("HowItWorks_Text_1")}
              </Typography>
            </Box>
            <Box sx={{
              padding: "30px 0px",
              width: {xs:"100%", lg:"60"},
            }}>
              <Box
                component={LazyLoadImage}
                width={"55%"}
                height={"50%"}
                src={howItWorks2}
                alt="..."
                sx={{
                  borderRadius: "15px",
                }}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("HowItWorks_2")}
              </Typography>
              <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                {t("HowItWorks_Text_2")}
              </Typography>
            </Box>
            <Box sx={{
              padding: "30px 0px",
              width: {xs:"100%", lg:"60"},
            }}>
              <Box
                component={LazyLoadImage}
                width={"55%"}
                height={"50%"}
                src={howItWorks3}
                alt="..."
                sx={{
                  borderRadius: "15px",
                }}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("HowItWorks_3")}
              </Typography>
              <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                {t("HowItWorks_Text_3")}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    </RootStyle>
  );
}

function TypeItem(type) {
  return (
    <Box
      sx={{
        py: 3,
        pr: 2,
        pl: 2,
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Box sx={{ width: "40%" }}>
        <Image
          alt={type}
          src={`https://images.unsplash.com/photo-1491349174775-aaafddd81942?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80`}
          ratio="1/1"
        />
      </Box>
      <Box>
        <Stack spacing={1} flexGrow={1}>
          <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
            Office space
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              backgroundColor: "#ccc",
              padding: "2px 10px",
              borderRadius: "180px",
              textAlign: "center",
              fontWeight: "600 !important",
            }}
          >
            9,321 places
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
