import { Link as LinkReact, useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
// icons
import cubeIcon from '@iconify/icons-carbon/cube';
import eventsIcon from '@iconify/icons-carbon/events';
import packageIcon from '@iconify/icons-carbon/package';
import receiptIcon from '@iconify/icons-carbon/receipt';
import calendarIcon from '@iconify/icons-carbon/calendar';
import purchaseIcon from '@iconify/icons-carbon/purchase';
import chevronLeft from '@iconify/icons-carbon/chevron-left';
// @mui
import { Typography, Stack, Avatar, Divider, Button } from '@mui/material';
// @utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// components
import { RatingLabel, TextIconLabel, Iconify } from '../../../components';

/* import imageCheckout from '../../../assets/images/have-space.jpg'; */
import { useEffect, useState } from "react";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

TravelCheckOutCompleteInfo.propTypes = {
  tour: PropTypes.shape({
    price: PropTypes.number,
    ratings: PropTypes.number,
    reviews: PropTypes.number,
    slug: PropTypes.string,
    tourGuide: PropTypes.shape({
      name: PropTypes.string,
      picture: PropTypes.string,
    }),
  }),
};

export default function TravelCheckOutCompleteInfo({ workspace, booking }) {
  const { t } = useTranslation();
  const ratings = 3.5;
  const reviews = 350;

  const [searchParams, setSearchParams] = useSearchParams();

  const [priceSale, setPriceSale] = useState(booking.booking.price);
  const [guests, setGuests] = useState({
    adults: parseInt(booking.booking.amount),
    children: 0,
  });
  const totalGuests = guests.adults + guests.children;

  const [fromDate, setFromDate] = useState(booking.booking.from);
  const [toDate, setToDate] = useState(booking.booking.to);

  const days = booking.booking.days;


  return (
    <Stack spacing={5}>
      <Typography variant="h2">{t("Payment_succeeded")}</Typography>

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Stack spacing={2}>
          <Typography variant="h5">{workspace.title} - {days} {t("Days")}</Typography>
          <RatingLabel ratings={workspace?.data?.rating} reviews={workspace?.data?.allReviews.length || 0} />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Avatar src={booking.userWorkplace.profilepicture} />
          <div>
            <Typography variant="body3" sx={{ color: 'text.disabled' }}>
              Created by
            </Typography>
            <Typography variant="subtitle2">{booking.userWorkplace.first_name} {booking.userWorkplace.last_name}</Typography>
          </div>
        </Stack>
      </Stack>

      <Stack
        spacing={3}
        sx={{
          p: 5,
          borderRadius: 2,
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5">Booking Details</Typography>

        <LineItem
          icon={<Iconify icon={calendarIcon} />}
          label="From: "
          value={fDate(fromDate)}
        />
        <LineItem
          icon={<Iconify icon={calendarIcon} />}
          label="To: "
          value={fDate(toDate)}
        />
        <LineItem icon={<Iconify icon={eventsIcon} />} label={t("Guests")} value={totalGuests + " guest(s)"} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <LineItem icon={<Iconify icon={cubeIcon} />} label="Booking code" value={booking.booking.id.slice(0, 8)} />
        <LineItem icon={<Iconify icon={receiptIcon} />} label="Total" value={"€" + fCurrency(priceSale)} />
        <LineItem icon={<Iconify icon={purchaseIcon} />} label="Payment method" value={booking.paymentMethod.card.brand} />
      </Stack>

      <Stack spacing={2.5} direction={{ xs: 'column', md: 'row' }} justifyContent="center">
        <Button
          component={LinkReact}
          to="/"
          variant="outlined"
          size="large"
          color="inherit"
          startIcon={<Iconify icon={chevronLeft} />}
        >
          Back Home
        </Button>
        <Button variant="contained" size="large" startIcon={<Iconify icon={packageIcon} />}>
          {t("Download_Invoice")}
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

LineItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
};

function LineItem({ icon, label, value }) {
  return (
    <TextIconLabel
      icon={icon}
      value={
        <>
          {label}
          <Typography
            variant="subtitle2"
            sx={{ color: 'text.primary', flexGrow: 1, textAlign: 'right' }}
          >
            {value}
          </Typography>
        </>
      }
      sx={{
        color: 'text.disabled',
        '& svg': { mr: 1, width: 24, height: 24 },
      }}
    />
  );
}
