import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as LinkReact } from "react-router-dom";
import { useLocation } from 'react-router-dom'
// icons
import menuIcon from '@iconify/icons-carbon/menu';
import chevronRight from '@iconify/icons-carbon/chevron-right';
import chevronDown from '@iconify/icons-carbon/chevron-down';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Link,
  Stack,
  Button,
  Drawer,
  Collapse,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';

// components
import { Logo, Scrollbar, Iconify, NavSection } from '../../components';
import { IconButtonAnimate } from '../../components/animate';
import { useGlobalContext } from '../../stores/global';
import { useTranslation } from 'react-i18next';
import { logout } from '../../helpers/asyncActions';

// ----------------------------------------------------------------------

const RootLinkStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  ...(active && {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
}));

// ----------------------------------------------------------------------

NavMobile.propTypes = {
  navConfig: PropTypes.array.isRequired,
  sx: PropTypes.object,
};

export default function NavMobile({ navConfig, sx }) {
  const { t } = useTranslation();

  const location = useLocation();
  const { pathname } = location;
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { user, dispatch } = useGlobalContext();

  const logOutUser = () => {
    logout(dispatch);
  }

  return (
    <>
      <IconButtonAnimate color="inherit" onClick={handleDrawerOpen} sx={sx}>
        <Iconify icon={menuIcon} />
      </IconButtonAnimate>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        <Scrollbar>
          <Box sx={{ px: 2.5, py: 3, lineHeight: 0 }}>
            <Logo />
          </Box>

          <List sx={{ px: 0 }}>
            {navConfig.map((link) => (
              <NavItemMobile key={link.title} item={link} />
            ))}
          </List>
          <Divider />
          <Stack spacing={2} sx={{ p: 2.5, pb: 5 }}>
            {!user ? (
              <>
                <Button component={LinkReact} to={'/login'} fullWidth variant="outlined" color="inherit">
                  Login
                </Button>

                <Button
                  sx={{ background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)` }}
                  component={LinkReact}
                  to={'/signup'} fullWidth variant="contained" color="inherit">
                  JETZT REGISTRIEREN
                </Button>

              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {user.profilepicture &&
                    <img src={user.profilepicture} alt="profile photo" style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "35px",
                      width: "35px",
                      objectFit: "cover",
                      margin: "3px 10px",
                      borderRadius: "50px"
                    }} />}
                  {user.first_name} {user.last_name}
                </Box>
                <List sx={{ px: 0 }}>
                  <NavItemMobile item={{title:t('ProfileNavigation_MyProfile'), path:"/profile-general"}} />
                  <NavItemMobile item={{title:"Chat", path:"/chat"}} />
                  <NavItemMobile item={{title:t('ProfileNavigation_MyBookings'), path:"/myBookings"}} />
                  <NavItemMobile item={{title:t('Menu_MyFavorites'), path:"/myFavorites"}} />
                  <NavItemMobile item={{title:t('ProfileNavigation_MyWorkspaces'), path:"/host/workspaces"}} />
                  <NavItemMobile item={{title:t('ProfileNavigation_Help'), path:"/faq"}} />
                  <Button style={{ padding: "5px 20px" }} onClick={logOutUser}>Logout</Button>
                </List>
              </>
            )}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

// ----------------------------------------------------------------------

NavItemMobile.propTypes = {
  item: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

function NavItemMobile({ item }) {
  const location = useLocation();

  const { title, path, children } = item;
  const rootPath = location.pathname.split('/')[1];
  const isActiveRoot = location.pathname === path;
  const isActiveRootWithChild = location.pathname.includes(`/${rootPath}/`);

  const [open, setOpen] = useState(isActiveRootWithChild);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <Link component={LinkReact} key={title} to={path}>
      <RootLinkStyle active={isActiveRoot}>
        <ListItemText disableTypography primary={title} />
      </RootLinkStyle>
    </Link>
  );
}
