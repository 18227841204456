import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as LinkReact, useNavigate } from "react-router-dom";
// icons
import locationIcon from '@iconify/icons-carbon/location';
import user from '@iconify/icons-carbon/user';
// @mui
import { Typography, Stack, Link, Avatar, Divider, Button } from '@mui/material';
// components
import {
  Iconify,
  ShareButton,
  RatingLabel,
  TextIconLabel,
  FavoriteButton,
} from '../../../components';
import './TravelTourHeader.css';
import { Box } from '@mui/system';
import { useTranslation } from "react-i18next";
import verified from "../../../assets/images/check.svg";
import { tag_user } from '../../../mocks/tag_user';
import axios from 'axios';
import config from '../../../config';
import Frame_1024 from '../../../assets/images/Frame 1024.svg';
import Frame_1025 from '../../../assets/images/Frame 1025.svg';
import Frame_1026 from '../../../assets/images/Frame 1026.svg';
import Frame_1027 from '../../../assets/images/Frame 1027.svg';
import moment from 'moment';
import { tag_list } from '../../../mocks/tag_list';

import { OfficeDescriptionDetails } from '.';
import { fCurrency } from '../../../utils/formatNumber';
import Map from './Map';

// ----------------------------------------------------------------------

TravelTourHeader.propTypes = {
  tour: PropTypes.shape({
    favorited: PropTypes.bool,
    location: PropTypes.string,
    ratings: PropTypes.number,
    reviews: PropTypes.number,
    slug: PropTypes.string,
    tourGuide: PropTypes.shape({
      name: PropTypes.string,
      picture: PropTypes.string,
    }),
  }),
};

export default function TravelTourHeader({ tour, categories, tagsAmenities, createPreview }) {

  const { t } = useTranslation();
  const [tags, setTags] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    tag_user.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
    axios.get(`${config.api.host}/users/${tour.data.user.id}`).then(res => {
      let tags = tag_user.filter((item) => (tour.data.user.tag_user || "").includes(item.id));
      setTags(tags);
    });
  }, []);

  const [customTagsUser, setCustomTagsUser] = useState([])

  const getCustomTagsUser = () => {
    axios.get(`${config.api.host}/taglist/new_tag_user/${tour.data.user.id}`)
      .then(async res => {
        //filter by selected == true
        let tags = res.data.tagList.filter((item) => item.selected == true);
        setCustomTagsUser(tags)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getCustomTagsUser();
  }, [tour])

  const renderOpenHours = () => {
    //Get hours between to times
    let hours = [];
    //get hour between tour.data.openedMondayFrom and tour.data.openedMondayTo with moment
    let timeMondayFrom = moment(tour.data.openedMondayFrom, "HH-mm");
    let timeMondayTo = moment(tour.data.openedMondayTo, "HH-mm");
    //check if timeMondayTo is 00:00 and put 24:00
    if (timeMondayTo.format("HH:mm") == "00:00") {
      timeMondayTo = moment("24:00", "HH:mm");
    }
    //get hours between timeMondayFrom and timeMondayTo in the future
    let hourMonday = timeMondayTo.diff(timeMondayFrom, 'hours');
    if (tour.data.openedMonday)
      hours.push(hourMonday);
    //Tuesday
    let timeTuesdayFrom = moment(tour.data.openedTuesdayFrom, "HH-mm");
    let timeTuesdayTo = moment(tour.data.openedTuesdayTo, "HH-mm");
    if (timeTuesdayTo.format("HH:mm") == "00:00") {
      timeTuesdayTo = moment("24:00", "HH:mm");
    }
    let hourTuesday = timeTuesdayTo.diff(timeTuesdayFrom, 'hours');
    if (tour.data.openedTuesday)
      hours.push(hourTuesday);
    //Wednesday
    let timeWednesdayFrom = moment(tour.data.openedWednesdayFrom, "HH-mm");
    let timeWednesdayTo = moment(tour.data.openedWednesdayTo, "HH-mm");
    if (timeWednesdayTo.format("HH:mm") == "00:00") {
      timeWednesdayTo = moment("24:00", "HH:mm");
    }
    let hourWednesday = timeWednesdayTo.diff(timeWednesdayFrom, 'hours');
    if (tour.data.openedWednesday)
      hours.push(hourWednesday);
    //Thursday
    let timeThursdayFrom = moment(tour.data.openedThursdayFrom, "HH-mm");
    let timeThursdayTo = moment(tour.data.openedThursdayTo, "HH-mm");
    if (timeThursdayTo.format("HH:mm") == "00:00") {
      timeThursdayTo = moment("24:00", "HH:mm");
    }
    let hourThursday = timeThursdayTo.diff(timeThursdayFrom, 'hours');
    if (tour.data.openedThursday)
      hours.push(hourThursday);
    //Friday
    let timeFridayFrom = moment(tour.data.openedFridayFrom, "HH-mm");
    let timeFridayTo = moment(tour.data.openedFridayTo, "HH-mm");
    if (timeFridayTo.format("HH:mm") == "00:00") {
      timeFridayTo = moment("24:00", "HH:mm");
    }
    let hourFriday = timeFridayTo.diff(timeFridayFrom, 'hours');
    if (tour.data.openedFriday)
      hours.push(hourFriday);
    //Saturday
    let timeSaturdayFrom = moment(tour.data.openedSaturdayFrom, "HH-mm");
    let timeSaturdayTo = moment(tour.data.openedSaturdayTo, "HH-mm");
    if (timeSaturdayTo.format("HH:mm") == "00:00") {
      timeSaturdayTo = moment("24:00", "HH:mm");
    }
    let hourSaturday = timeSaturdayTo.diff(timeSaturdayFrom, 'hours');
    if (tour.data.openedSaturday)
      hours.push(hourSaturday);

    //Sunday
    let timeSundayFrom = moment(tour.data.openedSundayFrom, "HH-mm");
    let timeSundayTo = moment(tour.data.openedSundayTo, "HH-mm");
    if (timeSundayTo.format("HH:mm") == "00:00") {
      timeSundayTo = moment("24:00", "HH:mm");
    }
    let hourSunday = timeSundayTo.diff(timeSundayFrom, 'hours');
    if (tour.data.openedSunday)
      hours.push(hourSunday);

    //get max hour
    let maxHour = Math.max(...hours);
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          width: "50%",
        }}
      >
        <img src={Frame_1024} alt="" />
        <Typography variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5 }}>
          {maxHour} Hours Open
        </Typography>
      </Box>
    )
  }

  const renderSpecials = () => {
    let text = "";
    //search id 26 in tour.data.tag_list
    if (tour?.data?.tag_list) {
      if (tour.data.tag_list.includes("27")) {
        console.log("true");
        text = "Food";
      }
      //search id 27 in tour.data.tag_list
      if (tour.data.tag_list.includes("26")) {
        console.log("true");
        if (tour.data.tag_list.includes("27")) {
          text += ", Drinks";
        } else {
          text = "Drinks";
        }
      }


      //get tag_list category specials
      let ids = tag_list.filter((item) => item.category === "specials").map((item) => item.id);
      console.log(ids);
      //check if tour.data.tag_list includes ids
      let result = ids.filter((item) => tour.data.tag_list.includes(item));
      console.log(result);
      if (result.length > 0) {
        if (tour.data.tag_list.includes("26") || tour.data.tag_list.includes("27")) {
          text += " and Specials";
        } else {
          text = "Specials";
        }
      }

      if (text !== "") {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              width: "50%",
            }}
          >
            <img src={Frame_1025} alt="" />
            <Typography variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5 }}>
              {t('Workspace_includes_drinks')} {text}
            </Typography>
          </Box>
        )
      } else {
        return null;
      }
    }
  }

  const renderSecurity = () => {
    if (tour?.data?.tag_list) {
      let ids = tag_list.filter((item) => item.category === "security").map((item) => item.id);
      console.log(ids);
      //check if tour.data.tag_list includes ids
      let result = ids.filter((item) => tour.data.tag_list.includes(item));
      console.log(result);
      if (result.length > 0) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              width: "50%",
            }}
          >
            <img src={Frame_1026} alt="" />
            <Typography variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5 }}>
              Security
            </Typography>
          </Box>
        )
      } else {
        return null;
      }
    }
  }


  return (
    <>
      {!createPreview && (<Typography variant="h3" >
        {t('Workspace_includes')}
      </Typography>)}
      {createPreview && (
        (<><Typography sx={{ marginBottom: 1 }} variant="h3" >
          €{fCurrency(tour.data.price)} <span style={{ fontSize: "14px", color: "#777E90" }}>/day</span>
        </Typography>
          <Typography variant="h5" sx={{ marginBottom: !createPreview ? 3 : 1 }} >
            {tour.data.title}
          </Typography>
        </>
        ))}
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
        <Typography className="noWrapText" variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5 }}>
          {t("Local_WorkspaceOverview")}
        </Typography>

        <LinkReact to={'/user/' + tour.data.user.id}>
          <Avatar
            src={tour.data.user.profilepicture}
            sx={{ width: 28, height: 28 }}
          />
        </LinkReact>

        <Link component={LinkReact} to={'/user/' + tour.data.user.id}
          variant="subtitle2" color="inherit">
          {tour.data.user.first_name}
        </Link>
      </Stack>

      <Divider width={"100%"} sx={{ marginTop: !createPreview ? "5px" : "35px", marginBottom: !createPreview ? "5px" : "15px" }} />

      <Box sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        marginBottom: !createPreview ? 0 : 3
      }}>
        <TextIconLabel
          className="noWrapText"
          icon={
            <Iconify
              icon={locationIcon}
              sx={{ width: 20, height: 20, mr: 0.5 }}
            />
          }
          value={
            tour.data.user.total_workplaces + " workspace" + (tour.data.user.total_workplaces > 1 ? "s" : "")
          }
        />
        <TextIconLabel
          className="noWrapText"
          icon={
            <Iconify
              icon={user}
              sx={{ width: 20, height: 20, mr: 0.5 }}
            />
          }
          value={
            tour.data.amount + " traveler" + (tour.data.amount > 1 ? "s" : "")
          }
        />
      </Box>
      {createPreview && (<Typography variant="h3" >
        {t('WorkspaceDetails1')}
      </Typography>)}
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        mt: 5
      }}>
        {renderOpenHours()}
        {renderSpecials()}
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        mt: 5,
        mb: 5
      }}>
        {renderSecurity()}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "50%",
          }}
        >
          <img src={Frame_1027} alt="" />
          <Typography variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5 }}>
            {t('Workspace_Hosted')} {tour.data.user?.languages ? tour.data.user?.languages.split(",").map((item, index) => <span key={index}>{(index !== 0 ? ", " : "") + t(item)}</span>) : "Deutsch"}
          </Typography>
        </Box>
      </Box>

      <section>
        <Typography variant="h4" sx={{ mb: 3, color: '#23262F' }}>
          {t('Workspace_AboutUs')}
        </Typography>
        <Typography variant="body2" sx={{ color: '#777E91', /* wordBreak: "break-all" */ }}>
          {tour.data.description || "No description"}
        </Typography>
        {/*         <Button
          component={Link}
          to={`/user/${tour.data.user.id}`}
          variant="contained"
          sx={{
            mt: 5,
            color: 'black',
            bgcolor: 'common.white',
            border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
            borderRadius: 15,
            '&:hover': { bgcolor: 'grey.500' }
          }}
        >
          {t('Workspace_ShowMore_Button')}
        </Button> */}
      </section>

      <Divider width={"100%"}
        sx={{ marginBottom: "40px", marginTop: "40px" }} />


      <section>
        <OfficeDescriptionDetails
          workspaces={tour}
          tagListOffice={tagsAmenities}
          categories={categories}
        />
      </section>
      {!createPreview && (<section>
        <Divider width={"100%"}
          sx={{ marginBottom: "40px", marginTop: "20px" }} />

        <Map workplace={tour} />
      </section>)}

      <Divider width={"100%"}
        sx={{ marginBottom: "40px", marginTop: "20px" }} />

      {tags.length > 0 &&
        <>
          <Box sx={{ marginTop: "10px", marginBottom: "40px" }}>
            <Typography className="noWrapText" variant="body3" sx={{ fontSize: 16, color: '#777E91', mx: 0.5, my: 1 }}>
              {t("Categories_WorkspaceOverview")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
              {tags.map((item, index) => (
                <Button
                  onClick={() => { navigate(`/users/${item.id}`) }}
                  key={index}
                  sx={{
                    backgroundColor:
                      item.category === t("Category_FoodAndDrinks") ?
                        "#EAAA08" :
                        item.category === t("Category_Recreation") ?
                          "#669F2A" :
                          item.category === t("Category_Sports") ?
                            "#06AED4" :
                            "#4E5BA6",
                    color: "#FCFCFD",
                    borderRadius: "360px",
                    padding: "8px 15px",
                  }}>
                  {item.name}
                </Button>
              ))}
              {customTagsUser.map((item, index) => (
                <Button
                  key={index}
                  sx={{
                    backgroundColor:
                      item.category_name === "Category_FoodAndDrinks" ?
                        "#EAAA08" :
                        item.category_name === "Category_Recreation" ?
                          "#669F2A" :
                          item.category_name === "Category_Sports" ?
                            "#06AED4" :
                            "#4E5BA6",
                    color: "#FCFCFD",
                    borderRadius: "360px",
                    padding: "8px 15px",
                  }}>
                  {item.tag_name}
                </Button>
              ))}
            </Box>
          </Box>
        </>
      }




    </>
  );
}
