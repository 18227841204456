import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
// icons
import searchIcon from '@iconify/icons-carbon/search';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Button, Divider, TextField, Tab, Tabs, Box, Typography, InputAdornment } from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import { Iconify, Image } from '../../../components';
//
import TravelTourFilterTime from './TravelTourFilterTime';
import TravelTourFilterLocation from './TravelTourFilterLocation';
import TravelTourFilterGuests from './TravelTourFilterGuests';
import moment from 'moment';
import { DatePicker } from '@mui/lab';
import calendar from '../../../assets/images/calendar.svg';
import { useTranslation } from 'react-i18next';
import TravelTourFilterEssen from './TravelTourFilterEssen';
import "./calendar_double.css";
import calendarIcon from '@iconify/icons-carbon/calendar';
import DateRangePicker from 'react-bootstrap-daterangepicker';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

const BarStyle = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'onDark',
})(({ onDark, theme }) => ({
  width: '100%',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.neutral,
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    ...(onDark && {
      ...cssStyles().bgBlur({
        blur: 4,
        opacity: 1,
        color: theme.palette.common.white,
      }),
      boxShadow: "-16px 16px 56px -8px rgba(145, 158, 171, 0.16)",
      '& .MuiFilledInput-root': {
        color: "black",
        '& .MuiFilledInput-input': {
          '&::placeholder': {
            color: "#777E91",
            /* color: theme.palette.grey[600], */
          },
        },
        '& .MuiInputAdornment-root svg': {
          color: "black",
        },
      },
      '& .MuiInputLabel-root': {
        color: "black",
      },
      '& .MuiSvgIcon-root': {
        color: "black",
      },
    }),
  },
}));

export const InputStyle = styled((props) => <TextField fullWidth {...props} />)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    color: '#919EAB',
    fontWeight: 800,
    '&.Mui-focused, &:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input': {
      padding: 0,
      height: 48,
      /* fontWeight: 800, */
    },
    '& .MuiInputAdornment-root': {
      marginTop: '0 !important',
    },
  },
}));

// todo: fix inputs different sizes and colors

// ----------------------------------------------------------------------

TravelTourBarFilters.propTypes = {
  onDark: PropTypes.bool,
  sx: PropTypes.object,
};

export default function TravelTourBarFilters({ onDark = false, sx }) {
  const { t } = useTranslation();

  const [departureDay, setDepartureDay] = useState(null);

  const [selected, setSelected] = useState("All");

  const [index, setIndex] = useState(0);
  const [guests, setGuests] = useState({
    adults: 0,
    children: 0,
  });

  //useState of startDate and endDate
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [openS, setOpenS] = useState(false);
  const [openE, setOpenE] = useState(false);

  const categories = [
    `${t("MainSearch_All")}`,
    `${t("Landing_Category_Munich")}`,
    `${t("Landing_Category_Vienna")}`,
    `${t("Landing_Category_Switzerland")}`,
    /*     `Berlin`,
        `Frankfurt`,
        `Barcelona`,
        `Kitzbühel`, */
  ];

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
  };

  const [city, setCity] = useState("München,%20Munich,%20Bavaria%2081737,%20Germany");
  const handleChangeCity = (indexTab) => {
    setIndex(indexTab);
    let arrCities = [
      "München,%20Munich,%20Bavaria%2081737,%20Germany",
      "München,%20Munich,%20Bavaria%2081737,%20Germany",
      "Vienna,%20Austria&distance=10&lat=48.2081743&long=16.3738189",
      "Switzerland&distance=10&lat=46.818188&long=8.227512",
    ];
    setCity(arrCities[indexTab]);
  };
  const [type, setType] = useState("all");

  const [fromDate, setFromDate] = useState(moment().add(1, 'days').toDate());
  const [toDate, setToDate] = useState(moment().add(2, 'days').toDate());

  //message date error
  const [dateError, setDateError] = useState("");
  const handleEvent = (event, picker) => {
    //check if from date is before to date plus 1 day
    console.log("picker", picker);
    if (moment(picker.startDate._d.toISOString()).isAfter(moment())) {
      setFromDate(picker.startDate._d.toISOString());
      setToDate(picker.endDate._d.toISOString());
    } else {
      setDateError("The date must be after today");
    }
  };

  const [tags_user, setTagsUser] = useState("");
  const handleChangeTagsUser = (tags) => {
    console.log("tags", tags);
    setTagsUser(tags);
  };

  const [tags_workspace, setTagsWorkspace] = useState("");
  const handleChangeTagsWorkspace = (tags) => {
    console.log("tags", tags);
    setTagsWorkspace(tags);
  };


  const search = useLocation().search.replace("?", "");
  const dataQuery = search
    ? JSON.parse(
      '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
    )
    : "";

  useEffect(() => {
    //get from date
    if (dataQuery?.from) {
      let from = dataQuery?.from;
      from = moment(from, "DD.MM.YYYY").toDate();
      setFromDate(from);
    }

    //get to date
    if (dataQuery?.to) {
      let to = dataQuery?.to;
      to = moment(to, "DD.MM.YYYY").toDate();
      setToDate(to);
    }

    //get type
    if (dataQuery?.type) {
      console.warn("dataQuery.type", dataQuery.type);
      setType(dataQuery?.type);
    }

    //get city
    if (dataQuery?.address) {
      console.warn("dataQuery.address", dataQuery.address);
      //replace space with %20
      let address = dataQuery?.address.replace(/ /g, "%20");
      console.warn("address", address);
      //search index of city
      let arrCities = [
        "München,%20Munich,%20Bavaria%2081737,%20Germany",
        "München,%20Munich,%20Bavaria%2081737,%20Germany",
        "Vienna,%20Austria&distance=10&lat=48.2081743&long=16.3738189",
        "Switzerland&distance=10&lat=46.818188&long=8.227512",
      ];
      let index = arrCities.findIndex((item) => item.includes(address));
      console.warn("index", index);
      setIndex(index);
      setCity(address);
    }

    //get tags
    if (dataQuery?.tags_user) {
      console.warn("dataQuery.tags", dataQuery.tags_user);
      setTagsUser(dataQuery?.tags_user);
    }

    if (dataQuery?.tags_workspace) {
      console.warn("dataQuery.tags", dataQuery.tags_workspace);
      setTagsWorkspace(dataQuery?.tags_workspace);
    }

    //get guests
    if (dataQuery?.guests) {
      console.log("dataQuery.guests", parseInt(dataQuery?.guests));
      setGuests({
        adults: parseInt(dataQuery?.guests),
        children: 0,
      });
    }
  }, []);

  return (
    <RootStyle sx={sx}>
      <BarStyle
        onDark={onDark}
        spacing={2.5}
      >
        <Box
          sx={{
            pt: 0,
            pb: { xs: 0, md: 0 },
            mb: { xs: 0, md: 3, xxl: 6 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={selected}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            TabIndicatorProps={{ style: { backgroundColor: "#353945" } }}
            onChange={handleChangeCategory}
          >
            {categories.map((category, indexTab) => (
              <Tab
                sx={{
                  color:
                    index === indexTab
                      ? "#353945 !important"
                      : "#777E91 !important",
                  borderRadius: index === indexTab ? "15px" : "0px",
                  marginRight: "25px !important",
                  paddingX: index === indexTab ? "20px !important" : "0px !important",
                  fontSize: { xs: "0.875rem", xxl: "1.6rem" },
                }}
                onClick={() => handleChangeCity(indexTab)}
                key={category}
                value={category}
                label={category}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}>
          <Stack
            spacing={2.5}
            sx={{ width: 1 }}
            alignItems="flex-start"
            direction={{ xs: 'column', md: 'row' }}
          >
            <TravelTourFilterLocation setType={setType} type={type} />
            <TravelTourFilterEssen tags_user={tags_user} handleChangeTagsUser={handleChangeTagsUser} tags_workspace={tags_workspace} handleChangeTagsWorkplace={handleChangeTagsWorkspace} />
            <Box display="flex" alignItems="flex-start" justifyContent="center" gap={1} sx={{ width: "100% !important" }}>
              <Box>
                <Image alt={"calendar"} src={calendar} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography fontSize={{ xs: "auto", xxl: "1.6rem" }} sx={{ fontWeight: 800, lineHeight: '1.1', px: "12px" }} > {t('MainSearch_When2')} </Typography>

                <DateRangePicker
                  alwaysShowCalendars={true}
                  onEvent={handleEvent}
                  initialSettings={{
                    startDate: fromDate,
                    endDate: toDate,
                    isInvalidDate: function (date) {
                      return date.isBefore(moment().add(1, 'days'), 'day');
                    },
                    locale: {
                      format: "DD.MM.YYYY",
                      separator: " - ",
                      applyLabel: t("Apply"),
                      cancelLabel: t("Cancel"),
                      fromLabel: "From",
                      toLabel: "To",
                      customRangeLabel: "Custom",
                      weekLabel: "W",
                      daysOfWeek: [t("ShortWeek_Su"), t("ShortWeek_Mo"), t("ShortWeek_Tu"), t("ShortWeek_We"), t("ShortWeek_Tu"), t("ShortWeek_Fr"), t("ShortWeek_Sa")],
                      monthNames: [
                        t("Months_Jan"),
                        t("Months_Feb"),
                        t("Months_Mar"),
                        t("Months_Apr"),
                        t("Months_May"),
                        t("Months_Jun"),
                        t("Months_Jul"),
                        t("Months_Aug"),
                        t("Months_Sep"),
                        t("Months_Oct"),
                        t("Months_Nov"),
                        t("Months_Dec"),
                      ],
                      firstDay: 1,
                    },
                  }}
                >
                  <Box sx={{ width: 1 }}>
                    <Button sx={{
                      backgroundColor: "transparent",
                      border: 0,
                      padding: "10px",
                      color: '#777E91',
                      fontWeight: 400,
                      fontSize: '14px',
                      '&:hover': { backgroundColor: 'transparent', },
                    }}> {moment(fromDate).format("DD.MM.YYYY")} - {moment(toDate).format("DD.MM.YYYY")}

                    </Button>
                  </Box>
                </DateRangePicker >
              </Box>
            </Box>
            <TravelTourFilterGuests guests={guests} setGuests={setGuests} />
          </Stack>

          <Button
            size="large"
            color="primary"
            variant="contained"
            component={Link}
            to={`/search?address=${city}&from=${moment(fromDate).format('DD.MM.YYYY')}&to=${moment(toDate).format('DD.MM.YYYY')}&min=1&max=250&type=${type}&tags_user=${tags_user}&tags_workspace=${tags_workspace}&guests=${guests.adults}`}
            sx={{
              px: 0,
              flexShrink: 0,
              minWidth: { xs: 1, md: 48 },
              borderRadius: "360px",
            }}
          >
            <Iconify icon={searchIcon} sx={{ width: 20, height: 20 }} />
          </Button>
        </Box>
      </BarStyle>
    </RootStyle >
  );
}