
let tag_user = [
  { id: '101', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Vegetarian' },

  { id: '102', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_BBQ', },

  { id: '103', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Coffee', },

  { id: '104', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie', 'night owl', 'collector'], name: 'Tag_Vino', },

  { id: '105', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Sushi', },

  { id: '106', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie', 'vegan', 'yogi'], name: 'Tag_Vegan', },

  { id: '107', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Burger', },

  { id: '108', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Asian', },

  { id: '109', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Tapas', },

  { id: '110', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie', 'night owl'], name: 'Tag_Beer', },

  { id: '111', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie', 'night owl'], name: 'Tag_Cocktails', },

  { id: '112', entity: 'person', type: 'interests', title: 'NewCategory_Profile_Tags', category: 'Category_FoodAndDrinks', persona: ['foodie'], name: 'Tag_Pizza', },

  { id: '125', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['culture lover', 'collector'], name: 'Tag_Art', },

  { id: '126', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['culture lover', 'explorer'], name: 'Tag_Travels', },

  { id: '127', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['culture lover'], name: 'Tag_Music', },

  { id: '128', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['culture lover', 'collector'], name: 'Tag_Books', },

  { id: '129', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['fashionista', 'collector'], name: 'Tag_Fashion', },

  { id: '130', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['explorer', 'action hero'], name: 'Tag_Outdoor', },

  { id: '131', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['night owl'], name: 'Tag_Nightlife', },

  { id: '132', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['gamer'], name: 'Tag_Gaming', },

  { id: '133', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['night owl'], name: 'Tag_Bars', },

  { id: '134', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['culture lover'], name: 'Tag_Cinema', },

  { id: '135', entity: 'person', type: 'interests', title: 'NewCategory_Recreation_Tags', category: 'Category_Recreation', persona: ['collector'], name: 'Tag_Cars', },

  { id: '150', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero', 'explorer'], name: 'Tag_Ski', },

  { id: '151', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero', 'explorer'], name: 'Tag_Snowboard', },

  { id: '152', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero', 'explorer'], name: 'Tag_Climbing', },

  { id: '153', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['yogi', 'vegan', 'athlete'], name: 'Tag_Yoga', },

  { id: '154', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero'], name: 'Tag_Boxing', },

  { id: '155', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_Basketball', },

  { id: '156', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_Fitness', },

  { id: '157', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero'], name: 'Tag_Skateboard', },

  { id: '158', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero'], name: 'Tag_Cycling', },

  { id: '159', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'action hero'], name: 'Tag_Surfing', },

  { id: '160', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_Football', },

  { id: '161', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_Volleyball', },

  { id: '162', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete', 'explorer'], name: 'Tag_Hiking', },

  { id: '163', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_Swimming', },

  { id: '164', entity: 'person', type: 'interests', title: 'NewCategory_Sports_Tags', category: 'Category_Sports', persona: ['athlete'], name: 'Tag_PingPong', },

  { id: '175', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Crypto', },

  { id: '176', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer', 'philosopher'], name: 'Tag_Language', },

  { id: '177', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Software', },

  { id: '178', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Tool', },

  { id: '179', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Presentation', },

  { id: '180', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Strategy', },

  { id: '181', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Startup', },

  { id: '182', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Investment', },

  { id: '183', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_Management', },

  { id: '184', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer', 'philosopher'], name: 'Tag_Technology', },

  { id: '185', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['philosopher'], name: 'Tag_Philosophy', },

  { id: '186', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['philosopher'], name: 'Tag_Politics', },

  { id: '187', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['philosopher'], name: 'Tag_Society', },

  { id: '188', entity: 'person', type: 'interests', title: 'NewCategory_Expert_Tags', category: 'Category_Skills', persona: ['high performer'], name: 'Tag_SocialMedia', }

];

export { tag_user };