//create component with multiple steps for complete data before register

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../stores/global";
import { styled } from '@mui/material/styles';
import { Iconify, Page } from "../../../components";
import { Autocomplete, Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, Divider } from "@mui/material";
import CheckRegister from "../../../assets/images/CheckRegister.svg";
import AuthCarousel from "../AuthCarousel";
import CompleteCarousel from "./components/completeCarousel";
import CompleteStepper from "./components/completeStepper";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import briefcase from "../../../assets/images/briefcase-02.svg";
import Arrow_card from "../../../assets/images/Arrow_card.svg";
import searchRefraction from "../../../assets/images/search-refraction.svg";
import { tag_list } from "../../../mocks/tag_list";
import stripe from './../../../assets/images/Stripe_Logo.svg';
import camera from './../../../assets/images/onboarding/camera.png';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Image, SearchNotFound } from "../../../components";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { tag_user } from "../../../mocks/tag_user";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../../config";
import { useRef } from "react";


const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        overflow: 'hidden',
        height: '100vh',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 480,
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan, Province of China',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

const CompleteRegister = () => {
    //get step of param
    const { step } = useParams();
    //get user from global context
    const { user, dispatch } = useGlobalContext();
    //set state for step
    const [currentStep, setCurrentStep] = useState(step);

    useEffect(() => {
        setCurrentStep(step);
    }, [step]);

    const [location, setLocation] = useState(user?.country ? { label: user.country } : { label: "Germany" });
    const handleChangeLocation = (keyword) => {
        setLocation(keyword);
    };



    console.log(user);
    const renderStep = (step) => {
        console.log(step);
        switch (step) {
            case '1':
                return renderStep1();
                break;
            case '2':
                return renderStep2();
                break;
            case '3':
                return renderStep3();
                break;
            case '4':
                return renderStep4();
                break;
            case '5':
                return renderStep5();
                break;
            case '6':
                return renderStep6();
                break;
            default:
                break;
        }
    }

    //navigate
    const navigate = useNavigate();

    const renderStep1 = () => {
        //render step 1
        //put card with message to complete register and button to next step in the center of the page
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', backgroundColor: '#F5F5F5' }}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        width: '100%',
                        maxWidth: 600,
                        p: 5,
                        mx: { xs: 2, sm: 0 },
                    }}
                >
                    <img src={CheckRegister} alt="CheckRegister" />
                    <Typography
                        sx={{ fontSize: "12px", color: "#202020", mb: 2, mt: 4 }}>
                        {t('Onboarding_Start_SmallHeadline')}
                    </Typography>
                    <Typography variant="h4" fontWeight={"bold"} paragraph sx={{ mb: 2 }}>
                        {t('Onboarding_Start_1')}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#777E91', mb: 7, px: { xs: 2, md: 12 } }}>
                        {t('Onboarding_Start_2')}
                    </Typography>
                    <Button
                        sx={{
                            background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
                            padding: '10px 30%',
                            textTransform: "none",
                        }}
                        component={Link} variant="contained" to={"/complete-register/2"} rel="noopener">
                        {t('Onboarding_Start_Button')}
                    </Button>

                    <Divider sx={{ py: 3 }}>
                        <Typography variant="body2" sx={{ color: '#5A5A5A' }}>
                            {t('Or')}
                        </Typography>
                    </Divider>

                    <Button
                        variant="outlined"
                        sx={{
                            /* background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`, */
                            padding: '10px 29%',
                            marginBottom: '10px',
                            textTransform: "none",
                        }}
                        component={Link} to={"/profile-general"} rel="noopener">
                        {t('WithoutOnboarding')}
                    </Button>

                </Card>
            </Box>
        );
    }

    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [userType, setUserType] = useState(0);
    const renderStep2 = () => {
        //render step 2
        //put card with message to complete register and button to next step in the center of the page
        return (
            <>
                <CompleteCarousel /* title={`Manage The Job \n More Effectively`} */ />
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '70%' },
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 5,
                        mt: "8%",
                        height: '100vh',
                    }}
                >
                    <Box sx={{ width: { xs: "90%", md: "50%" }, mt: { xs: 12, md: 0 } }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 40,
                            }}
                        >
                            <Typography display="inline" fontSize={{ xs: 12, md: 16 }} fontWeight={"400"} paragraph sx={{ mb: 2 }}>
                                {t('New_Onboarding_Cancel1')}&nbsp;
                                <Typography component={Link} to="/" fontSize={{ xs: 12, md: 16 }} display="inline" fontWeight={"400"} paragraph sx={{ color: '#EF4F5B' }}>
                                    {t('New_Onboarding_Cancel2')}
                                </Typography>
                            </Typography>
                        </Box>
                        <Typography variant="h5" fontWeight={"bold"} paragraph
                            sx={{ mb: 2 }}>
                            {t('New_Onboarding_Step1_Headline')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                            {t('New_Onboarding_Step1_Subline')}
                        </Typography>

                        <Box
                            onMouseEnter={() => setHover1(true)}
                            onMouseLeave={() => setHover1(false)}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mt: 2,
                                gap: 5,
                                border: '2px solid #E6E8EC',
                                pr: 2,
                                pl: 4,
                                py: 2,
                                borderRadius: '10px',
                                cursor: 'pointer',
                                //remove a style
                                color: '#202020',
                            }}
                            onClick={() => setUserType(0)}
                            component={Link} to={"/complete-register/3"}
                        >
                            <img src={briefcase} alt="briefcase" style={{ width: '40px', height: '40px' }} />
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 5,
                                }}
                            >
                                <Box sx={{ mr: 0 }}>
                                    <Typography fontWeight={"bold"} paragraph sx={{ mb: 0 }}>
                                        {t('New_Onboarding_Step1_Option1')}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#777E91' }}>
                                        {t('New_Onboarding_Step1_Option1_Subline')}
                                    </Typography>
                                </Box>
                                <img src={Arrow_card} alt="arrow" style={{
                                    width: '50px',
                                    height: '50px',
                                    paddingRight: '10px',
                                    display: hover1 ? 'block' : 'none',
                                }} />
                            </Box>
                        </Box>

                        <Box
                            onMouseEnter={() => setHover2(true)}
                            onMouseLeave={() => setHover2(false)}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mt: 2,
                                gap: 5,
                                border: '2px solid #E6E8EC',
                                pr: 2,
                                pl: 4,
                                py: 2,
                                borderRadius: '10px',
                                cursor: 'pointer',
                                //remove a style
                                color: '#202020',
                            }}
                            onClick={() => setUserType(1)}
                            component={Link} to={"/complete-register/3"}
                        >
                            <img src={searchRefraction} alt="briefcase"
                                style={{ width: '40px', height: '40px' }} />
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 5,
                                }}
                            >
                                <Box sx={{ mr: 0 }}>
                                    <Typography fontWeight={"bold"} paragraph sx={{ mb: 0 }}>
                                        {t('New_Onboarding_Step1_Option2')}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#777E91' }}>
                                        {t('New_Onboarding_Step1_Option2_Subline')}
                                    </Typography>
                                </Box>
                                <img src={Arrow_card} alt="arrow" style={{
                                    width: '50px',
                                    height: '50px',
                                    paddingRight: '10px',
                                    display: hover2 ? 'block' : 'none',
                                }} />
                            </Box>
                        </Box>

                        {/*create footer in bottom of div*/}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: "5%",
                                right: { xs: "15%", md: "35%" },
                                left: { xs: "15%", md: "35%" },
                            }}
                        >
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                                {t('New_Onboarding_Nr1')}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={20}
                                sx={{
                                    mb: 4,
                                    '& .MuiLinearProgress-bar1Determinate': {
                                        background: 'linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)',
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                <Button
                                    sx={{
                                        background: `white`,
                                        color: 'black',
                                        padding: '10px 10%',
                                        border: '1px solid #777E91',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/1"} rel="noopener">
                                    {t('Button_GoBack')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }

    //states step 3
    const [phone_mobile, setPhone_mobile] = useState(user?.phone_mobile || '');
    const [city, setCity] = useState(user?.city || '');
    const [description, setDescription] = useState(user?.description || '');
    const [job, setJob] = useState(user?.job || '');
    const [company, setCompany] = useState(user?.company || '');
    //submit form 3
    const onSubmit3 = (data) => {
        let body = {
            phone_mobile,
            city,
            description,
            job,
            company,
        };
        body.country = location?.label || "Germany";
        axios.defaults.headers.common = {
            Authorization: user.token,
        };
        axios
            .put(`${config.api.host}/users`,
                body
            )
            .then(async (res) => {
                navigate("/complete-register/4");
            })
            .catch((error) => {
                alert(error);
            });
    };

    const renderStep3 = () => {
        //render step 3
        //put card with message to complete register and button to next step in the center of the page
        return (
            <>
                <CompleteStepper step={1} />
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '70%' },
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 5,
                        mt: "8%",
                        height: '100vh',
                    }}
                >
                    <Box
                        sx={{ width: { xs: "90%", md: "50%" }, pl: { xs: 0, md: 5 }, mt: { xs: 12, md: 0 } }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 40,
                            }}
                        >
                            <Typography display="inline" fontSize={{ xs: 12, md: 16 }} fontWeight={"400"} paragraph sx={{ mb: 2 }}>
                                {t('New_Onboarding_Cancel1')}&nbsp;
                                <Typography component={Link} to="/" fontSize={{ xs: 12, md: 16 }} display="inline" fontWeight={"400"} paragraph sx={{ color: '#EF4F5B' }}>
                                    {t('New_Onboarding_Cancel2')}
                                </Typography>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "block",
                                padding: "15px",
                                overflowY: "scroll",
                                height: "450px",
                                overflowX: "hidden",
                                //change scrollbar
                                '&::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    outline: '1px solid slategrey'
                                }
                            }}
                        >
                            <Typography variant="h4" fontWeight={"bold"} paragraph
                                sx={{ mb: 1 }}>
                                {t('New_Onboarding_Step2_Headline')}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 1 }}>
                                {t('New_Onboarding_Step2_Subline')}
                            </Typography>
                            <Box>
                                <TextField
                                    fullWidth
                                    label={t('New_Onboarding_Phone')}
                                    margin="normal"
                                    name="phone"
                                    type="text"
                                    value={phone_mobile}
                                    onChange={(e) => setPhone_mobile(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 1 }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 2,
                                        mb: 1
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Beruf"
                                        margin="normal"
                                        name="beruf"
                                        type="text"
                                        value={job}
                                        onChange={(e) => setJob(e.target.value)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Firma"
                                        margin="normal"
                                        name="lastName"
                                        type="text"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 2,
                                        mb: 1
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Stadt"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        variant="outlined"
                                    />
                                    <Autocomplete
                                        fullWidth
                                        autoHighlight
                                        options={countries}
                                        getOptionLabel={(option) => option.label}
                                        filterOptions={createFilterOptions({
                                            stringify: (option) => option.label + option.code,
                                        })}
                                        value={location}
                                        onChange={(event, value) => handleChangeLocation(value)}
                                        noOptionsText={<SearchNotFound keyword={location?.label} />}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                placeholder=""
                                                InputProps={{
                                                    ...params.InputProps,
                                                    autoComplete: 'search',
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option.label, inputValue);
                                            const parts = parse(option.label, matches);
                                            return (
                                                <Box component="li" {...props}>
                                                    <Image
                                                        alt="flag country"
                                                        src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                                                        sx={{
                                                            mr: 1,
                                                            width: 24,
                                                            height: 24,
                                                            flexShrink: 0,
                                                            borderRadius: '50%',
                                                        }}
                                                    />

                                                    {parts.map((part, index) => (
                                                        <Box
                                                            key={index}
                                                            component="span"
                                                            sx={{
                                                                ...(part.highlight && {
                                                                    fontWeight: 'fontWeightBold',
                                                                }),
                                                            }}
                                                        >
                                                            {part.text}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            );
                                        }}
                                    />
                                </Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={5}
                                    label="Erzähle uns was von dir"
                                    margin="normal"
                                    name="lastName"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />

                            </Box>

                        </Box>
                        {/*create footer in bottom of div*/}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: "5%",
                                right: { xs: "15%", md: "35%" },
                                left: { xs: "15%", md: "35%" },
                            }}
                        >
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                                {t('New_Onboarding_Nr2')}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={40}
                                sx={{
                                    mb: 4,
                                    '& .MuiLinearProgress-bar1Determinate': {
                                        background: 'linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)',
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                <Button
                                    sx={{
                                        background: `white`,
                                        color: 'black',
                                        padding: '10px 10%',
                                        border: '1px solid #777E91',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/2"} rel="noopener">
                                    {t('Button_GoBack')}
                                </Button>
                                <Button
                                    sx={{
                                        background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
                                        padding: '10px 10%',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    onClick={onSubmit3}
                                    variant="contained" rel="noopener">
                                    {t('Create_Workspace_ButtonNext')}
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </>
        );
    }

    const imgRef = useRef(null);
    const openFile = () => {
        document.getElementById('file').click();
    }

    function beforeUpload(file) {
        const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
        if (!isJpgOrPngOrSvg) {
            alert('Es können nur JPG/PNG/SVG-Dateien hochgeladen werden');
        }
        const isLt2M = file.size < 5000000;
        if (!isLt2M) {
            alert('Das Bild muss kleiner als 2MB sein!');
        }
        return isJpgOrPngOrSvg && isLt2M;
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const changeImageFile = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        if (beforeUpload(e.target.files[0])) {
            getBase64(e.target.files[0], img => {
                axios.defaults.headers.common = {
                    Authorization: user.token,
                };
                axios
                    .put(`${config.api.host}/users`,
                        { profilepicture: img },
                    )
                    .then(async (res) => {
                        console.log("changeImageFile", e);
                        const file = e.target.files[0];
                        //convert file to url to display image
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            imgRef.current.src = reader.result;
                        }
                        reader.readAsDataURL(file);
                    })
                    .catch((error) => {
                        alert("Änderungen konnten nicht gespeichert werden");
                    });
            });
        }
    }

    const dropFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.files.length === 0) {
            return;
        }

        console.log(e.dataTransfer.files.length);
        console.log(e.dataTransfer.files[0]);
        if (beforeUpload(e.dataTransfer.files[0])) {
            getBase64(e.dataTransfer.files[0], img => {
                axios.defaults.headers.common = {
                    Authorization: user.token,
                };
                axios
                    .put(`${config.api.host}/users`,
                        { profilepicture: img },
                    )
                    .then(async (res) => {
                        imgRef.current.src = img;
                    })
                    .catch((error) => {
                        console.log(error);
                        alert("Änderungen konnten nicht gespeichert werden");
                    });
            });
        }
    }



    const renderStep4 = () => {
        //render step 4
        //put card with message to complete register and button to next step in the center of the page
        return (
            <>
                <CompleteStepper step={2} />
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '70%' },
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 5,
                        mt: "8%",
                        height: '100vh',
                    }}
                >
                    <Box sx={{ width: { xs: "90%", md: "50%" }, mt: { xs: 12, md: 0 }, pl: { sx: 0, md: 10 } }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 40,
                            }}
                        >
                            <Typography display="inline" fontSize={{ xs: 12, md: 16 }} fontWeight={"400"} paragraph sx={{ mb: 2 }}>
                                {t('New_Onboarding_Cancel1')}&nbsp;
                                <Typography component={Link} to="/" fontSize={{ xs: 12, md: 16 }} display="inline" fontWeight={"400"} paragraph sx={{ color: '#EF4F5B' }}>
                                    {t('New_Onboarding_Cancel2')}
                                </Typography>
                            </Typography>
                        </Box>
                        <Typography variant="h4" fontWeight={"bold"} paragraph
                            sx={{ mb: 1 }}>
                            {t('New_Onboarding_Step3_Headline')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#777E91', mb: 1 }}>
                            {t('New_Onboarding_Step3_Subline')}
                        </Typography>
                        <Box
                            sx={{
                                background: '#F9F9F9',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                p: 3,
                            }}
                        >
                            <div onDragEnter={dropFile}
                                onDrop={dropFile}
                                onDragOver={dropFile}
                                onClick={() => openFile()}>
                                <img ref={imgRef} src={camera} alt="userImage" style={{
                                    width: '80px',
                                    height: '80px',
                                    objectFit: 'cover',
                                    borderRadius: '360px',
                                    cursor: 'pointer',
                                }} />
                            </div>
                            <Box>
                                <Typography sx={{ color: '#202020', fontWeight: "bold" }} >
                                    {user.first_name} {user.last_name}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 1, color: '#898989' }}>
                                    {job} {t('OfferCreaton_At')} {company}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '30%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <input onChange={changeImageFile} type="file" id="file" style={{ display: 'none' }} />
                            <Button
                                variant="contained"
                                onClick={() => openFile()}
                                sx={{
                                    padding: '10px 34%',
                                    marginBottom: '10px',
                                    borderRadius: '15px',
                                    color: 'white',
                                }}
                            >
                                {t('New_Onboarding_Step3_AddPhoto')}
                            </Button>
                        </Box>

                        {/*create footer in bottom of div*/}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: "5%",
                                right: { xs: "15%", md: "35%" },
                                left: { xs: "15%", md: "35%" },
                            }}
                        >
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                                {t('New_Onboarding_Nr3')}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={60}
                                sx={{
                                    mb: 4,
                                    '& .MuiLinearProgress-bar1Determinate': {
                                        background: 'linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)',
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                <Button
                                    sx={{
                                        background: `white`,
                                        color: 'black',
                                        padding: '10px 10%',
                                        border: '1px solid #777E91',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/3"} rel="noopener">
                                    {t('Button_GoBack')}
                                </Button>
                                <Button
                                    sx={{
                                        background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
                                        padding: '10px 10%',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/5"} rel="noopener">
                                    {t('Create_Workspace_ButtonNext')}
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </>
        );
    }

    const [tags, setTags] = useState([])

    const [categories, setCategories] = useState([])
    const [categoriesKey, setCategoriesKey] = useState([])
    const [titles, setTitles] = useState([])
    const [customTagsUser, setCustomTagsUser] = useState([])

    const getCustomTagsUser = () => {
        axios.defaults.headers.common = {
            Authorization: user.token,
        };
        axios.get(`${config.api.host}/taglist/new_tag_user/${user.id}`)
            .then(async res => {
                setCustomTagsUser(res.data.tagList)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        tag_user.map((item) => {
            item._t_category = t(item.category);
            item.name = t(item.name);
            item.title = t(item.title);
        });

        let tags = tag_user.filter((item) => item.category);

        let categoriesKey = tags.map((item, index) => {
            if ((user.tag_user || "").includes(item.id)) {
                tags[index].mainActive = true;
            }
            return item.category;
        });

        let categories = tags.map((item, index) => {
            if ((user.tag_user || "").includes(item.id)) {
                tags[index].mainActive = true;
            }
            return item._t_category;
        });

        let titles = tags.map((item, index) => {
            return item.title;
        });

        setTags(tags);
        categories = categories.filter(
            (item, index) => categories.indexOf(item) === index
        );
        titles = titles.filter(
            (item, index) => titles.indexOf(item) === index
        );
        categoriesKey = categoriesKey.filter(
            (item, index) => categoriesKey.indexOf(item) === index
        );
        console.log("CATEGORIES KEY", categoriesKey);
        setCategories(categories);
        setTitles(titles);
        setCategoriesKey(categoriesKey);
    }, [])

    const { t } = useTranslation();

    const setChip = (index) => {
        let mainActive = tags.filter((tag) => tag.mainActive);
        let newArr = [...tags]; // copying the old datas array
        if (!newArr[index].mainActive) {
            newArr[index].mainActive = true; // replace e.target.value with whatever you want to change it to
            setTags(newArr);
        } else {
            if (newArr[index].mainActive) {
                newArr[index].mainActive = false; // replace e.target.value with whatever you want to change it to
                setTags(newArr);
            } else {
                //setOpen(true);
                //setMsg("Sie können nur fünfzehn auswählen");
            }
        }
    };

    const updateCustomTags = (id_tag) => {
        axios.defaults.headers.common = {
            Authorization: user.token,
        };
        axios.put(`${config.api.host}/taglist/new_tag_user/${id_tag}`)
            .then(async res => {
                //search in customTagsUser and update with negate select and update customTagsUser
                let newCustomTagsUser = customTagsUser.map((tag) => {
                    if (tag.id === id_tag) {
                        tag.selected = !tag.selected
                    }
                    return tag
                })
                setCustomTagsUser(newCustomTagsUser)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const [addNewTagUserModal, setAddNewTagUserModal] = useState(false);

    const handleCloseNewTagUserModal = () => {
        setAddNewTagUserModal(false);
    };

    const [selectedCategory, setSelectedCategory] = useState(null);
    const handleOpenNewTagUserModal = (category) => {
        setAddNewTagUserModal(true);
        console.log(category);
        setSelectedCategory(category)
    };

    const handleAddNewTagUserModal = (tag) => {
        let body = {
            user_id: user.id,
            tag_name: tag,
            category_name: selectedCategory,
            selected: true
        };
        axios.defaults.headers.common = {
            Authorization: user.token,
        };

        axios
            .post(`${config.api.host}/taglist/new_tag_user/`, body)
            .then(async (res) => {
                //refecth tags
                getCustomTagsUser();
                setAddNewTagUserModal(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onSubmit5 = (data) => {
        let body = {}
        let active = tags.filter((tag) => tag.mainActive);
        if (active.length !== 0) {
            body["tag_user"] = tags
                .filter((tag) => tag.mainActive)
                .map((tag) => tag.id)
                .toString()
        }
        axios.defaults.headers.common = {
            Authorization: user.token,
        };
        axios
            .put(`${config.api.host}/users`,
                body
            )
            .then(async (res) => {
                navigate("/complete-register/6")
            })
            .catch((error) => {
                alert("Änderungen konnten nicht gespeichert werden");
            });
    };

    const renderStep5 = () => {
        //render step 3
        //put card with message to complete register and button to next step in the center of the page
        return (
            <>
                <CompleteStepper step={3} />
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '70%' },
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 5,
                        mt: "8%",
                        height: '100vh',
                    }}
                >
                    <Box sx={{ width: { xs: "90%", md: "50%" }, mt: { xs: 12, md: 0 }, pl: { sx: 0, md: 5 } }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 40,
                            }}
                        >
                            <Typography display="inline" fontSize={{ xs: 12, md: 16 }} fontWeight={"400"} paragraph sx={{ mb: 2 }}>
                                {t('New_Onboarding_Cancel1')}&nbsp;
                                <Typography component={Link} to="/" fontSize={{ xs: 12, md: 16 }} display="inline" fontWeight={"400"} paragraph sx={{ color: '#EF4F5B' }}>
                                    {t('New_Onboarding_Cancel2')}
                                </Typography>
                            </Typography>
                        </Box>
                        <Typography variant="h4" fontWeight={"bold"} paragraph
                            sx={{ mb: 1 }}>
                            {t('New_Onboarding_Step4_Headline')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#777E91', mb: 1 }}>
                            {t('New_Onboarding_Step4_Subline')}
                        </Typography>
                        <Box
                            sx={{
                                display: "block",
                                marginTop: "25px",
                                padding: "15px",
                                overflowY: "scroll",
                                height: { xs: "360px", md: "290px", lg: "320px", xl: "320px" },
                                overflowX: "hidden",
                                //change scrollbar
                                '&::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    outline: '1px solid slategrey'
                                }
                            }}
                        >
                            {categories.map((category, index) => {
                                return (
                                    <div key={index}>
                                        <span style={{ fontSize: "1.2rem", color: "#000" }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginTop: 2,
                                                    marginBottom: 2,
                                                }}
                                            >
                                                {titles[index]}
                                            </Box>
                                            <div
                                                style={{
                                                    margin: "10px",
                                                    backgroundColor: "white",
                                                    padding: "15px",
                                                }}
                                            >
                                                {tags.map((tag, index2) => {
                                                    if (tag._t_category === category)
                                                        return (
                                                            <Chip
                                                                onClick={() => setChip(index2)}
                                                                sx={{
                                                                    color: "common.white",
                                                                    opacity: tag.mainActive ? "1" : "0.4",
                                                                    backgroundColor:
                                                                        tag._t_category === t("Category_FoodAndDrinks") ?
                                                                            "#EAAA08" :
                                                                            tag._t_category === t("Category_Recreation") ?
                                                                                "#669F2A" :
                                                                                tag._t_category === t("Category_Sports") ?
                                                                                    "#06AED4" :
                                                                                    "#4E5BA6",
                                                                    '&:hover': { backgroundColor: 'red', },
                                                                    color: "#FCFCFD",
                                                                    borderRadius: "360px",
                                                                    padding: "8px 8px",
                                                                    fontWeight: '600',
                                                                    fontSize: '0.875rem',
                                                                }}
                                                                key={tag.id}
                                                                label={tag.name}
                                                            />
                                                        );
                                                })}
                                                {customTagsUser.map((tag, index2) => {
                                                    console.log("tag.category_name", tag);
                                                    console.log("category", categoriesKey[index]);
                                                    if (tag.category_name === categoriesKey[index])
                                                        return (
                                                            <Chip
                                                                onClick={() => updateCustomTags(tag.id)}
                                                                sx={{
                                                                    color: "common.white",
                                                                    opacity: tag.selected ? "1" : "0.4",
                                                                    backgroundColor:
                                                                        tag.category_name === "Category_FoodAndDrinks" ?
                                                                            "#EAAA08" :
                                                                            tag.category_name === "Category_Recreation" ?
                                                                                "#669F2A" :
                                                                                tag.category_name === "Category_Sports" ?
                                                                                    "#06AED4" :
                                                                                    "#4E5BA6",
                                                                    '&:hover': { backgroundColor: 'red', },
                                                                    color: "#FCFCFD",
                                                                    borderRadius: "360px",
                                                                    padding: "8px 8px",
                                                                    fontWeight: '600',
                                                                    fontSize: '0.875rem',
                                                                }}
                                                                key={tag.id}
                                                                label={tag.tag_name}
                                                            />
                                                        );
                                                })}
                                                <Chip
                                                    onClick={() => handleOpenNewTagUserModal(categoriesKey[index])}
                                                    sx={{
                                                        color: "common.white",
                                                        backgroundColor: "#D0D5DD",
                                                        border: "1px solid #D0D5DD",
                                                        '&:hover': { backgroundColor: 'red', },
                                                        color: "white",
                                                        borderRadius: "360px",
                                                        padding: "8px 8px",
                                                        fontWeight: '600',
                                                        fontSize: '0.875rem',
                                                    }}
                                                    label={t('Add_NewTag')}
                                                />
                                            </div>
                                        </span>
                                    </div>
                                );
                            })}
                        </Box>

                        {/*create footer in bottom of div*/}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: "5%",
                                right: { xs: "15%", md: "35%" },
                                left: { xs: "15%", md: "35%" },
                            }}
                        >
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                                {t('New_Onboarding_Nr4')}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={80}
                                sx={{
                                    mb: 4,
                                    '& .MuiLinearProgress-bar1Determinate': {
                                        background: 'linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)',
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                <Button
                                    sx={{
                                        background: `white`,
                                        color: 'black',
                                        padding: '10px 10%',
                                        border: '1px solid #777E91',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/4"} rel="noopener">
                                    {t('Button_GoBack')}
                                </Button>
                                <Button
                                    sx={{
                                        background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
                                        padding: '10px 10%',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                    }}
                                    onClick={onSubmit5}
                                    variant="contained" rel="noopener">
                                    {t('Create_Workspace_ButtonNext')}
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </>
        );
    }

    const [linkedIn, setLinkedIn] = useState(user?.social_linkedin || "");
    const [github, setGithub] = useState(user?.social_github || "");
    const [instagram, setInstagram] = useState(user?.social_instagram || "");
    const [twitter, setTwitter] = useState(user?.social_twitter || "");
    const [tiktok, setTiktok] = useState(user?.social_tiktok || "");
    const [facebook, setFacebook] = useState(user?.social_facebook || "");
    const onSubmit6 = (data) => {
        let body = {
            social_linkedin: linkedIn,
            social_github: github,
            social_instagram: instagram,
            social_twitter: twitter,
            social_tiktok: tiktok,
            social_facebook: facebook,
        }

        axios.defaults.headers.common = {
            Authorization: user.token,
        };
        axios
            .put(`${config.api.host}/users`,
                body
            )
            .then(async (res) => {
                navigate(userType === 0 ? "/host/workspaces" : "/")
                axios.get(`${config.api.host}/users/${user.id}`)
                    .then(async res => {
                        let userdata = res.data.data.user
                        dispatch({
                            type: 'SET_USER',
                            payload: {
                                token: user.token,
                                ...userdata,
                            },
                        });
                    })
                    .catch(error => {
                        console.log(error)
                        if (error.response.status === 401) {
                            localStorage.removeItem('token');
                            window.location.reload(false);
                        }
                    })
            })
            .catch((error) => {
                alert(error);
            });
    };
    const renderStep6 = () => {
        return (
            <>
                <CompleteStepper step={4} />
                <Box
                    sx={{
                        width: { xs: '100%', sm: '100%', lg: '70%' },
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 5,
                        mt: "8%",
                        height: '100vh',
                    }}
                >
                    <Box sx={{ width: { xs: "90%", md: "50%" }, mt: { xs: 12, md: 0 }, pl: { sx: 0, md: 10 } }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 40,
                                right: 40,
                            }}
                        >
                            <Typography display="inline" fontWeight={"400"} fontSize={{ xs: 12, md: 16 }} paragraph sx={{ mb: 2 }}>
                                {t('New_Onboarding_Cancel1')}&nbsp;
                                <Typography component={Link} to="/" display="inline" fontSize={{ xs: 12, md: 16 }} fontWeight={"400"} paragraph sx={{ color: '#EF4F5B' }}>
                                    {t('New_Onboarding_Cancel2')}
                                </Typography>
                            </Typography>
                        </Box>
                        <Typography variant="h4" fontWeight={"bold"} paragraph
                            sx={{ mb: 1 }}>
                            {t('New_Onboarding_Step5_Headline')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#777E91', mb: 1 }}>
                            {t('OfferCreation')}
                        </Typography>
                        <Box
                            sx={{
                                display: "block",
                                marginTop: "25px",
                                padding: "15px",
                                overflowY: "scroll",
                                height: { xs: "340px", md: "280px", lg: "310px", xl: "400px" },
                                overflowX: "hidden",
                                //change scrollbar
                                '&::-webkit-scrollbar': {
                                    width: '0.4em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    outline: '1px solid slategrey'
                                }
                            }}

                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        LinkedIn
                                    </Typography>
                                    <TextField
                                        value={linkedIn}
                                        onChange={(e) => setLinkedIn(e.target.value)}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        Github
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={github}
                                        onChange={(e) => setGithub(e.target.value)}
                                        /* label="Github" */
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        Instagram
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={instagram}
                                        onChange={(e) => setInstagram(e.target.value)}
                                        /* label="Instagram" */
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        Twitter
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={twitter}
                                        onChange={(e) => setTwitter(e.target.value)}
                                        /* label="Twitter" */
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        TikTok
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={tiktok}
                                        onChange={(e) => setTiktok(e.target.value)}
                                        /* label="TikTok" */
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{ marginBottom: 2 }}
                                        fontWeight={700}
                                    >
                                        Facebook
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={facebook}
                                        onChange={(e) => setFacebook(e.target.value)}
                                        /* label="Facebook" */
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                        </Box>


                        {/*create footer in bottom of div*/}
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: "5%",
                                right: { xs: "15%", md: "35%" },
                                left: { xs: "15%", md: "35%" },
                            }}
                        >
                            <Typography variant="body2" sx={{ color: '#777E91', mb: 2 }}>
                                {t('New_Onboarding_Nr5')}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={100}
                                sx={{
                                    mb: 4,
                                    '& .MuiLinearProgress-bar1Determinate': {
                                        background: 'linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)',
                                    }
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                <Button
                                    sx={{
                                        background: `white`,
                                        color: 'black',
                                        padding: '10px 10%',
                                        border: '1px solid #777E91',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                        fontSize: { xs: '10px', md: '14px' },
                                    }}
                                    component={Link} variant="contained" to={"/complete-register/5"} rel="noopener">
                                    {t('Button_GoBack')}
                                </Button>
                                <Button
                                    onClick={onSubmit6}
                                    sx={{
                                        background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
                                        padding: '10px 10%',
                                        marginBottom: '10px',
                                        borderRadius: '35px',
                                        fontSize: { xs: '10px', md: '14px' },
                                    }}
                                    variant="contained">
                                    {t('New_Onboarding_FinalButton')}
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </>
        );
    }

    return (
        <Page title="Register">
            <RootStyle>
                {renderStep(currentStep)}
                <ModalAddNewUserTag open={addNewTagUserModal} handleClose={handleCloseNewTagUserModal} handleAddNewTag={handleAddNewTagUserModal} />
            </RootStyle>
        </Page>

    );
}

function ModalAddNewUserTag(props) {
    const { open, handleClose, handleAddNewTag } = props;
    const [tag, setTag] = useState("");
    const [tagError, setTagError] = useState(false);
    const [tagErrorMessage, setTagErrorMessage] = useState("");

    const handleAddTag = () => {
        if (tag === "") {
            setTagError(true);
            setTagErrorMessage("Please enter a tag");
        } else {
            setTagError(false);
            setTagErrorMessage("");
            handleAddNewTag(tag);
            setTag("");
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    marginBottom: 2,
                }}
                id="alert-dialog-title">{"Add New Tag"}</DialogTitle>
            <DialogContent>
                <TextField
                    id="outlined-basic"
                    label="Tag"
                    variant="outlined"
                    fullWidth
                    error={tagError}
                    helperText={tagErrorMessage}
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddTag} color="primary" autoFocus>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CompleteRegister;