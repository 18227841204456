import React from "react";
import { styled } from '@mui/material/styles';
// config
import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from '../../config';
// layouts
import Layout from "../../layouts";
// sections
import { HaveSpaceHero, HaveSpaceFaq, HaveSpaceSteps } from '../../sections/@travel';
// components
import { Page } from '../../components';
import HaveSpaceFaqPage from "../../sections/@travel/haveSpace/HaveSpaceFaqPage";
import VideoZone from "../../sections/@travel/videoZone/videoZone";
import Testimonials from "../../sections/@travel/testimonials/Testimonials";
import HaveSpaceKey from "../../sections/@travel/haveSpace/HaveSpaceKey";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: HEADER_MOBILE_HEIGHT,
  [theme.breakpoints.up('md')]: {
    paddingTop: HEADER_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------


const HaveWorkplace = () => {

  return (
    <Layout>
      <Page title="Have Workplace">
        <RootStyle>
          <HaveSpaceHero />
          <HaveSpaceKey />
          <HaveSpaceSteps />
          <HaveSpaceFaqPage />
          <Box sx={{ marginBottom: 15 }}>
            <VideoZone />
          </Box>
          <Testimonials />
        </RootStyle>
      </Page>
    </Layout>
  );
};

export default HaveWorkplace;
