import React from "react";
import { Link as LinkReact } from "react-router-dom";
// Yup
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
// icons
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, Link, TextField, IconButton, InputAdornment } from '@mui/material';
// components
import { Iconify } from '../../components';
import axios from "axios";
import config from "../../config";
import { useGlobalContext } from "../../stores/global";
import ErrorMsg from "../../components/errors/ErrorMsg";

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  email: Yup.string().required('Bitte gib eine E-Mail ein!').email('Das ist keine E-Mail'),
  password: Yup.string()
    .required('Bitte gib ein Passwort ein!')
    .min(6, 'Das Passwort sollte mindestens 6 Zeichen lang sein'),
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const loginUser = async (user) => {
    axios.post(`${config.api.host}/auth/login`, user)
      .then(res => {
        let data = res.data;
        axios.defaults.headers.common = {
          "Authorization": data.token,
        };
        axios.get(`${config.api.host}/users/${data.id}`)
          .then(async res => {
            let userdata = res.data.data.user
            // this.props._userService.colorPicture = Math.floor(Math.random() * 16777215).toString(16)
            // this.props._userService.user = userdata
            //  this.props._userService.picture.picture = userdata.profilepicture
            //let hash;
            // const formattedEmail = ('' + this.props._userService.user.email).trim().toLowerCase();
            //hash = md5(formattedEmail, { encoding: 'binary' })
            dispatch({
              type: 'SET_USER',
              payload: {
                token: data.token,
                ...userdata,
              },
            });
            //let permissions = this.props._authService.getUserPermissions()
            //if (permissions.includes('can_access_adminpanel')) {
            //this.props.history.push('/');
            window.open('/', '_self')
            // } else {
            //this.props.history.push('/');
            //  window.open('/', '_self')
            //}

          })
          .catch(error => {
            setOpen(true);
            setMsg("Ein unerwarteter Fehler ist aufgetreten");
            if (error.response.status === 401) {
              localStorage.removeItem('token');
            }
          })
      })
      .catch(error => {
        console.log(error)
        setOpen(true);
        setMsg("Ungültige Anmeldeinformationen");
        //this.openNotification("Fehler", "error", "Der Benutzername oder das Passwort ist falsch.")
      })
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    loginUser(data)
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5} alignItems="flex-end">
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="E-Mail Addresse"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Passwort"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? viewIcon : viewOff} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Link component={LinkReact} to={'/forgotPassword'} variant="body3" underline="always" color='#777E91'>
            Passwort vergessen?
          </Link>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </form>
      <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
    </>
  );
}
