import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Button, Typography, Stack } from '@mui/material';
import Layout from "../../layouts";
import { Page, Image } from '../../components';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(10, 2.5),
  [theme.breakpoints.up('sm')]: {
    height: '100vh',
    padding: theme.spacing(15, 2.5),
  },
}));

// ----------------------------------------------------------------------
export default function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <Layout disabledFooter>
      <Page title="Maintenance">
        <RootStyle>
          <Stack alignItems="center"
            sx={{
              paddingTop: { xs: 0, sm: 5, md: 10 },
              maxWidth: { xs: '98%', sm: '70%' }
            }}>
            <Typography variant="h3" paragraph
              sx={{ fontSize: { xs: '1.2rem', sm: '2.4rem' } }}>
              {t('Platform_Maintenance')}
            </Typography>

            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: { xs: '1rem', sm: '1.5rem' },
              }}>
              {t('Platform_Maintenance_Subline')}
            </Typography>

            <Image
              alt="maintenance"
              src="https://zone-assets-api.vercel.app/assets/illustrations/illustration_maintenance.svg"
              sx={{ maxWidth: 320, my: { xs: 6, sm: 8 } }}
            />

            <Button component={Link} to={'/demo'} variant="contained">
              {t('Platform_Maintenance_Button')}
            </Button>

          </Stack>
        </RootStyle>
      </Page>
    </Layout>
  );
}




