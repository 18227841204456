import axios from 'axios';
// config
import config from '../config';
// ----------------------------------------------------------------------

export const basePath = process.env.NODE_ENV === 'production' ? config.api.host : config.api.dev;

const axiosInstance = axios.create({
  baseURL: basePath,
});

export default axiosInstance;
