import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// components
import { Image, LightboxModal } from '../../../components';
import { varTranHover } from '../../../components/animate';
import config from '../../../config';
/* import Frame1 from '../../../assets/images/Frame 1018-1.png';
import Frame2 from '../../../assets/images/Frame 1018.png';
import Frame3 from '../../../assets/images/Frame 1019-1.png';
import Frame4 from '../../../assets/images/Frame 1019.png';
import Frame5 from '../../../assets/images/Frame 1020-1.png';
import Frame6 from '../../../assets/images/Frame 1020.png';
import Frame7 from '../../../assets/images/Frame 1021-1.png';
import Frame8 from '../../../assets/images/Frame 1021.png'; */
import { useGlobalContext } from '../../../stores/global';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
}));


export default function ProfileGallery() {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const { id } = useParams();
  const [userData, setUserData] = useState({ galleryList: [] });

  useEffect(() => {
    axios.get(`${config.api.host}/users/${id}`).then(res => {
      setUserData(res.data.data.user);
    });
  }, []);

  const handleOpenLightbox = (id) => {
    const selectedImage = userData.galleryList.findIndex((item) => id === item.id);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <RootStyle>


      <Box
        paddingTop={4} paddingBottom={0} paddingLeft={6}
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          },
        }}
      >
        {userData.galleryList.length > 0 && userData.galleryList.map((item) => (
          <PhotoItem key={item.id} photo={config.api.host + item.data} onOpenLightbox={() => handleOpenLightbox(item.id)} />
        ))}
      </Box>

      <LightboxModal
        images={userData.galleryList}
        mainSrc={userData.galleryList.length > 0 && config.api.host + userData.galleryList[selectedImage].data}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

PhotoItem.propTypes = {
  onOpenLightbox: PropTypes.func,
  photo: PropTypes.string,
};

function PhotoItem({ photo, onOpenLightbox }) {
  return (
    <m.div
      whileHover="hover"
      variants={{
        hover: { opacity: 0.8 },
      }}
      transition={varTranHover()}
    >
      <Image
        alt="photo"
        src={photo}
        ratio="1/1"
        onClick={onOpenLightbox}
        sx={{ borderRadius: 2, cursor: 'pointer' }}
      />
    </m.div>
  );
}
