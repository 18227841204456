import React from "react";
import { Link } from "react-router-dom";
// MUI
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Images
import space1 from "../../../assets/images/img_container_space01.png";
import space2 from "../../../assets/images/img_container_space02.png";

import { useTranslation } from "react-i18next";
import { Button, Container, Stack } from "@mui/material";
import styled from "@emotion/styled";
import twimyoLogo from './../../../assets/logos/twimyo-logo-gradient-nospace.svg';

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 15),
    padding: theme.spacing(0, 0),
  },
}));

export default function LandingBuenaventura() {
  const { t } = useTranslation();

  const mock = [
    {
      image: `${space1}`,
      /* description: `6,879 Workspaces`, */
      title: "Travelers",
      linkto: null,
    },
    {
      image: `${space2}`,
      /*       description: `6,879 Workspaces`, */
      title: "Locals",
    },
  ];

  const theme = useTheme();
  return (
    <RootStyle>
      <Container maxWidth>

        {/* Section Title */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: "center",
            marginBottom: "35px",
          }}
        >
          <Stack spacing={3} flexGrow={1}>

            <Box sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "24px",
              marginTop: "20px",
              alignItems: "center",
            }}>
              <img src={twimyoLogo} alt="twimyo" style={{ width: "24%", height: "auto", marginRight: "12px" }} />
              <Typography variant="h2">
                 verbindet
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              Wir bieten Reisenden ein Zuhause und Daheimgebliebenen die Welt
              {/* {t('Subline_Life')} */}
            </Typography>
          </Stack>
        </Stack>

        {/* Section Images */}
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", gap: 5 }}>
          {mock.map((item, i) => (
            <Box>
              <Link to={item.linkto === null ? "/needspace" : "/havespace"}>
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: 2,
                    "&:hover": {
                      "& img": {
                        transform: "scale(1.2)",
                      },
                    },
                    "& .lazy-load-image-loaded": {
                      display: "flex !important",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    margin: 0,
                  }}
                >
                  <Box
                    component={LazyLoadImage}
                    src={item.image}
                    width={1}
                    alt="..."
                    effect="blur"
                    sx={{
                      transition: "transform .7s ease !important",
                      transform: "scale(1.0)",
                      objectFit: "fill",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  />
                </Box>

                <Box
                  padding={0}
                  sx={{ textAlign: "center", marginTop: "10px" }}
                >
                  <Box
                    sx={{
                      padding: "10px 0 0px 24px",
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      fontWeight={700}
                      sx={{ color: "black" }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      </Container>
    </RootStyle>
  );
}
