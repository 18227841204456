import { NumericFormat } from 'react-number-format';
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Divider,
  Grid,
  MenuItem,
  Radio,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  // ErrorScreen,
  Iconify,
  Image,
  LoadingScreen,
  Page,
  TextMaxLine,
} from "../../../components";
// import chevronRight from "@iconify/icons-carbon/chevron-right";
import { Box } from "@mui/system";
//Images
import Logo from "../../../assets/images/host-logo-dark.svg";
import OfficeIllustration from "../../../assets/images/office-illustration.png";
import DeskIllustration from "../../../assets/images/desk-illustration.png";
import MeetingroomIllustration from "../../../assets/images/meetingroom-illustration.png";
import MiscIllustration from "../../../assets/images/misc-illustration.png";
import UploadFile from "../../../assets/images/uploadFile.svg";
import ErrorMsg from "../../../components/errors/ErrorMsg";
import axios from "axios";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import config from "../../../config";
import "../../../sections/@travel/tours/Map.scss";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// import { TextFields } from "@mui/icons-material";
import NumberFormat from "react-number-format";
//import data from "@iconify/icons-carbon/direction-straight-right";
//import SVGIcon from "../../../components/icons.element";
import { TimePicker } from "@mui/lab";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../stores/global";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { tag_list } from "../../../mocks/tag_list";
import { useTranslation } from "react-i18next";
import directionStraightRight from "@iconify/icons-carbon/chevron-left";
import directionStraightLeft from "@iconify/icons-carbon/chevron-right";
import Layout from "../../../layouts";
import { styled } from "@mui/material/styles";
import { TravelTourHeader } from "../../../sections/@travel";
import { fCurrency } from "../../../utils/formatNumber";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

mapboxgl.accessToken = config.mapbox.access_token;

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      style={{ fontSize: 16 }}
      thousandSeparator
      isNumericString
      prefix="€"
    />
  );
});

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

export default function CreateWorkpace() {
  const { t } = useTranslation();

  const { user } = useGlobalContext();

  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleChange = (index) => {
    validationStep(index);
  };

  //refs
  const geocoder = useRef();
  const mapContainer = useRef();

  //map
  var popup = new mapboxgl.Popup();
  const [map, setMap] = useState(null);
  var workspaceMarker = null;
  const [mapElement, setMapElement] = useState(<div ref={mapContainer} />);
  var popupCheckPin = `<div className={"officePopup"}>
      <div className={"popupContent"}>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            textAlign: "center",
            color: "#51555E",
            marginBottom: 0,
          }}
        >
          Passt dieser Pin? Du kannst ihn auch manuell einfach verschieben,
          damit man z.b. den Eingang besser findet.
        </p>
      </div>
    </div>`;

  //States Form
  const [type, setType] = useState(null);
  const [latitude, setLatitude] = useState(48.137108);
  const [longitude, setLongitude] = useState(11.575382);
  const [address, setAddress] = useState(null);
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(null);
  const [amount, setAmount] = useState(1);
  const [extraPercent, setExtraPercent] = useState(25);

  const [description, setDescription] = useState("");

  const goToStep = () => {
    switch (step) {
      case 1:
        return firstSection();

      case 2:
        return secondSection();

      case 3:
        return thirdSection();

      case 4:
        return fourthSection();
    }
  };

  const validationStep = (step) => {
    switch (step) {
      case 2:
        let valid = true;
        if (address && latitude && longitude) {
        } else {
          setOpen(true);
          setMsg("Adresse erforderlich");
          valid = false;
        }

        if (title && price && description.length > 0) {
        } else {
          setOpen(true);
          let msg = "";
          if (!title) {
            msg += "| Titel ist erforderlich |";
          }
          if (!price) {
            msg += "| Preis ist erforderlich |";
          }
          if (description.length === 0) {
            msg += "| Die Beschreibung ist erforderlich |";
          }
          setMsg(msg);
          valid = false;
        }

        if (imageList.length > 0) {
        } else {
          setOpen(true);
          setMsg("Ein Bild ist erforderlich");
          valid = false;
        }

        if (valid) {
          setStep(2);
        }
        break;

      case 3:
        let active = tags.filter((tag) => tag.mainActive);
        if (active.length > 0) {
          setStep(3);
        } else {
          setOpen(true);
          setMsg("Tags sind erforderlich");
        }
        break;
      case 4:
        let open = openings.filter((opens) => opens.open);
        if (open.length > 0) {
          setLoading(true);
          createOffice();
        } else {
          setOpen(true);
          setMsg("Openings sind erforderlich");
        }
        break;

      default:
        return firstSection();
    }
  };

  const [loading, setLoading] = useState(false);
  const [savedWorkspace, setSavedWorkspace] = useState(null);
  const createOffice = () => {
    let workplace = {
      id: uuidv4(),
      title: title,
      price: price,
      description: description,
      office_id: null,
      imageList: imageList,
      type: type,
      tag_list: tags
        .filter((tag) => tag.mainActive)
        .map((tag) => tag.id)
        .toString(),
      openings: openings,
      amount: amount,
      extra_percent: extraPercent,
      latitude: latitude,
      longitude: longitude,
      address: address,
      categories: selectedCategoriesWorkplace,
    };
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    workplace.user = user;
    workplace.openedMondayFrom = openings[0].from;
    workplace.openedMondayTo = openings[0].to;
    workplace.openedMonday = openings[0].open;
    workplace.openedTuesdayFrom = openings[1].from;
    workplace.openedTuesdayTo = openings[1].to;
    workplace.openedTuesday = openings[1].open;
    workplace.openedWednesdayFrom = openings[2].from;
    workplace.openedWednesdayTo = openings[2].to;
    workplace.openedWednesday = openings[2].open;
    workplace.openedThursdayFrom = openings[3].from;
    workplace.openedThursdayTo = openings[3].to;
    workplace.openedThursday = openings[3].open;
    workplace.openedFridayFrom = openings[4].from;
    workplace.openedFridayTo = openings[4].to;
    workplace.openedFriday = openings[4].open;
    workplace.openedSaturdayFrom = openings[5].from;
    workplace.openedSaturdayTo = openings[5].to;
    workplace.openedSaturday = openings[5].open;
    workplace.openedSundayFrom = openings[6].from;
    workplace.openedSundayTo = openings[6].to;
    workplace.openedSunday = openings[6].open;
    let res = {
      data: workplace,
    };
    setSavedWorkspace(res);
    axios
      .post(`${config.api.host}/host/office/workplace`, workplace)
      .then((res) => {
        setStep(4);
        setLoading(false);
      })
      .catch((error) => {
        setOpen(true);
        setLoading(false);
        setMsg("Ein Fehler ist aufgetreten");
      });
  };

  const goBackMap = () => {
    setMap(null);
    setStep(1);
  };

  useEffect(() => {
    goToStep(step);
  }, [step]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const firstSection = () => {
    if (mapContainer.current && !map) {
      //setMarker
      const el = document.createElement("div");
      el.className = "officeMarker";
      workspaceMarker = new mapboxgl.Marker(el, { draggable: true });

      workspaceMarker.on("dragend", (e) => {
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target._lngLat.lng},${e.target._lngLat.lat}.json?access_token=${config.mapbox.access_token}&language=de`
          )
          .then((res) => {
            setLatitude(e.target._lngLat.lat);
            setLongitude(e.target._lngLat.lng);
            setAddress(res.data.features[0].place_name);
            document.querySelector(".geocoder input").value =
              res.data.features[0].place_name;
            popupCheckPin = `
          <div className="officePopup">
            <div className={"popupContent"}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#51555E",
                  marginBottom: 0,
                }}
              >
                ${res.data.features[0].place_name}
              </p>
            </div>
          </div>
        `;
            workspaceMarker.setPopup(
              new mapboxgl.Popup().setHTML(popupCheckPin)
            );
            workspaceMarker.togglePopup();
          });
      });
      workspaceMarker.setLngLat([longitude, latitude]);
      //setMap
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/xsinister/ckt6eauig2x7h17o55mghtd22",
        center: [longitude, latitude],
        zoom: 13,
        antialias: true,
        bearing: -17.6,
        pitch: 45,
      });
      map.on("load", () => {
        map.addSource("places", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "place-labels",
          type: "symbol",
          source: "places",
          minzoom: 5,
          layout: {
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "icon-image": ["get", "icon"],
            "icon-size": 0.5,
            "text-size": 12,
            "text-padding": 15,
          },
          paint: {
            "icon-color": "#FD6A56",
            "text-color": "#8f8b8a",
            "text-halo-color": "#fafafa",
            "text-halo-width": 1,
            "text-halo-blur": 1,
          },
        });
      });
      setMap(map);

      const mapboxGeocoder = new MapboxGeocoder({
        accessToken: config.mapbox.access_token,
        mapboxgl: mapboxgl,
        marker: false,
      });
      mapboxGeocoder.on("result", (result) => {
        setLatitude(result.result.center[1]);
        setLongitude(result.result.center[0]);
        setAddress(result.result.place_name);
        document.querySelector(".geocoder input").value =
          result.result.place_name;
        workspaceMarker.setLngLat([
          result.result.center[0],
          result.result.center[1],
        ]);
        workspaceMarker.setPopup(new mapboxgl.Popup().setHTML(popupCheckPin));
        workspaceMarker.togglePopup();
      });
      mapboxGeocoder.setLanguage("de");
      mapboxGeocoder.setTypes("place,poi,address");
      geocoder.current.appendChild(mapboxGeocoder.onAdd(map));

      document.querySelector(".geocoder input").value = address || "";

      workspaceMarker.addTo(map);
    }

    return (
      <>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Box sx={{ marginBottom: 5 }}>
              <Typography variant="h2">{t("WorkspaceFlow_Step1")}</Typography>
            </Box>
            {sixthStep()}
            {firstStep()}
          </Grid>
          <Grid item xs={12} md={4}>
            {previewWorkplace()}
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <section>
              <Box>
                <Box>
                  <Typography variant="h6" sx={{ color: "#000" }}>
                    {t("WorkspaceDetails1")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    {t("WorkspaceDetails2")}
                  </Typography>
                  <TextField
                    sx={{
                      fontSize: { md: "1.6rem", xs: "1rem" },
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "15px",
                    }}
                    value={title}
                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                    onInput={handleChangeTitle}
                    variant="outlined"
                    id="outlined-required"
                    label={t("WorkspaceDescription2")}
                    /* label="Name des workspace" */
                  />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    {t("WorkspacePricing1")}
                  </Typography>
                  <TextField
                    label={t("Create_Workspace_Price_Box")}
                    value={price}
                    onChange={handleChangePrice}
                    name="numberformat"
                    variant="outlined"
                    fullWidth
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    {t("WorkspaceAdress1")}
                  </Typography>
                  <div>
                    <div className="create__office-step3-map">
                      <div ref={geocoder} className={"geocoder"} />
                    </div>
                  </div>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    {t("AllowedTravelers")}
                  </Typography>
                  <TextField
                    type={"number"}
                    sx={{
                      fontSize: { md: "1.6rem", xs: "1rem" },
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "15px",
                    }}
                    label={t("AllowedLabel")}
                    variant="outlined"
                    value={amount}
                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                    onInput={handleChangeAmount}
                    /* label="Name des workspace" */
                  />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    Extra price for additional user
                  </Typography>
                  <Select
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "15px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={extraPercent}
                    onChange={handleChangePercent}
                  >
                    <MenuItem value={25}>25%</MenuItem>
                    <MenuItem value={35}>35%</MenuItem>
                    <MenuItem value={45}>45%</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    fontSize={"14px"}
                    variant={"body2"}
                    sx={{ color: "#777E90", marginBottom: 2 }}
                  >
                    {t("WorkspaceDescription1")}
                  </Typography>
                  <TextField
                    multiline
                    rows={5}
                    sx={{
                      fontSize: { md: "1.6rem", xs: "1rem" },
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "15px",
                    }}
                    label={t("WorkspaceDescription2")}
                    variant="outlined"
                    value={description}
                    inputProps={{ style: { fontSize: 16 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                    onInput={handleChangeDescription}
                    /* label="Name des workspace" */
                  />
                </Box>
              </Box>
            </section>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                height: "50%",
                display: { md: "block", xs: "none" },
                width: "100%",
              }}
            >
              <div
                className={"map-visible-create"}
                style={{ width: "100%", height: 500 }}
              >
                {mapElement}
              </div>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const secondSection = () => {
    return (
      <>
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="h2">{t("WorkspaceFlow_Step2")}</Typography>
        </Box>
        {fourthStep()}
      </>
    );
  };

  const thirdSection = () => {
    return (
      <>
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="h2">{t("WorkspaceFlow_Step3")}</Typography>
        </Box>
        {fifthStep()}
      </>
    );
  };

  const fourthSection = () => {
    return (
      <>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                marginBottom: 5,
                width: "100%",
                height: "40%",
                borderRadius: "20px",
              }}
            >
              <img
                src={savedWorkspace.data.imageList[0].data}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: 0 }}>
              <Typography variant="h2">
                {t("WorkspaceFlow_FinalStep")}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <Typography color="#777E90">
                {t("WorkspaceFlow_FinalStep2")} 🎉
              </Typography>
            </Box>
            <TravelTourHeader
              tour={savedWorkspace}
              categories={categories}
              tagsAmenities={tags}
              createPreview={true}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const firstStep = () => {
    return (
      <section>
        <Box>
          <Typography variant="h6" sx={{ color: "#000" }}>
            {t("WorkspaceFlow_Type")}
          </Typography>
          <Typography
            fontSize={"12px"}
            variant={"body2"}
            sx={{ color: "#777E90" }}
          >
            {t("WorkspaceFlow_Type2")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            margin: "3% 0%",
            width: "100%",
            rowGap: { xs: 1, md: 1 },
            columnGap: 2,
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
            },
          }}
        >
          {/* Create radio buttons with title and radio in the right */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("desk")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat1")}
            </Typography>
            <Radio
              checked={type === "desk"}
              onChange={() => setType("desk")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("meetingroom")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat2")}
            </Typography>
            <Radio
              checked={type === "meetingroom"}
              onChange={() => setType("meetingroom")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("makerspace")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat3")}
            </Typography>
            <Radio
              checked={type === "makerspace"}
              onChange={() => setType("makerspace")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("studio")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat4")}
            </Typography>
            <Radio
              checked={type === "studio"}
              onChange={() => setType("studio")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("misc")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat5")}
            </Typography>
            <Radio
              checked={type === "misc"}
              onChange={() => setType("misc")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #E5E5E5",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #000",
              },
            }}
            onClick={() => setType("event")}
          >
            <Typography variant="body2" fontWeight={600} sx={{ color: "#000" }}>
              {t("Create_Workspace_Cat6")}
            </Typography>
            <Radio
              checked={type === "event"}
              onChange={() => setType("event")}
              value={type}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
          </Box>
        </Box>
      </section>
    );
  };

  const secondStep = () => {
    if (mapContainer.current && !map) {
      //setMarker
      const el = document.createElement("div");
      el.className = "officeMarker";
      workspaceMarker = new mapboxgl.Marker(el, { draggable: true });

      workspaceMarker.on("dragend", (e) => {
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target._lngLat.lng},${e.target._lngLat.lat}.json?access_token=${config.mapbox.access_token}&language=de`
          )
          .then((res) => {
            setLatitude(e.target._lngLat.lat);
            setLongitude(e.target._lngLat.lng);
            setAddress(res.data.features[0].place_name);
            document.querySelector(".geocoder input").value =
              res.data.features[0].place_name;
            popupCheckPin = `
          <div className="officePopup">
            <div className={"popupContent"}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#51555E",
                  marginBottom: 0,
                }}
              >
                ${res.data.features[0].place_name}
              </p>
            </div>
          </div>
        `;
            workspaceMarker.setPopup(
              new mapboxgl.Popup().setHTML(popupCheckPin)
            );
            workspaceMarker.togglePopup();
          });
      });
      workspaceMarker.setLngLat([longitude, latitude]);
      //setMap
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/xsinister/ckt6eauig2x7h17o55mghtd22",
        center: [longitude, latitude],
        zoom: 13,
        antialias: true,
        bearing: -17.6,
        pitch: 45,
      });
      map.on("load", () => {
        map.addSource("places", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "place-labels",
          type: "symbol",
          source: "places",
          minzoom: 5,
          layout: {
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "icon-image": ["get", "icon"],
            "icon-size": 0.5,
            "text-size": 12,
            "text-padding": 15,
          },
          paint: {
            "icon-color": "#FD6A56",
            "text-color": "#8f8b8a",
            "text-halo-color": "#fafafa",
            "text-halo-width": 1,
            "text-halo-blur": 1,
          },
        });
      });
      setMap(map);

      const mapboxGeocoder = new MapboxGeocoder({
        accessToken: config.mapbox.access_token,
        mapboxgl: mapboxgl,
        marker: false,
      });
      mapboxGeocoder.on("result", (result) => {
        setLatitude(result.result.center[1]);
        setLongitude(result.result.center[0]);
        setAddress(result.result.place_name);
        document.querySelector(".geocoder input").value =
          result.result.place_name;
        workspaceMarker.setLngLat([
          result.result.center[0],
          result.result.center[1],
        ]);
        workspaceMarker.setPopup(new mapboxgl.Popup().setHTML(popupCheckPin));
        workspaceMarker.togglePopup();
      });
      mapboxGeocoder.setLanguage("de");
      mapboxGeocoder.setTypes("place,poi,address");
      geocoder.current.appendChild(mapboxGeocoder.onAdd(map));

      document.querySelector(".geocoder input").value = address || "";

      workspaceMarker.addTo(map);
    }

    return (
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            height: "100%",
            display: { md: "block", xs: "none" },
            width: "50%",
          }}
        >
          <div
            className={"map-visible-create"}
            style={{ width: "100%", height: 600 }}
          >
            {mapElement}
          </div>
        </Box>
        <Box
          sx={{
            padding: "8% 3%",
            width: { md: "50%", xs: "100%" },
          }}
        >
          <div>
            <Box
              sx={{
                fontSize: { md: "1.8rem", xs: "1.4rem" },
                fontWeight: "bold",
                color: "#E52270",
                marginY: "15px",
              }}
            >
              {t("Create_Workspace_Adress")}
              {/* Welche Adresse hat dein Workspace? */}
            </Box>
            <div>
              <div className="create__office-step3-map">
                <div ref={geocoder} className={"geocoder"} />
              </div>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              bottom: "10%",
              right: "15%",
              gap: "30px",
            }}
          >
            <Button onClick={() => goBackMap()} variant="outlined">
              {t("Create_Workspace_ButtonBack")}
              {/* BACK */}
            </Button>
            <Button onClick={() => validationStep(2)} variant="contained">
              {t("Create_Workspace_ButtonNext")}
              {/* WEITER */}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleChangePercent = (event) => {
    setExtraPercent(event.target.value);
  };

  const [categoriesWorkplace, setCategoriesWorkplace] = useState([]);
  const [selectedCategoriesWorkplace, setSelectedCategoriesWorkplace] =
    useState([]);
  const getCategoriesWorkplace = () => {
    axios
      .get(`${config.api.host}/office/workplace_categories`)
      .then(function (response) {
        console.log(response.data.data);
        setCategoriesWorkplace(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setValueCategoriesWorkplace = (value) => {
    console.log(value);
    let arr = value.map((item) => item.id);
    console.log(arr);
    setSelectedCategoriesWorkplace(arr);
  };

  useEffect(() => {
    getCategoriesWorkplace();
  }, []);

  const thirdStep = () => {
    return (
      <section>
        <Box>
          <div>
            <Box
              sx={{
                fontSize: { md: "2rem", xs: "1.4rem" },
                fontWeight: "bold",
                color: "#E52270",
              }}
            >
              {t("Create_Workspace_Detail1")}
              {/* Wie möchtest du deinen Schreibtisch nennen? */}
            </Box>
            <Box>
              <TextField
                sx={{
                  fontSize: { md: "1.6rem", xs: "1rem" },
                  width: "100%",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #777E91",
                  borderRadius: "15px",
                }}
                value={title}
                inputProps={{ style: { fontSize: 16 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                onInput={handleChangeTitle}
                id="outlined-required"
                label={t("Create_Workspace_Name_Box")}
                /* label="Name des workspace" */
              />
            </Box>
          </div>
          <div style={{ marginTop: "25px" }}>
            <Box
              sx={{
                fontSize: { md: "2rem", xs: "1.4rem" },
                fontWeight: "bold",
                color: "#E52270",
              }}
            >
              {t("Create_Workspace_Detail2")}
              {/* Was darf dein Schreibtisch pro Tag kosten? */}
            </Box>
            <Box>
              <TextField
                label={t("Create_Workspace_Price_Box")}
                /* label="Price" */
                value={price}
                onChange={handleChangePrice}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                sx={{
                  fontSize: { md: "1.6rem", xs: "1rem" },
                  width: "100%",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #777E91",
                  borderRadius: "15px",
                }}
              />
            </Box>
          </div>
          <div style={{ marginTop: "25px", display: "none" }}>
            <Box
              sx={{
                fontSize: { md: "2rem", xs: "1.4rem" },
                fontWeight: "bold",
                color: "#E52270",
              }}
            >
              {t("Create_Workspace_Detail3")}
              {/* Welche Zwecke erfüllt dein Angebot? */}
            </Box>
            <Box>
              <Autocomplete
                sx={{
                  fontSize: { md: "1.6rem", xs: "1rem" },
                  width: "100%",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #777E91",
                  borderRadius: "15px",
                }}
                multiple
                id="checkboxes-tags-demo"
                options={categoriesWorkplace}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={(event, value) => setValueCategoriesWorkplace(value)} // prints the selected value
                renderInput={(params) => (
                  <TextField {...params} label="Categories" />
                )}
              />
            </Box>
          </div>
          <div style={{ marginTop: "25px" }}>
            <Box
              sx={{
                fontSize: { md: "2rem", xs: "1.4rem" },
                fontWeight: "bold",
                color: "#E52270",
              }}
            >
              {t("WorkspaceDescription1")}
            </Box>
            <Box>
              {/* text area for description */}
              <TextField
                multiline
                rows={5}
                sx={{
                  fontSize: { md: "1.6rem", xs: "1rem" },
                  width: "100%",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #777E91",
                  borderRadius: "15px",
                }}
                value={description}
                inputProps={{ style: { fontSize: 16 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                onInput={handleChangeDescription}
                /* label="Name des workspace" */
              />
            </Box>
          </div>
        </Box>
      </section>
    );
  };
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let tags = tag_list.filter((item) => item.category);
    setTags(tags);
    let categories = tags.map((item) => {
      return item.category;
    });
    categories = categories.filter(
      (item, index) => categories.indexOf(item) === index
    );
    setCategories(categories);
  }, []);

  const setChip = (index) => {
    let mainActive = tags.filter((tag) => tag.mainActive);
    console.log(tags);
    let newArr = [...tags]; // copying the old datas array
    if (mainActive.length < 15 && !newArr[index].mainActive) {
      newArr[index].mainActive = true; // replace e.target.value with whatever you want to change it to
      setTags(newArr);
    } else {
      if (newArr[index].mainActive) {
        newArr[index].mainActive = false; // replace e.target.value with whatever you want to change it to
        setTags(newArr);
      } else {
        setOpen(true);
        setMsg("Sie können nur fünfzehn auswählen");
      }
    }
  };
  const fourthStep = () => {
    return (
      <section>
        <div>
          <Box
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            {t("Create_Workspace_TagHeadline")}
            {/* Wähle drei Tags, die zu deiner Location am besten passen. */}
          </Box>
          <div>
            <span style={{ fontSize: "1rem", color: "#777E91" }}>
              {t("WorkspaceFlow_Step2_Subline")}
            </span>
          </div>
        </div>
        <Box
          sx={{
            display: "block",
            marginTop: "25px",
            border: "1px solid #777E91",
            borderRadius: "15px",
            padding: "15px",
          }}
        >
          {categories.map((category, index) => {
            return (
              <div key={index}>
                <span style={{ fontSize: "1.2rem", color: "#777E90" }}>
                  {category.slice(0, 1).toUpperCase() + category.slice(1)}
                  <div
                    style={{
                      margin: "10px",
                      backgroundColor: "white",
                      padding: "15px",
                    }}
                  >
                    {tags.map((tag, index) => {
                      if (tag.category === category)
                        return (
                          <Chip
                            onClick={() => setChip(index)}
                            sx={{
                              color: tag.mainActive ? "white" : "#23262F",
                              opacity: tag.mainActive ? "1" : "0.8",
                              backgroundColor: tag.mainActive
                                ? "#FB5A55"
                                : "#E6E8EC",
                              padding: "8px",
                              margin: "5px 5px",
                              textTransform: "none",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            key={tag.id}
                            icon={
                              <Iconify
                                icon={tag.icon}
                                sx={{
                                  width: 18,
                                  height: 18,
                                  color: tag.mainActive
                                    ? "white !important"
                                    : "#000 !important",
                                }}
                              />
                            }
                            label={tag.name}
                          />
                        );
                    })}
                  </div>
                </span>
              </div>
            );
          })}
        </Box>
      </section>
    );
  };

  const [openings, setOpenings] = useState([
    {
      type: "MO",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "DI",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "MI",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "DO",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "FR",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "SA",
      open: false,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "SO",
      open: false,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
  ]);

  const changeOpeningsFrom = (index, value) => {
    let newArr = [...openings];
    newArr[index].from = moment(value).format("HH:mm").replace(":", "-");
    newArr[index].dateValueFrom = value;
    //if index is equal to 0 change all arra from to value
    if (index === 0) {
      newArr.map((item, index) => {
        if (index !== 0) {
          item.from = moment(value).format("HH:mm").replace(":", "-");
          item.dateValueFrom = value;
        }
      });
    }
    setOpenings(newArr);
  };

  const changeOpeningsTo = (index, value) => {
    let newArr = [...openings];
    newArr[index].to = moment(value).format("HH:mm").replace(":", "-");
    newArr[index].dateValueTo = value;
    //if index is equal to 0 change all arra from to value
    if (index === 0) {
      newArr.map((item, index) => {
        if (index !== 0) {
          item.to = moment(value).format("HH:mm").replace(":", "-");
          item.dateValueTo = value;
        }
      });
    }
    setOpenings(newArr);
  };

  const setOpenOpenings = (index, value) => {
    let newArr = [...openings];
    newArr[index].open = value;
    setOpenings(newArr);
  };

  const fifthStep = () => {
    return (
      <section>
        <div>
          <Box
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            {t("Create_Workspace_OpeningHours")}
            {/* Zu welchen Zeiten ist dein Schreibtisch nutzbar? */}
          </Box>
        </div>
        <Box sx={{ margin: "20px auto" }}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F2F4F7",
              borderRadius: "15px",
              border: "1px solid #777E91",
            }}
          >
            {openings.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "5",
                  margin: "20px 0",
                }}
                key={item.type}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    padding: "10px 20px",
                  }}
                >
                  {item.type}
                  <Switch
                    onClick={(e) => {
                      setOpenOpenings(index, e.target.checked);
                    }}
                    checked={item.open}
                  />
                </Box>
                <Box>
                  <TimePicker
                    label={t("OpeningHours_From")}
                    ampm={false}
                    value={item.dateValueFrom}
                    onChange={(newValue) => {
                      changeOpeningsFrom(index, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          backgroundColor: "white",
                          minWidth: "370px",
                          borderRadius: "10px",
                          border: "1px solid #777E91",
                          "& .MuiFilledInput-root": {
                            backgroundColor: item.open ? "white" : "#EAECF0",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <TimePicker
                    ampm={false}
                    label={t("OpeningHours_To")}
                    value={item.dateValueTo}
                    onChange={(newValue) => {
                      changeOpeningsTo(index, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                          border: "1px solid #777E91",
                          minWidth: "370px",
                          //change color filled input
                          "& .MuiFilledInput-root": {
                            backgroundColor: item.open ? "white" : "#EAECF0",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </section>
    );
  };

  const [images, setImages] = React.useState([]);
  const [imageList, setImagesList] = React.useState([]);
  const maxNumber = 5;

  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    let json = [];
    await Promise.all(
      imageList.map((item) => {
        json.push({
          id: uuidv4(),
          data: item.data_url,
        });
      })
    );
    setImagesList(json);
    setImages(imageList);
  };

  const deleteAll = () => {
    setImagesList([]);
  };

  const deleteOne = (index) => {
    let newarr = [...imageList];
    newarr.splice(index, 1);
    setImagesList(newarr);
  };

  const sixthStep = () => {
    return (
      <section>
        <Box>
          <Typography variant="h6" sx={{ color: "#000" }}>
            {t("WorkspaceFlow_Photos")}
          </Typography>
          <Typography
            fontSize={"12px"}
            variant={"body2"}
            sx={{ color: "#777E90" }}
          >
            {t("WorkspaceFlow_Photos2")}
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <>
                <Box
                  style={isDragging ? { color: "red" } : undefined}
                  sx={{
                    width: "100%",
                    height: "200px",
                    backgroundColor: "#F4F5F6",
                    fontSize: "3rem",
                    cursor: "pointer",
                  }}
                  onClick={onImageUpload}
                  onDrop={onImageUpload}
                  {...dragProps}
                >
                  {/*put image and text in the center*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <img src={UploadFile} alt="upload" />
                      <Typography fontSize={14} sx={{ color: "#777E90" }}>
                        PNG, JPG, WEBP. Max 5Mb.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Grid container sx={{ margin: "30px 50px" }} spacing={2}>
                  {imageList.map((image, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          width: "35%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "20px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={image["data_url"]}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={() => onImageUpdate(index)}
                            variant="outlined"
                          >
                            {t("ButtonUpdate")}
                          </Button>
                          <Button
                            onClick={() => onImageRemove(index)}
                            variant="outlined"
                          >
                            {t("ButtonRemove")}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </ImageUploading>
        </Box>
      </section>
    );
  };

  const seventhStep = () => {
    return (
      <section>
        <div>
          <Box
            sx={{
              fontSize: { md: "2rem", xs: "1.4rem" },
              fontWeight: "bold",
              color: "#E52270",
            }}
          >
            {t("Create_Workspace_Final")}
            {/* Perfekt! du hast deinen Schreibtisch erfolgreich angelegt. */}
          </Box>
        </div>
        <Button
          sx={{
            display: "flex",
            margin: "30px 0",
            color: "white",
          }}
          onClick={() => validationStep(6)}
          variant="contained"
        >
          <Link
            to="/host/workspaces"
            style={{ textDecoration: "none", color: "white" }}
          >
            {t("Final_Button")}
            {/* FERTIGSTELLEN */}
          </Link>
        </Button>
      </section>
    );
  };

  function previewWorkplace() {
    return (
      <Card>
        {imageList.length > 0 && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                pt: 1.5,
                pl: 2,
                pr: 1.5,
                top: 0,
                width: 1,
                zIndex: 9,
                position: "absolute",
              }}
            >
              <Stack
                spacing={0.5}
                direction="row"
                sx={{
                  px: 1,
                  borderRadius: 0.75,
                  typography: "subtitle2",
                  bgcolor: "#FD5854",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                }}
              >
                €{fCurrency(price)}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "common.white" }}
                >
                  / {t("Day")}
                </Typography>
              </Stack>
            </Stack>

            <Image alt={title} src={imageList[0]["data"]} ratio="4/3" />
          </>
        )}

        <Box
          sx={{
            p: 2.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <TextMaxLine variant="h6">{title || "No title"}</TextMaxLine>
            <Typography
              variant="body2"
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              {user.first_name}{" "}
            </Typography>
          </Box>
        </Box>

        <Divider />

        {address && (
          <Box
            sx={{
              p: 2.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Tooltip
                title={address.split(",")[1] + ", " + address.split(",")[2]}
              >
                <Typography
                  variant="body3"
                  sx={{ color: "#777E91" }}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {address.split(",")[1] + ", " + address.split(",")[2]}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Card>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Layout>
          <Page title={`Create Workplace`}>
            <RootStyle>
              <Container
                style={{
                  width: "100%",
                  padding: "2% 5%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.history.back();
                    }}
                    startIcon={
                      <Iconify
                        icon={directionStraightRight}
                        sx={{ width: 16, height: 16, color: "black" }}
                      />
                    }
                    sx={{
                      display: { xs: "none", md: "inline-flex" },
                      color: "black",
                      fontWeight: "700",
                      border: "2px solid #E6E8EC",
                      borderRadius: "90px",
                      textTransform: "none",
                    }}
                  >
                    {t("Button_GoBack_Workspaces")}
                  </Button>
                  <Breadcrumbs
                    sx={{ color: "#B1B5C4", fontWeight: "700" }}
                    links={[
                      { name: "Home", href: "/" },
                      {
                        name: "Workspace hinzufügen",
                        href: "/host/workspaces",
                      },
                    ]}
                  />
                </Box>
                <Box>
                  {/*Create tabs with number of steps, title and underline*/}
                  <Tabs
                    value={step}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    sx={{
                      borderBottom: "1px solid #E6E8EC",
                    }}
                  >
                    <Tab
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            py: 3,
                            gap: "20px",
                            borderBottom:
                              step === 1
                                ? "2px solid #E54257"
                                : step > 1
                                ? "2px solid #000"
                                : "2px solid #B1B5C3",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              backgroundColor:
                                step === 1
                                  ? "#E54257"
                                  : step > 1
                                  ? "#000"
                                  : "#B1B5C3",
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            1
                          </Box>
                          <Box
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              color:
                                step === 1
                                  ? "#E54257"
                                  : step > 1
                                  ? "#000"
                                  : "#B1B5C3",
                            }}
                          >
                            {t("WorkspaceDetails1")}
                          </Box>
                        </Box>
                      }
                    />
                    <Tab
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            py: 3,
                            gap: "20px",
                            borderBottom:
                              step === 2
                                ? "2px solid #E54257"
                                : step > 2
                                ? "2px solid #000"
                                : "2px solid #B1B5C3",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              backgroundColor:
                                step === 2
                                  ? "#E54257"
                                  : step > 2
                                  ? "#000"
                                  : "#B1B5C3",
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            2
                          </Box>
                          <Box
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              color:
                                step === 2
                                  ? "#E54257"
                                  : step > 2
                                  ? "#000"
                                  : "#B1B5C3",
                            }}
                          >
                            Tags auswahl
                            {/* Schreibtisch */}
                          </Box>
                        </Box>
                      }
                    />
                    <Tab
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            py: 3,
                            gap: "20px",
                            borderBottom:
                              step === 3
                                ? "2px solid #E54257"
                                : step > 3
                                ? "2px solid #000"
                                : "2px solid #B1B5C3",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              backgroundColor:
                                step === 3
                                  ? "#E54257"
                                  : step > 3
                                  ? "#000"
                                  : "#B1B5C3",
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            3
                          </Box>
                          <Box
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              color:
                                step === 3
                                  ? "#E54257"
                                  : step > 3
                                  ? "#000"
                                  : "#B1B5C3",
                            }}
                          >
                            {t("WorkspaceFlow_Step3")}
                            {/* Schreibtisch */}
                          </Box>
                        </Box>
                      }
                    />
                  </Tabs>
                </Box>
                <div
                  style={{
                    padding: "7% 0%",
                    margin: "0 auto",
                  }}
                >
                  {goToStep()}
                </div>
                {/*Add next step button*/}
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                    mt: 5,
                  }}
                >
                  {step > 1 && step !== 4 && (
                    <Button
                      variant="filled"
                      size="large"
                      onClick={() => {
                        if (step - 1 === 1) {
                          goBackMap();
                        }
                        setStep(step - 1);
                      }}
                      startIcon={
                        <Iconify
                          icon={directionStraightRight}
                          sx={{ width: 16, height: 16, color: "white" }}
                        />
                      }
                      sx={{
                        backgroundColor: "#E54257",
                        color: "white",
                        px: 4,
                        borderRadius: "90px",
                      }}
                    >
                      {t("Button_GoBack")}
                    </Button>
                  )}
                  {step !== 4 && (
                    <Button
                      variant="filled"
                      size="large"
                      onClick={() => handleChange(step + 1)}
                      endIcon={
                        <Iconify
                          icon={directionStraightLeft}
                          sx={{ width: 16, height: 16, color: "white" }}
                        />
                      }
                      sx={{
                        backgroundColor: "#E54257",
                        color: "white",
                        px: 4,
                        borderRadius: "90px",
                      }}
                    >
                      {step !== 3 ? "Weiter" : "Fertigstellen"}
                    </Button>
                  )}
                </Box>
              </Container>
              <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
            </RootStyle>
          </Page>
        </Layout>
      )}
    </>
  );
}
