import React, { useEffect, useState } from "react";
import { Link as LinkReact, useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
// hooks
import useRequest from "../../../hooks/useRequest";
// @mui
import { styled } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Divider,
  Collapse,
  Container,
  Typography,
  Box,
  Button,
  Avatar,
  /* Link, */
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
// layouts
import Layout from "../../../layouts";
// components
import {
  Page,
  ErrorScreen,
  LoadingScreen,
  Breadcrumbs,
  SocialsButton,
  ShareButton,
  FavoriteButton,
  TextIconLabel,
  RatingLabel,
  Iconify,
} from "../../../components";
// sections
import {
  ReviewForm,
  ReviewTravelTourList,
  ReviewTravelToolbar,
} from "../../../sections/reviews";
import {
  TravelTourHeader,
  TravelTourGallery,
  TravelTourDetails,
  TravelTourSimilar,
  TravelTourGuideInfo,
  TravelTourReserveForm,
  OfficeDescriptionDetails,
} from "../../../sections/@travel";
import Map from "../../../sections/@travel/tours/Map";
import AvailableDays from "../../../sections/@travel/tours/AvailableDays";
import { tag_list } from "../../../mocks/tag_list";
import verified from "../../../assets/images/check.svg";

import locationIcon from "@iconify/icons-carbon/location";
import directionStraightRight from "@iconify/icons-carbon/chevron-left";
import timeIcon from "@iconify/icons-carbon/time";

import { useTranslation } from "react-i18next";
import { Calendar } from "react-calendar";
import config from "../../../config";
import axios from "axios";
import { useGlobalContext } from "../../../stores/global";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));
// ----------------------------------------------------------------------

export const Workplace = () => {
  let params = useParams();
  const { t } = useTranslation();

  //Fetch information workplace detail
  const {
    data: workplace,
    error: workplaceError,
    mutate,
  } = useRequest({
    url: params.id ? `/office/workplace/${params.id}` : "",
  });

  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let tags = tag_list.filter((item) => item.category);
    setTags(tags);
    let categories = tags.map((item) => {
      return item.category;
    });
    categories = categories.filter(
      (item, index) => categories.indexOf(item) === index
    );
    console.log(categories);
    //put Specials in second position
    let specials = categories.indexOf("specials");
    //get the element
    let specialsElement = categories[specials];
    categories.splice(specials, 1);
    categories.splice(1, 0, specialsElement);

    setCategories(categories);
  }, []);

  const [sort, setSort] = useState("latest");
  const [openReview, setOpenReview] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [value, onChange] = useState(new Date());

  const handleSelect = (ranges) => {
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  const [ratingData, setRatingData] = useState({
    success: true,
    data: {
      rating: 0,
      reviews: 0,
    },
  });
  useEffect(() => {
    if (workplace) {
      console.log(workplace);
      setFavorite(workplace.data.favorite);
      //get rating of user
      axios
        .get(
          `${config.api.host}/users/get/review/rating/${workplace?.data?.user?.id}`
        )
        .then((res) => {
          setRatingData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [workplace]);

  const { user } = useGlobalContext();

  const handleChangeFavorite = (event) => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    if (event.target.checked) {
      axios
        .post(`${config.api.host}/office/favorite/${workplace.data.id}`, {})
        .then((res) => {
          setFavorite(true);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      axios
        .delete(`${config.api.host}/office/favorite/${workplace.data.id}`, {})
        .then((res) => {
          setFavorite(false);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const goToReviews = () => {
    let div_review = document.getElementById("container-reviews");
    //scroll to position div with smooth
    div_review.scrollIntoView({ behavior: "smooth" });
  };

  if (workplaceError) {
    return <ErrorScreen />;
  }

  if (!workplace) {
    return <LoadingScreen />;
  }

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  return (
    <Layout>
      <Page title={`${workplace.data.title} - Travel`}>
        <RootStyle>
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  window.history.back();
                }}
                startIcon={
                  <Iconify
                    icon={directionStraightRight}
                    sx={{ width: 16, height: 16, color: "black" }}
                  />
                }
                sx={{
                  display: { xs: "none", md: "inline-flex" },
                  color: "black",
                  fontWeight: "700",
                  border: "2px solid #E6E8EC",
                  borderRadius: "90px",
                  textTransform: "none",
                }}
              >
                {t("Button_GoBack")}
              </Button>
              <Breadcrumbs
                sx={{ color: "#B1B5C4", fontWeight: "700" }}
                links={[
                  { name: "Home", href: "/" },
                  { name: "Workspaces", href: "/search" },
                  { name: workplace.data.title },
                ]}
              />
            </Box>

            <Box>
              <Stack
                spacing={3}
                direction={{ xs: "column", md: "row" }}
                sx={{
                  mb: { xs: 3, md: 2 },
                }}
              >
                <Typography
                  variant="h3"
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  component="h1"
                  sx={{ flexGrow: 1, pr: { md: 10 }, color: "#23262F" }}
                >
                  {workplace.data.title}{" "}
                  {workplace.data.user.stripe_id && (
                    <Tooltip title="Verified User">
                      <img src={verified} alt="verified" width="40px" />
                    </Tooltip>
                  )}
                </Typography>
                <Stack direction="row" alignItems="center" flexShrink={0}>
                  <ShareButton />
                  {user && (
                    <FavoriteButton
                      checked={favorite}
                      onChange={handleChangeFavorite}
                    />
                  )}
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                <LinkReact to={"/user/" + workplace.data.user.id}>
                  <Avatar
                    src={workplace.data.user.profilepicture}
                    sx={{ width: 34, height: 34 }}
                  />
                </LinkReact>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={goToReviews}
                >
                  <RatingLabel
                    className="noWrapText"
                    ratings={workplace?.data?.rating}
                    reviews={workplace?.data?.allReviews.length || 0}
                  />
                </Box>
                <TextIconLabel
                  className="noWrapText"
                  icon={
                    <Iconify
                      icon={locationIcon}
                      sx={{ width: 20, height: 20, mr: 0.5 }}
                    />
                  }
                  value={
                    workplace.data.office.address.split(",")[1] +
                    ", " +
                    workplace.data.office.address.split(",")[2]
                  }
                />
              </Stack>
            </Box>

            <TravelTourGallery gallery={workplace.data.imageList} />

            <Grid container spacing={8} direction="row-reverse">
              <Grid item xs={12} md={5} lg={4}>
                <TravelTourReserveForm tour={workplace} />
                <Card sx={{ marginTop: 5, backgroundColor: "#F4F5F6" }}>
                  <CardContent sx={{ p: 3 }}>
                    <Grid item xs={12} md={12}>
                      <section>
                        <Typography variant="h4" paragraph>
                          <Iconify
                            icon={timeIcon}
                            sx={{ width: 20, height: 20, mr: 1 }}
                          />
                          {t("OpeningHours_WorkspaceOverview")}
                        </Typography>
                        <div
                          className="workplace__profile-image-wrapper"
                          style={{ marginLeft: "25px" }}
                        >
                          <div>
                            {workplace.data.openedMonday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Montag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedMondayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedMondayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedTuesday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Dienstag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedTuesdayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedTuesdayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedWednesday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Mittwoch
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedWednesdayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedWednesdayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedThursday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Donnerstag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedThursdayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedThursdayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedFriday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Freitag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedFridayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedFridayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedSaturday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Samstag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedSaturdayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedSaturdayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                            {workplace.data.openedSunday ? (
                              <div className="flex-container">
                                <Typography variant="h6" paragraph>
                                  Sonntag
                                </Typography>
                                <Typography variant="h6" paragraph>
                                  {workplace.data.openedSundayFrom.replace(
                                    "-",
                                    ":"
                                  )}{" "}
                                  -{" "}
                                  {workplace.data.openedSundayTo.replace(
                                    "-",
                                    ":"
                                  )}
                                </Typography>
                              </div>
                            ) : null}
                          </div>
                          <div style={{ opacity: 0.3 }}>
                            {!workplace.data.openedMonday ? (
                              <Typography variant="h6" paragraph>
                                Montag
                              </Typography>
                            ) : null}
                            {!workplace.data.openedTuesday ? (
                              <Typography variant="h6" paragraph>
                                Dienstag
                              </Typography>
                            ) : null}
                            {!workplace.data.openedWednesday ? (
                              <Typography variant="h6" paragraph>
                                Mittwoch
                              </Typography>
                            ) : null}
                            {!workplace.data.openedTuesday ? (
                              <Typography variant="h6" paragraph>
                                Donerstag
                              </Typography>
                            ) : null}
                            {!workplace.data.openedFriday ? (
                              <Typography variant="h6" paragraph>
                                Freitag
                              </Typography>
                            ) : null}
                            {!workplace.data.openedSaturday ? (
                              <Typography variant="h6" paragraph>
                                Samstag
                              </Typography>
                            ) : null}
                            {!workplace.data.openedSunday ? (
                              <Typography variant="h6" paragraph>
                                Sonntag
                              </Typography>
                            ) : null}
                          </div>
                        </div>
                      </section>
                    </Grid>
                  </CardContent>
                </Card>
                <Card sx={{ marginTop: 5 }}>
                  <CardContent sx={{ p: 3 }}>
                    <Grid item xs={12}>
                      <Typography variant="h4" paragraph>
                        {t("Availability_WorkspaceOverview")}
                      </Typography>
                      <Calendar
                        onChange={onChange}
                        value={value}
                        next2Label={null}
                        prev2Label={null}
                        minDate={new Date()}
                        showNeighboringMonth={false}
                        locale={"de"}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={7} lg={8}>
                <TravelTourHeader
                  tour={workplace}
                  categories={categories}
                  tagsAmenities={tags}
                />
                <TravelTourDetails
                  tour={workplace}
                  tags={tags}
                  categories={categories}
                  setTags={setTags}
                />
              </Grid>
            </Grid>
          </Container>
          <Divider sx={{ my: { xs: 10, md: 8 } }} />
          <Container id="container-reviews">
            <Grid container spacing={8}>
              <Grid item xs={12} md={5} lg={4}>
                <TravelTourGuideInfo tour={workplace} ratingData={ratingData} />
              </Grid>

              <Grid item xs={12} md={7} lg={8}>
                <ReviewTravelToolbar
                  totalReview={workplace?.data?.allReviews.length || 0}
                  workplace={workplace.data}
                  onOpenReview={() => setOpenReview(!openReview)}
                  sort={sort}
                  onChangeSort={handleChangeSort}
                />
                <Collapse in={openReview}>
                  <ReviewForm
                    mutate={mutate}
                    workplace={workplace.data}
                    onClose={() => setOpenReview(false)}
                  />
                </Collapse>
                <ReviewTravelTourList workplace={workplace.data} />
              </Grid>
            </Grid>
          </Container>

          {/*<TravelTourSimilar tours={tours.slice(-4)} /> */}
        </RootStyle>
      </Page>
    </Layout>
  );
};
