// icons
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Stack, Container, Grid, Link, Divider } from '@mui/material';
// components
import { SocialsButton, Iconify, TextIconLabel } from '../../components';
import ElearningContactForm from './ElearningContactForm';

import { useTranslation } from 'react-i18next';
//
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

// ----------------------------------------------------------------------

export default function ElearningContactInfo() {
  const { t } = useTranslation();
  return (
    <RootStyle>
      <Container>
        <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h2"
              sx={{
                mb: 5,
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {t("Support_MainHeadline")}
            </Typography>

            <Stack spacing={3} alignItems={{ xs: 'center', md: 'flex-start' }}>
              <Stack spacing={1}>
                <TextIconLabel
                  icon={<Iconify icon={emailIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                  value="Email"
                  sx={{ typography: 'subtitle2' }}
                />
                <Link color="inherit" variant="body2" href="mailto:contact@twimyo.com">
                  contact@twimyo.com
                </Link>
              </Stack>

              <Stack spacing={1}>
                <TextIconLabel
                  icon={<Iconify icon={mobileIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                  value={t('Support_Phone')}
                  sx={{ typography: 'subtitle2' }}
                />

                <Typography variant="body2">+49 89 25 00 662 66</Typography>
              </Stack>

              <Stack spacing={1}>
                <TextIconLabel
                  icon={<Iconify icon={locationIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                  value={t("Support_Address")}
                  sx={{ typography: 'subtitle2' }}
                />
                <Typography variant="body2">
                  Garmischer Straße 4, 80339 München
                </Typography>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed', width: 1 }} />

              <Stack spacing={1} alignItems={{ xs: 'center', md: 'flex-start' }}>
                <Typography variant="overline">
                  {t('FollowUs')}
                </Typography>
                <SocialsButton sx={{ color: 'text.primary' }} />
              </Stack>
            </Stack>
          </Grid>
          <ElearningContactForm />
        </Grid>
      </Container>
    </RootStyle>
  );
}
