import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Container from "./Container";
import { useRequest } from "../../../hooks";
import { Tab, Tabs } from "@mui/material";
import { tag_user } from "../../../mocks/tag_user";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const UserCardGrid = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [selected, setSelected] = useState(t('LocalOverviewNavigation1'));
  const categories = [`${t('LocalOverviewNavigation1')}`, `${t('LocalOverviewNavigation2')}`, `${t('LocalOverviewNavigation3')}`, `${t('LocalOverviewNavigation4')}`, `${t('LocalOverviewNavigation5')}`];
  /* ['All', 'Entrepreneurship', 'Work Hard', 'Eating together', 'Learn about the City']; */


  //get tag of params
  const { tag } = useParams();

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
  };

  const [users, setUsers] = useState([])

  const { data: userData, error: userError } = useRequest({
    url: `/users/getall`,
  });

  useEffect(() => {
    tag_user.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
  })

  const [filterIds, setFilterIds] = useState([]);
  const [indexSelected, setIndexSelected] = useState(0);
  const searchIds = (index) => {
    setIndexSelected(index);
    switch (index) {
      case 0:
        setFilterIds([]);
        break;
      case 1:
        setFilterIds(tag_user.filter(tag => tag.persona.includes("high performer")));
        break;
      case 2:
        setFilterIds(tag_user.filter(tag => tag.persona.includes("foodie")));
        break;
      case 3:
        setFilterIds(tag_user.filter(tag => tag.persona.includes("explorer")));
        break;
      case 4:
        setFilterIds(tag_user.filter(tag => tag.persona.includes("athlete")));
        break;
      default:
        setFilterIds([]);
        break;
    }
  }

  //get name of tag
  const getNameTag = () => {
    let tagZ = tag_user.find(tagR => tagR.id === tag);
    return tagZ ? tagZ.name : "Users";
  }

  useEffect(() => {
    console.log(userData)
    if (tag) {
      let data = userData?.data ? userData?.data.users : []
      //filter by tag id
      let filterData = data.filter(user => (user.tag_user || "").includes(tag))
      setUsers(filterData.slice(0, 16));
    } else {
      setUsers(userData?.data ? userData?.data.users.reverse().slice(0, 16) : [])
    }
  }, [userData])

  return (
    <Box bgcolor={"alternate.main"}>
      <Container>
        {/*title of page with description center h2*/}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          p={2}
          m={2}
          bgcolor={"background.paper"}
          borderRadius={"borderRadius"}
          boxShadow={"boxShadow"}
        >
          <Typography variant={"h2"}>
            <Box fontWeight={"fontWeightBold"}>
              {getNameTag()}
            </Box>
          </Typography>
          <Typography variant={"body1"}>
            <Box fontWeight={"fontWeightBold"}>
              User community
            </Box>
          </Typography>
        </Box>
        {/*end of title of page with description*/}
        <Grid container spacing={4}>
          {users.map((user, i) => {
            let filter = []
            if (user.tag_user) {
              filter = filterIds.filter(tag => user.tag_user.includes(tag.id))
            }
            if (filter.length > 0 && indexSelected !== 0) {
              return user.profilepicture && (
                <Grid key={i} item xs={12} sm={4}>
                  <Card
                    sx={{
                      p: { xs: 2, md: 4 },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: 1,
                      height: 1,
                      background: "transparent",
                      backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 75%, ${theme.palette.primary.main} 0%)`,
                    }}
                  >
                    <Avatar
                      src={user.profilepicture}
                      variant={"circular"}
                      sx={{
                        width: 120,
                        height: 120,
                      }}
                    />
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      marginTop={2}
                    >
                      <Typography fontWeight={700}>{user.first_name} {user.last_name}</Typography>

                      <Box
                        component={"svg"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={22}
                        height={22}
                        color={"primary.main"}
                        marginLeft={1}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                        />
                      </Box>
                    </Box>
                    <Typography color={'#777E91'}>{user.job}</Typography>
                    <Box flexGrow={1} />
                    <Stack
                      spacing={2}
                      marginTop={4}
                      width={1}
                      alignItems={"center"}
                    >
                      <Button
                        component={"a"}
                        variant={"outlined"}
                        color={"primary"}
                        href={`/user/${user.id}`}
                        fullWidth
                      >
                        View profile
                      </Button>
                    </Stack>
                  </Card>
                </Grid>)
            } else {
              if (indexSelected === 0) {
                return user.profilepicture && (
                  <Grid key={i} item xs={12} sm={4}>
                    <Card
                      sx={{
                        p: { xs: 2, md: 4 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: 1,
                        height: 1,
                        background: "transparent",
                        backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 75%, ${theme.palette.primary.main} 0%)`,
                      }}
                    >
                      <Avatar
                        src={user.profilepicture}
                        variant={"circular"}
                        sx={{
                          width: 120,
                          height: 120,
                        }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        marginTop={2}
                      >
                        <Typography fontWeight={700}>{user.first_name} {user.last_name}</Typography>
                        {user.stripe_id && (
                          <Box
                            component={"svg"}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={22}
                            height={22}
                            color={"primary.main"}
                            marginLeft={1}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                            />
                          </Box>)}
                      </Box>
                      <Typography color={'#777E91'}>{user.job}</Typography>
                      <Box flexGrow={1} />
                      <Stack
                        spacing={2}
                        marginTop={4}
                        width={1}
                        alignItems={"center"}
                      >
                        <Button
                          component={"a"}
                          variant={"outlined"}
                          color={"primary"}
                          href={`/user/${user.id}`}
                          fullWidth
                        >
                          View profile
                        </Button>
                      </Stack>
                    </Card>
                  </Grid>)
              }
            }
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default UserCardGrid;
