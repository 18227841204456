import React from "react";
import Page from "../../components/Page";
import Layout from "../../layouts";
import UserCardGrid from "../../sections/@travel/userGrid/UserCardGrid";

const Users = () => {
  return (
    <Layout>
      <Page>
        <UserCardGrid />;
      </Page>
    </Layout>
  )
};

export default Users;