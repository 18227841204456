/* import PropTypes from 'prop-types'; */
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  /* Card, */ Button,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// hooks
import { useBoundingClientRect, useRequest } from "../../../hooks";
// components
import {
  CarouselArrows /*  Image, Iconify, SvgIconStyle  */,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
/* import config from '../../../config'; */
import TravelTourItem from "../tours/TravelTourItem";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 15),
    padding: theme.spacing(12, 0),
  },
}));

const CarouselArrowsStyle = styled(CarouselArrows)(({ theme }) => ({
  position: "unset",
  justifyContent: "center",
  "& button": {
    borderRadius: "50%",
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

// ----------------------------------------------------------------------

export default function ShowWorkplaces({ needspaceCenter }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(t("WorkspacesNavigation1"));
  const [index, setIndex] = useState(0);
  const categories = [
    `${t("Button_RecentlyAdded")}`,
    `${t("WorkspacesNavigation1")}`,
    `${t("WorkplacesNavigation2")}`,
    `${t("WorkplacesNavigation3")}`,
    `${t("WorkplacesNavigation4")}`,
    `Offices`,
    `Event locations`,
    `${t("WorkplacesNavigation5")}`,
  ];
  /* ['All', 'Focus', 'Meetings', 'Events & Netzwerk']; */

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
  };

  // ----------------------------------------------------------------------

  const theme = useTheme();

  const [workplaces, setWorkplaces] = useState([]);
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const container = useBoundingClientRect(containerRef);

  const { data: workplacesData, error: workplacesError } = useRequest({
    url: `/office/workplaces`,
  });

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && workplacesData?.data) {
      setWorkplaces(
        workplacesData?.data
          ? workplacesData?.data
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            ///check if has images
            .filter((item) => item.images.length > 0)
            .slice(0, 16)
          : []
      );

      setInit(true);
    }
  }, [workplacesData, init]);


  //reorder workplaces by created_at if index is 1, if not, reorder put workplaceData
  const changeOrder = (index) => {
    console.log("index", index);
    setIndex(index);
    if (index === 1) {

      let workspacesArr = workplacesData?.data ? workplacesData?.data : [];
      let arrId = [
        "70ad0746-0f18-4692-a17d-648a25025548",
        "fe28d507-54ba-4a08-b529-59f81b3c20be",
        "576eb70a-2f69-4927-9812-3971c249d8f1",
        "c4a49aa5-9efd-4ff6-b392-eecccf035c3f",
        "ed237f9c-0fc5-4352-9b0c-6b0347cb7b51",
        "92268872-0d96-404b-8a9b-57235f81f999"
      ];

      let workspaceFinal = [];

      arrId.forEach((id) => {
        let index = workspacesArr.findIndex((workspace) => workspace.id === id);
        if (index !== -1) {
          let workspace = workspacesArr[index];
          workspaceFinal.push(workspace);
        }
      });

      workspaceFinal = [...workspaceFinal];
      setWorkplaces(workspaceFinal);
    } else {
      if (index == 2) {
        setWorkplaces(
          workplacesData?.data
            ? workplacesData?.data
              .filter((item) => item.images.length > 0 && item.type == "desk")
              .slice(0, 16)
            : []
        );
      } else {
        if (index == 3) {
          setWorkplaces(
            workplacesData?.data
              ? workplacesData?.data
                .filter((item) => item.images.length > 0 && item.type == "meetingroom")
                .slice(0, 16)
              : []
          );
        } else {
          if (index == 4) {
            setWorkplaces(
              workplacesData?.data
                ? workplacesData?.data
                  .filter((item) => item.images.length > 0 && item.type == "studio")
                  .slice(0, 16)
                : []
            );
          } else {
            if (index == 5) {
              setWorkplaces(
                workplacesData?.data
                  ? workplacesData?.data
                    .filter((item) => item.images.length > 0 && item.type == "office")
                    .slice(0, 16)
                  : []
              );
            } else {
              if (index == 6) {
                setWorkplaces(
                  workplacesData?.data
                    ? workplacesData?.data
                      .filter((item) => item.images.length > 0 && item.type == "event")
                      .slice(0, 16)
                    : []
                );
              } else {
                if (index == 7) {
                  setWorkplaces(
                    workplacesData?.data
                      ? workplacesData?.data
                        .filter((item) => item.images.length > 0 && item.type == "misc")
                        .slice(0, 16)
                      : []
                  );
                } else {
                  setWorkplaces(
                    workplacesData?.data
                      ? workplacesData?.data
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        ///check if has images
                        .filter((item) => item.images.length > 0)
                        .slice(0, 16)
                      : []
                  );
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: {
              xs: "center",
              md: needspaceCenter ? "center" : "unset",
            },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">
              {t("WorkspacesOverview_Headline")}
            </Typography>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t("WorkspacesOverview_Subline")}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            pt: 2,
            pb: { xs: 0, md: 0 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={selected}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            onChange={handleChangeCategory}
          >
            {categories.map((category, indexTab) => (
              <Tab
                sx={{
                  backgroundColor:
                    index === indexTab ? "#353945 !important" : "transparent",
                  color:
                    index === indexTab
                      ? "#FCFCFD !important"
                      : "#777E91 !important",
                  borderRadius: index === indexTab ? "15px" : "0px",
                  marginRight: "25px !important",
                  paddingX: index === indexTab ? "20px !important" : "0px !important",
                }}
                onClick={() => changeOrder(indexTab)}
                key={category}
                value={category}
                label={category}
              />
            ))}
          </Tabs>
          {/*           <Box
            sx={{
              width: "20%",
              display: { xs: "none", md: "flex" },
            }}
          >
            <FormControl
              sx={{
                backgroundColor: "white !important",
                color: "black",
                borderRadius: "15px",
              }}
              variant="filled"
              fullWidth
            >
              <InputLabel
                sx={{ color: "black", fontWeight: "bold" }}
                id="demo-simple-select-label"
              >
                {t("Button_RecentlyAdded")}
              </InputLabel>
              <Select
                sx={{
                  backgroundColor: "white !important",
                }}
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: 3,
            flexWrap: "wrap",
            marginY: 3,
          }}
        >
          {workplaces.slice(0, 6).map((workplace) => {
            return (
              <Box
                key={workplace.id}
                sx={{
                  width: { xs: "100%", sm: "40%", lg: "30%" },
                }}
              >
                <TravelTourItem
                  key={workplace.id}
                  workplace={{
                    ...workplace,
                    address: workplace.office.address,
                    imageList: workplace.images,
                  }}
                />
              </Box>
            );
          })}
        </Box>

        <Box sx={{ textAlign: "center", marginTop: "10px" }}>
          <Link to={"/search"}>
            <Button
              size="large"
              sx={{
                textTransform: "none",
                color: "black",
                border: "1px solid #ccc",
                borderRadius: "15px",
              }}
            >
              {/* Alle Workspaces anzeigen */}
              {t("WorkspaceOverview_Button")}
            </Button>
          </Link>
        </Box>
      </Container>
    </RootStyle>
  );
}
