// @mui
import { styled } from '@mui/material/styles';
import { Page } from '../../components';
import Layout from '../../layouts';
import { ElearningContactInfo } from '../../sections/contact';
import BannerContact from '../../sections/contact/BannerContact';
import FaqContact from '../../sections/contact/FaqContact';
//import SectionButtonContact from '../../sections/contact/SectionButtonContact';
// sections

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

// ----------------------------------------------------------------------

export default function ElearningContactUsPage() {
  return (
    <Layout>
      <Page title="Contact Us - E-Learning">
        <RootStyle>
          <BannerContact />
          {/* <SectionButtonContact /> */}
          <FaqContact />
        </RootStyle>
      </Page>
    </Layout>
  );
}

