/* import PropTypes from "prop-types"; */
import React, { useEffect, useState } from "react";
/* import { useRef } from "react"; */
// @mui
import { Box, Container, Divider, /* , Grid */ 
Modal} from "@mui/material";
// layouts
import Layout from "../../layouts";
// components
import { Page /*  ErrorScreen, Image  */ } from "../../components";
// sections
import { TravelTourBarFilters } from "../../sections/@travel";
import ShowWorkplaces from "../../sections/@travel/showWorkplaces/ShowWorkplaces";
import SliderCities from "../../sections/@travel/sliderCities/SliderCities";
import Testimonials from "../../sections/@travel/testimonials/Testimonials";
import {
  CareerLandingHero,
  TravelLandingIntroduce,
} from "../../sections/@career";

import "./Landing.scss";
//Sections
import LandingServices from "../../sections/@travel/landingServices/LandingServices";
import LandingBuenaventura from "../../sections/@travel/landingBuenaventura/LandingBuenaventura";
import SliderUser from "../../sections/@travel/sliderUser/SliderUser";
import ShowTypes from "../../sections/@travel/showTypes/showTypes";
import styled from "@emotion/styled";
import MemoriesTravel from "../../sections/@travel/memoriesTravel/memoriesTravel";
import HowItWorks from "../../sections/@travel/howItWorks/howItWorks";
import ShareWorkspace from "../../sections/@travel/shareWorkspace/shareWorkspace";
import ExploreNearby from "../../sections/@travel/exploreNearby/exploreNearby";
import VideoZone from "../../sections/@travel/videoZone/videoZone";
import ModalReview from "../../sections/modalReview";
import { useGlobalContext } from "../../stores/global";
import axios from "axios";
import config from "../../config";

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

export const Landing = () => {
  const { user } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [reviewUser, setReviewUser] = useState(null);
  const [reviewWorkplace, setReviewWorkplace] = useState(null);
  const [reviewBooking, setReviewBooking] = useState(null);
  //get query params
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if(urlParams.get("reviewUser") && urlParams.get("reviewWorkplace") && urlParams.get("reviewBooking")){
      setReviewUser(urlParams.get("reviewUser"));
      setReviewWorkplace(urlParams.get("reviewWorkplace"));
      setReviewBooking(urlParams.get("reviewBooking"));
      let body = {
        workplace_id: urlParams.get("reviewWorkplace"),
        user_id: urlParams.get("reviewUser"),
        booking_id: urlParams.get("reviewBooking"),
      };
      console.log(body);
      if (user) {
        axios.defaults.headers.common = {
          Authorization: user.token,
        };
        axios
          .post(`${config.api.host}/users/can/create/review`, body)
          .then((res) => {
            console.log(res);
            if(res.data.success){
                setOpen(true);
                setUserData(res.data.data.first_name);
            }else{
                alert("You can't review this booking")
                window.location.href = "/";
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        alert('You must be logged in to leave a review');
      }
    }
  }, [])

  return (
    <Layout>
      <Page title="Home - Twimyo">
        <RootStyle>
          <Box sx={{ position: "relative" }}>
            <CareerLandingHero />
            <Container
              maxWidth={{xs:false, xxl:true}}
              sx={{
                left: 0,
                right: 0,
                bottom: { xs: "0%", sm: "0%", md: "calc(-10% - 1em)", lg: "calc(-12% - 1em)", xxl: "calc(0% - 1em)" },
                px: { xs: 0, md: "5%", lg:"5%", xxl: "10%" },
                mx: "auto",
                width: {xs:"90%", xxl:"100%"},
                position: { md: "absolute" },
              }}
            >
              <TravelTourBarFilters onDark sx={{ py: { xs: 3, md: 10 } }} />
            </Container>
          </Box>
          <SliderUser />
          <VideoZone />
          <ShowWorkplaces />
          {/* <ShowTypes /> */}
          <LandingBuenaventura />
          <MemoriesTravel />
          <HowItWorks />
          {/* <LandingServices /> */}
          {/* <ShareWorkspace /> */}
          <SliderCities />
          {/* <ExploreNearby /> */}
          <Testimonials />
          <ModalReview open={open} setOpen={setOpen} reviewUser={reviewUser} reviewWorkplace={reviewWorkplace} reviewBooking={reviewBooking} userData={userData} />
        </RootStyle>
      </Page>
    </Layout>
  );
};
