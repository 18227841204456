import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: `Datenschutzhinweise
    Vorwort`,
    description: `Wir, die Twimyo GmbH (nachfolgend auch „Twimyo, „wir“ oder „uns“ genannt) nehmen den Schutz personenbezogener Daten sehr ernst und möchten Sie, als betroffene Person (nachfolgend auch als „Nutzer“, „Kunde“, „Sie“, „Ihnen“ oder „Betroffene/r“ genannt), hier über den Datenschutz bei Twimyo informieren. Wir verarbeiten Ihre Daten in Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz personenbezogener Daten, insbesondere der Europäischen Datenschutz-Grundverordnung (Verordnung (EU) 2016/679 im Folgenden auch „DSGVO“) und den für uns geltenden landesspezifischen Ausführungsgesetzen.
    Sofern und soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die Pflicht, Sie transparent über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14 DSGVO).
    Im Rahmen dieser Datenschutzhinweise (nachfolgend auch "Datenschutzerklärung" oder „Datenschutzhinweise“ genannt) informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen Daten von uns verarbeitet werden.
    Weitere rechtliche Informationen:
    Bezüglich der Twimyo Plattform:`,
  },
  {
    title: `Verantwortlicher & Datenschutzbeauftragte des Verantwortlichen:`,
    description: `Verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DSGVO:
    Twimyo GmbH
    Geschäftsführer: Gregor Gebhardt
    Garmischer Str. 4 
    80339 München, E-Mail: contact@twimyo.com, Tel. + 49 89 25 00 66 2 66
    
    Weitere Angaben zum Unternehmen finden Sie in unserem Impressum. [LINK zu Impressum]`,
  },
  {
    title: `II. Allgemeines
    1. Begriffsbestimmungen`,
    description: `Die Begriffsbestimmungen dieser Datenschutzerklärung liegen orientieren sich an den Definitionen des Art. 4 DSGVO:
    ▪ "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person („betroffene Person“) beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert werden kann (Art. 4 Nr. 1 DSGVO). 
    ▪ "Verarbeitung" ist jeder - mit oder ohne Hilfe automatisierter Verfahren - Vorgang, mit Bezug auf personenbezogenen Daten, wie etwa insbesondere das Erheben (d.h. die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten (Art. 4 Nr. 2 DSGVO).
    ▪ "Verantwortlicher" ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet (Art. 4 Nr. 7 DSGVO).
    ▪ "Auftragsverarbeiter" ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen (weisungsabhängig und nicht zu eigenen Zwecken) verarbeitet (Art. 4 Nr. 8 DSGVO).
    ▪ "Dritter" ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten (Art. 4 Nr. 10 DSGVO).
    ▪ "Einwilligung" der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist (Art. 4 Nr. 11 DSGVO).`,
  },
  {
    title: `2. Umfang der Verarbeitung personenbezogener Daten`,
    description: `Eine Verarbeitung der personenbezogenen Daten unserer Nutzer erfolgt nur insoweit, wie dies zur Bereitstellung einer funktionsfähigen Internetseite erforderlich und für die Darstellung unserer Inhalte und Leistungen notwendig ist. Die personenbezogenen Daten unserer Nutzer werden regelmäßig nur nach Einwilligung des Nutzers verarbeitet oder in den Fällen, in denen die Verarbeitung durch eine gesetzliche Vorschrift gestattet ist und damit eine Rechtsgrundlage für die Verarbeitung besteht.`,
  },
  {
    title: `3. Rechtsgrundlagen für die Verarbeitung personenbezogener Daten`,
    description: `Sofern nicht gesondert im Rahmen dieser Datenschutzerklärung benannt, gilt Folgendes -Rechtsgrundlage
    ▪ bei Vorliegen einer Einwilligung ist Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO).
    ▪ zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, Art. 6 Abs. 1 lit. b DSGVO. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
    ▪ zur Erfüllung einer rechtlichen Verpflichtung, der wir unterliegen, ist Art. 6 Abs. 1 lit. c DSGVO.
    ▪ bei lebenswichtigen Interessen der betroffenen Person oder einer anderen natürlichen Person, ist Art. 6 Abs. 1 lit. d DSGVO.
    ▪ Art. 6 Abs. 1 lit. e DSGVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder
    ▪ zur Wahrung unseres berechtigten Interesses oder das eines Dritten, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht überwiegen, ist Art. 6 Abs. 1 lit. f DSGVO.`,
  },
  {
    title: `4. Datenlöschung und Speicherdauer`,
    description: `Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, so werden personenbezogene Daten der betroffenen Person gelöscht oder gesperrt sobald der einer Speicherung zugrundeliegende Zweck entfällt. Über diesen Zeitpunkt hinaus kann eine Speicherung dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Es können Aufbewahrungs- und Dokumentationspflichten - etwa aus dem Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) – die eine Pflicht zur Aufbewahrung bzw. Dokumentation von bis zehn Jahren über das Ende der Geschäftsbeziehung bzw. des vorvertraglichen Rechtsverhältnisses hinaus vorsehen, bestehen.`,
  },
  {
    title: `III. SSL- bzw. TLS-Verschlüsselung und Datensicherheit`,
    description: `Wir nutzen auf unserer Website aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte (z.B. Anfragen), die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung ist daran zu erkennen, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in der Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können an uns übermittelte Daten, nicht von Dritten mitgelesen werden.
    Drüber verbessern wir fortlaufend unsere Sicherheitsmaßnahmen entsprechend der technologischen Entwicklung. Auch bedienen wir uns stets geeigneter technischer und organisatorischer Maßnahmen, um Ihre Daten unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) für den Betroffenen gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen (z.B. Verschlüsselung).`,
  },
  {
    title: `IV. Bereitstellung der Website und Erstellung von Logfiles
    1. Beschreibung und Umfang der Datenverarbeitung`,
    description: `Wird unsere Website von einem Nutzer aufgerufen, so erfasst unser System, mit jedem Aufruf, automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Bei der bloßen informatorischen Nutzung unserer Website werden nur die personenbezogenen Daten erhoben, die der Browser des Nutzers an unseren Server bzw. Provider übermittelt und die technisch notwendig sind, zu dem Zweck, Nutzern unsere Website anzuzeigen und deren Stabilität und Sicherheit gewährleisten zu können.
    Wird unsere Website von einem Nutzer aufgerufen, so erfasst unser System, mit jedem Aufruf, automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Hierzu gehören standardmäßig: 
    ▪ der verwendete Browsertyp und die Browserversion (sofern vom Benutzer übermittelt), 
    ▪ das verwendete Betriebssystem, 
    ▪ Datum und Uhrzeit der Serveranfrage, 
    ▪ die anonymisierte IP-Adresse des anfragenden Rechners 
    ▪ die Anzahl der Besuche, 
    ▪ die Verweildauer auf der Website, 
    ▪ die vorher besuchte Website (sofern vom Benutzer übermittelt), 
    ▪ übertragene Datenmenge, 
    ▪ Zeitzonendifferenz zur Greenwich Mean Time (GMT), 
    Diese Daten werden in den Logfiles gespeichert. Es findet keine gemeinsame Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers statt.
    Für das Hosting unserer Webseiten sowie für statistische Auswertungen der Protokolldaten setzen wir IT-Dienstleister ein, die diese in unserem Auftrag hosten und warten. Für das Hosting und die technische Bereitstellung unserer Website die Firma Hetzner Online GmbH, Industriestraße 25, 91710 Gunzenhausen (im Folgenden „Hetzner“) beauftragt. Wir haben mit Hetzner die datenschutzrechtlich erforderliche Vereinbarung zur Auftragsverarbeitung nach Art. 28 DSGVO abgeschlossen. Gemäß dieser Vereinbarung verpflichtet sich Hetzner dazu, den notwendigen Schutz Ihrer Daten zu gewährleisten und diese gemäß den geltenden Datenschutzbestimmungen ausschließlich in unserem Auftrag zu verarbeiten. Weitere Informationen zu Hetzner finden Sie unter: https://www.hetzner.com/de/rechtliches/datenschutz 
    Es findet keine eigenständige Nutzung der Daten durch den Dienstleister statt, auch eine Weitergabe dieser Daten an Dritte erfolgt nicht.`,
  },
  {
    title: `2. Zweck und Rechtsgrundlage `,
    description: `Wir verarbeiten die genannten Daten zu folgenden Zwecken:
    Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, Gewährleistung einer komfortablen und reibungslosen Nutzung unserer Website, Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken. Rückschlüsse auf den Nutzer werden in keinem Fall gezogen. In den zuvor aufgeführten Zwecken liegt unser berechtigtes Interesse, Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.`,
  },
  {
    title: `3. Dauer der Speicherung`,
    description: `Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Bei der Erfassung der Daten zur Bereitstellung der Webseite ist dies der Fall, wenn die jeweilige Sitzung beendet ist, d.h. wenn Sie unsere Webseite verlassen. Die Speicherdauer der Logfiles zu den oben aufgeführten Zwecken beträgt bis zu 14 Tagen.
    Backups werden 14 Tage in verschlüsselter Form aufbewahrt.`,
  },
  {
    title: `4. Widerspruchs- und Beseitigungsmöglichkeit`,
    description: `Für den Betrieb und die Bereitstellung der Internetseite ist die Erfassung der Daten und deren Speicherung in Logfiles zwingend erforderlich. Eine Widerspruchsmöglichkeit besteht seitens des Nutzers nicht.`,
  },
  {
    title: `V.Cookies, Plugins und sonstige Dienste
    1. Beschreibung und Umfang der Datenverarbeitung`,
    description: `Wir setzen auf unserer Website sog. Cookies ein. Cookies sind kleine Textdateien, die auf dem Endgerät des Nutzers abgelegt werden, d.h. sie werden im Internetbrowser des Nutzers gespeichert, wenn der Nutzer die Website aufruft. Dieses Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach dem Schließen Ihres Browsers, wieder gelöscht (sogenannte „Session Cookies“ oder auch „transiente Cookies“ genannt). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (auch „persistente Cookies“ oder „permanente Cookies“ genannt). Neben Cookies die von uns als für die Datenverarbeitung Verantwortlichen gesetzt werden (sogenannten „First-Party-Cookies“), kommen auch Cookies zum Einsatz, die von anderen Anbietern angeboten werden (sogenannte „Third-Party-Cookies“). 
    Beim Aufruf unserer Website informieren wir unsere Nutzer mittels Consent-Management-Plattform (auch „Consent Management“, „Zustimmunsgverwaltungsdienst“ oder „CMP“ genannt) über die Verwendung von Cookies und weiteren technischen Maßnahmen. Der Einsatz von Cookies und technischen Maßnahmen, für die gesetzlich die Einholung einer vorherigen aktiven Einwilligung vorgeschrieben ist oder für die wir festlegen, dass eine Einwilligung erfolgen soll, erfolgt erst, wenn Sie vor Einsatz die erforderliche Einwilligung erteilt haben. Dies ist systemseitig entsprechend sichergestellt. Diese Vorgehensweise gilt nicht für technisch notwendige Cookies. Der Nutzer wird über die Consent-Management-Plattform zu weiterführenden Informationen über die eingesetzten technischen Maßnahmen, zu denen auch Cookies gehören informiert und im Bedarfsfall weitergeleitet.
    Lassen Ihre Browsereinstellungen den Einsatz von Cookies zu, können u.a. folgende Cookies auf unseren Webseiten zum Einsatz kommen: 
    a. Technisch notwendige Cookies:
    Wir setzen Cookies ein, um unsere Inhalte der Website darzustellen sowie unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Website erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. Diese Cookies werden uns bei jedem Seitenaufruf übermittelt, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung.
    b. Analyse-Cookies:
    Wir bzw. unsere eingebundenen Drittanbieter verwenden auf dieser Webseite Cookies, die eine Analyse des Surfverhaltens unserer Nutzer ermöglichen. Auf diese Weise können eingegebene Suchbegriffe, die Häufigkeit von Seitenaufrufen oder die Inanspruchnahme von Webseiten-Funktionen analysiert werden. Die auf diese Weise erhobenen Daten der Nutzer werden im Zeitpunkt der Erhebung durch eine eingerichtete technische Maßnahme anonymisiert. Eine Zuordnung der Daten zum aufrufenden Nutzer der Webseite ist nicht mehr möglich. Diese anonymisierten Daten werden auch nicht gemeinsam mit personenbezogenen Daten der Nutzer gespeichert, die über ein Einwilligungsverfahren in deren Verarbeitung erhoben werden.
    Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Webseite und deren Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie unsere Website genutzt wird und können so unser Angebot stetig optimieren sowie etwaige auftretende Problemfälle und Fehler schneller beheben.
    c. Werbe-Cookies:
    Zusätzlich können Cookies von Drittanbietern für den Einsatz von Werbenetzwerken gesetzt werden. Diese Cookies werden verwendet, um Ihnen Werbung von Drittanbietern anzuzeigen, die für Sie aufgrund Ihres Surfverhaltens von Interesse sein könnten. Drittanbietern wird es ermöglicht zur Anzeigenschaltung die auf unserer Seite gesammelten Informationen mit bestehenden Informationen kombinieren, um Ihnen so personalisierte und interessensbezogene Anzeigen anzuzeigen.
    Im Rahmen unserer Consent-Management-Plattform haben Sie die Möglichkeit, nach Ihren Vorgaben über das Setzen von Cookies und den Einsatz vergleichbarer Technologien zu entscheiden und die Cookie-Nutzung jederzeit zu ändern. Eine detaillierte Übersicht mit umfassenden Informationen zu den genutzten Diensten sowie Zugriff auf Ihre Consent-Einstellungen einschließlich der Möglichkeit des Widerrufs erhalten Sie hier.
    Sie haben darüber hinaus jederzeit die Möglichkeit die Consent-Management Plattform über das zugehörige Icon am Bildschirmrand zu öffnen, Einstellungen vorzunehmen oder zu ändern sowie etwaige erteilte Einwilligungen zu widerrufen.`,
  },
  {
    title: `2. Zweck und Rechtsgrundlage`,
    description: `Der Zweck des Einsatzes technisch notwendiger Cookies ist es, die Nutzung unserer Websites für Sie überhaupt zu ermöglichen. Auf unserer Internetseite gibt es einige Funktionen, die ohne den Einsatz von Cookies nicht angeboten werden können. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. Die durch diese technisch-notwendigen Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet. Technisch notwendige Cookies verarbeiteten Daten die für die oben genannten Zwecke notwendig sind, worin unser berechtigtes Interesse nach Art. 6 Abs. 1 S. 1 lit. f) DSGVO zu sehen ist.
    Rechtsgrundlage für die Verarbeitung personenbezogener Daten, die unter Einsatz von Cookies zu statistischen Zwecken, Marketing- oder Werbezwecken: Der Einsatz dieser Art von Cookies erfolgt erst nach zuvor erteilter Einwilligung Art. 6 Abs. 1 S. 1 lit. a) DSGVO.
    Weitere Ausführungen zu Zwecken und den Widerrufsmöglichkeiten dieser Art von Cookies finden Sie in den einzelnen Abschnitten dieser Datenschutzerklärung bzw. in unserer Consent-Management-Plattform.`,
  },
  {
    title: `3. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit`,
    description: `Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unsere Seite übermittelt. Das bedeutet, dass Sie als Nutzer auch die volle Kontrolle über die Verwendung und den Einsatz von Cookies behalten. Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
    Wir bieten Ihnen im Rahmen des Consent-Managements die Möglichkeit, nach Ihren Vorgaben über das Setzen von Cookies und den Einsatz vergleichbarer Technologien zu entscheiden und die Cookie-Nutzung jederzeit zu ändern. Eine detaillierte Übersicht mit umfassenden Informationen zu den genutzten Diensten sowie Zugriff auf Ihre Consent-Einstellungen einschließlich der Möglichkeit des Widerrufs erhalten Sie.
    Durch Veränderung des jeweiligen Schiebereglers verhindern Sie, das technische Maßnahmen eines Anbieters ausgeführt bzw. Cookies gesetzt werden. Eine Nutzung unserer Angebote ist grundsätzlich auch ohne Cookies möglich, wenngleich dies mit erheblichen Einschränkungen der Funktionen und der Benutzerfreundlichkeit unseres Angebots auf der Website verbunden sein kann. Weitere Informationen zu dem von uns genutzten Zustimmungsverwaltungsdienst Usercentrics, ein Dienst der Usercentrics GmbH, Sendlinger Str. 7, 80331 Munich, Germany, finden Sie ebenfalls im CMP, das Sie über den zuvor aufgeführten Link aufrufen können.
    Nutzer, die nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, können die entsprechende Option in den Systemeinstellungen ihres Browsers deaktivieren oder bereits gespeicherte Cookies löschen. Dies kann auch automatisiert erfolgen. Die Deaktivierung von Cookies kann zu Funktionseinschränkungen unseres Angebots führen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Anbei einige Links für gängige Browser:
    Internet Explorer: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies 
    Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-undablehnen 
    Chrome:http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647 
    Safari: https://support.apple.com/kb/ph21411?locale=de_DE 
    Opera: http://help.opera.com/Windows/10.20/de/cookies.html `,
  },
  {
    title: `VI. Kontakt: Kontaktformular, E-Mail-Kontakt 
    1. Beschreibung und Umfang der Datenverarbeitung`,
    description: `Nutzer können uns über die bereitgestellte E-Mail-Adresse elektronisch kontaktieren. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers und die übermittelten Inhalte einer Nachricht verarbeitet. 
    Darüber hinaus stellen wir unseren Nutzern auf unserer Internetseite ein Kontaktformular zur Verfügung. Der Nutzer gibt dabei Daten in die Eingabemaske ein, die – nebst den Inhalten der Nachricht - an uns übermittelt und von uns verarbeitet werden. Im Rahmen des Kontaktformulars werden mindestens die folgenden Daten als Pflichtfelder abgefragt: Name und Vorname, E-Mail-Adresse, Anliegen bzw. Inhalt der Nachricht.
    Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten verarbeitet: Datum und Uhrzeit. Darüber hinaus speichern wir die Kontaktformulardaten sowie die jeweilige IP-Adresse des Nutzers.
    2. Zweck und Rechtsgrundlage 
    Wir verwenden die in der jeweiligen E-Mail gemachten Angaben und Daten ausschließlich für die Bearbeitung der Kontaktaufnahme/Anfrage und zur Führung der damit verbundenen Kommunikation. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.
    Rechtsgrundlage bei Vorliegen einer Einwilligung des Nutzers, ist Art. 6 Abs. 1 lit. a DSGVO.
    Rechtsgrundlage für die Kontaktaufnahme per E-Mail, ist Art. 6 Abs. 1 lit. f DSGVO. 
    Ist das Ziel der Kontaktaufnahme der Vertragsschluss mit dem Verantwortlichen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
    Wir speichern die Kontaktformulardaten sowie die jeweilige IP-Adresse um Nachweispflichten nachzukommen, zur Sicherstellung der Einhaltung und Dokumentation gesetzlicher Anforderungen, um ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären zu können und um die Sicherheit unserer Systeme zu gewährleisten.
    Rechtsgrundlage ist Art. 6 Abs. 1 lit. c bzw. lit. f DSGVO.
    3. Dauer der Speicherung
    Personenbezogene Daten, die per E-Mail übermittelt wurden, werden gelöscht, wenn die jeweilige Kommunikation mit dem Nutzer abgeschlossen ist. Die Kommunikation wird zu dem Zeitpunkt als abgeschlossen betrachtet, wenn der Grund oder der der Sachverhalt, der der Anfrage/Kontaktaufnahme zugrunde liegt, abschließend beantwortet und geklärt wurde.  
    Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
    4. Widerspruchs- und Beseitigungsmöglichkeit
    Der Nutzer kann etwaig erteilte Einwilligung zur Verarbeitung der personenbezogenen Daten widerrufen. Sofern die Kontaktaufnahme per E-Mail stattfindet, so kann der Nutzer jederzeit der weiteren Verarbeitung seiner personenbezogenen Daten widersprechen. Die Kommunikation mit dem Nutzer kann dann nicht weitergeführt werden. Der Widerruf kann an die unter I. aufgeführten Kontaktdaten des Verantwortlichen erfolgen oder per E-Mail an customer-service@twimyo.com.
    In diesem Fall erfolgt die Löschung der personenbezogenen Daten, die in diesem Zusammenhang gespeichert wurden, sofern je nach Inhalt - nicht etwaige gesetzliche Aufbewahrungsfristen entgegenstehen.
    5. Empfänger
    Für die Bereitstellung von Formularen auf unserer Website setzen wir externe IT-Dienstleister im Wege der Auftragsverarbeitung ein.`,
  },
  {
    title: `VII. Registrierung Nutzerkonto
    1. Beschreibung und Umfang der Datenverarbeitung und Datenkategorie`,
    description: `Auf unserer Internetseite haben Sie die Möglichkeit Angebote und Inhalte von Vermietern (nachfolgend auch „Local“ oder „Anbieter“ genannt) ohne Registrierung aufzurufen bzw. zu durchsuchen.
    Sofern Sie allerdings Buchungen von Räumlichkeiten oder Flächen tätigen wollen oder als Local derartige Angebote einstellen wollen, so ist hierfür eine Registrierung notwendig. Nutzer haben daher die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren und die zugehörigen Funktionen zu nutzen. Die Daten werden dabei in eine Eingabemaske eingegeben, an uns übermittelt und gespeichert. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben: 
    ▪ E-Mail-Adresse
    ▪ Vor- und Nachname
    ▪ PLZ und Ort
    ▪ selbst gewähltes Passwort
    ▪ weitere Angaben auf freiwilliger Basis, wie z.B. Telefonnummer oder Adresse.
    Im Zeitpunkt der Registrierung werden zudem die folgenden Daten gespeichert:
    IP-Adresse und Datum und Uhrzeit der Registrierung.
    Sie können im Rahmen der Registrierung auch unseren Newsletter abonnieren. Die Anmeldung erfolgt – wie auch der Registrierungsprozess selbst - mittels sog. Double-Opt-In-Verfahren. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung mittels Klicks auf einen sog. Verifikations-Link gebeten werden. Diese Bestätigung über den Link ist notwendig, sodass sich niemand mit einer fremden E-Mail-Adresse anmelden kann.`,
  },
  {
    title: `2. Zweck:`,
    description: `Eine Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich und eine Verarbeitung der angegebenen Daten zu den folgenden Zwecken notwendig: zur Identifizierung; zur Kontrolle der Berechtigung zum Abruf des Angebots und zur Erfüllung angebotener Leistungen; zur Kontaktaufnahme; als Sicherheitsmaßnahme zum Schutz vor Missbrauch; zur Verifikation der Anmeldung sowie zur Verwaltung und auch zur Übersendung unseres Newsletters - sofern Sie sich im Rahmen der Registrierung für diesen angemeldet haben.
    Sofern Ihre Einwilligung dazu vorliegt, werden mit Nutzung der Funktion „Angemeldet bleiben“ sog. persistente Cookies auf ihrem Endgerät abgelegt, welche dazu dienen, dass Sie sich bei darauffolgenden Besuchen auf unserer Website nicht erneut anmelden müssen. Diese Funktion steht Ihnen nicht zur Verfügung, wenn Sie in den Einstellungen Ihres Browsers die Speicherung von solchen Cookies deaktiviert haben.
    3. Rechtsgrundlage: Art. 6 Abs. 1 lit. a, b bzw. f DSGVO
    4. Dauer der Speicherung
    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Eine Speicherung erfolgt für die Dauer der Registrierung. 
    5. Widerspruchs- und Beseitigungsmöglichkeit
    Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung zu kündigen, indem Sie uns z.B. eine E-Mail an [kundenkonto@twimyo.de schicken. Sie haben darüber hinaus die Möglichkeit Ihr Nutzerkonto und Ihre in diesem Zusammenhang hinterlegten personenbezogenen Daten jederzeit in Ihrem persönlichen Kundenkonto aktualisieren oder löschen. Dieser Anspruch besteht vorbehaltlich etwaiger vertraglicher oder gesetzlicher Verpflichtungen die einer Löschung entgegenstehen.
    6. Empfänger
    Für die Bereitstellung von Formularen auf unserer Website setzen wir externe IT-Dienstleister im Wege der Auftragsverarbeitung ein.`,
  },
  {
    title: `VIII. Registrierung/Anmeldung über soziale Netzwerke`,
    description: `In diesem Abschnitt beschreiben wir die Verarbeitung Ihrer personenbezogenen Daten, wenn Sie sich mittels Ihrer Login-Informationen eines sozialen Netzwerks (nachfolgend auch „Netzwerk“ genannt) bei uns anmelden. Diese Anmeldemethode geht mit Datenübermittlungen von und an das betreffende soziale Netzwerk einher. Wir beschränken den Datenaustausch auf das Minimum, das zur Nutzung dieser Anmeldemöglichkeit erforderlich ist. Sie haben stets die alternative Möglichkeit, sich direkt bei uns zu registrieren, um eine solche Datenübermittlung zu vermeiden. 
    Sie können sich – alternativ zu der in Abschnitt VII. beschriebenen direkte Registrierung bei uns - auch mittels Nutzung von Login-Informationen von den nachfolgenden sozialen Netzwerken für unsere Services registrieren:`,
  },
  {
    title: `1. Umfang und Beschreibung der Datenverarbeitung bei Anmeldung über soziale Netzwerke`,
    description: `Wir bieten Ihnen die Möglichkeit, sich über Ihr Konto bei den nachfolgend genannten sozialen Netzwerken bei uns zu registrieren bzw. anzumelden. Wenn Sie hiervon Gebrauch machen, erhalten wir die für die Registrierung bzw. Anmeldung benötigten Daten (z. B. E-Mail-Adresse, Name) vom jeweiligen Sozialen Netzwerk.
    Wir haben keinen Einfluss auf den Umfang der vom jeweiligen Netzwerk mittels des Logins erhobenen Daten. Wenn Sie nicht möchten, dass das jeweilige Netzwerk im Zusammenhang mit Ihrer Nutzung unseres Online-Angebots Daten über Sie erhebt und für eigene Zwecke nutzt, sollten Sie diese Form der Anmeldung nicht verwenden.
    a. Google
    Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“).
    Beschreibung und Umfang der Datenverarbeitung und Datenkategorie:
    Sofern Sie sich über Google bei uns anmelden, bittet Google um Ihre Erlaubnis, bestimmte Details Ihres Google-Kontos mit uns zu teilen. Dazu gehören unter anderem Ihr Vorname, Nachname, Geschlecht und Ihre E-Mail-Adresse zur Bestätigung Ihrer Identität sowie ein Link zu Ihrem Google-Profil und Ihrem Profilbild. Diese Daten werden von Google erhoben und unter Beachtung der Datenschutzbestimmungen von Google an uns übermittelt. Sofern Sie sich bei Google anmelden, werden standardmäßig Informationen über Ihre Aktivitäten auf unsrer Website gemäß den Nutzungsbedingungen von Google und der Datenschutzerklärung von Google an Google weitergegeben.
    b. Anmeldung über Apple
    Apple Inc. One Apple Park Way, Cupertino, California, USA, 95014 („Apple“).`,
  },
  {
    title: `Beschreibung und Umfang der Datenverarbeitung und Datenkategorie:`,
    description: `Sobald Sie sich mit Ihrem Apple-Konto bei uns anmelden, wird Apple Sie um eine Bestätigung bitten, Ihr Apple-Konto für die Anmeldung bei uns nutzen zu dürfen. Die Anmeldung bei Apple schützt Ihre Privatsphäre, indem diese es Ihnen ermöglicht, sich bei unserer Webseite und unseren Apps anmelden zu können, ohne uns Informationen zur Verfügung stellen zu müssen, die Sie persönlich identifizieren, mit Ausnahme von Informationen, die Ihr Browser für normale Webfunktionen verwendet.
    Möglicherweise fragen wir Sie nach Ihrem Namen und Ihrer E-Mail-Adresse, wenn Sie auf die Anmeldung mittels Apple zurückgreifen. Dabei wird Ihr Name standardmäßig auf den Namen Ihr Apple-ID gesetzt und für die E-Mail-Adresse können Sie uns jede der E-Mail-Adressen, die mit ihrer Apple-ID verknüpft sind, mitteilen. Auch können Sie Ihre E-Mail-Adresse vor uns verbergen. Sofern Sie Ihre E-Mail-Adresse vor uns verbergen und auch die E-Mail-Weiterleitung von der von Apple erstellten E-Mail-Adresse an Ihre persönliche E-Mail-Adresse deaktivieren, können wir Sie nicht mit unseren Kommunikationsnachrichten erreichen. 
    c. Anmeldung über linkedIn
    LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland („linkedIn“).`,
  },
  {
    title: `Beschreibung und Umfang der Datenverarbeitung und Datenkategorie:`,
    description: `Sobald Sie sich mit Ihrem linkedIn-Konto bei uns anmelden, werden Sie zur Anmeldung auf die Seiten von LinkedIn weitergeleitet, wo Sie sich mit Ihren LinkedIn-Nutzerdaten anmelden können. Anschließend werden Sie explizit um Freigabe der folgenden bei LinkedIn hinterlegten Daten gebeten: Vor- und Nachname, Profilbild, E-Mail-Adresse, Derzeitige Job-Headline. Diese Daten werden von linkedIn erhoben und unter Beachtung der Datenschutzbestimmungen von linkedIn an uns übermittelt. LinkedIn wird bei jedem Anmeldevorgang einmalig kontaktiert, weil Ihre Identität erneut überprüft werden muss. Dieser Vorgang kann von LinkedIn protokolliert werden. Sofern Sie sich bei linkedIn anmelden, werden standardmäßig Informationen über Ihre Aktivitäten auf unserer Website gemäß den Nutzungsbedingungen von linkedIn und der Datenschutzerklärung von linkedIn an linkedIn weitergegeben.
    2. Zweck: Identifizierung; Prüfung der Zugangsberechtigung; Einrichtung und Personalisierung des Nutzerkontos bzw. Bereitstellung eines nutzerfreundlichen Angebots mit vereinfachter Anmeldung.
    3. Rechtsgrundlage: Art. 6 Abs.1 lit. b), f) DSGVO
    4. Widerspruchs- und Beseitigungsmöglichkeit
    Sofern Sie die Verknüpfung Ihres Nutzerkontos beim jeweiligen sozialen Netzwerk nicht mehr nutzen zu wollen, müssen sie diese Verbindung innerhalb ihres Nutzerkontos beim jeweiligen sozialen Netzwerk aufheben. 
    Ihr Nutzerkonto bei uns können Sie jederzeit kündigen, indem Sie uns z.B. eine E-Mail an [kundenkonto@twimyo.de] schicken. Sie haben darüber hinaus die Möglichkeit Ihr Nutzerkonto und Ihre in diesem Zusammenhang hinterlegten personenbezogenen Daten jederzeit in Ihrem persönlichen Kundenkonto aktualisieren oder löschen. Dieser Anspruch besteht vorbehaltlich etwaiger vertraglicher oder gesetzlicher Verpflichtungen die einer Löschung entgegenstehen
    5. Empfänger: Betreiber des jeweiligen sozialen Netzwerks (s.o.)
    Weitere Informationen über Zweck und Umfang der Erhebung sowie die weitere Verarbeitung und Nutzung Ihrer Daten durch das jeweilige soziale Netzwerk sowie zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer Daten finden Sie in den Datenschutzhinweisen:
    Facebook: https://www.facebook.com; Datenschutzerklärung: https://www.facebook.com/about/privacy; Widerspruchsmöglichkeit (Opt-Out): https://www.facebook.com/adpreferences/ad_settings (Login bei Facebook ist erforderlich).
    Google: https://www.google.de; Datenschutzerklärung: https://policies.google.com/privacy; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für die Darstellung von Werbeeinblendungen: https://adssettings.google.com/authenticated.
    Apple: https://www.apple.com/legal/privacy/de-ww/ und
    https://www.apple.com/de/legal/privacy/data/,  https://www.apple.com/de/privacy/
    linkedIn: linkedin.com/legal/privacy-policy`,
  },
  {
    title: `6. Vervollständigung der Registrierung`,
    description: `Unabhängig davon, ob Sie sich direkt auf unserer Website oder über einen Drittanbieter registrieren, werden Sie während der ersten Anmeldung um die Vervollständigung weiterer Daten gebeten.
    Es ist möglich, dass zwingend erforderliche Felder bei der Registrierung über einen Drittanbieter nicht ausgefüllt werden. Aus diesem Grund werden Sie unter Umständen gebeten, die folgenden Pflichtfelder nachträglich auszufüllen:
    ▪ Name
    ▪ E-Mail-Adresse
    ▪ PLZ, Ort
    Die E-Mail-Adresse ist für Ihre Registrierung zwingend erforderlich, um sie identifizieren zu können. Der Ort wird zur Vervollständigung Ihres Profils und zur Verbesserung Ihrer Suchergebnisse genutzt.
    Wenn gewünscht, können im zweiten Schritt der Registrierung die weitere persönliche Informationen freiwillig ergänzen: [AUFZÄHLUNG]`,
  },
  {
    title: `IX. Newsletter
    1. Beschreibung, Umfang und Zweck der Datenverarbeitung`,
    description: `Auf unserer Website besteht die Möglichkeit einen kostenfreien Newsletter zu abonnieren. Dabei werden bei der Anmeldung zum Newsletter die Daten aus der Eingabemaske an uns übermittelt die Eingabe der E-Mail-Adresse ist für die Übersendung des Newsletters erforderlich. Etwaige weitere Angaben sind freiwillig und werden verwendet, um unsere Nutzer persönlich ansprechen zu können.
    Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung mittels Klicks auf einen sog. Verifikations-Link gebeten werden. Diese Bestätigung über den Link ist notwendig, sodass sich niemand mit einer fremden E-Mail-Adresse anmelden kann. 
    Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts, als auch der IP-Adresse sowie der Anmeldedaten durch uns.
    Der Newsletter enthält Informationen zu sämtlichen von uns [oder von mit uns verbundenen Unternehmen oder Partnerunternehmen] angebotenen Dienstleistungen, Waren, Produkte, Angebote und Aktionen.
    2. Rechtsgrundlage: 
    Der Versand des Newsletters [und dessen Personalisierung] erfolgt auf Grundlage einer Einwilligung gem. Art. 6 Abs. 1 Satz 1 lit. a DSGVO. Die Protokollierung des Anmeldeverfahrens, erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 Satz 1 f DSGVO. Unser Interesse richtet sich auf den Einsatz eines sicheren Newslettersystems bzw. um die Einwilligung nachweisen zu können.
    3. Speicherdauer und Widerruf:
    Ihre Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand der Informationen können Sie jederzeit widerrufen. Der Widerruf kann über den in jedem Newsletter enthaltenen Abmelde-Link oder per Mitteilung an die obenstehenden Kontaktmöglichkeiten erfolgen, z.B. per E-Mail an support@twimyo.com.
    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse des Nutzers wird demnach solange gespeichert, wie das Abonnement des Newsletters aktiv ist bzw. bis zum Widerruf Ihrer Einwilligung zum Erhalt des Newsletters. 
    Wenn Sie die Newsletteranmeldung nicht innerhalb von 24 Stunden bestätigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch gelöscht.
    4. Empfänger: 
    Für die Versendung des Newsletters und Bereitstellung von Formularen auf unserer Website setzen wir externe IT-Dienstleister im Wege der Auftragsverarbeitung ein.`,
  },
  {
    title: `X.Vermittlung von Buchungen, Durchführung und Abwicklung
    1.Beschreibung und Umfang der Datenverarbeitung`,
    description: `Wir benötigen, zur Durchführung unserer Vermittlungsleistung, die folgenden Kategorien personenbezogener Daten, wenn Sie auf unserer Website eine Anfrage bzw. Buchung auslösen:
    ▪ E-Mail-Adresse (zur Übermittlung von Anfragen bzw. zur Bestätigung von Buchungen)
    ▪ Name und Vorname
    ▪ Firmenname (sofern Local und/oder Nutzer Unternehmer i.S.d. § 14 BGB ist/sind)
    ▪ Rechnungsadresse
    ▪ Zahlungsdaten (diese werden beim Zahlungsdienstleister direkt eingegeben)
    ▪ Zustimmung zu den jeweiligen Geschäftsbedingungen
    ▪ (optional) Telefonnummer zur telefonischen Unterstützung.`,
  },
  {
    title: `2. Zweck und Rechtsgrundlage: `,
    description: `Die Verarbeitung dieser Daten erfolgt zur Vermittlung, Durchführung und Abwicklung der Buchung einschließlich der Kundenbetreuung. Wir geben die oben genannten Daten an den oder die jeweiligen Locals weiter.
    Zusätzlich zu diesen Daten speichern wir den Zeitpunkt (Datum und Uhrzeit) der Übermittlung Ihrer Daten an uns, sowie Ihre IP-Adresse. Die Verarbeitung dieser Daten entspricht unserem berechtigten Interesse (Art. 6 Abs. 1 lit. f DSGVO), um die Sicherheit unserer Systeme zu gewährleisten und Missbrauch zu verhindern. 
    Rechtsgrundlage ist Art. 6 Abs. 1 lit. b und f DSGVO.
    3. Speicherdauer und Widerruf:
    Die Verarbeitung bzw. Speicherung der oben genannten Daten erfolgt nur so lange, wie sie zur Erreichung des zugrundeliegenden Zwecks erforderlich ist und damit bis die Durchführung und Abwicklung des Vermittlungsprozesses über die gegenständliche Buchung abgeschlossen ist, einschließlich etwaig verbundener Kundenbetreuung oder der Erfüllung etwaiger rechtlicher Verpflichtungen. Im Falle etwaig bestehender gesetzlicher Aufbewahrungspflichten, werden nur die Daten gespeichert, die von uns zur Erfüllung dieser Pflichten aufzubewahren sind. In Bezug auf die Verpflichtung nach 147 AO, 257 HGB kann diese Aufbewahrungsdauer sechs Jahre (z.B. bei Handels- oder Geschäftsbriefe) bzw. zehn Jahre (z.B. bei Buchungsbelegen) betragen. 
    4. Zahlungsanbieter
    Beschreibung, Umfang und Zwecke der Datenverarbeitung
    Für die sichere Abwicklung der von Ihnen über unsere Vermittlungsplattform veranlassten Zahlungen an den jeweiligen Local werden die erforderlichen Zahlungsdaten an einen Zahlungsdienstleister übermittelt, ohne, dass die Zahlungsdaten bzw. Konto- oder Kreditkareninformationen selbst auf unseren eigenen Servern gespeichert werden. Wir setzen den folgenden Zahlungsanbieter ein:`,
  },
  {
    title: `Zahlungsanbieter`,
    description: `Stripe Payments Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland
    Datenschutzerklärung: https://stripe.com/de/privacy
    Die Datenverarbeitung durch Stripe kann teilweise auf Servern in den USA stattfinden. Nähere Informationen zum Datenschutz bei Stripe und zur Einhaltung der Anforderungen von Art.44 ff DSGVO finden Sie hier und hier. Im Übrigen verweisen wir auf Abschnitt XII dieser Datenschutzerklärung.
    
    Zu den durch den Zahlungsdienstleister verarbeiteten Daten gehören bei Zahlung mittels Kreditkarte mindestens: Vorname, Nachname, Kreditkartennummer, die Prüfziffer (CVC) und das Ablaufdatum der Kreditkarte. Darüber hinaus können, je nach Zahlungsart und Zahlungsanbieter die folgenden Daten vom Zahlungsanbieter verarbeitet werden: Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen bzw. Kreditkartennummer, Ablaufdatum, Prüfziffer (CVC), Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern).
    Diese Angaben sind erforderlich, damit der Zahlungsdienstleister die Transaktionen durchführen oder etwa Missbrauchsprävention betreiben kann. Die eingegebenen Daten werden nur durch den jeweiligen Zahlungsdienstleister verarbeitet und bei diesem gespeichert, wir erhalten in diesem Zusammenhang lediglich Informationen mit Bestätigung über eine erfolgreiche Bezahlung Negativauskunft in dem Fall, dass keine Zahlung erfolgt ist. Nach Abwicklung der Zahlung erhalten Sie einen Zahlungsbeleg per E-Mail. Stripe teilt uns den Zahlungseingang mit und wir speichern insofern die Information zum Zahlungseingang sowie die Angaben zum bestellten Inhalt bzw. der getätigten Buchung und zur Buchungsdauer, das Entgelt, Kündigungsbedingungen und die gewählte Zahlungsmethode in Verbindung mit Ihrem Nutzerkonto, um eingegangene Zahlungen zuordnen und nachweisen und Ihre bestellten Inhalte in Ihrem Nutzerkonto verwalten zu können. 
    Für weitere Informationen im Hinblick auf die Verarbeitungsvorgänge beim Zahlungsdienstleister selbst (bspw. wenn Nutzerdaten an Wirtschaftsauskunfteien zur Identitäts- und Bonitätsprüfung übermittelt werden) verweisen wir ebenso wie für die Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten - auf dessen AGB und Datenschutzhinweise. 
    Rechtsgrundlage: Rechtsgrundlage für die mit dem Einsatz von Zahlungsanbietern verbundene Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO.
    Aufbewahrungsdauer: Die Angaben über erfolgte Zahlungen werden aus buchhalterischen Gründen im Zusammenhang mit den Registrierungsdaten für die Dauer von sechs Jahren aufbewahrt. Rechtsgrundlage für diese Aufbewahrung ist Art. 6 Abs. 1 lit. c DSGVO in Verbindung mit § 257 HGB sowie § 147 AO. Steuerrechtlich relevante Unterlagen wie bspw. Buchungbelege können einer gesetzlichen Aufbewahrungsfrist von zehn Jahren unterliegen.`,
  },
  {
    title: `XI. Empfänger von Daten oder Kategorien von Empfängern`,
    description: `Eine interne Weitergabe von Daten erfolgt nur an diejenigen Stellen bzw. Organisationseinheiten, die diese zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder im Rahmen der Bearbeitung und Umsetzung unseres berechtigten Interesses benötigen. 
    Eine Weitergabe Ihrer Daten an externe Stellen kann, über die in dieser Datenschutzerklärung in den einzelnen Abschnitten genannten Fällen hinaus, in den folgenden Fällen erfolgen:
    ● Im Zusammenhang mit Vertragsabwicklungen,
    ● soweit externe (Dienstleistungs-) Unternehmen Daten in unserem Auftrag als Auftragsverarbeiter i.S.d. Art. 28 DSGVO oder in gemeinsamer Verantwortlichkeit mit uns i.S.d. Art. 26 DSGVO verarbeiten (z. B. externe Rechenzentren, IT-Dienstleister (wie z.B. Dienstleister für die Wartung von EDV-/IT-Anwendungen, Support-Services oder Hosting), Archivierung, Kundenverwaltung, Lettershops, Marketing-Agenturen Newsletterversand, Abrechnung, Management unserer Internetauftritte, Kreditinstitute, Druckereien oder Unternehmen für Datenentsorgung, Kurierdienste, Logistik. Eine Vereinbarung über die Verarbeitung personenbezogener Daten im Auftrag i.S.d. Art. 28 DSGVO wurde jeweils geschlossen. In den Fällen, in denen es sich um eine gemeinsame Verantwortlichkeit handelt, wurde eine Vereinbarung nach Art. 26 DSGVO geschlossen.
    ● an Gerichte, Schiedsgerichte, Behörden oder Rechtsberater, wenn dies zur Einhaltung geltenden Rechts, oder zu Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist oder die Datenweitergabe im öffentlichen Interesse liegt oder
    ● wenn Sie uns eine Einwilligung zur Übermittlung an Dritte gegeben haben
    Eine darüberhinausgehende Weitergabe Ihrer Daten an Dritte erfolgt nicht. Soweit Dienstleister im Rahmen einer Auftragsverarbeitung von uns beauftragt werden, unterliegen Ihre Daten dort den gleichen Sicherheitsstandards wie bei uns. In allen anderen Fällen dürfen die Empfänger die Daten nur für die Zwecke nutzen, für die sie dem jeweiligen Empfänger übermittelt wurden.`,
  },
  {
    title: `XII.Weitergabe von personenbezogenen Daten in Drittländer und Voraussetzungen`,
    description: `Personenbezogene Daten können - im Rahmen unserer Geschäftsbeziehungen mit Ihnen -auch an Dritte, die sich auch außerhalb des Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden, weitergegeben oder offengelegt werden.
    Mittels sogenannter Angemessenheitsbeschlüsse bescheinigt die Europäische Kommission einigen Drittländern, dass deren Datenschutzniveau, mit dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der Angemessenheitsbeschlüsse finden Sie hier: 
    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en ).
    Über Einzelheiten einer etwaigen Weitergabe in Drittländer, im Zusammenhang mit den von uns vorgenommenen Verarbeitungen, unterrichten wir Sie im Rahmen dieser Datenschutzerklärung an den dafür relevanten Stellen oder im Rahmen unserer Consent-Management-Plattform.
    Werden gegebenenfalls personenbezogene Daten in Drittländer ohne Angemessenheitsbeschluss übertragen, so herrscht dort unter Umständen - wegen fehlender gesetzlicher Bestimmungen - kein durchgängig hohes Datenschutzniveau. In diesen Fällen achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist, indem wir etwa zusätzlich zum Abschluss von sog. Standard-Vertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten, geeignete zusätzliche Maßnahmen (wie eine ausreichende Verschlüsselung etc.) sicherstellen und den Empfehlungen des European Data Protection Board folgen: https://edpb.europa.eu/our-work-tools/our-documents/recommendations/recommendations-012020-measures-supplement-transfer_de 
    Sofern es im Einzelfall vergleichbare Dienstleister in der EU / EWR gibt so setzen wir diese grundsätzlich bevorzugt ein.`,
  },
  {
    title: `XIII. (Externe) Links zu anderen Websites `,
    description: `Wir weisen darauf hin, dass unsere Website weitere Verlinkungen auf Internet-Auftritte anderer Unternehmen enthalten kann auf deren Inhalte wir keinen Einfluss haben Für die Inhalte dieser Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.`,
  },
  {
    title: `XIV. Betroffenenrechte - Ihre Rechte`,
    description: `Sie können als betroffene Person jederzeit uns gegenüber Ihre Rechte bezüglich Ihrer personenbezogenen Daten insbesondere unter den in Abschnitt I. angegebenen Kontaktdaten geltend machen. Betroffenen Personen stehen folgende Rechte aus der DSGVO zur Verfügung:
    
    Recht auf Auskunft
    Sie haben gem. Art. 15 DSGVO das Recht von uns Bestätigung darüber zu verlangen, ob Ihre personenbezogenen Daten von uns verarbeitet werden. Ist dies der Fall, so haben Sie das Recht Auskunft über diese personenbezogenen Daten und die in Art, 15 DSGVO genannten Inforationen zu verlangen. Auf Verlangen stellen wir Ihnen eine Kopie der Daten, die Gegenstand der Verarbeitung sind, zur Verfügung. 
    
    Recht auf Berichtigung
    Nach Art. 16 DSGVO haben Sie das Recht Berichtigung etwaiger unrichtiger personenbezogener Daten zu verlangen, sofern diese Ihre Person betreffen. Auch können Sie eine Vervollständigung verlangen, sofern Ihre personenbezogenen Daten unvollständig sind.
    
    Recht auf Löschung („Recht auf Vergessenwerden“)
    Bei Vorliegen einer der in Art. 17 DSGVO genannten Gründe, können Sie die Löschung Ihrer personenbezogenen Daten verlangen. Ein solcher Anspruch auf Löschung hängt u. a. davon ab, ob die Sie betreffenden Daten von uns zur Erfüllung gesetzlicher Vorgaben noch benötigt werden.
    
    Recht auf Einschränkung der Verarbeitung
    Sie haben gemäß Art. 18 DSGVO das Recht eine Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen, wenn eine der in Art. 18 DSGVO genannten Voraussetzungen vorliegt.
    
    Recht auf Datenübertragbarkeit
    Sie haben nach Art. 20 DSGVO das Recht Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, sofern die Voraussetzungen nach Art. 20 DSGVO vorliegen.`,
  },
  {
    title: `Widerspruchsrecht
    Sie haben nach Art. 21 Abs. 1 DSGVO das Recht, aus Gründen die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f erfolgt, zu widersprechen.
    Nach Art. 21 Abs. 2 DSGVO können Sie der Verarbeitung Ihrer personenbezogenen Daten zum Zwecke der Direktwerbung jederzeit mit Wirkung für die Zukunft widersprechen (sog. Werbewiderspruch bei Direktwerbung).`
  },
  {
    title: `Recht auf Beschwerde`,
    description: `Soweit und sofern Sie der Auffassung sind, dass wir bei der Verarbeitung Ihrer Daten datenschutzrechtliche Vorschriften nicht beachtet bzw. gegen Vorgaben der DSGVO verstoßen haben, so können Sie sich gem. Art. 77 DSGVO bei einer Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten beschweren, z.B. bei der für uns zuständigen Aufsichtsbehörde: Bayerisches Landesamt für Datenschutzaufsicht, Promenade 18 in 91522 Ansbach.
    Recht auf Widerruf einer Einwilligung
    Sie können von Ihre Einwilligungen zur Verarbeitung Ihrer Daten jederzeit mit Wirkung für die Zukunft widerrufen. 
     
    Sollten Sie noch Fragen, Anregungen oder Kritik zum Thema Datenschutz haben oder betreffend die Erhebung, Verarbeitung und/oder der Nutzung Ihrer personenbezogenen Daten, so freuen wir uns über Ihre Nachricht. Wir bemühen uns Ihnen schnellstmöglich zu antworten: support@twimyo.com.
    Mit Weiterentwicklung unserer Internetseite und der darauf enthaltenen Angebote oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung anzupassen. Die jeweils aktuelle Datenschutzerklärung kann jederzeit unter [URL] abgerufen werden.
     
    Stand: Oktober 2021`,
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
          whiteSpace: 'pre-line'
        }}
      >
        {title}
      </Typography>
      <Typography align={"justify"} sx={{whiteSpace: 'pre-line'}} component={'p'} color={'#777E91'}>
        {description}
      </Typography>
    </Box>
  );
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
