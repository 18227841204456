// @mui
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
// config
import config, {
  HEADER_MOBILE_HEIGHT,
  HEADER_DESKTOP_HEIGHT,
} from "../../config";
// hooks
import { useRequest } from "../../hooks";
// _data
//import { _tours } from '../../../_data/mock';
// layouts
import Layout from "../../layouts";
// components
import { Page, ErrorScreen, Image, LoadingScreen } from "../../components";
// sections
import { TravelCheckOutCompleteInfo } from "../../sections/@travel";

/* import imageCheckout from "../../assets/images/have-space.jpg"; */
import { useSearchParams } from "react-router-dom";
/* import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import axios from "axios"; */
import { useEffect } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: HEADER_MOBILE_HEIGHT,
  [theme.breakpoints.up("md")]: {
    paddingTop: HEADER_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

export default function TravelCheckoutCompletePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("booking");

  const { data: booking, error: bookingError } = useRequest({
    url: id ? `/booking/${id}` : "",
  });

  useEffect(() => {
    if (booking) {
      console.log("workspace", booking.booking.workplace);
      console.log("booking", booking.booking);
    }
  }, [booking]);

  if (bookingError) {
    return <ErrorScreen />;
  }

  if (!booking?.booking) {
    return <LoadingScreen />;
  }

  return (
    <Layout>
      <Page title="Checkout Complete - Travel">
        <RootStyle>
          <Container
            sx={{
              mt: { xs: 8, md: 10 },
              mb: { xs: 10, md: 15 },
              alignItems: "flex-start",
              display: "grid",
              gap: 10,
              gridTemplateColumns: { md: "repeat(2, 1fr)" },
            }}
          >
            <Image
              alt="cover"
              src={
                config.api.host + booking.booking.workplace.imageList[0].data
              }
              ratio="3/4"
              sx={{ borderRadius: 2, display: { xs: "none", md: "block" } }}
            />
            <TravelCheckOutCompleteInfo
              workspace={booking.booking.workplace}
              booking={booking.booking}
            />
          </Container>
        </RootStyle>
      </Page>
    </Layout>
  );
}
