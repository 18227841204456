import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// components
import { Image, LightboxModal } from '../../../components';
import { varTranHover } from '../../../components/animate';
import config from '../../../config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

TravelTourGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default function TravelTourGallery({ gallery }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleOpenLightbox = (id) => {
    const selectedImage = gallery.findIndex((item) => id === item.id);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          },
        }}
      >
        <PhotoItem photo={config.api.host + gallery[0].data} onOpenLightbox={() => handleOpenLightbox(gallery[0].id)} />

        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {gallery.slice(1, 5).map((item) => (
            <PhotoItem key={item.id} photo={config.api.host + item.data} onOpenLightbox={() => handleOpenLightbox(item.id)} />
          ))}
        </Box>
      </Box>

      <LightboxModal
        images={gallery}
        mainSrc={config.api.host + gallery[selectedImage].data}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

PhotoItem.propTypes = {
  onOpenLightbox: PropTypes.func,
  photo: PropTypes.string,
};

function PhotoItem({ photo, onOpenLightbox }) {
  return (
    <m.div
      whileHover="hover"
      variants={{
        hover: { opacity: 0.8 },
      }}
      transition={varTranHover()}
    >
      <Image
        alt="photo"
        src={photo}
        ratio="1/1"
        onClick={onOpenLightbox}
        sx={{ borderRadius: 2, cursor: 'pointer' }}
      />
    </m.div>
  );
}
