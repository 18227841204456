/* import PropTypes from 'prop-types'; */
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
/* import { m } from 'framer-motion'; */
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Stack, /* Card, */ Button, Tabs, Tab, Divider, } from '@mui/material';
// hooks
import { useBoundingClientRect, useRequest } from '../../../hooks';
// components
import { CarouselArrows,/*  Image, Iconify, SvgIconStyle  */ } from '../../../components';
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link, useParams } from 'react-router-dom';
/* import config from '../../../config'; */
import TravelTourItem from '../tours/TravelTourItem';
import { useTranslation } from 'react-i18next';
import { cities } from '../../../mocks/cities';
import './styles.css'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  },
}));
// ----------------------------------------------------------------------

export default function CitiesTabs() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(`Scores`);
  const [index, setIndex] = useState(0);
  const categories = [`Scores`, `Digital Nomad Guide`, `Cost of Living`, `People`];
  const [init, setInit] = useState(false);
  const [workplaces, setWorkplaces] = useState([]);

  const { data: workplacesData, error: workplacesError } = useRequest({
    url: `/office/workplaces`,
  });

  // get params
  const params = useParams()
  const [city, setCity] = useState("münchen")
  useEffect(() => {
    if (!init && workplacesData?.data) {

      let workspacesArr = workplacesData?.data ? workplacesData?.data : [];
      console.log(cities[0][city]["users"])
      setCity(params.city)
      //check if workplace is in city
      let workplaceInCity = workspacesArr.filter((item) => {
        if (item?.address) {
          console.log(item.address)
          return item.address.toLowerCase().includes(params.city.toLowerCase())
        }
      })
      setWorkplaces(workplaceInCity)
      setInit(true)
    }
  }, [params, workplacesData, init])

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
  };

  const renderProgressBar = (item) => {
    let color = 'green';
    let colorDefault = '#ccc';
    let to = "left"
    let widthGray = 0;
    let widthColor = 0;
    console.log(item)
    switch (item) {
      case '3.76/5 (Rank #104)609 reviews':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 24;
        widthGray = 76;
        break;

      case 'Great':
        color = 'green';
        widthColor = 0;
        widthGray = 0;
        break;
      case 'Good':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 30;
        widthGray = 70;
        break;
      case 'High':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 15;
        widthGray = 85;
        break;
      case '🌱 Great: 32 US AQI':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 30;
        widthGray = 70;
        break;
      case '🌱 Great: 19 US AQI':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 30;
        widthGray = 70;
        break;
      case 'High:	$3,538 / mo':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 40;
        widthGray = 60;
        break;
      case '👍93% liked it  👎7% disliked it':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 7;
        widthGray = 93;
        break;
      case '🧘‍♀️ Low density: 5k ppl/km² (15x15m)':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 7;
        widthGray = 93;
        break;

      case '🌞 Nice: 21°C71°F (feels 21°C70°F)':
        color = '#ccc';
        to = "right"
        colorDefault = 'green';
        widthColor = 21;
        widthGray = 79;
        break;
      case 'Okay':
        color = 'orange';
        widthColor = 50;
        widthGray = 50;
        break;
      case '😊 Comfy: 69%':
        color = '#ccc';
        to = "right"
        colorDefault = 'orange';
        widthColor = 31;
        widthGray = 69;
        break;
      case 'Bad':
        color = 'red';
        widthColor = 20;
        widthGray = 70;
        break;
      case '🧐 Too expensive: $4,658 / mo':
        color = 'red';
        widthColor = 20;
        widthGray = 70;
        break;
    }
    return (
      <div style={{
        background: `linear-gradient(to ${to}, ${colorDefault} ${widthGray}%, ${color} ${widthColor}%)`,
        padding: "5px 35px",
        width: "40%",
        textAlign: "center",
        borderRadius: "15px",
      }}>
        <Typography variant="h6" sx={{ color: "white" }}>{item}</Typography>
      </div>
    )
  }

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'flex-end' }}
          sx={{
            marginY: '2rem',
            textAlign: { xs: 'center', md: 'center' },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h3">{city.charAt(0).toUpperCase() + city.slice(1)}: fakten</Typography>
          </Stack>
        </Stack>
        {/* <Box
          sx={{
            pt: 1,
            pb: { xs: 0, md: 0 },
          }}
        >
          <Tabs
            value={selected}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeCategory}
          >
            {categories.map((category, index) => (
              <Tab onClick={() => setIndex(index)} key={category} value={category} label={category} />
            ))}
          </Tabs>
          {index === 0 && (<Box sx={{
            marginY: '1rem',
            marginX: 'auto',
          }}>
            {
              Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).map((item, i) => (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginX: { xs: '0', lg: '6rem' },
                  marginY: '0.5rem',
                  paddingY: '0.5rem',
                  borderBottom: '1px solid #ccc',
                }}>
                  <Typography variant="button">{item[0]}:</Typography>
                  {renderProgressBar(item[1])}
                </Box>
              ))
            }
          </Box>)}
          {index !== 3 && index !== 0 ? (
            <Box sx={{
              marginY: '2rem',
              display: 'flex',
              justifyContent: 'space-around',
              itemsAlign: 'center',
            }}>
              <div>
                {
                  Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).slice(0, (Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).length) / 2).map((item, i) => (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginY: '0.5rem',
                      paddingY: '0.5rem',
                      borderBottom: '1px solid #ccc',
                    }}>
                      <Typography variant="button">{item[0]}:</Typography>
                      <Typography variant="string">{item[1]}</Typography>
                    </Box>
                  ))
                }
              </div>
              <Divider orientation="vertical" flexItem />
              <div>
                {
                  Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).slice((Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).length) / 2, (Object.entries(cities[0][city][index === 0 ? 'scores' : index === 1 ? 'digital_nomad_guide' : 'cost_of_living']).length)).map((item, i) => (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginY: '0.5rem',
                      paddingY: '0.5rem',
                      borderBottom: '1px solid #ccc',
                    }}>
                      <Typography variant="button">{item[0]}:</Typography>
                      <Typography variant="string">{item[1]}</Typography>
                    </Box>
                  ))
                }
              </div>
            </Box>)
            : index === 3 && (
              <div style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
                className="users"
                dangerouslySetInnerHTML={{ __html: cities[0][city]["users"] }}
              >

              </div>
            )}
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            flexWrap: "wrap",
            marginY: 3,
          }}
        >
          {workplaces.slice(0, 6).map((workplace) => {
            return (
              <Box
                key={workplace.id}
                sx={{
                  width: { xs: "100%", sm: "40%", lg: "30%" },
                }}
              >
                <TravelTourItem
                  key={workplace.id}
                  workplace={{
                    ...workplace,
                    address: workplace.office.address,
                    imageList: workplace.images,
                  }}
                />
              </Box>
            );
          })}
        </Box>

        <Box sx={{ textAlign: "center", marginTop: "10px" }}>
          <Link to={"/search"}>
            <Button
              size="large"
              sx={{
                textTransform: "none",
                color: "black",
                border: "1px solid #ccc",
                borderRadius: "15px",
              }}
            >
              {/* Alle Workspaces anzeigen */}
              {t("WorkspaceOverview_Button")}
            </Button>
          </Link>
        </Box>
      </Container>
    </RootStyle>
  );
}