import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button, Container, Divider, FilledInput, InputAdornment } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
// components
import { /* LogoCoral */ Iconify, SocialsButton } from '../../components';
// styles
import LogoIcon from '../../assets/logos/twimyo-logo-1c-black.svg';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import directionStraightRight from '@iconify/icons-carbon/direction-straight-right';
import SuccessMsg from '../../components/success/SuccessMsg';
import axios from 'axios';
import config from '../../config';

const Footer = () => {
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  const [email, setEmail] = useState("");

  const subscribeNewsletter = (e) => {
    e.preventDefault();
    console.log("subscribeNewsletter", email);
    if (email) {
      axios.
        post(config.api.host + "/users/subscribe/newsletter", { email: email })
        .then((res) => {

          setMsgSuccess("Thank you for subscribing to our newsletter!");
          setOpenSuccess(true);
          setEmail("");
        })
        .catch((err) => {
          alert(err);
        });
    };
  };

  return (
    <>
      <Divider />
      <Container>
        <Grid container columnSpacing={15} paddingRight={2} paddingLeft={2}>
          <Grid item>
            <Box
              marginTop={5}
              display={'flex'}
              component={ReactLink}
              to="/"
              title="twimyo"
              width={100}
            >
              <Box component={'img'} src={LogoIcon} height={"100%"} width={"100%"} />
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={5} >
              <Link
                underline="none"
                component={ReactLink}
                to="/faq"
                color='#777E91'
                variant={'subtitle2'}
              >
                FAQ
              </Link>
            </Box>
            <Box marginTop={5} >
              <Link
                underline="none"
                component={ReactLink}
                to="/imprint"
                color='#777E91'
                variant={'subtitle2'}
              >
                {t("Legal_Notice")}
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={5} >
              <Link
                underline="none"
                component={ReactLink}
                to="/aboutUs"
                color='#777E91'
                variant={'subtitle2'}
              >
                {t("What_we_do")}
              </Link>
            </Box>
            <Box marginTop={5} >
              <Link
                underline="none"
                component={ReactLink}
                to="/dataPolitics"
                color='#777E91'
                variant={'subtitle2'}
              >
                {t("Data_Security")}
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={5} >
              <Link
                underline="none"
                target="_blank"
                href="https://www.linkedin.com/company/twimyo/"
                color='#777E91'
                variant={'subtitle2'}
              >
                {/* Datenschutz */}
                Jobs
              </Link>
            </Box>
            <Box marginTop={5} >
              <Link
                underline="none"
                component={ReactLink}
                to="/companyTerms"
                color='#777E91'
                variant={'subtitle2'}
              >
                {t("Terms_&_Conditions")}
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={5} sx={{ whiteSpace: 'nowrap', width: "100%" }}>
              <Link
                underline="none"
                component={ReactLink}
                to="/signup"
                color="text.primary"
                variant={'subtitle2'}
              >
                {t("Join_our_community")} 🔥
              </Link>
            </Box>
            <Box marginTop={3} sx={{ whiteSpace: 'nowrap', width: "100%" }}>
              <FilledInput
                placeholder={t("Email_Information")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                endAdornment={
                  <InputAdornment position="end" sx={{
                    paddingRight: "5px"
                  }}>
                    <Button
                      size="medium"
                      color="primary"
                      variant="contained"
                      onClick={subscribeNewsletter}
                      sx={{
                        px: 0,
                        flexShrink: 0,
                        minWidth: { xs: 1, md: 32 },
                        borderRadius: "360px"
                      }}
                    >
                      <Iconify icon={directionStraightRight} sx={{ width: 20, height: 20 }} />
                    </Button>
                  </InputAdornment>
                }
                sx={{
                  pr: 0.5,
                  '& .MuiFilledInput-input': { py: '14px' },
                  width: "70%",
                  backgroundColor: "white",
                  border: "1px solid #e6e6e6",
                  borderRadius: "50px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          width={1}
          marginY={10}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Typography
            align={'center'}
            variant={'subtitle2'}
            color='#777E91'
            fontSize={12}
            gutterBottom
          >
            Copyright © 2022 Twimyo . All rights reserved
          </Typography>
        </Box>
      </Container>
      <SuccessMsg
        open={openSuccess}
        msg={msgSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default Footer;
