import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  /* Card, */ Button,
  Tabs,
  Tab,
} from "@mui/material";
// hooks
import { useBoundingClientRect, useRequest } from "../../../hooks";
// components
import {
  CarouselArrows /*  Image, Iconify, SvgIconStyle  */,
  Image,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; 
import { Link } from "react-router-dom"; 
import config from '../../../config';
import TravelTourItem from "../tours/TravelTourItem"; */
import browse1 from "../../../assets/images/holidaySummer1.png";
import browse2 from "../../../assets/images/holidaySummer2.png";
import browse3 from "../../../assets/images/holidaySummer3.png";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12, 0),
  },
}));

const CarouselArrowsStyle = styled(CarouselArrows)(({ theme }) => ({
  position: "unset",
  justifyContent: "center",
  "& button": {
    borderRadius: "50%",
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

// ----------------------------------------------------------------------

export default function ShowTypes() {
  const { t } = useTranslation();
  // ----------------------------------------------------------------------

  const theme = useTheme();

  const [types, setType] = useState(
    [
      {
        name: `${t("OfferCategory_1")}`,
        count: "9,326",
        image: browse1,
      },
      {
        name: `${t("OfferCategory_2")}`,
        count: "9,326",
        image: browse2,
      },
      {
        name: `${t("OfferCategory_3")}`,
        count: "9,326",
        image: browse3,
      },
/*       {
        name: "Office space",
        count: "9,326",
        image: browse1,
      }, */
    ]
  );
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const container = useBoundingClientRect(containerRef);

  useEffect(() => {
    //setWorkplaces();
  }, []);

  /* const offsetLeft = container?.left; */

  const carouselSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: {
              xs: "center",
              md: "center",
            },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">{t('Headline_BrowseWorkspaces')}</Typography>
            <Typography sx={{ fontSize: "28px", color: '#777E91' }}>
              {t('Subline_BrowseWorkspaces')}
            </Typography>
          </Stack>
        </Stack>
        {/* Arrows and offices cards */}
        <Slider ref={carouselRef} {...carouselSettings}>
          {types.map((type, index) => {
            return (
              <Box
                key={index}
                sx={{
                  /* ml: '-1px', */
                  py: 3,
                  pr: { xs: 2, md: 4 },
                  pl: { xs: 2, md: 4 },
                }}
              >
                <TypeItem type={type} />
              </Box>
            );
          })}
        </Slider>

        <Box sx={{ textAlign: "center", marginTop: "10px" }}>
          <CarouselArrowsStyle
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{
              display: "block",
            }}
          />
        </Box>
      </Container>
    </RootStyle>
  );
}

function TypeItem({type}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        py: 3,
        pr: 2,
        pl: 2,
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Box sx={{ width: "40%" }}>
        <Image
          alt={type.name}
          src={type.image}
          ratio="1/1"
        />
      </Box>
      <Box>
        <Stack spacing={1} flexGrow={1}>
          <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
            {type.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              backgroundColor: "#E6E8EC",
              padding: "2px 10px",
              borderRadius: "180px",
              textAlign: "center",
              fontWeight: "600 !important",
            }}
          >
            {type.count} {t("PlacesWorkspace")}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
