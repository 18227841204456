import React, { useEffect, useState } from "react";
// Yup
import * as Yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, TextField, IconButton, InputAdornment, Button, Card, CircularProgress } from '@mui/material';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LoadingButton } from '@mui/lab';
// icons
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';

import Page from "../components/Page";
import Layout from "../../../layouts";
import { Iconify } from '../../../components';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ErrorMsg from "../../../components/errors/ErrorMsg";
import SuccessMsg from "../../../components/success/SuccessMsg";
import config from "../../../config";
import { useGlobalContext } from "../../../stores/global";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";
import plusSquare from "../../../assets/images/plus-square.svg";


const Profile = () => {
  const { t } = useTranslation();
  const { user, dispatch } = useGlobalContext();

  const [showPassword, setShowPassword] = useState(false);



  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const [banner, setBanner] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const maxNumberBanner = 1;

  const onChangeBanner = async (bannerList, addUpdateIndex) => {

    if (bannerList[0].file.size <= 2000000) {
      // data for submit
      let json = [];
      await Promise.all(
        bannerList.map((item) => {
          json.push({
            id: uuidv4(),
            data: item.data_url,
          });
        })
      );
      console.log(bannerList);
      setBannerList(json);
      setBanner(bannerList);
    } else {
      setMsg("Banner image size must be less than 2MB");
      setOpen(true);
    }
  };

  const deleteOne = (index) => {
    let newarr = [...bannerList];
    newarr.splice(index, 1);
    setBannerList(newarr);
  };

  //gallery
  const [gallery, setGallery] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const maxNumberGallery = 8;

  const onChangeGallery = async (galleryList, addUpdateIndex) => {
    // data for submit
    let last_index_update = addUpdateIndex[0];
    if (galleryList[last_index_update].file.size <= 2000000) {
      let json = [];
      await Promise.all(
        galleryList.map((item) => {
          json.push({
            id: uuidv4(),
            data: item.data_url,
          });
        })
      );
      console.log(galleryList);
      setGalleryList(json);
      setGallery(galleryList);
    } else {
      setMsg("Gallery image size must be less than 2MB");
      setOpen(true);
    }
  };

  const deleteOneGallery = (index) => {
    let newarr = [...galleryList];
    newarr.splice(index, 1);
    setGalleryList(newarr);
  };

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }


  const saveImages = (banner, gallery) => {
    if (banner.length > 0 && gallery.length > 0) {
      setLoading(true);
      axios.defaults.headers.common = {
        Authorization: user.token,
      };
      let body = {
        titlepicture: banner[0].data,
      };
      console.log(body)
      axios
        .put(`${config.api.host}/users`,
          body
        )
        .then(async (res) => {
          setOpenSuccess(true);
          setMsgSuccess("Daten erfolgreich aktualisiert");
          axios.get(`${config.api.host}/users/${user.id}`)
            .then(async res => {
              let userdata = res.data.data.user
              dispatch({
                type: 'SET_USER',
                payload: {
                  token: user.token,
                  ...userdata,
                },
              });
              setLoading(false);
            })
            .catch(error => {
              console.log(error)
              setLoading(false);
              if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload(false);
              }
            })
        })
        .catch((error) => {
          setLoading(false);
          setOpen(true);
          setMsg("Änderungen konnten nicht gespeichert werden");
        });

      let body2 = {
        galleryList: gallery,
      };
      console.log(body2)
      axios
        .put(`${config.api.host}/users/public_profile/update_images`,
          body2
        )
        .then(async (res) => {
          setOpenSuccess(true);
          setMsgSuccess("Daten erfolgreich aktualisiert");
          axios.get(`${config.api.host}/users/${user.id}`)
            .then(async res => {
              let userdata = res.data.data.user
              dispatch({
                type: 'SET_USER',
                payload: {
                  token: user.token,
                  ...userdata,
                },
              });
            })
            .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload(false);
              }
            })
        })
        .catch((error) => {
          setOpen(true);
          setMsg("Änderungen konnten nicht gespeichert werden");
        });

    } else {
      setOpen(true);
      setMsg("Please upload banner and gallery images");
    }
  };


  useEffect(() => {
    let imagesBannerApi = [];
    let imageListBannerApi = [];
    //check if user.titlepicture is a base64 string
    if (user.titlepicture && !user.titlepicture.includes("data:image")) {
      console.log(config.api.host + user.titlepicture)
      toDataUrl(config.api.host + user.titlepicture, (data) => {
        imagesBannerApi.push({
          data_url: data,
        });
        imageListBannerApi.push({
          id: uuidv4(),
          data: data,
        });
        console.log(imagesBannerApi);
        setBanner(imagesBannerApi);
        setBannerList(imageListBannerApi);
      });
    } else {
      imagesBannerApi.push({
        data_url: user.titlepicture,
      });
      imageListBannerApi.push({
        id: uuidv4(),
        data: user.titlepicture,
      });
      console.log(imagesBannerApi);
      setBanner(imagesBannerApi);
      setBannerList(imageListBannerApi);
    }

    let imagesGalleryApi = [];
    let imageListGalleryApi = [];
    let i = 0;
    setGallery([]);
    setGalleryList([]);
    while (user.galleryList.length > i) {
      console.log(user.galleryList[i])
      toDataUrl(config.api.host + user.galleryList[i].data, (data) => {
        setGallery(prevArr => [...prevArr, { data_url: data }]);
        setGalleryList(prevArr => [...prevArr, { id: uuidv4(), data: data }]);
      })
      i = i + 1;
    }
  }, [user]);

  return (
    <Layout>
      <Page>
        <Box>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <Typography variant="h6" fontWeight={700}>
              {t('MyPictures_Change1')}
            </Typography>
          </Box>
          <Box paddingY={4}>
            <Divider />
          </Box>
        </Box>
        <Box>
          {/*title banne image*/}
          <Box>
            <Typography variant="h6" fontWeight={700}>
              {t('MyPictures_Banner')}
            </Typography>
          </Box>
          <Box paddingY={4}>
            <Divider />
            <Box sx={{ margin: { md: "1% 30px", xs: 0 } }}>
              <ImageUploading
                value={banner}
                onChange={onChangeBanner}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      height: { md: "60vh", xs: "300px" },
                    }}
                  >
                    <Card
                      style={isDragging ? { color: "red" } : undefined}

                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 6,
                        bgcolor: "#EAECF0",
                        cursor: "pointer",
                        border: "1px solid #D0D5DD",
                      }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <img
                        src={plusSquare}
                        alt="add"
                        width="50px"
                      />
                    </Card>
                    {banner.map((image, index) => {
                      if (image["data_url"]) return (
                        <Box
                          sx={{ position: "relative", margin: "10px" }}
                          key={index}
                        >
                          <img src={image["data_url"]} alt="" width="200" />
                          <button
                            style={{
                              position: "absolute",
                              backgroundColor: "black",
                              width: "20px",
                              height: "20px",
                              color: "white",
                              left: "10px",
                              top: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              onImageRemove(index);
                              deleteOneGallery(index);
                            }}
                          >
                            X
                          </button>
                        </Box>
                      )
                    })}
                  </Box>
                )}
              </ImageUploading>
            </Box>
          </Box>
        </Box>
        <Box>
          {/*title banne image*/}
          <Box>
            <Typography variant="h6" fontWeight={700}>
              {t('MyPictures_Gallery')}
            </Typography>
          </Box>
          <Box paddingY={4}>
            <Divider />
            <Box sx={{ margin: { md: "1% 30px", xs: 0 } }}>
              <ImageUploading
                multiple
                value={gallery}
                onChange={onChangeGallery}
                maxNumber={maxNumberGallery}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      height: { md: "60vh", xs: "300px" },
                      overflowY: "scroll",
                    }}
                  >
                    <Card
                      style={isDragging ? { color: "red" } : undefined}

                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 6,
                        bgcolor: "#EAECF0",
                        cursor: "pointer",
                        border: "1px solid #D0D5DD",
                      }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <img
                        src={plusSquare}
                        alt="add"
                        width="50px"
                      />
                    </Card>
                    {gallery.map((image, index) => (
                      <Box
                        sx={{ position: "relative", margin: "10px" }}
                        key={index}
                      >
                        <img src={image["data_url"]} alt="" width="200" />
                        <button
                          style={{
                            position: "absolute",
                            backgroundColor: "black",
                            width: "20px",
                            height: "20px",
                            color: "white",
                            left: "10px",
                            top: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            onImageRemove(index);
                            deleteOne(index);
                          }}
                        >
                          X
                        </button>
                      </Box>
                    ))}
                  </Box>
                )}
              </ImageUploading>
            </Box>
          </Box>
        </Box>
        {/*Button save*/}
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", md: "center" }}
          paddingY={4}
        >
          {loading ? (
            <Button
              variant="contained"
              color="primary"
            >
              <CircularProgress color="inherit" size={20} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                saveImages(bannerList, galleryList);
              }}
            >
              {t('SaveButton')}
            </Button>
          )}
        </Box>
        <ErrorMsg open={open} msg={msg} bottom={true} handleClose={handleClose} />
        <SuccessMsg
          open={openSuccess}
          msg={msgSuccess}
          bottom={true}
          handleClose={handleCloseSuccess}
        />
      </Page>
    </Layout>
  );
};

export default Profile;
