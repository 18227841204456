import React, { useRef, useState } from "react";
/* import { LazyLoadImage } from "react-lazy-load-image-component"; */
/* import { useTheme } from "@mui/material/styles"; */
import { Box, Container, Grid, Button, Typography, Card, CardContent, TextField, Divider, Avatar } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

/* import imageCheckout from '../../../../assets/images/have-space.jpg'; */
import { useEffect } from "react";
import { useRequest } from "../../../../hooks";
import { ErrorScreen, LoadingScreen } from "../../../../components";
import { set } from "date-fns";
import { useGlobalContext } from "../../../../stores/global";
import axios from "axios";
import config from "../../../../config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const MessageContent = ({ idBooking, client, seller, workplace, setOpenDetail }) => {
  const { t } = useTranslation();

  const { user } = useGlobalContext();

  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const { data: messages, error: messagesError, mutate } = useRequest({
    url: idBooking ? `/chat/messages/${idBooking}` : ""
  }, { refreshInterval: 5000, refreshWhenHidden: true });



  useEffect(() => {
    const objDiv = document.getElementById('div');
    if (objDiv)
      objDiv.scrollTop = objDiv.scrollHeight;
  }, [messages]);

  if (messagesError) {
    return <Box sx={{ position: "relative" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <ErrorScreen /></Box></Box>;
  }

  if (!messages) {
    return (
      <Box sx={{ position: "relative" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          marginBottom={4}
        >
        </Box>
        <Box paddingY={0}>
          <Divider />
        </Box>


        <Grid container spacing={2}>
          {/* Body messages */}
          <Grid item xs={12} md={12}>
            <Box sx={{ position: 'relative' }}>
              <Grid container spacing={1} sx={{ overflowY: "scroll" }} height={"60vh"}>
                Nachrichten laden...
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }


  //Messages create part
  const getValueMsg = (e) => {
    console.log(e.target.value)
    setMessage(e.target.value)
  }

  const sendMsg = () => {
    if (message) {
      console.log({
        booking_id: idBooking,
        from_user_id: user.id,
        to_user_id: user.id === client.id ? seller.id : client.id,
        message: message,
      })
      axios.defaults.headers.common = {
        Authorization: user.token,
      };
      axios
        .post(`${config.api.host}/chat/message`, {
          booking_id: idBooking,
          from_user_id: user.id,
          to_user_id: user.id === client.id ? seller.id : client.id,
          message: message,
        })
        .then(async (res) => {
          setLoading(true)
          setMessage("")
          await mutate()
          setLoading(false)
        })
        .catch((error) => {
          alert("An unexpected error occurred.");
        });
    }
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      sendMsg()
    }
  }
  
  /* const theme = useTheme(); */
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={1}
      >
        <Box
          component={Card}
          width={1}
          height={1}
          boxShadow={0}
          sx={{ backgroundImage: "none", bgcolor: "transparent", cursor: "pointer", width: "70%" }}
        >
          <Box
            component={Link}
            to={"/user/" + seller.id}
            sx={{
              display: "flex !important",
              alignItems: "center",
              gap: 1,
              "& .lazy-load-image-loaded": {
                height: 1,
                display: "flex !important",
              },
            }}
          >
            <Box
              component={LazyLoadImage}
              height={"50px"}
              width={"50px"}
              src={seller?.id !== user.id ? seller?.profilepicture : client?.profilepicture}
              alt="..."
              sx={{
                borderRadius: "360px",
                objectFit: "cover",
              }}
            />

            <CardContent
              sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}
            >
              <Typography fontWeight={500}
                sx={{
                  fontSize: "18px",
                  color: '#101828!important',
                }}
              >
                {seller?.id !== user.id ? seller?.first_name : client?.first_name}
              </Typography>
              <Box marginY={1 / 4}>
                <Typography
                  /* variant={"caption"} */
                  sx={{
                    fontSize: "14px",
                    color: '#667085!important',
                  }}
                  fontWeight={400}
                  component={"i"}
                >
                  {workplace?.title}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Button
            onClick={() => { setOpenDetail(true) }}
            sx={{ background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`, color: "#fff", borderRadius: "8px", padding: "8px 16px", fontSize: "14px", fontWeight: "500", lineHeight: "20px", textTransform: "none", boxShadow: "none", }}
            size="large"
            endIcon={
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            {t("Chat_RoomDetails")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: 1,
          marginBottom: 2
        }}
      >

        <Divider />
        <Box marginY={1}>
          <Typography
            sx={{
              fontSize: "14px",
              color: '#667085!important',
            }}
            fontWeight={400}
          >
            {workplace?.address}
          </Typography>
        </Box>

        <Divider />
      </Box>


      <Grid container spacing={2}>
        {/* Body messages */}
        <Grid item xs={12} md={12}>
          <Box sx={{ position: 'relative' }}>
            <Grid id="div" container spacing={1} sx={{ overflowY: "scroll" }} height={"60vh"}>
              {messages && messages.allMessage.map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    width={1}
                    borderRadius={0}
                    boxShadow={0}
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "row" }}
                    sx={{ backgroundImage: "none", bgcolor: "transparent" }}
                  >
                    <Box
                      sx={{
                        width: { xs: 1, md: "100%" },
                        display: "flex",
                        flexDirection: "column",
                        marginX: 2
                      }}
                    >
                      <Box display={"flex"} gap={1} sx={{ alignItems: "flex-start", justifyContent: item.from_user_id.id === user.id ? "flex-end" : "flex-start" }}>
                        {item.from_user_id.id !== user.id && <Avatar src={item.from_user_id.profilepicture} />}
                        <Box width={"70%"}>
                          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography
                              fontWeight={700}
                              fontSize={"14px"}
                              sx={{ textTransform: "uppercase", marginLeft: 1.2 }}
                            >
                              {item.from_user_id.id === user.id ? "Me" : item.from_user_id.first_name + " " + item.from_user_id.last_name}
                            </Typography>

                            <Box sx={{ justifyContent: item.from_user_id.id === user.id ? "flex-end" : "flex-start" }}>
                              <Typography
                                color={'#777E91'}
                                component={"i"}
                              >
                                {moment(item.message.created_at).format("DD.MM.YYYY hh:mm")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ backgroundColor: item.from_user_id.id !== user.id ? "#F2F4F7" : "#FB5A55", paddingY: 2, paddingX: 2, borderRadius: "15px" }}>
                            <Typography display={"flex"} sx={{ marginLeft: 1.2, justifyContent: "flex-start" }} color={item.from_user_id.id === user.id ? '#fff' : '#101828'}>
                              {item.message.message}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        {/* footer send message */}
        <Grid item container>
          <Container>
            <Box sx={{ paddingTop: { xs: 3, md: 3 }, width: '100%' }} /* display={"block"} */ justifyContent={"flex-end"}>
              <Box
                noValidate
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    bgcolor: "background.paper",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems={{ xs: "center", md: "flex-start" }}
                  justifyContent={{ xs: "center" }}
                >
                  <Box
                    display="block"
                    flex={"1 /* 1 auto  */"}
                    component={TextField}
                    onKeyDown={handleEnter}
                    onInput={getValueMsg}
                    value={message}
                    label="Message"
                    variant="outlined"
                    color="primary"
                    fullWidth
                  /* height={54} */
                  /*               sx={{
                                  maxWidth: 422,
                                }} */
                  />
                  <Box
                    component={Button}
                    sx={{ background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)` }}
                    onClick={sendMsg}
                    endIcon={<SendIcon />}
                    variant="contained"
                    /* color="primary" */
                    size="medium"
                    height={54}
                    marginTop={{ xs: 2, md: 0 }}
                    marginLeft={{ md: 2 }}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessageContent;
