import { Grid, Typography, Box, Alert } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import Calendar from 'react-calendar';
import './AvailableDays.css';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import config from '../../../config';
import 'moment/locale/de';

moment.locale('de');

const getAuthHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`
});

const api = axios.create({
  baseURL: config.api.host
});

export default function AvailableDays({ workplace, notShowCalendar, onDateRangeSelect }) {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([null, null]);
  const [notAvailableDates, setNotAvailableDates] = useState([]);
  const [priceDetails, setPriceDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const clearError = useCallback(() => setError(null), []);

  useEffect(() => {
    const fetchNotAvailableDates = async () => {
      const token = localStorage.getItem('token');
      if (!token || !workplace?.data?.id) {
        setError(t("AuthenticationRequired"));
        return;
      }
      
      setLoading(true);
      setError(null);
      
      try {
        const response = await api.post('/booking/check_range_date_not_available',
          {
            workplace_id: workplace.data.id,
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().add(6, 'months').format('YYYY-MM-DD')
          },
          { headers: getAuthHeaders() }
        );
        
        if (response.data.success && Array.isArray(response.data.notAvailableDates)) {
          setNotAvailableDates(response.data.notAvailableDates);
        } else {
          setError(t("ErrorLoadingAvailability"));
        }
      } catch (error) {
        console.error('Error fetching not available dates:', error);
        setError(t("ErrorLoadingAvailability"));
      } finally {
        setLoading(false);
      }
    };

    fetchNotAvailableDates();
  }, [workplace]);

  const calculatePrice = (days) => {
    if (!workplace?.data?.price) return null;
    
    const basePrice = parseFloat(workplace.data.price) * days;
    const serviceFee = Math.round(basePrice * 0.15 * 100) / 100; // Round to 2 decimals
    const totalPrice = Math.round((basePrice + serviceFee) * 100) / 100;

    return {
      days,
      pricePerDay: parseFloat(workplace.data.price),
      serviceFee,
      totalPrice
    };
  };

  const handleDateChange = async (dates) => {
    setDateRange(dates);
    if (!Array.isArray(dates) || !dates[0] || !dates[1]) {
      setPriceDetails(null);
      return;
    }

    const startDate = moment(dates[0]);
    const endDate = moment(dates[1]);
    const days = endDate.diff(startDate, 'days') + 1;

    // Sofort Preisdetails anzeigen
    const price = calculatePrice(days);
    if (price) {
      setPriceDetails(price);
    }

    // Verfügbarkeit im Hintergrund prüfen
    try {
      const response = await api.post('/booking/check_range_date_available',
        {
          workplace_id: workplace.data.id,
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD')
        },
        { headers: getAuthHeaders() }
      );

      if (!response.data.success) {
        setDateRange([null, null]);
        setPriceDetails(null);
        alert(t("DateRangeNotAvailable"));
      } else if (onDateRangeSelect && price) {
        onDateRangeSelect(dates, days, price.totalPrice);
      }
    } catch (error) {
      console.error('Error checking date range:', error);
      // Nicht den Kalender zurücksetzen, nur eine Warnung anzeigen
      alert(t("ErrorCheckingAvailability"));
    }
  };

  const tileDisabled = ({ date }) => {
    if (!Array.isArray(notAvailableDates)) return false;
    const formattedDate = moment(date).format('DD-MM-YYYY');
    const isBeforeToday = date < moment().startOf('day');
    const isNotAvailable = notAvailableDates.includes(formattedDate);
    return isBeforeToday || isNotAvailable;
  };

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={!notShowCalendar ? 6 : 12}>
        <section>
          <Typography variant="h4" paragraph>
            {t("OpeningHours_WorkspaceOverview")}
          </Typography>
          <div className="workplace__profile-image-wrapper">
            <div>
              {
                workplace.data.openedMonday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Montag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedMondayFrom.replace('-', ':')} - {workplace.data.openedMondayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedTuesday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Dienstag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedTuesdayFrom.replace('-', ':')} - {workplace.data.openedTuesdayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedWednesday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Mittwoch</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedWednesdayFrom.replace('-', ':')} - {workplace.data.openedWednesdayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedThursday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Donnerstag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedThursdayFrom.replace('-', ':')} - {workplace.data.openedThursdayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedFriday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Freitag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedFridayFrom.replace('-', ':')} - {workplace.data.openedFridayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedSaturday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Samstag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedSaturdayFrom.replace('-', ':')} - {workplace.data.openedSaturdayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
              {
                workplace.data.openedSunday ?
                  <div className="flex-container">
                    <Typography variant="h6" paragraph>Sonntag</Typography>
                    <Typography variant="h6" paragraph>{workplace.data.openedSundayFrom.replace('-', ':')} - {workplace.data.openedSundayTo.replace('-', ':')}</Typography>
                  </div>
                  : null
              }
            </div>
            <div style={{ opacity: 0.3 }}>
              {
                !workplace.data.openedMonday ?
                  <Typography variant="h6" paragraph>Montag</Typography>
                  : null
              }
              {
                !workplace.data.openedTuesday ?
                  <Typography variant="h6" paragraph>Dienstag</Typography>
                  : null
              }
              {
                !workplace.data.openedWednesday ?
                  <Typography variant="h6" paragraph>Mittwoch</Typography>
                  : null
              }
              {
                !workplace.data.openedTuesday ?
                  <Typography variant="h6" paragraph>Donerstag</Typography>
                  : null
              }
              {
                !workplace.data.openedFriday ?
                  <Typography variant="h6" paragraph>Freitag</Typography>
                  : null
              }
              {
                !workplace.data.openedSaturday ?
                  <Typography variant="h6" paragraph>Samstag</Typography>
                  : null
              }
              {
                !workplace.data.openedSunday ?
                  <Typography variant="h6" paragraph>Sonntag</Typography>
                  : null
              }
            </div>
          </div>
        </section>
      </Grid>

      {!notShowCalendar && <Grid item xs={12} md={6} lg={6}>
        <Typography variant="h4" paragraph>
          {t("Availability_WorkspaceOverview")}
        </Typography>
        
        {error && (
          <Alert severity="error" onClose={clearError} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ position: 'relative' }}>
          {loading && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1
            }}>
              <Typography>{t("LoadingAvailability")}</Typography>
            </Box>
          )}
          <Calendar
            onChange={handleDateChange}
            value={dateRange}
            minDate={new Date()}
            showNeighboringMonth={false}
            locale={"de"}
            tileDisabled={loading ? () => true : tileDisabled}
            selectRange={true}
          />
        </Box>
        
        {priceDetails && (
          <Box mt={2} p={3} sx={{
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            backgroundColor: '#f8f9fa',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
          }}>
            <Typography variant="h6" gutterBottom color="primary" sx={{ fontWeight: 600 }}>
              {t("BookingDetails")}
            </Typography>
            
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="text.secondary">
                  {t("Days")}
                </Typography>
                <Typography fontWeight="500">
                  {priceDetails.days} {t("DaysLabel")}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="text.secondary">
                  {t("PricePerDay")}
                </Typography>
                <Typography>
                  €{priceDetails.pricePerDay.toFixed(2)}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography color="text.secondary">
                    {t("ServiceFee")}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    (15%)
                  </Typography>
                </Box>
                <Typography>
                  €{priceDetails.serviceFee.toFixed(2)}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center"
                mt={2} pt={2}
                sx={{
                  borderTop: '2px solid #e0e0e0'
                }}
              >
                <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
                  {t("TotalPrice")}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
                  €{priceDetails.totalPrice.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>}
    </Grid>
  )
}
