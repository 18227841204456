import { useEffect, useState } from 'react';
import { Link, Link as LinkReact } from "react-router-dom";
import Typed from "react-typed";
// icons
/* import playIcon from '@iconify/icons-carbon/play'; */
import chevronRight from '@iconify/icons-carbon/chevron-right';
// @mui
/*import {  styled, alpha,  useTheme } from '@mui/material/styles';*/
import { Typography, Stack, /* Container, */ Box, /* Grid, */ Button, Avatar } from '@mui/material';
// utils
/* import cssStyles from '../../../utils/cssStyles'; */
/* import { fShortenNumber } from '../../../utils/formatNumber'; */
// utils
/* import { _brands } from '../../../../_data/mock'; */
// assets
/* import { CareerHeroIllustration } from '../../../assets/illustrations'; */
/* import HeaderVideo from '../../../assets/videos/Header-Animation-01.mp4' */
// components
import { Iconify, /* BgOverlay, */ Image, /* TextIconLabel, PlayerWithButton */ } from '../../../components';
import munichImage from './../../../assets/images/fullMunich.png';
import home from './../../../assets/images/Home_Titlte.svg';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../../hooks';
/* import { FabButtonAnimate } from '../../../components/animate'; */
/* import { TravelTourBarFilters } from '../../../sections/@travel'; */
//
/* import { CareerJobKeywordFilter, CareerJobLocationsFilter } from '../filters'; */

// ----------------------------------------------------------------------

/* const RootStyle = styled(Stack)(({ theme }) => ({
  ...cssStyles(theme).bgImage(),
  overflow: 'hidden',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(26),
  },
})); */

/* const BarStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  '& .MuiAutocomplete-root': {
    '& .MuiFilledInput-root': {
      height: '48px !important',
      backgroundColor: 'transparent !important',
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})); */

// ----------------------------------------------------------------------

/* const video = `https://www.dropbox.com/s/8hvvci7grn524v6/twimyoTrailerEinschieben720.mp4?dl=0`; */

export default function CareerLandingHero() {
  /* const theme = useTheme(); */
  /*   const [openVideo, setOpenVideo] = useState(false); */
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    filterKeyword: null,
    filterLocation: null,
  });

  const handleChangeKeyword = (keyword) => {
    setFilters({
      ...filters,
      filterKeyword: keyword,
    });
  };

  const handleChangeLocation = (keyword) => {
    setFilters({
      ...filters,
      filterLocation: keyword,
    });
  };

  const [users, setUsers] = useState([]);

  const { data: userData, error: userError } = useRequest({
    url: `/users/getall`,
  });

  useEffect(() => {
    //filter only user that have profilepicture
    setUsers(userData?.data ? userData?.data.users.filter((user) => user.profilepicture).reverse().slice(0, 8) : []);
  }, [userData]);

  /*   const handleOpenVideo = () => {
      setOpenVideo(true);
    };
  
    const handleCloseVideo = () => {
      setOpenVideo(false);
    }; */

  return (
    <Box sx={{ minHeight: { xs: '100%', md: '100vh' }, position: 'relative', paddingX: "5%" }}>
      <Box
        sx={{
          display: 'flex',
          textAlign: { xs: "center", md: 'left' },
          position: 'relative',
          color: 'common.white',
          justifyContent: 'left',
        }}
      >
        <Stack
          alignItems="left"
          sx={{
            zIndex: 9,
            py: { xs: 10, md: 0 },
            marginTop: "5%",
            paddingLeft: { xs: 0, md: "6%" },
            paddingRight: { xs: 0, md: "30%" },
            width: { xs: '100%', md: '75%' },
            position: { md: 'absolute' },
          }}
        >
          <img src={home} alt="twimyo" style={{ width: "88%", height: "auto" }} />
          <Box
            alignItems="center"
            sx={{
              marginTop: { xs: 1, md: 1 },
              marginBottom: { xs: 2, md: 2 },
              '& svg': {
                mr: 1,
                width: 24,
                height: 24,
                color: 'primary.main',
              },
            }}
          >
            <Typography sx={{
              maxWidth: 820,
              fontSize: { xs: "1rem", sm: "1.5rem", md: "1.2rem", lg: "1.4rem", xxl: "2.4rem" },
              padding: { xs: "5%", md: 0, xl: 0, xxl: 0 }, fontWeight: '400',
              fontFamily: 'Poppins',
              fontStyle: 'normal',
            }}>
              {t("Hero_Subline")}
            </Typography>
          </Box>

          {/* <Stack spacing={1}> */}
          {/*             <Typography variant="h2" sx={{ lineHeight: '1.1' }} >
              Die Welt ist mein {''}
              <Box component="span" sx={{ color: 'primary.main' }}>Workspace</Box>
            </Typography> */}
          {/* <Typography sx={{ color: 'grey.500' }}> */}
          {/*             <Typography sx={{ fontSize: '18px', color: '#3F3F3F' }}>
              Bei Twimyo kann man jederzeit einen passenden Ort zum Arbeiten findest und dich dabei mit anderen Leuten vernetzen kannst. Ob am Schreibtisch, im Studio, oder ...! - dein perfektes Work-Life-Match findest du hier! Bald weltweit.
            </Typography> */}
          {/* </Stack> */}
          {/*avatars user secction*/}
          <Box sx={{ display: { xs: "none", md: 'flex' }, justifyContent: 'center', alignItems: 'center', marginTop: '5%', gap: -100, position: "relative" }}>
            {users.map((user, index) => (
              <Box component={Link} to={"/needspace"} key={user.id} sx={{ position: "absolute", left: (30 * index), zIndex: (200 - index) }}>
                <Avatar sx={{ border: "3px solid #FFFFFF", borderRadius: "360px", width: "44px !important", height: "44px !important" }} src={user.profilepicture} />
              </Box>
            ))}
          </Box>

          <Button
            sx={{
              width: { xs: '59%', sm: '50%', md: '60%', lg: '46%', },
              marginTop: { xs: "10%", sm: "10%", md: "10%", lg: "10%", xxl: "20%" },
              marginBottom: '10px',
              marginX: { xs: 'auto', md: '0' },
              padding: '16px 24px',
              borderRadius: '90px',
              backgroundColor: '#F5465D',
              fontSize: { xs: "0.8rem", sm: "0.80rem", md: "0.80rem", lg: "0.83rem", xxl: "1.3rem" }
            }}
            component={LinkReact}
            to='/search'
            variant="contained"
          >
            {t('DiscoverWorkspaces')}
          </Button>

          {/*  <Stack spacing={3} alignItems="center" direction={{ xs: 'column', md: 'row' }}> */}

          {/*                   <TextIconLabel
                    icon={
                      <FabButtonAnimate color="secondary" sx={{ mr: 2 }}>
                        <Iconify icon={playIcon} />
                      </FabButtonAnimate>
                    }
                    value="Video ansehen"
                    onClick={handleOpenVideo}
                    justifyContent={{ xs: 'center', sm: 'unset' }}
                    sx={{
                      cursor: 'pointer',
                      typography: 'h6',
                      '&:hover': { opacity: 0.72 },
                    }}
                  /> */}
          {/* </Stack> */}
          {/* <TravelTourBarFilters sx={{ py: { xs: 3, md: 8 } }} /> */}
        </Stack>
        {/* Background */}
        <Box
          sx={{
            width: 1,
            height: 1,
            position: {
              xs: 'absolute',
              md: 'relative',
            },
            borderRadius: "35px"
          }}
        >
          <Image
            alt="Munich Life"
            src={munichImage}
            sx={{
              height: { xs: 1, md: '85vh' },
              borderRadius: "35px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
