import React from "react";
import { styled } from '@mui/material/styles';
// config
import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from '../../config';
// layouts
import Layout from "../../layouts";
// sections
import { NeedSpaceFaq, /* NeedSpaceSteps, */ NeedSpaceHero } from '../../sections/@travel';
import ShowWorkplaces from "../../sections/@travel/showWorkplaces/ShowWorkplaces";
// components
import { Page } from '../../components';
import VideoZone from "../../sections/@travel/videoZone/videoZone";
import NeedSpaceKey from "../../sections/@travel/needSpace/NeedSpaceKey";
import Testimonials from "../../sections/@travel/testimonials/Testimonials";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: HEADER_MOBILE_HEIGHT,
  [theme.breakpoints.up('md')]: {
    paddingTop: HEADER_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------


const NeedWorkplace = () => {

  return (
    <Layout>
      <Page title="Need Workplace">
        <RootStyle>
          <NeedSpaceHero />
          <NeedSpaceKey />
          <ShowWorkplaces needspaceCenter={true} />
          <NeedSpaceFaq />
          <Box sx={{ marginBottom: 15 }}>
            <VideoZone />
          </Box>
          <Testimonials />
        </RootStyle>
      </Page>
    </Layout>
  );
};

export default NeedWorkplace;
