import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery, Grid } from "@mui/material";

import Container from "../companyInformation/components/Container";
import { MyBookingsCards, SidebarDetails } from "./components";
// layouts
import Layout from "../../layouts";
// components
import { ErrorScreen, LoadingScreen, Page } from "../../components";
import { useRequest } from "../../hooks";

const MyBookings = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { data: bookings, error: bookingsError } = useRequest({
    url: "/booking/getAll",
  });

  const [selectBooking, setSelectBooking] = useState(-1)

  if (bookingsError) {
    return <ErrorScreen />;
  }

  if (!bookings) {
    return <LoadingScreen />;
  }

  console.log(bookings)

  return (
    <Layout>
      <Page>
        <Box bgcolor={"alternate.main"}>
          <Container>
            <Grid sx={{ margin: '50px 0 30px 0' }} container spacing={isMd ? 4 : 0}>
              <Grid item xs={12} md={selectBooking >= 0 ? 8 : 12}>
                <MyBookingsCards bookings={bookings.allBookings} setSelectBooking={setSelectBooking} />
              </Grid>
              {selectBooking >= 0 &&
                <Grid item xs={12} md={4}>
                  <SidebarDetails booking={bookings.allBookings[selectBooking]} />
                </Grid>
              }
            </Grid>
          </Container>
        </Box>
      </Page>
    </Layout>
  );
};

export default MyBookings;
