import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const ContactCard = () => {
  const theme = useTheme();

  return (
    <Box
      component={Card}
      boxShadow={0}
      border={`1px solid ${theme.palette.divider}`}
    >
      <Box padding={{ xs: 2, sm: 3 }}>
        <Typography
          sx={{
            fontWeight: '700',
          }}
          gutterBottom
        >
          Wie kannst du uns kontaktieren?
        </Typography>
        <Typography
          variant={'body2'}
          color={'#777E91'}
          sx={{
            marginBottom: 2,
          }}
        >
          Bei Fragen kannst Du uns jederzeit erreichen
        </Typography>
        <Typography variant={'body2'} color={'#777E91'}>
          contact@twimyo.com
          <br />
          Garmischer Straße 4, 80339 München
          <br />
          Deutschland
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactCard;
