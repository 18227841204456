import PropTypes from 'prop-types';
// icons
import checkmarkIcon from '@iconify/icons-carbon/checkmark';
// @mui
import { Typography, Stack, Box, Divider, Chip, Grid } from '@mui/material';
// utils
import { TextIconLabel, Iconify } from '../../../components';
import SVGIcon from '../../../components/icons.element';
import { useTranslation } from "react-i18next";


// ----------------------------------------------------------------------

TravelTourDetails.propTypes = {
  workspaces: PropTypes.shape({
    availableEnd: PropTypes.string,
    availableStart: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.string,
    highlights: PropTypes.array,
    includes: PropTypes.array,
    languages: PropTypes.array,
    location: PropTypes.string,
    program: PropTypes.array,
    officeTags: PropTypes.array,
    tourGuide: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  }),
};

export default function TravelTourDetails({ workspaces, tagListOffice, categories }) {
  const { t } = useTranslation();

  return (
    <Stack spacing={5}>
      {/* -- Tour Includes -- */}
      <section>
        <Typography variant="h4" paragraph sx={{ mb: 3, color: '#23262F' }}> {/* ToDo: check the Workspace.type for this title */}
          {t('Workspace_Display')}
        </Typography>

        <Grid container spacing={4}>
          {categories.map((category, index) => {
            if (category !== "security") {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Typography key={index} variant="h6" paragraph>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                    <div>
                      {(workspaces?.data?.tag_list ? workspaces?.data?.tag_list.split(",") : []).map((tag) => {
                        let tagListItem = tagListOffice.filter((element) => element.id === tag)[0];
                        if (tagListItem && tagListItem.category === category)
                          return (
                            <Box>
                              <Chip
                                sx={{
                                  color: "#23262F",
                                  opacity: 0.8,
                                  backgroundColor: "#E6E8EC",
                                  padding: "8px",
                                  margin: "5px 5px",
                                  textTransform: "none",
                                  fontSize: "16px",
                                }}
                                key={tagListItem.id}
                                icon={
                                  <Iconify
                                    icon={tagListItem.icon}
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      color: "#000 !important",
                                      margin: "0px !important",
                                    }}
                                  />
                                }
                                label={tagListItem.name}
                              />
                            </Box>)
                      })}
                    </div>
                  </Typography>
                  {category === "location" && (
                    <Typography variant="h6" paragraph>
                      {categories[index + 1].charAt(0).toUpperCase() + categories[index + 1].slice(1)}
                      <div>
                        {(workspaces?.data?.tag_list ? workspaces?.data?.tag_list.split(",") : []).map((tag) => {
                          let tagListItem = tagListOffice.filter((element) => element.id === tag)[0];
                          if (tagListItem && tagListItem.category === categories[index + 1])
                            return (
                              <Box>
                                <Chip
                                  sx={{
                                    color: "#23262F",
                                    opacity: 0.8,
                                    backgroundColor: "#E6E8EC",
                                    padding: "8px",
                                    margin: "5px 5px",
                                    textTransform: "none",
                                    fontSize: "16px",
                                  }}
                                  key={tagListItem.id}
                                  icon={
                                    <Iconify
                                      icon={tagListItem.icon}
                                      sx={{
                                        width: 24,
                                        height: 24,
                                        color: "#000 !important",
                                        margin: "0px !important",
                                      }}
                                    />
                                  }
                                  label={tagListItem.name}
                                />
                              </Box>)
                        })}
                      </div>
                    </Typography>
                  )}
                </Grid>
              )
            }
          })}
        </Grid>
      </section>

    </Stack>
  );
}

// ----------------------------------------------------------------------

OverviewItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  text: PropTypes.string,
};

function OverviewItem({ icon, label, text = '-' }) {
  return (
    <TextIconLabel
      spacing={1.5}
      alignItems="flex-start"
      icon={icon}
      value={
        <Stack spacing={0.5}>
          <Typography variant="body2">{label}</Typography>
          <Typography sx={{ color: '#777E91' }}>{text}</Typography>
        </Stack>
      }
      sx={{ '& svg': { width: 24, height: 24 } }}
    />
  );
}

// ----------------------------------------------------------------------

HighlightItem.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};

function HighlightItem({ label, text }) {
  return (
    <Stack spacing={1}>
      <Typography
        variant="h6"
        sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}
      >
        <Box
          component="span"
          sx={{ width: 12, height: 2, borderRadius: 1, bgcolor: 'currentColor', mr: 1.5 }}
        />
        {label}
      </Typography>
      <Typography>{text}</Typography>
    </Stack>
  );
}
