import React from "react";
// @mui
import { Grid, Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import smile from "../../../assets/images/smile.svg";
import lightning from "../../../assets/images/lightning.svg";
import percent from "../../../assets/images/percent.svg";
// ----------------------------------------------------------------------

export default function NeedSpaceKey() {
  const { t } = useTranslation();

  const mock = [
    {
      /* title: "Deine Vorteile", */
      icon: smile,
      title: `${t('NeedSpace_Benefits')}`,
      subtitle: `${t('NeedSpace_Benefits_Content')}`,
      subtitle1: `${t('NeedSpace_Benefits_Content2')}`,
      subtitle2: `${t('NeedSpace_Benefits_Content3')}`,
      subtitle3: `${t('NeedSpace_Benefits_Content4')}`,
      /*     subtitle: `Nutzung einzigartiger und inspirierender Workspaces.`,
          subtitle1: `Gezielte Suche nach interessanten Profilen.`,
          subtitle2: `Lokales Wissen an allen Standorten.`,
          subtitle3: `Werde Teil einer kosmopolitischen Community.`, */
    },
    {
      icon: lightning,
      /* title: "Unser Service", */
      title: `${t('NeedSpace_Service')}`,
      subtitle: `${t('NeedSpace_Service_Content')}`,
      subtitle1: `${t('NeedSpace_Service_Content2')}`,
      subtitle2: `${t('NeedSpace_Service_Content3')}`,
      /*     subtitle: `Suchen & Sammeln der interessantesten Workspaces und der dahinterstehenden Menschen.`,
          subtitle1: `Kuratierung einer Plattform, die ihren Nutzer:innen ein perfektes Work-Life-Match ermöglichen will.`,
          subtitle2: `Anlaufsteller für deine Fragen und Feedback.`, */
    },
    {
      icon: percent,
      /* title: "Kosten", */
      title: `${t('NeedSpace_Cost')}`,
      subtitle: `${t('NeedSpace_Cost_Content')}`,
      subtitle1: `${t('NeedSpace_Cost_Content2')}`,
      /* subtitle: `Preise für die tage- oder monatsweise Nutzung werden von von den Locals festgelegt.`,
      subtitle1: `twimyo erhält 15% des Buchungsvolumens.`, */
    },
  ];
  return (
    <Container sx={{
      marginBottom: { xs: 5, md: 10 },
    }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{
          paddingTop: { xs: 8, md: 10 },
          paddingBottom: { xs: 5, md: 5 },
          textAlign: 'center',
        }}
      >
        <Box marginBottom={1}>
          <Typography sx={{ fontSize: "12px", color: '#777E91' }}>
            {t("You_Got_Questions")}
          </Typography>
          <Typography fontWeight={700} variant={"h2"} align={"center"}>
            {/* Make the world you office */}
            {t('NeedSpace_Headline2')}
          </Typography>
          {/*           <Typography align={"center"} sx={{ color: '#777E91' }}>
          The right Workplace makes all the difference!
          </Typography> */}
        </Box>
        {/*        </Grid> */}
      </Grid>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Box sx={{ backgroundColor: "#F4F5F6", borderRadius: "20px", padding: "64px 32px", height: "100%" }}>
              <Box sx={{ marginBottom: "30px" }}>
                <img src={item.icon} alt={item.title} />
              </Box>
              <Box sx={{ marginBottom: "20px" }}>
                <Typography variant={"h4"} align={"justify"} fontWeight={600} sx={{ textTransform: 'none' }}>
                  {item.title}
                </Typography>
              </Box>
              <Typography sx={{ marginBottom: "10px" }} fontSize={14} component="li" align={"left"} color='#777E91'>
                {item.subtitle}
              </Typography>
              <Typography sx={{ marginBottom: "10px" }} fontSize={14} component="li" align={"left"} color='#777E91'>
                {item.subtitle1}
              </Typography>
              {item.subtitle2 === undefined ?
                <></>
                :
                <Typography sx={{ marginBottom: "10px" }} fontSize={14} component="li" align={"left"} color='#777E91'>
                  {item.subtitle2}
                </Typography>
              }
              {item.subtitle3 === undefined ?
                <></>
                :
                <Typography sx={{ marginBottom: "10px" }} fontSize={14} component="li" align={"left"} color='#777E91'>
                  {item.subtitle3}
                </Typography>
              }
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
