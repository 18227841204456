import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function SuccessMsg({
  msg,
  open,
  handleClose,
  bottom = "false",
}) {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: bottom ? "bottom" : "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
