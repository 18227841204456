import React from "react";
import { Link } from "react-router-dom";
// MUI
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Images
import space1 from "../../../assets/images/container_space1.png";
import space2 from "../../../assets/images/container_space2.png";
import space3 from "../../../assets/images/container_space3.png";

import { useTranslation } from "react-i18next";
import { Button, Container, Stack } from "@mui/material";
import styled from "@emotion/styled";

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 15),
    padding: theme.spacing(12, 0),
  },
}));

export default function LandingServices() {
  const { t } = useTranslation();

  const mock = [
    {
      image: `${space1}`,
      description: `6,879 Workspaces`,
      /* description: "Ich brauche einen Workspace", */
      title: "Communal Area",
      linkto: null,
    },
    {
      image: `${space2}`,
      description: `6,879 Workspaces`,
      /* description: "Ich habe einen Workspace", */
      title: "Deskspace",
    },
    {
      image: `${space3}`,
      description: `6,879 Workspaces`,
      /* description: "Ich habe einen Workspace", */
      title: "Unique stay",
    },
  ];

  const theme = useTheme();
  return (
    <RootStyle>
      <Container maxWidth>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: "center",
            marginBottom: "35px",
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">
              {/* Entdecke Neues */}
              {t('Headline_Life')}
            </Typography>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t('Subline_Life')}
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid key={i} item xs={12} md={4}>
              <Link to={item.linkto === null ? "/needspace" : "/havespace"}>
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: 2,
                    "&:hover": {
                      "& img": {
                        transform: "scale(1.2)",
                      },
                    },
                    "& .lazy-load-image-loaded": {
                      display: "flex !important",
                    },
                    margin: 2,
                  }}
                >
                  <Box
                    component={LazyLoadImage}
                    width={1}
                    src={item.image}
                    alt="..."
                    effect="blur"
                    sx={{
                      transition: "transform .7s ease !important",
                      transform: "scale(1.0)",
                      objectFit: "cover",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  />
                </Box>

                <Box
                  padding={0}
                  sx={{ textAlign: "center", marginTop: "10px" }}
                >
                  <Box
                    sx={{
                      padding: "10px 0 0px 24px",
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      fontWeight={700}
                      sx={{ color: "black" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#777E90",
                        marginBottom: 15,
                        paddingBottom: "10px",
                        fontSize: "14px",
                      }}
                      gutterBottom
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: "center" }}>
          <Link to={"/search"}>
            <Button
              size="large"
              sx={{
                textTransform: "none",
                color: "black",
                border: "1px solid #ccc",
                borderRadius: "15px",
              }}
            >
              {t("LoadMoreSpaces")}
            </Button>
          </Link>
        </Box>
      </Container>
    </RootStyle>
  );
}
