import React, {
  createContext,
  useContext,
  useReducer,
} from 'react';
import reducer from './reducer';


const context = createContext({});

export function useGlobalContext() {
  return useContext(context);
}

export function Provider({ children, initialState }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <context.Provider value={{ ...state, dispatch }}>
      {children}
    </context.Provider>
  );
}
