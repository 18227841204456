/*create page of confirm active account*/
// Language: javascript

import styled from "@emotion/styled";
import { Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../components";
import config from "../../config";


const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(15, 2.5),
  [theme.breakpoints.up('sm')]: {
    height: '100vh',
  },
}));

export default function Active() {
  //get hast parameter from url with react router
  const { hash } = useParams();
  //create error 
  const [error, setError] = useState(null);
  //redirect after 5s
  useEffect(() => {
    //get twimyo_user from localstorage
    const twimyo_user = localStorage.getItem("twimyo_user");
    //if twimyo_user is not null get token
    let token = "";
    if (twimyo_user) {
      token = JSON.parse(twimyo_user).token;
    }
    axios.defaults.headers.common = {
      "Authorization": token
    };
    axios.get(`${config.api.host}/auth/activate/${hash}`)
      .then(async res => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.success) {
            setTimeout(() => {
              window.location.href = '/';
            }, 5000)
          } else {
            setError("Error: your account is already activated");
          }
        }
      }).catch((e) => {
        setError("Login first to activate your account");
      })
  }, [])

  return (
    <Page title="Activate | Account">
      <RootStyle>
        <Container>

          <Typography variant="h4" gutterBottom>
            Activate
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Your account has been activated successfully.
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {error || "You will be redirected to the homepage after 5 seconds ..."}
          </Typography>
        </Container>
      </RootStyle>

    </Page>
  );
}

