export const tag_list = [
  {
    id: '01', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Meetingraum', icon: "healthicons:group-discussion-meeting-outline"
  },
  { id: '02', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'LAN', icon: "cil:lan" },

  { id: '03', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Bildschirm', icon: "cil:screen-desktop" },

  { id: '04', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Drucker', icon: "ant-design:printer-outlined" },

  { id: '05', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'WLAN', icon: "ant-design:wifi-outlined" },

  { id: '06', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Küche', icon: "ic:outline-soup-kitchen" },

  { id: '07', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Kaffeemaschine', icon: "ic:baseline-coffee-maker" },

  { id: '08', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Flipchart', icon: "cil:bar-chart" },

  { id: '09', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Tastatur', icon: "cil:keyboard" },

  { id: '10', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Barrierefrei', icon: "cil:wheelchair" },

  { id: '11', entity: 'office', type: 'equipment', category: 'location', name: 'Parkplatz', icon: "ic:baseline-local-parking" },

  { id: '12', entity: 'office', type: 'equipment', category: 'location', name: 'ÖPNV', icon: "ic:sharp-directions-transit" },

  { id: '13', entity: 'office', type: 'equipment', category: 'basic equipment', name: 'Klimaanlage', icon: "icon-park-outline:air-conditioning" },

  { id: '14', entity: 'office', type: 'equipment', category: 'location', name: 'City', icon: "ic:sharp-directions-transit" },

  { id: '15', entity: 'office', type: 'equipment', category: 'location', name: 'Shopping', icon: "akar-icons:shopping-bag" },

  { id: '16', entity: 'office', type: 'equipment', category: 'location', name: 'Cafès', icon: "material-symbols:local-cafe-rounded" },

  { id: '17', entity: 'office', type: 'equipment', category: 'location', name: 'Am Wasser', icon: "ic:baseline-water" },

  { id: '18', entity: 'office', type: 'equipment', category: 'location', name: 'Museum', icon: "icon-park-outline:museum-one" },

  { id: '19', entity: 'office', type: 'equipment', category: 'location', name: 'Bars', icon: "map:night-club" },

  { id: '20', entity: 'office', type: 'equipment', category: 'location', name: 'Restaurants', icon: "ic:round-restaurant" },

  { id: '21', entity: 'office', type: 'equipment', category: 'location', name: 'Park', icon: "bxs:tree" },

  { id: '22', entity: 'office', type: 'equipment', category: 'security', name: 'Security', icon: "fluent:video-security-24-regular" },

  { id: '23', entity: 'office', type: 'equipment', category: 'security', name: 'Alarm', icon: "ci:notification-active" },

  { id: '24', entity: 'office', type: 'equipment', category: 'security', name: 'Abschließbar', icon: "akar-icons:lock-on" },

  { id: '25', entity: 'office', type: 'equipment', category: 'security', name: 'Persönlicher Safe', icon: "ri:safe-line" },

  { id: '26', entity: 'office', type: 'equipment', category: 'specials', name: 'Getränkeflat', icon: "cil:drink" },

  { id: '27', entity: 'office', type: 'equipment', category: 'specials', name: 'Snacks', icon: "dashicons:food" },

  { id: '28', entity: 'office', type: 'equipment', category: 'specials', name: 'Obst', icon: "carbon:fruit-bowl" },

  { id: '29', entity: 'office', type: 'equipment', category: 'specials', name: 'Gaming Zone', icon: "carbon:game-console" },

  { id: '30', entity: 'office', type: 'equipment', category: 'specials', name: 'Aufzug', icon: "mdi:elevator-passenger-outline" },

  { id: '31', entity: 'office', type: 'equipment', category: 'specials', name: 'Lounge', icon: "la:couch" },

  { id: '32', entity: 'office', type: 'equipment', category: 'specials', name: 'Billardtisch', icon: "tabler:sport-billard" },

  { id: '33', entity: 'office', type: 'equipment', category: 'specials', name: 'E-Autoanschluss', icon: "material-symbols:ev-charger-outline" },

  { id: '34', entity: 'office', type: 'equipment', category: 'specials', name: 'Dusche', icon: "material-symbols:shower" },

  { id: '35', entity: 'office', type: 'equipment', category: 'specials', name: 'Dachterrasse', icon: "icon-park:terrace" },

  { id: '36', entity: 'office', type: 'equipment', category: 'specials', name: 'Gym', icon: "gg:gym" },

  { id: '37', entity: 'office', type: 'equipment', category: 'location', name: 'Hotel', icon: "ic:round-local-hotel" },

  { id: '38', entity: 'office', type: 'equipment', category: 'location', name: 'Wellness', icon: "fa-solid:swimming-pool" },

  { id: '39', entity: 'office', type: 'equipment', category: 'location', name: 'ATM', icon: "fluent-emoji-high-contrast:atm-sign" }]