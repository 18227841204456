import { NumericFormat } from 'react-number-format';
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import config from "../../../config";
import { useGlobalContext } from "../../../stores/global";
import axios from "axios";

import { useTranslation } from 'react-i18next';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

export const Delete = ({ open, handleClose, id }) => {
  const { t } = useTranslation();

  const { user } = useGlobalContext();
  if (user) {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
  }

  const deleteWorkplace = () => {
    axios
      .delete(`${config.api.host}/office/workplace/delete/${id}`)
      .then((res) => {
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Möchten Sie diesen Arbeitsbereich wirklich löschen?
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "2%",
            justifyContent: "center",
            marginY: "10px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: "red", color: "white" }}
          >
            {t("Button_DeleteWorkspace_No")}
          </Button>
          <Button
            onClick={() => {
              deleteWorkplace();
            }}
            sx={{ backgroundColor: "#E52270", color: "white" }}
          >
            {t("Button_DeleteWorkspace_Yes")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
