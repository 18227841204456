/* import PropTypes from 'prop-types'; */
import { useEffect, useRef, useState } from "react";
/* import Slider from "react-slick"; */
/* import { m } from 'framer-motion'; */
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  /* Card, */ Button,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
// hooks
import { useBoundingClientRect, useRequest } from "../../../hooks";
// components
import {
  CarouselArrows /*  Image, Iconify, SvgIconStyle  */,
  Image,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
/* import config from '../../../config'; */
import TravelTourItem from "../tours/TravelTourItem";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import travelToMakeMemories from "../../../assets/images/team_pic.png";
import backgroundColor from "../../../assets/images/backgroundColorDegra.png";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12, 0),
  },
}));

const CarouselArrowsStyle = styled(CarouselArrows)(({ theme }) => ({
  position: "unset",
  justifyContent: "center",
  "& button": {
    borderRadius: "50%",
    border: `solid 1px ${theme.palette.divider}`,
  },
}));

// ----------------------------------------------------------------------

export default function MemoriesTravel() {
  const { t } = useTranslation();
  // ----------------------------------------------------------------------

  const theme = useTheme();

  const [types, setType] = useState(
    Array.from({ length: 10 }, (_, i) => i + 1)
  );
  const carouselRef = useRef(null);
  const containerRef = useRef(null);
  const container = useBoundingClientRect(containerRef);


  useEffect(() => {
    //setWorkplaces();
  }, []);

  /* const offsetLeft = container?.left; */

  const carouselSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ sm: "center",  md: "flex-end" }}
          sx={{
            textAlign: {
              xs: "center",
              md: "center",
            },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography sx={{ maxWidth: { xs: "100%", md: "70%" }, margin: "auto" }} variant="h2">{t("Headline_Memories")}
              <Box component="span" sx={{ backgroundImage: `url(${backgroundColor})`, color: "white", paddingX: "20px", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                {t("Headline_Memories2")}
              </Box>
              {t("Headline_Memories3")}
            </Typography>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t('Subline_Memories')}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          gap={{ xs: 10, lg: 30 }}
          sx={{
            marginTop: "50px",
          }}
        >
          <Box>
            <Box sx={{
              padding: "30px 0px",
              width: "100%",
              textAlign: {xs: "center", md: "left"},
            }}>
              <Chip label={"01"}
                sx={{
                  color: "common.white",
                  backgroundColor: "#8BC5E5",
                  padding: "1px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                  borderRadius: "100px",
                }} />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("Advantages_1")}
              </Typography>
              {/*               <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                Sed amet, pellentesque nam eu ultrices ac vestibulum pulvinar est. Convallis diam et vitae.
              </Typography> */}
            </Box>
            <Box sx={{
              padding: "30px 0px",
              width: "100%",
              textAlign: {xs: "center", md: "left"},
            }}>
              <Chip label={"02"}
                sx={{
                  color: "common.white",
                  backgroundColor: "#92A5EF",
                  padding: "1px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                  borderRadius: "100px",
                }} />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("Advantages_2")}
              </Typography>
              {/*               <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                Non adipiscing sit aliquet vivamus donec nascetur. Ultrices ullamcorper cursus nunc.
              </Typography> */}
            </Box>
            <Box sx={{
              padding: "30px 0px",
              width: "100%",
              textAlign: {xs: "center", md: "left"},
            }}>
              <Chip label={"03"}
                sx={{
                  color: "common.white",
                  backgroundColor: "#58C27D",
                  padding: "1px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                  borderRadius: "100px",
                }} />
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", }}>
                {t("Advantages_3")}
              </Typography>
              {/*               <Typography sx={{ fontSize: "16px", color: '#777E91' }}>
                At quam feugiat non suspendisse turpis. Tempus ipsum faucibus euismod a id tortor scelerisque.
              </Typography> */}
            </Box>
            <Box sx={{ textAlign: {xs: "center", md: "left"}, marginTop: "10px" }}>
              <Link to={"/search"}>
                <Button size="large" variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: "50px !important",
                    background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`
                  }}>
                  {/* Alle Workspaces anzeigen */}
                  {t("Advantages_Button")}
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            component={LazyLoadImage}
            maxHeight="650px !important"
            src={travelToMakeMemories}
            alt="..."
            sx={{
              borderRadius: "15px",
            }}
          />
        </Stack>
        
      </Container>
    </RootStyle>
  );
}

function TypeItem(type) {
  return (
    <Box
      sx={{
        py: 3,
        pr: 2,
        pl: 2,
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Box sx={{ width: "40%" }}>
        <Image
          alt={type}
          src={`https://images.unsplash.com/photo-1491349174775-aaafddd81942?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80`}
          ratio="1/1"
        />
      </Box>
      <Box>
        <Stack spacing={1} flexGrow={1}>
          <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
            Office space
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              backgroundColor: "#ccc",
              padding: "2px 10px",
              borderRadius: "180px",
              textAlign: "center",
              fontWeight: "600 !important",
            }}
          >
            9,320 places
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
