import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
// icons
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';
// @mui
import { LoadingButton } from '@mui/lab';
import { Typography, Stack, Link, TextField, IconButton, InputAdornment } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';

// components
import { Iconify } from '../../components';
/* import md5 from "md5"; */
import axios from 'axios';
import config from '../../config';
import { useGlobalContext } from '../../stores/global';
import ErrorMsg from '../../components/errors/ErrorMsg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Bitte gib ein Name ein!')
    .min(1, 'mindestens 1 Zeichen')
    .max(25, 'Maximal 25 Zeichen'),
  last_name: Yup.string()
    .required('Bitte gib ein Name ein!')
    .min(1, 'mindestens 1 Zeichen')
    .max(25, 'Maximal 25 Zeichen'),
  birthday: Yup.string()
    .required('Bitte gib ein Datum ein!'),
  email: Yup.string().required('Bitte gib eine E-Mail ein!').email('Das ist keine E-Mail'),
  password: Yup.string()
    .required('Bitte gib ein Passwort ein!')
    .min(6, 'Das Passwort sollte mindestens 6 Zeichen lang sein'),
  /*   confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], "Password's not match"), */
});

export default function RegisterForm() {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      birthday: '',
      email: '',
      password: '',
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { dispatch } = useGlobalContext();

  const registerUser = async (user) => {
    user.email = user.email.split(" ").join("");
    axios.post(`${config.api.host}/auth/register`, user)
      .then(res => {
        let data = res.data;
        axios.defaults.headers.common = {
          "Authorization": data.token,
        };
        if (data.success) {
          loginUser(user);
        } else {
          setMsg("Dieser Account existiert bereits");
          setOpen(true);
        }
      })
      .catch(error => {
        console.log(error)
        setOpen(true);
        setMsg("Fehler", "error", "Der Benutzername oder das Passwort ist falsch.");
      })
  }
  const loginUser = async (user) => {
    axios.post(`${config.api.host}/auth/login`, user)
      .then(res => {
        let data = res.data;
        axios.defaults.headers.common = {
          "Authorization": data.token,
        };
        axios.get(`${config.api.host}/users/${data.id}`)
          .then(async res => {
            let userdata = res.data.data.user
            dispatch({
              type: 'SET_USER',
              payload: {
                token: data.token,
                ...userdata,
              },
            });
            window.open('/complete-register/1', '_self')
          })
          .catch(error => {
            console.log(error)
            setOpen(true);
            setMsg("Ein unerwarteter Fehler ist aufgetreten");
            if (error.response.status === 401) {
              localStorage.removeItem('token');
              window.location.reload(false);
            }
          })
      })
      .catch(error => {
        console.log(error)
        setOpen(true);
        setMsg("Fehler", "error", "Der Benutzername oder das Passwort ist falsch.");
        //this.openNotification("Fehler", "error", "Der Benutzername oder das Passwort ist falsch.")
      })
  }

  const onSubmit = async (data) => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    registerUser(data)
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5}>
          <Controller
            name="first_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t('FirstName')}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t('LastName')}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="birthday"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                label={t('Birthday')}
                renderInput={(params) => <TextField
                  {...params}
                  error={Boolean(error)}
                  helperText={error?.message} />}
                inputFormat="dd.MM.yyyy"
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Email_Information')}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? viewIcon : viewOff} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          {/*         <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? viewIcon : viewOff} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        /> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            REGISTRIEREN
          </LoadingButton>

          <Typography variant="caption" align="center" sx={{ color: '#777E91', mt: 3 }}>
            Mit der Registrierung stimmst du unseren
            <Link color="text.primary" href="/">
              {''} Terms of Service {''}
            </Link>
            und
            <Link color="text.primary" href="/">
              {''} Privacy Policy {''}
            </Link>
            zu.
          </Typography>
        </Stack>
      </form>
      <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
    </>
  );
}
