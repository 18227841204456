import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Search } from "./pages/workplace";
import { Workplace } from "./pages/workplace/detail";
import LoginIllustrationPage from "./pages/auth/login-illustration";
import RegisterIllustrationPage from "./pages/auth/register-illustration";
import NeedWorkplace from "./pages/needWorkplace";
import HaveWorkplace from "./pages/haveWorkplace";
import CompanyInformation from "./pages/companyInformation";
import CompanyTerms from "./pages/companyTerms";
import PageNotFound from "./pages/404/404";
import MaintenancePage from "./pages/Maintenance";
import CompanyDataSecurity from "./pages/companyDataSecurity";
import Faq from "./pages/faq";
import ProtectedRoute from "./sections/auth/ProtectedRoute";
import AccountGeneralPage from "./pages/account-general";
import AccountSecurity from "./pages/account-security";
import AccountBillingPage from "./pages/account-billing";
import Checkout from "./pages/checkout";
import MeineWorkspaces from "./pages/meineWorkspaces";
import CreateWorkpace from "./pages/meineWorkspaces/create";
import Complete from "./pages/checkout/complete";
import Chat from "./pages/chat";
import MyBookings from "./pages/myBookings";
import UpdateWorkspace from "./pages/meineWorkspaces/update";
import ResetPasswordPage from "./pages/auth/reset-password";
import VerifyCodePage from "./pages/auth/verify-code";
import PublicProfile from "./pages/publicProfile/PublicProfile";
import UserCardGrid from "./sections/@travel/userGrid/UserCardGrid";
import Users from "./pages/users/users";
import Cities from "./pages/cities/cities";
import Active from "./pages/activate";
import ElearningContactUsPage from "./pages/contact-us";
import AboutUs from "./pages/aboutUs";
import AccountProfilePage from "./pages/account-profile";
import MyFavorites from "./pages/myFavorites";
import mixpanel from "mixpanel-browser";
import config from "./config";
import CompleteRegister from "./sections/auth/completeRegister";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(config.mix_panel, { debug: true });

export const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/search" element={<Search />} />
      <Route path="/workspace/:id" element={<Workplace />} />
      <Route path="/login" element={<LoginIllustrationPage />} />
      <Route path="/signup" element={<RegisterIllustrationPage />} />
      <Route path="/complete-register/:step" element={<CompleteRegister />} />
      <Route path="/contact-us" element={<ElearningContactUsPage />} />
      <Route path="/activate/:hash" element={<Active />} />
      <Route path="/forgotPassword" element={<ResetPasswordPage />} />
      <Route path="/password-reset/:hash" element={<VerifyCodePage />} />
      <Route path="/needspace" element={<NeedWorkplace />} />
      <Route path="/havespace" element={<HaveWorkplace />} />
      <Route path="/imprint" element={<CompanyInformation />} />
      <Route path="/companyterms" element={<CompanyTerms />} />
      <Route path="/dataPolitics" element={<CompanyDataSecurity />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/checkout/complete" element={<Complete />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/:tag" element={<Users />} />
      <Route path="/city/:city" element={<Cities />} />
      <Route path="/user/:id" element={<PublicProfile />} />{" "}
      {/* put user name as ID in URL with @ */}
      {/*Protected Routes*/}
      <Route
        path="/chat"
        element={
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile-general"
        element={
          <ProtectedRoute>
            <AccountGeneralPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile-security"
        element={
          <ProtectedRoute>
            <AccountSecurity />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile-billing"
        element={
          <ProtectedRoute>
            <AccountBillingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile-pictures"
        element={
          <ProtectedRoute>
            <AccountProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/host/workspaces"
        element={
          <ProtectedRoute redirectPath={"/login"}>
            <MeineWorkspaces />
          </ProtectedRoute>
        }
      />
      <Route
        path="/host/workspaces/create"
        element={
          <ProtectedRoute>
            <CreateWorkpace />
          </ProtectedRoute>
        }
      />
      <Route
        path="/host/workspaces/update/:id"
        element={
          <ProtectedRoute>
            <UpdateWorkspace />
          </ProtectedRoute>
        }
      />
      <Route
        path="/myBookings"
        element={
          <ProtectedRoute>
            <MyBookings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/myFavorites"
        element={
          <ProtectedRoute>
            <MyFavorites />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
