import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";

import Container from "../companyInformation/components/Container";
import { MessageContent, SidebarMessages, } from "./components";
// layouts
import Layout from "../../layouts";
// components
import { ErrorScreen, LoadingScreen, Page } from "../../components";
import { useRequest } from "../../hooks";
import { SidebarDetails } from "../myBookings/components";

const Chat = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { data: bookings, error: bookingsError } = useRequest({
    url: "/booking/getAllChats",
  });

  const [selectBooking, setSelectBooking] = useState(-1)
  const [openDetail, setOpenDetail] = useState(false)

  if (bookingsError) {
    return <ErrorScreen />;
  }

  if (!bookings) {
    return <LoadingScreen />;
  }

  return (
    <Layout>
      <Page>
        <Container>
          <Grid sx={{ margin: '50px 0 30px 0' }} container spacing={isMd ? 4 : 2}>
            {!openDetail &&
              <Grid item xs={12} md={4}>
                <SidebarMessages bookings={bookings.allBookings} setSelectBooking={setSelectBooking} />
              </Grid>
            }
            {isMd && selectBooking >= 0 ? (
              <Grid item xs={12} md={8}>
                <MessageContent setOpenDetail={setOpenDetail} booking={bookings.allBookings[selectBooking].booking} workplace={bookings.allBookings[selectBooking].workplace} idBooking={bookings.allBookings[selectBooking].booking.id} client={bookings.allBookings[selectBooking].userRequest} seller={bookings.allBookings[selectBooking].user} />
              </Grid>
            ) : null}
            {openDetail &&
              <Grid item xs={12} md={4}>
                <SidebarDetails setOpenDetail={setOpenDetail} booking={bookings.allBookings[selectBooking]} />
              </Grid>
            }
          </Grid>
        </Container>
      </Page>
    </Layout>
  );
};

export default Chat;
