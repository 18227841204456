import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Container from "./Container";
import { Link, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../stores/global";
import { /* Chip, */ Chip, Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import config from "../../../config";
import { tag_user } from "../../../mocks/tag_user";
import SVGIcon from "../../../components/icons.element";
import moment from "moment";

//images
import house from "../../../assets/images/house.svg";
import share from "../../../assets/images/share.svg";
import more from "../../../assets/images/more.svg";
import facebook from "../../../assets/logos/social/facebook.svg";
import twitter from "../../../assets/logos/social/twitter.svg";
import instagram from "../../../assets/logos/social/instagram.svg";
import linkedin from "../../../assets/logos/social/linkedin.svg";
import star from "../../../assets/images/starGray.svg";
import flag from "../../../assets/images/flag.svg";
import { useRequest } from "../../../hooks";
import { useReducedMotion } from "framer-motion";

const UserCardWithBackgroundLogged = ({ showInput, handleChange }) => {
  const { t } = useTranslation();
  //get params id from url
  const { user } = useGlobalContext();

  //get rating of user
  const { data: ratingData, error: ratingError } = useRequest({
    url: `/users/get/review/rating/${user.id}`,
  });

  return (
    <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: 10, padding: 4 }}>

      {user?.profilepicture ? (
        <img src={user?.profilepicture} onClick={showInput} alt="profile photo" style={{
          maxWidth: "200px",
          maxHeight: "200px",
          width: "200px",
          height: "200px",
          borderRadius: "360px",
          objectFit: "cover",
          cursor: "pointer"
        }} />
      ) : (
        /*button with + icon*/
        <Button
          variant="contained"
          sx={{
            width: "200px",
            height: "200px",
            borderRadius: "360px",
            backgroundColor: "#F5F5F5",
            color: "#000000",
            fontSize: "100px",
            fontWeight: "bold",
            cursor: "pointer"
          }}
          onClick={showInput}
        >
          +
        </Button>
      )}
      <input style={{ display: "none" }} type="file" id="img" name="img" onChange={handleChange} />

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} paddingTop={3}>
        <Typography
          variant="h4"
          fontWeight={600}
          gutterBottom
          sx={{ color: "#333" }}
        >
          {user?.first_name}
        </Typography>

        <Link to={'/user/' + user.id}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              marginBottom: 5,
            }}
          >
            <SVGIcon type={"star"} height={18} width={18} color={"orange"} />
            <Typography fontWeight={600}>
              {ratingData?.data?.rating}
            </Typography>
            <Typography variant="body2">
              ({ratingData?.data?.reviews} {t("Reviews")})
            </Typography>
          </Box>
        </Link>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 3,
            backgroundColor: "#F4F5F6",
            padding: "8px 35px",
            borderRadius: "15px",
            marginBottom: 3,
            flexWrap: "wrap",
            margin: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <img src={house} alt="house" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            <Typography variant="body2" color={"#777E91"}>
              {user?.has_workplace ? "Super Local" : "Traveler"}
            </Typography>

          </Box>
          {/*           <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <img src={star} alt="star" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            <Typography variant="body2" color={"#777E91"}>
              ({ratingData?.data?.reviews} reviews)
            </Typography>
          </Box> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 1,
            marginBottom: 3,
          }}
        >
          <Button sx={{
            backgroundColor: "#23262F",
            color: "#FCFCFD",
            borderRadius: "360px",
            padding: "8px 15px",
          }}>
            {t("Contact")}
          </Button>
          <Box sx={{
            border: "2px solid #E6E8EC",
            color: "#FCFCFD",
            borderRadius: "360px",
            cursor: "pointer",
            paddingTop: "8px",
            paddingX: "8px",
          }}>
            <img src={share} alt="share" style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </Box>
          <Box sx={{
            border: "2px solid #E6E8EC",
            color: "#FCFCFD",
            borderRadius: "360px",
            cursor: "pointer",
            paddingTop: "8px",
            paddingX: "8px",
          }}>
            <img src={more} alt="more" style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 3,
          }}
        >
          {user?.social_twitter && (
            <Box sx={{
              backgroundColor: "transparent",
              borderRadius: "360px",
              cursor: "pointer",
            }}>
              <img src={twitter} onClick={() => window.open(user?.social_twitter, '_blank')} alt="twitter" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            </Box>
          )}

          {user?.social_instagram && (
            <Box sx={{
              backgroundColor: "transparent",
              borderRadius: "360px",
              cursor: "pointer",
            }}>
              <img src={instagram} onClick={() => window.open(user?.social_instagram, '_blank')} alt="instagram" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            </Box>
          )}

          {user?.social_facebook && (
            <Box sx={{
              backgroundColor: "transparent",
              borderRadius: "360px",
              cursor: "pointer",
            }}>
              <img src={facebook} onClick={() => window.open(user?.social_facebook, '_blank')} alt="facebook" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            </Box>
          )}

          {user?.social_linkedin && (
            <Box sx={{
              backgroundColor: "transparent",
              borderRadius: "360px",
              cursor: "pointer",
            }}>
              <img src={linkedin} onClick={() => window.open(user?.social_linkedin, '_blank')} alt="LinkedIn" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            </Box>
          )}

        </Box>
        <Divider sx={{ backgroundColor: "#E6E8EC", margin: "40px 0", width: "70%" }} orientation="horizontal" />
        <Typography variant="body2" color={"#777E91"} sx={{ marginBottom: 3, }}>
          {t("Member_since")} {moment(user?.created_at).format("MMM DD, YYYY")}
        </Typography>
{/*         <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 1,
            marginBottom: 3,
          }}
        >
          <Typography variant="body2" color={"#777E91"}>
            <img src={flag} alt="flag" style={{ maxWidth: "20px", maxHeight: "20px", marginRight: "10px" }} />
            {t("Report_this_host")}
          </Typography>
        </Box> */}
      </Box>
    </Card>

  );
};

export default UserCardWithBackgroundLogged;
