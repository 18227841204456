import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

// @mui
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Box, Stack, Button, AppBar, Divider, Container, Menu, MenuItem } from '@mui/material';
// hooks
import { useOffSetTop, useResponsive } from '../../hooks';
// components
import { Logo, /* Label */ } from '../../components';
//
import Searchbar from '../Searchbar';
import LanguagePopover from '../LanguagePopover';
import { NavMobile, NavDesktop/* , navConfig */ } from '../nav';
import { ToolbarStyle, ToolbarShadowStyle } from './HeaderToolbarStyle';
import { useGlobalContext } from '../../stores/global';
import { logout } from '../../helpers/asyncActions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

Header.propTypes = {
  transparent: PropTypes.bool,
};

export default function Header({ transparent }) {
  const { t } = useTranslation();

  const navConfig = [
    { title: `${t('TopNavigation_NeedSpace')}` /* 'Brauche Workspace' */, path: "/needspace" },
    { title: `${t('TopNavigation_HaveSpace')}` /* 'Habe Workspace' */, path: "/havespace" },
  ];

  const { user, dispatch } = useGlobalContext();

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const isScrolling = useOffSetTop(96);

  const logOutUser = () => {
    setAnchorEl(null);
    logout(dispatch);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle disableGutters transparent={transparent} scrolling={isScrolling}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo onDark={transparent && !isScrolling} />
          </Box>

          {isDesktop && (
            <NavDesktop
              isScrolling={isScrolling}
              isTransparent={transparent}
              navConfig={navConfig}
            />
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack spacing={3} direction="row" alignItems="center">
            {isDesktop && (<Box component={Link} to={"/contact-us"} color={"#777E91"}>
              Support
            </Box>)}

            <LanguagePopover
              sx={{
                ...(isScrolling && { color: 'text.primary' }),
              }}
              isDesktop={isDesktop}
            />

            {isDesktop && (
              <Stack direction="row" spacing={3}>
                {!user ? (
                  <>
                    <Button
                      component={Link}
                      to={"/login"}
                      color="inherit"
                      variant="outlined"
                      sx={{
                        ...(transparent && {
                          color: 'common.white',
                        }),
                        ...(isScrolling && isLight && { color: 'text.primary' }),
                      }}
                    >
                      Login
                    </Button>
                    <Button
                      sx={{ background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)` }}
                      component={Link} variant="contained" to={"/signup"} rel="noopener">
                      JETZT REGISTRIEREN
                    </Button>
                  </>
                ) :
                  <div>
                    <Button
                      rel="noopener"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      sx={{
                        ...(transparent && {
                          color: 'common.white',
                        }),
                        ...(isScrolling && isLight && { color: 'text.primary' }),
                      }}>
                      {user.profilepicture &&
                        <img src={user.profilepicture} alt="profile photo" style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          height: "35px",
                          width: "35px",
                          objectFit: "cover",
                          margin: "3px 10px",
                          borderRadius: "50px"
                        }} />}
                      {user.first_name} {user.last_name}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/profile-general">{t('ProfileNavigation_MyProfile')}{/* Mein Profil */}</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/chat">Chat</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/myBookings">
                        {t('ProfileNavigation_MyBookings')}{/* Meine Buchungen */}</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/myFavorites"> Favorites{/* Meine Favoriten */}</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/host/workspaces">{t('ProfileNavigation_MyWorkspaces')}{/* Meine Workspaces */}</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} component={Link} to="/faq">{t('ProfileNavigation_Help')}{/* Hilfe */}</MenuItem>
                      <MenuItem style={{ padding: "5px 20px" }} onClick={logOutUser}>Logout</MenuItem>
                    </Menu></div>}
              </Stack>
            )}
          </Stack>

          {!isDesktop && (
            <NavMobile
              navConfig={navConfig}
              sx={{
                ml: 1,
                ...(isScrolling && { color: 'text.primary' }),
              }}
            />
          )}
        </Container>
      </ToolbarStyle>

      {isScrolling && <ToolbarShadowStyle />}
    </AppBar>
  );
}
