import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as LinkReact, useNavigate } from "react-router-dom";
// @mui
import { Typography, Stack, Box, Button, Divider, Card, Avatar, IconButton } from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import { TravelTourFilterGuests, TravelTourFilterTime } from '../filters';
import { useGlobalContext } from '../../../stores/global';
import { useTranslation } from 'react-i18next';
import { Iconify, RatingLabel } from '../../../components';
import shoppingBag from '@iconify/icons-carbon/shopping-bag';

// ----------------------------------------------------------------------

TravelTourReserveForm.propTypes = {
  tour: PropTypes.shape({
    price: PropTypes.number,
    priceSale: PropTypes.number,
  }),
};

export default function TravelTourReserveForm({ tour }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  //get user
  const { user } = useGlobalContext();


  const [guests, setGuests] = useState({
    adults: 0,
    children: 0,
  });

  const { price } = tour.data;

  const [priceSale, setPriceSale] = useState(0)
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [days, setDays] = useState(0);

  const totalGuests = guests.adults + guests.children;

  const calculatePrice = (currentDays, currentGuests) => {
    if (!tour.data.amount || !tour.data.extra_percent) {
      return price * currentDays;
    }

    if (currentGuests > tour.data.amount) {
      const basePrice = price * currentDays;
      const extraGuests = currentGuests - tour.data.amount;
      const extraPercent = tour.data.extra_percent / 100;
      const extraPrice = basePrice * extraPercent;
      return basePrice + (extraPrice * extraGuests);
    }

    return price * currentDays;
  };

  const calculateTotalPrice = (newDays) => {
    setDays(newDays);
    setPriceSale(calculatePrice(newDays, totalGuests));
  };

  useEffect(() => {
    setPriceSale(calculatePrice(days, totalGuests));
  }, [guests, days, totalGuests, price, tour.data.amount, tour.data.extra_percent]);

  const changeDates = (from, to) => {
    setFromDate(from);
    setToDate(to)
  }

  const handleChangeReserve = () => {
    //check if user is logged if not redirect to login
    if (user) {
      navigate(`/checkout?workspace=${tour.data.id}&price=${priceSale}&dateFrom=${fromDate}&dateTo=${toDate}&guests=${totalGuests}&days=${days}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <Card>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'h4' }}>
              €{fCurrency(price)}
              <Typography variant="body2" component="span" sx={{ color: 'text.disabled' }}>
                /Day
              </Typography>
            </Stack>
            <RatingLabel className="noWrapText" ratings={tour?.data?.rating} reviews={tour?.data?.allReviews.length || 0} fontSize={12} />
          </Box>
          <LinkReact to={'/user/' + tour.data.user.id}>
            <Avatar
              src={tour.data.user.profilepicture}
              sx={{ width: 70, height: 70 }}
            />
          </LinkReact>
        </Box>
        <Stack sx={{ bgcolor: 'grey.5008', borderRadius: 1, padding: "12px 14px 0" }} spacing={1.5}>
          <Box sx={{ py: 0.5, }}>
            <TravelTourFilterTime noWhite={true} setTotalPrice={calculateTotalPrice} changeDates={changeDates} workspace={tour.data} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Divider width={"80%"} />
          </Box>
          <Box sx={{ padding: "12px 14px 0" }}>
            <TravelTourFilterGuests guests={guests} setGuests={setGuests} />
          </Box>
        </Stack>
      </Stack>

      <Typography variant="h5" sx={{ px: 3, py: 2 }}>
        {t('Workspace_PriceDetails')}
      </Typography>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {t("Days")}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {days}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {t('Workspace_ServiceFee')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            €{fCurrency(priceSale * 0.15)}
          </Typography>
        </Stack>

        <Divider />
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between" backgroundColor={"#F4F5F6"} paddingY={3} paddingX={2} borderRadius={"5px"}>
          <Typography variant="h5">Total <span style={{ color: "#777E91" }}>(EUR)</span></Typography>
          <Typography variant="h5">€{fCurrency(priceSale)}</Typography>
        </Stack>
        <Button
          size="large"
          variant="contained"
          disabled={totalGuests === 0 || priceSale === 0}
          onClick={handleChangeReserve}
          sx={{
            color: "white !important",
            fontWeight: "700",
            /* border: "1px solid #E6E8EC", */
            borderRadius: "90px",
            textTransform: "none",
          }}
          endIcon={<Iconify
            icon={shoppingBag}
            sx={{ width: 16, height: 16, color: "white" }}
          />}
        >
          {t('Button_ReserveNow')}
        </Button>
      </Stack>
    </Card>
  );
}
