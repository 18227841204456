import PropTypes from 'prop-types';
import Slider from 'react-slick';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Step, StepLabel, Stepper, Typography } from '@mui/material';
// components
import { Image, BgOverlay, CarouselDots } from '../../../../components';
// Images
import bannerRegisterComplete from "../../../../assets/images/bannerRegisterComplete.png";
import LogoTwimyoRegisterComplete from "../../../../assets/images/LogoTwimyoRegisterComplete.png";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        width: "25%",
        flexGrow: 1,
        display: 'block',
        position: 'relative',
    },
}));



export default function CompleteStepper({ step }) {
    const { t } = useTranslation();

    const steps = [
        { label: t('New_Onboarding_Step1') },
        { label: t('New_Onboarding_Step2') },
        { label: t('New_Onboarding_Step3') },
        { label: t('New_Onboarding_Step4') },
        { label: t('New_Onboarding_Step5') },
    ];

    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(step);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        setActiveStep(step);
    }, [step]);

    return (
        <RootStyle>
            <Box sx={{
                width: "100%",
                height: 1,
                backgroundColor: "#F5F5F5",
            }}>
                <Box
                    sx={{
                        width: "250px",
                        display: "flex",
                        pl: 10,
                        pt: 10,
                    }}
                >
                    <Image src={LogoTwimyoRegisterComplete} alt="logo" />
                </Box>
                <Box
                    sx={{
                        pl: 10,
                        pt: 10,
                    }}
                >
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step
                                key={step.label}
                                sx={{
                                    '& .MuiStepIcon-root': {
                                        color: "#fff",
                                        //change size of circle
                                        width: 30,
                                        height: 30,
                                        border: "1px solid #DCDCDC",
                                        borderRadius: "50%",
                                    },

                                    '& .MuiStepLabel-root .MuiStepIcon-text': {
                                        fill: 'black', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .Mui-active':
                                    {
                                        color: 'common.white', // Just text label (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#FB5561', // circle color (COMPLETED)
                                    },
                                    '& .MuiStepLabel-root .Mui-complete .MuiStepIcon-text': {

                                        fill: '#FB5561', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                        fill: '#FB5561', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepLabel-label': {
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#000000 !important",
                                    },
                                }}
                            >
                                <StepLabel>
                                    {step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
        </RootStyle >
    );
}
