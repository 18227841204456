import { setUser, removeUser } from '../../helpers/storage';

export default function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_LOGIN_MODAL':
      return { ...state, loginModal: action.payload };
    case 'SET_ALERT_MODAL':
      return { ...state, alertModal: action.payload };
    case 'SET_USER':
      if (action.payload) {
        setUser(action.payload);
      } else if (action.payload === undefined) {
        removeUser();
      }

      return { ...state, user: action.payload };
    default:
      return state;
  }
}
