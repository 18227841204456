import axios from 'axios';
import { Dispatch } from 'react';
import config from '../config';
//import { getUserToken, logout as apiLogout, me } from '../api';
import { Action } from '../stores/global/reducer';
import { removeUser } from './storage';

export async function logout(dispatch) {
  try {
    removeUser()
  } finally {
    dispatch({ type: 'SET_USER', payload: undefined });
    window.location.href = '/';
  }
}

export function openSigninOn401Error(dispatch, err) {
  const errorStatus = err?.response?.status;

  if (errorStatus === 401) {
    dispatch({ type: 'SET_LOGIN_MODAL', payload: 'signin' });
  } else {
    throw err;
  }
}

export async function syncUser(
  dispatch,
) {
  /*  const token = localStorage.getItem('twimyo_user');
   if (!token.token) return;
 
   let res = await axios.get(`${config.api.host}/users/${token.id}`)
   let userdata = res.data.data.user
   dispatch({
     type: 'SET_USER',
     payload: {
       token: data.token,
       ...userdata,
     },
   }); */

}
