// next
// icons
import chevronLeft from '@iconify/icons-carbon/chevron-left';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import { Image, Page } from '../../components';
import { VerifyCodeForm } from '../../sections/auth';
// routes
// components
// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'relative',
  padding: theme.spacing(20, 2.5),
  [theme.breakpoints.up('sm')]: {
    height: '100vh',
  },
}));

// ----------------------------------------------------------------------

export default function VerifyCodePage() {
  return (
    <Page title="Verify Code">
      <RootStyle>
        <Box sx={{ maxWidth: 480 }}>
          <Image
            alt="email inbox"
            src="https://zone-assets-api.vercel.app/assets/icons/ic_email_inbox.svg"
            sx={{ mb: 5, width: 96, height: 96, mx: 'auto' }}
          />

          <Typography variant="h3" paragraph>
            Passwort zurücksetzen

          </Typography>
          <Typography variant="body2" sx={{ color: '#777E91' }}>
            Wählen ein neues sicheres Passwort, das du in Zukunft zum einloggen nutzen möchtest.
          </Typography>

          <VerifyCodeForm />
        </Box>
      </RootStyle>
    </Page>
  );
}
