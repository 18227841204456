import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  Card,
  /* Button, */ Divider,
  Chip,
  IconButton,
  Tabs,
  Tab,
  Button,
  Tooltip,
} from "@mui/material";
// hooks
import { /* useBoundingClientRect, */ useRequest } from "../../../hooks";
// components
import {
  CarouselArrows,
  Image,
  /* Iconify, SvgIconStyle, IconButtonAnimate, Label,  */ TextMaxLine,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
/* import moment from "moment"; */

// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SVGIcon from "../../../components/icons.element";
import { useTranslation } from "react-i18next";
import { tag_user } from "../../../mocks/tag_user";
import verified from "../../../assets/images/check.svg";
// ----------------------------------------------------------------------

SliderUser.propTypes = {
  children: PropTypes.node,
  customIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(5, 15),
    padding: theme.spacing(12, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SliderUser({
  customIcon, // Set icon right
  onNext,
  onPrevious,
  children,
  ...other
}) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(t("LocalOverviewNavigation1"));
  const categories = [
    `${t("LocalOverviewNavigation1")}`,
    `${t("LocalOverviewNavigation2")}`,
    `${t("LocalOverviewNavigation3")}`,
    `${t("LocalOverviewNavigation4")}`,
    `${t("LocalOverviewNavigation5")}`,
    `Culture Lovers`,
    `Collectors`,
    `Action Heroes`,
    `Philosophers`,
    `Yogis`,
    `Vegans`,
    `Night Owls`,
  ];
  /* ['All', 'Entrepreneurship', 'Work Hard', 'Eating together', 'Learn about the City']; */

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
  };

  // ----------------------------------------------------------------------
  const [users, setUsers] = useState([]);

  const { data: userData, error: userError } = useRequest({
    url: `/users/getall`,
  });

  useEffect(() => {
    tag_user.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
    let usersArr = userData?.data ? userData?.data.users : [];

    let arrId = [
      "64f52bf3-421b-4280-8885-c38f832a4a90",
      "cc768f8a-c848-4eca-b5ea-27f53fc57b11",
      "bf7b34e2-4f41-4e40-9a23-740cfd952825",
      "b51ab4b0-f06c-40bf-8268-70425612ea41",
      "53dbe84b-9c20-49ff-86ed-1ca47f8da633",
      "075ce649-f45b-4e95-b987-1aaee3f081c4",
      "de079f88-4465-42d1-9be8-bb7c010b13be",
      "c0aa6bd3-3d59-424f-9e06-41c7360102f6",
    ];

    let userFinal = [];
    arrId.forEach((id) => {
      let index = usersArr.findIndex((user) => user.id === id);
      if (index !== -1) {
        let user = usersArr.splice(index, 1);
        userFinal.push(user[0]);
      }
    });
    userFinal = [...userFinal, ...usersArr];
    setUsers(userFinal);
    if (isMobile) {
      //go to slide 0
      carouselRefMobile.current.slickGoTo(0);
    }
  }, [userData]);

  const containerRef = useRef(null);

  const theme = useTheme();

  const carouselRef = useRef(null);
  const carouselRefMobile = useRef(null);

  const [carouselSettings, setCarouselSettings] = useState({
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  });

  const [isMobile, setIsMobile] = useState(false);

  const carouselSettingsMobile = {
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
    carouselRefMobile.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    carouselRefMobile.current?.slickNext();
  };

  const [filterIds, setFilterIds] = useState([]);
  const [indexSelected, setIndexSelected] = useState(0);
  const searchIds = useCallback((index) => {
    let filter = [];
    setIndexSelected(index);
    switch (index) {
      case 0:
        setFilterIds([]);
        filter = [];
        break;
      case 1:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("high performer"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("high performer"));
        break;
      case 2:
        setFilterIds(tag_user.filter((tag) => tag.persona.includes("foodie")));
        filter = tag_user.filter((tag) => tag.persona.includes("foodie"));
        break;
      case 3:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("explorer"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("explorer"));
        break;
      case 4:
        setFilterIds(tag_user.filter((tag) => tag.persona.includes("athlete")));
        filter = tag_user.filter((tag) => tag.persona.includes("athlete"));
        break;
      case 5:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("culture lover"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("culture lover"));
        break;
      case 6:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("collector"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("collector"));
        break;
      case 7:
        setFilterIds(tag_user.filter((tag) => tag.persona.includes("action hero")));
        filter = tag_user.filter((tag) => tag.persona.includes("action hero"));
        break;
      case 8:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("philosopher"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("philosopher"));
        break;
      case 9:
        setFilterIds(tag_user.filter((tag) => tag.persona.includes("yogi")));
        filter = tag_user.filter((tag) => tag.persona.includes("yogi"));
        break;
      case 10:
        setFilterIds(tag_user.filter((tag) => tag.persona.includes("vegan")));
        filter = tag_user.filter((tag) => tag.persona.includes("vegan"));
        break;
      case 11:
        setFilterIds(
          tag_user.filter((tag) => tag.persona.includes("night owl"))
        );
        filter = tag_user.filter((tag) => tag.persona.includes("night owl"));
        break;
      default:
        setFilterIds([]);
        filter = [];
        break;
    }
    let arr = 0;
    users.map((user) => {
      let result = [];
      if (user.tag_user) {
        result = filter.filter((tag) =>
          user.tag_user.includes(tag.id)
        );
      }
      if (result.length > 0) {
        arr++;
      }
    });
    if (arr === 0) {
      console.log("ENTRO")
      console.log(arr)
      setCarouselSettings({
        arrows: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 2,
        slidesPerRow: 1,
        rtl: Boolean(theme.direction === "rtl"),
        responsive: [
          {
            breakpoint: theme.breakpoints.values.md,
            settings: { slidesToShow: 2 },
          },
          {
            breakpoint: theme.breakpoints.values.sm,
            settings: { slidesToShow: 1 },
          },
        ],
      });
    } else {
      console.log("ENTRO 2")
      console.log(arr)
      setCarouselSettings({
        arrows: false,
        infinite: false,
        slidesToShow: arr >= 4 ? 4 : arr,
        slidesToScroll: 1,
        rows: arr >= 8 ? 2 : 1,
        slidesPerRow: 1,
        rtl: Boolean(theme.direction === "rtl"),
        responsive: [
          {
            breakpoint: theme.breakpoints.values.md,
            settings: { slidesToShow: arr >= 2 ? 2 : arr },
          },
          {
            breakpoint: theme.breakpoints.values.sm,
            settings: { slidesToShow: arr >= 1 ? 1 : arr },
          },
        ],
      });
    }
  }, [filterIds, indexSelected, users]);

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: "center",
            marginBottom: "50px",
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">{t('Headline_Ambassadors')}</Typography>
            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t('Subline_Ambassadors')}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            pt: 2,
            pb: { xs: 0, md: 0 },
          }}
        >
          <Tabs
            value={selected}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            onChange={handleChangeCategory}
          >
            {categories.map((category, index) => (
              <Tab
                sx={{
                  backgroundColor:
                    index === indexSelected
                      ? "#353945 !important"
                      : "transparent",
                  color:
                    index === indexSelected
                      ? "#FCFCFD !important"
                      : "#777E91 !important",
                  borderRadius: index === indexSelected ? "15px" : "0px",
                  padding: index === indexSelected ? "10px !important" : "0px",
                }}
                onClick={() => searchIds(index)}
                key={category}
                value={category}
                label={category}
              />
            ))}
          </Tabs>
        </Box>

        {!isMobile ? (
          <>
            <Box
              sx={{
                position: "relative",
                ml: { md: -2 },
                width: { md: "calc(100% + 32px)" },
              }}
            >
              <Slider ref={carouselRef} {...carouselSettings}>
                {users.map((user) => {
                  let filter = [];
                  if (user.tag_user) {
                    filter = filterIds.filter((tag) =>
                      user.tag_user.includes(tag.id)
                    );
                  }
                  if (filter.length > 0 && indexSelected !== 0) {
                    return (

                      <Box
                        key={user.id}
                        sx={{
                          px: 3,
                          pt: { xs: 5, md: 5 },
                          pb: { xs: 2, md: 2 },
                        }}
                      >
                        <UserItem user={user} />
                      </Box>

                    );
                  } else {
                    if (indexSelected === 0) {
                      return (
                        <Box
                          key={user.id}
                          sx={{
                            px: 3,
                            pt: { xs: 5, md: 5 },
                            pb: { xs: 2, md: 2 },
                          }}
                        >
                          <UserItem user={user} />
                        </Box>

                      );
                    }
                  }
                })}
              </Slider>
            </Box>
            <Box sx={{ textAlign: "center", marginTop: "10px" }}>
              <CarouselArrows
                onNext={handleNext}
                onPrevious={handlePrevious}
                sx={{
                  display: "block",
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                position: "relative",
                ml: { md: -2 },
                width: "100%",
              }}
            >
              <Slider ref={carouselRefMobile} {...carouselSettingsMobile}>
                {users.map((user) => {
                  let filter = [];
                  if (user.tag_user) {
                    filter = filterIds.filter((tag) =>
                      user.tag_user.includes(tag.id)
                    );
                  }
                  if (filter.length > 0 && indexSelected !== 0) {
                    return (
                      user.profilepicture && (
                        <Box
                          key={user.id}
                          sx={{
                            px: 3,
                            pt: { xs: 5, md: 5 },
                            pb: { xs: 2, md: 2 },
                          }}
                        >
                          <UserItem user={user} />
                        </Box>
                      )
                    );
                  } else {
                    if (indexSelected === 0) {
                      return (
                        user.profilepicture && (
                          <Box
                            key={user.id}
                            sx={{
                              px: 3,
                              pt: { xs: 5, md: 5 },
                              pb: { xs: 2, md: 2 },
                            }}
                          >
                            <UserItem user={user} />
                          </Box>
                        )
                      );
                    }
                  }
                })}
              </Slider>
            </Box>
            <Box sx={{ textAlign: "center", marginTop: "10px" }}>
              <CarouselArrows
                onNext={handleNext}
                onPrevious={handlePrevious}
                sx={{
                  display: "block",
                }}
              />
            </Box>
          </>
        )}
      </Container>
    </RootStyle>
  );
}

function UserItem({ user }) {
  const { job, city, profilepicture, first_name, last_name } = user;


  return (
    <Link
      to={{
        pathname: `/user/${user.id}`,
        search: "",
      }}
    >
      <Card
        sx={{
          display: { sm: "flex" },
          boxShadow: (theme) => theme.customShadows.z16,
          "&:hover": {
            boxShadow: (theme) => theme.customShadows.z24,
          },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexShrink: { sm: 0 }, position: "relative", display: "flex", justifyContent: "center" }}>
          <Box sx={{
            padding: "40px",
            margin: "0 20px",
            "&:hover": {
              transform: 'scale(1.3)',
              transition: 'all 0.2s ease-in-out',

            }
          }}>
            <img
              alt={"img-city"}
              src={profilepicture || "https://via.placeholder.com/150"}
              width="150px"
              height="150px"
              style={{
                borderRadius: "360px",
                objectFit: "cover",
                paddingX: "auto",
              }}
            />
          </Box>
          {user?.stripe_id && (
            <Tooltip
              title="Verified User"
            >
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: 12,
                  right: 12,
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <img src={verified} alt="verified" width="40px" />
              </div>
            </Tooltip>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0 10px",
            alignItems: "center",
          }}
        >
          <TextMaxLine variant="body1" line={2}>
            <strong>
              {first_name} {/* {last_name} */}
            </strong>
          </TextMaxLine>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0 10px",
            alignItems: "center",
          }}
        >
          <TextMaxLine variant="body2" color='#777E91' line={2}>
            {city ? city.slice(0, 15) + (city.length > 15 ? "..." : "") : "No city"}
          </TextMaxLine>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0 10px 10px 10px",
            alignItems: "center",
          }}
        /*           sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 20px",
                    alignItems: "center",
                  }} */
        >
          <TextMaxLine variant="body2" color='#777E91' line={2}>
            {job ? job.slice(0, 20) + (job.length > 20 ? "..." : "") : "No job"}
          </TextMaxLine>
        </Box>
      </Card>
    </Link>
  );
}
