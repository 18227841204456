import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Container from "./Container";
import { Link, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../stores/global";
import { /* Chip, */ Chip, Divider, Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import config from "../../../config";
import { tag_user } from "../../../mocks/tag_user";
import SVGIcon from "../../../components/icons.element";
import moment from "moment";

//images
import house from "../../../assets/images/house.svg";
import share from "../../../assets/images/share.svg";
import more from "../../../assets/images/more.svg";
import facebook from "../../../assets/logos/social/facebook.svg";
import twitter from "../../../assets/logos/social/twitter.svg";
import instagram from "../../../assets/logos/social/instagram.svg";
import linkedin from "../../../assets/logos/social/linkedin.svg";
import facebookGray from "../../../assets/logos/social/facebook-gray.svg";
import twitterGray from "../../../assets/logos/social/twitter-gray.svg";
import instagramGray from "../../../assets/logos/social/instagram-gray.svg";
import linkedinGray from "../../../assets/logos/social/linkedin-gray.svg";
import star from "../../../assets/images/starGray.svg";
import flag from "../../../assets/images/flag.svg";


const UserCardWithBackground = ({ ratingData }) => {
  const { t } = useTranslation();

  //get params id from url
  const { id } = useParams();

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const theme = useTheme();

  const [userData, setUserData] = useState({});

  const [tags, setTags] = useState([])

  const [categories, setCategories] = useState([])

  const { user } = useGlobalContext();


  //get user by id with useEffect
  useEffect(() => {
    if (id) {
      if (user) {
        axios.defaults.headers.common = {
          Authorization: user.token,
        };
      }
      tag_user.map((item) => {
        item.category = t(item.category);
        item.name = t(item.name);
      });
      axios.get(`${config.api.host}/users/${id}`).then(res => {
        setUserData(res.data.data.user);
        let user = res.data.data.user;
        let tags = tag_user.filter((item) => item.category);

        let categories = tags.map((item, index) => {
          if ((user.tag_user || "").includes(item.id)) {
            tags[index].mainActive = true;
          }
          return item.category;
        });
        setTags(tags);
        categories = categories.filter(
          (item, index) => categories.indexOf(item) === index
        );
        setCategories(categories);
      });
    }
  }, [id]);


  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} paddingTop={4} paddingBottom={0}>

      <img src={userData?.profilepicture} alt="profile photo" style={{
        maxWidth: "200px",
        maxHeight: "200px",
        width: "200px",
        height: "200px",
        borderRadius: "360px",
        objectFit: "cover",
      }} />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} paddingTop={3}>
        <Typography
          variant="h4"
          fontWeight={600}
          gutterBottom
          sx={{ color: "#333" }}
        >
          {userData?.first_name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            marginBottom: 3,
          }}
        >
          <SVGIcon type={"star"} height={18} width={18} color={"orange"} />
          <Typography fontWeight={600}>
            {ratingData?.data?.rating}
          </Typography>
          <Typography variant="body2">
            ({ratingData?.data?.reviews} {t("Reviews")})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 3,
            backgroundColor: "#F4F5F6",
            padding: "8px 35px",
            borderRadius: "15px",
            marginBottom: 3,
            flexWrap: "wrap",
            margin: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <img src={house} alt="house" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            <Typography variant="body2" color={"#777E91"}>
              {userData?.has_workplace ? "Super Local" : "Traveler"}
            </Typography>

          </Box>
          {/*           <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <img src={star} alt="star" style={{ maxWidth: "20px", maxHeight: "20px" }} />
            <Typography variant="body2" color={"#777E91"}>
              ({ratingData?.data?.reviews} reviews)
            </Typography>

          </Box> */}
        </Box>

        {!user && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              my: 2
            }}
          >
            <Button sx={{
              background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`,
              color: "#FCFCFD",
              borderRadius: "360px",
              padding: "8px 15px",
              mx: 1,
            }}
              onClick={() => { window.location.href = "/login" }}
            >
              Zum Vernetzen einloggen
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 1,
            marginBottom: 3,
          }}
        >
          <Button sx={{
            backgroundColor: user ? "#23262F" : "#777E91",
            color: "#FCFCFD",
            borderRadius: "360px",
            padding: "8px 15px",
          }}>
            {t("Contact")}
          </Button>
          <Box sx={{
            border: "2px solid #E6E8EC",
            color: "#FCFCFD",
            borderRadius: "360px",
            cursor: "pointer",
            paddingTop: "8px",
            paddingX: "8px",
          }}>
            <img src={share} alt="share" style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </Box>
          <Box sx={{
            border: "2px solid #E6E8EC",
            color: "#FCFCFD",
            borderRadius: "360px",
            cursor: "pointer",
            paddingTop: "8px",
            paddingX: "8px",
          }}>
            <img src={more} alt="more" style={{ maxWidth: "20px", maxHeight: "20px" }} />
          </Box>
        </Box>
        {userData?.log_user_has_booking ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: 3,
            }}
          >
            {userData?.social_twitter && (
              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={twitter} onClick={() => window.open(userData?.social_twitter, '_blank')} alt="twitter" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>
            )}

            {userData?.social_instagram && (
              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={instagram} onClick={() => window.open(userData?.social_instagram, '_blank')} alt="instagram" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>
            )}

            {userData?.social_facebook && (
              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={facebook} onClick={() => window.open(userData?.social_facebook, '_blank')} alt="facebook" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>
            )}

            {userData?.social_linkedin && (
              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={linkedin} onClick={() => window.open(userData?.social_linkedin, '_blank')} alt="LinkedIn" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>
            )}

            {!userData?.social_twitter
              && !userData?.social_instagram
              && !userData?.social_facebook
              && !userData?.social_linkedin
              && (
                <Tooltip title={'The user does not have social networks'}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      gap: 3,
                      /* filter: "blur(1px)", */
                    }}
                  >
                    <Box sx={{
                      backgroundColor: "transparent",
                      borderRadius: "360px",
                      cursor: "pointer",
                    }}>
                      <img src={twitterGray} alt="twitter" style={{ maxWidth: "20px", maxHeight: "20px" }} />
                    </Box>

                    <Box sx={{
                      backgroundColor: "transparent",
                      borderRadius: "360px",
                      cursor: "pointer",
                    }}>
                      <img src={instagramGray} alt="instagram" style={{ maxWidth: "20px", maxHeight: "20px" }} />
                    </Box>

                    <Box sx={{
                      backgroundColor: "transparent",
                      borderRadius: "360px",
                      cursor: "pointer",
                    }}>
                      <img src={facebookGray} alt="facebook" style={{ maxWidth: "20px", maxHeight: "20px" }} />
                    </Box>

                    <Box sx={{
                      backgroundColor: "transparent",
                      borderRadius: "360px",
                      cursor: "pointer",
                    }}>
                      <img src={linkedinGray} alt="LinkedIn" style={{ maxWidth: "20px", maxHeight: "20px" }} />
                    </Box>

                  </Box>

                </Tooltip>
              )}
          </Box>
        ) : (
          <Tooltip title={t('SocialMedia_Function')}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 3,
                /* filter: "blur(1px)", */
              }}
            >
              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={twitterGray} alt="twitter" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>

              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={instagramGray} alt="instagram" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>

              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={facebookGray} alt="facebook" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>

              <Box sx={{
                backgroundColor: "transparent",
                borderRadius: "360px",
                cursor: "pointer",
              }}>
                <img src={linkedinGray} alt="LinkedIn" style={{ maxWidth: "20px", maxHeight: "20px" }} />
              </Box>

            </Box>

          </Tooltip>
        )}

        <Divider sx={{ backgroundColor: "#E6E8EC", margin: "40px 0", width: "70%" }} orientation="horizontal" />
        <Typography variant="body2" color={"#777E91"} sx={{ marginBottom: 3, }}>
          {t("Member_since")} {moment(userData?.created_at).format("MMM DD, YYYY")}
        </Typography>
        <Link to="/contact-us">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: 1,
              marginBottom: 3,
            }}
          >
            <Typography variant="body2" color={"#777E91"}>
              <img src={flag} alt="flag" style={{ maxWidth: "20px", maxHeight: "20px", marginRight: "10px" }} />
              {t("Report_this_host")}
            </Typography>
          </Box>
        </Link>
      </Box>
    </Box >

  );
};

export default UserCardWithBackground;
