// @mui
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
// hooks
import { useRequest } from "../../hooks";
// layouts
import Layout from "../../layouts";
// components
import { Page, ErrorScreen, LoadingScreen } from "../../components";
// sections
import {
  TravelTourList,
  TravelTourBarFilters,
} from "../../sections/@travel";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import config from "../../config";
import "./workplace_list.css";
import Footer from "../../layouts/footer/Footer";
import ReactDOMServer from "react-dom/server";
import SVGIcon from "../../components/icons.element";
import { data } from "jquery";

mapboxgl.accessToken = config.mapbox.access_token;

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

// ----------------------------------------------------------------------

export const Search = () => {
  //states
  const mapContainer = useRef();
  const [dataFilter, setDataFilter] = useState(null);
  let map = null;
  const [mapElement, setMapElement] = useState(
    <div style={{ height: "100%" }} ref={mapContainer} />
  );
  let locmarker = null;
  //ref

  //query-params
  const search = useLocation().search.replace("?", "");
  const dataQuery = search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : "";
  //fetch
  const queryParams = new URLSearchParams(window.location.search);
  //get page
  const page = queryParams.get('page') || 1;
  const { data: listWorkplaces = [], error } = useRequest({
    url: `/search/office`,
    method: "post",
    data: {
      filter: {
        radius: {
          distance: dataQuery?.distance ? dataQuery?.distance : 10,
          lat: dataQuery?.lat ? dataQuery?.lat : 48.137154,
          long: dataQuery?.long ? dataQuery?.long : 11.576124,
        },
        from: moment(dataQuery.from, "DD.MM.YYYY"),
        to: moment(dataQuery.to, "DD.MM.YYYY"),
        tags_user: dataQuery.tags_user ? dataQuery.tags_user.split(",") : [],
        tags_workspace: dataQuery.tags_workspace ? dataQuery.tags_workspace.split(",") : [],
        benefits: [],
        priceRange: [dataQuery.min || 1, dataQuery.max || 250],
        availability: true,
        type: dataQuery?.type ? dataQuery?.type : "all",
        amount: dataQuery?.amount ? dataQuery?.guests : 1,
      },
      page: page,
    },
  });

  
  const handleChangePage = (event, newPage) => {
    //get query params and detect if page is already there if not add it if yes replace it
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('page', newPage);
    //redirect to the same page with the new query params

    window.location.href = "/search?"+queryParams.toString();
  };

  useEffect(() => {
    console.log("dataQuery", dataQuery);
    setDataFilter({
      filter: {
        radius: {
          distance: dataQuery?.distance ? dataQuery?.distance : 10,
          lat: dataQuery?.lat ? dataQuery?.lat : 48.137154,
          long: dataQuery?.long ? dataQuery?.long : 11.576124,
        },
        from: moment(dataQuery.from, "DD.MM.YYYY"),
        to: moment(dataQuery.to, "DD.MM.YYYY"),
        tags: [],
        benefits: [],
        priceRange: [dataQuery.min || 1, dataQuery.max || 250],
        availability: false,
        types: { office: true, desk: true, meetingroom: true, misc: true },
      },
    });
    console.log(listWorkplaces)
    //Create Map
    if (listWorkplaces?.data?.results?.length > 0) {
      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/xsinister/ckt6eauig2x7h17o55mghtd22",
        center: [
          dataQuery?.long ? dataQuery?.long : 11.576124,
          dataQuery?.lat ? dataQuery?.lat : 48.137154,
        ],
        zoom: 11,
        antialias: true,
        bearing: -17.6,
        pitch: 45,
      });
      listWorkplaces?.data?.results?.map((result, i) => {
        let image = null;
        if (result.workplaces[0].imageList.length > 0) {
          image = `${config.api.host}${result.workplaces[0].imageList[0].data}`;
        }
        const el = document.createElement("div");
        el.className = "priceMarker";
        el.innerHTML = `<div class="${
          result.workplaces[0].type
        }">${ReactDOMServer.renderToString(
          <SVGIcon
            type={result.workplaces[0].type}
            height={16}
            width={16}
            color={"white"}
          />
        )}</div><div>${parseInt(result.workplaces[0].price).toFixed(
          0
        )} <span>€</span></div>`;

        result.marker = new mapboxgl.Marker(el).setPopup(
          new mapboxgl.Popup().setHTML(`
        <div class="officePopup">
            <div class="popupImage" style="background: url('${image}')"></div>
            <div class="popupContent">
                <p style="margin-bottom: 0">${result.workplaces[0].title}</p>
                ${
                  result.workplaces[0].type === "office"
                    ? '<span class="workplaceType" style="color: #FDA84D"> Büro</span>'
                    : result.workplaces[0].type === "desk"
                    ? '<span class="workplaceType" style="color: #FD6A56"> Schreibtisch</span>'
                    : result.workplaces[0].type === "meetingroom"
                    ? '<span class="workplaceType" style="color: #E52270"> Meetingraum</span>'
                    : '<span class="workplaceType" style="color: #523F7B"> Workplace</span>'
                }
                <div class="popupFooter">
                    <div style="display: flex; align-items: center">
                        ${ReactDOMServer.renderToString(
                          <SVGIcon
                            type={"star"}
                            height={18}
                            width={18}
                            color={"#523F7B"}
                          />
                        )}
                        <span style="margin-left: 5px; font-weight: 900">4.8</span>
                    </div>                                   
                </div>
            </div>
        </div>
        
    `)
        );
        result.marker
          .getElement()
          .addEventListener("mouseenter", () => result.marker.togglePopup());
        result.marker
          .getElement()
          .addEventListener("mouseleave", () => result.marker.togglePopup());
        result.marker
          .getElement()
          .addEventListener("click", () =>
            this.props.history.push(`/workspace/${result.workplaces[0].id}`)
          );
      });
      let allCoordinates = [];
      listWorkplaces?.data?.results.map((result) => {
        result.marker.setLngLat([result.longitude, result.latitude]);
        result.marker.addTo(map);
        allCoordinates.push([result.longitude, result.latitude]);
      });
      let ne = [
        Math.max.apply(
          Math,
          allCoordinates.map(function (o) {
            return o[0];
          })
        ),
        Math.max.apply(
          Math,
          allCoordinates.map(function (o) {
            return o[1];
          })
        ),
      ];
      let sw = [
        Math.min.apply(
          Math,
          allCoordinates.map(function (o) {
            return o[0];
          })
        ),
        Math.min.apply(
          Math,
          allCoordinates.map(function (o) {
            return o[1];
          })
        ),
      ];

      locmarker = new mapboxgl.Marker({
        draggable: false,
        color: "#ec415d",
      });
      locmarker.setLngLat([
        dataQuery?.long ? dataQuery?.long : 11.576124,
        dataQuery?.lat ? dataQuery?.lat : 48.137154,
      ]);
      map.fitBounds([ne, sw], {
        padding: { top: 60, bottom: 60, left: 60, right: 60 },
      });
    }
  }, [listWorkplaces]);

  if (error) {
    return <ErrorScreen />;
  }

  if (!listWorkplaces) {
    return <LoadingScreen />;
  }

  return (
    <Layout disabledFooter>
      <Page title="Offices">
        <RootStyle>
          <Container maxWidth="xl">
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <TravelTourBarFilters />
                <TravelTourList
                  workplaces={listWorkplaces?.data?.results}
                  total={listWorkplaces?.data?.total}
                  loading={!listWorkplaces?.data?.results && !error}
                  handleChangePage={handleChangePage}
                  page={page}
                />
                <Footer />
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="searchresults__map">{mapElement}</div>
              </Grid>
            </Grid>
          </Container>
        </RootStyle>
      </Page>
    </Layout>
  );
};
