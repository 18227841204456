import { Accordion, AccordionDetails, Button, Grid, Typography, Tabs, Tab } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Iconify } from "../../components";
import { useTheme } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Home from "./../../assets/images/Home.svg";
import CircleAndSquare from "./../../assets/images/CircleAndSquare.svg";
import Lightning2 from "./../../assets/images/Lightning2.svg";
import Pen from "./../../assets/images/Pen.svg";

export default function FaqContact() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("Allgemein");
  const [index, setIndex] = useState(0);
  const categories = [
    "Allgemein",
    "Compliance",
  ];

  const [expanded, setExpanded] = useState(-1);
  const theme = useTheme();

  const [items, setItems] = useState([
    {
      title: `${t('FAQ_Local_Advantages_Headline')}`,
      /* title: "Vorteile, wenn due deine Workspace bei twimyo anbieten", */
      subtitle: `${t('FAQ_Local_Advantages')}

      ${t('FAQ_Local_Advantages2')}`,
    },
    {
      title: `${t('FAQ_Local_Service_Headline')}`,
      /* title: "Was beinhaltet unser Service?", */
      subtitle: `${t('FAQ_Local_Service')}`,
    },
    {
      title: `${t('FAQ_Local_Cost_Headline')}`,
      /* title: "Wieviel kostet es?", */
      subtitle: `${t('FAQ_Local_Cost')}`,
    },
    {
      title: `${t('FAQ_Local_Workspace_Headline')}`,
      /* title: "Was ist ein Workplace?", */
      subtitle: `${t('FAQ_Local_Workspace')}

      ${t('FAQ_Local_Workspace2')}`,
    },
    {
      title: `${t('FAQ_Local_Local_Headline')}`,
      /* title: "Was ist ein Local?", */
      subtitle: `${t('FAQ_Local_Local')}`,
    },
    {
      title: `${t('FAQ_Local_Traveler_Headline')}`,
      /* title: "Wieviel kostet es?", */
      subtitle: `${t('FAQ_Local_Traveler')}`,
    },
  ])

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
    if (newValue === "Allgemein") {
      setItems([
        {
          title: `${t('FAQ_Local_Advantages_Headline')}`,
          /* title: "Vorteile, wenn due deine Workspace bei twimyo anbieten", */
          subtitle: `${t('FAQ_Local_Advantages')}
    
          ${t('FAQ_Local_Advantages2')}`,
        },
        {
          title: `${t('FAQ_Local_Service_Headline')}`,
          /* title: "Was beinhaltet unser Service?", */
          subtitle: `${t('FAQ_Local_Service')}`,
        },
        {
          title: `${t('FAQ_Local_Cost_Headline')}`,
          /* title: "Wieviel kostet es?", */
          subtitle: `${t('FAQ_Local_Cost')}`,
        },
        {
          title: `${t('FAQ_Local_Workspace_Headline')}`,
          /* title: "Was ist ein Workplace?", */
          subtitle: `${t('FAQ_Local_Workspace')}
    
          ${t('FAQ_Local_Workspace2')}`,
        },
        {
          title: `${t('FAQ_Local_Local_Headline')}`,
          /* title: "Was ist ein Local?", */
          subtitle: `${t('FAQ_Local_Local')}`,
        },
        {
          title: `${t('FAQ_Local_Traveler_Headline')}`,
          /* title: "Wieviel kostet es?", */
          subtitle: `${t('FAQ_Local_Traveler')}`,
        },
      ]);
    } else {
      setItems([
        {
          title: `${t('FAQ_Compliance1')}`,
          subtitle: `${t('FAQ_Compliance2')}`,
        },
        {
          title: `${t('FAQ_Compliance3')}`,
          subtitle: `${t('FAQ_Compliance4')}
          
          ${t('FAQ_Compliance4_1')}`,
        },
        {
          title: `${t('FAQ_Compliance5')}`,
          subtitle: `${t('FAQ_Compliance6')}`,
        },
        {
          title: `${t('FAQ_Compliance7')}`,
          subtitle: `${t('FAQ_Compliance8')}`,
        },
        {
          title: `${t('FAQ_Compliance9')}`,
          subtitle: `${t('FAQ_Compliance10')}`,
        },
        {
          title: `${t('FAQ_Compliance11')}`,
          subtitle: `${t('FAQ_Compliance12')}`,
        },
      ])
    }
  };

  return (
    <Container sx={{ my: 20, }}>
      <Box
        sx={{
          pb: 10,
          textAlign: "center",
        }}
      >
        <Typography color="#777E90" sx={{ px: "30%" }}>
          {t('Support_SmallHeadline')}
        </Typography>
        <Typography variant="h1">
          {t('Support_Headline')}
        </Typography>
        <Typography color="#000" sx={{ px: "20%" }}>
          {t('Support_Text1')}
          {t('Support_Text2')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              textAlign: "left",
              px: 5,
              pt: 3,
              bgcolor: "#fff",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={selected}
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
              onChange={handleChangeCategory}
            >
              {categories.map((category, indexTab) => (
                <Tab
                  sx={{
                    backgroundColor:
                      index === indexTab ? "#353945 !important" : "transparent",
                    color:
                      index === indexTab
                        ? "#FCFCFD !important"
                        : "#777E91 !important",
                    borderRadius: index === indexTab ? "15px" : "0px",
                    marginRight: "25px !important",
                    paddingX: index === indexTab ? "20px !important" : "0px !important",
                  }}
                  onClick={() => setIndex(indexTab)}
                  key={category}
                  value={category}
                  label={category}
                />
                /*               <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: 2,
                                  mb: 4
                                }}
                              >
                                <img src={Home} alt="house" />
                                <Typography sx={{ color: "#000" }}>
                                  Allgemein
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: 2,
                                  mb: 4
                                }}
                              >
                                <img src={CircleAndSquare} alt="house" />
                                <Typography sx={{ color: "#000" }}>
                                  Unterstützung
                                </Typography>
                              </Box> */
              ))}

            </Tabs>
          </Box>
        </Grid>

        <Grid item xs={12} md={9}>
          <Box
            sx={{
              textAlign: "center",
              bgcolor: "#fff",
            }}
          >
            <Box>
              <Box marginBottom={6}>
                <Box>
                  <Box>
                    {items.map((item, i) => (
                      <>
                        <Box
                          component={Accordion}
                          key={i}
                          padding={1}
                          borderBottom={"1px solid #E6E8EC"}
                          borderRadius={`${theme.spacing(1)} !important`}
                          sx={{
                            '&::before': {
                              display: 'none',
                            },
                          }}
                          onClick={() => { setExpanded(i) }}
                        >
                          <Box
                            component={AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header--${i}`}
                          >
                            <Typography fontWeight={600}>{item.title}</Typography>
                          </Box>

                          <AccordionDetails>
                            <Typography align={"justify"} sx={{ whiteSpace: 'pre-line' }} color='#777E91'>{item.subtitle}</Typography>
                            <Button
                              variant="contained"
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                backgroundColor: '#fff',
                                color: '#000',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '30px 0',
                                border: '1px solid #E6E8EC',
                                '&:hover': {
                                  backgroundColor: '#fff',
                                  color: '#000',
                                  border: '1px solid #E6E8EC',
                                },
                              }}
                            >
                              Mehr lesen
                            </Button>
                          </AccordionDetails>
                        </Box>
                      </>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}