import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Container from "../../../../pages/companyInformation/components/Container";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../../stores/global";
import { /* Chip, */ Divider } from "@mui/material";
/* import { margin } from "@mui/system";
import SVGIcon from "../../../../components/icons.element"; */
import { useRequest } from "../../../../hooks";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import config from "../../../../config";
import UserCardWithBackgroundLogged from "../../../publicProfile/UserCardWithBackgroundLogged";

const Page = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user, dispatch } = useGlobalContext();
  const pages = [
    {
      id: "general",
      href: "/profile-general",
      title: `${t('SideMenu1')}`,
    },
    {
      id: "pictures",
      href: "/profile-pictures",
      title: `${t('MyPictures')}`,
    },
    {
      id: "security",
      href: "/profile-security",
      title: `${t('SideMenu2')}`,
    },
    {
      id: "billing",
      href: "/profile-billing",
      title: `${t('SideMenu3')}`,
    },
  ];

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);


  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPngOrSvg) {
      alert('Es können nur JPG/PNG/SVG-Dateien hochgeladen werden');
    }
    const isLt2M = file.size < 5000000;
    if (!isLt2M) {
      alert('Das Bild muss kleiner als 2MB sein!');
    }
    return isJpgOrPngOrSvg && isLt2M;
  }

  const showInput = () => {
    document.getElementById("img").click();
  }

  const handleChange = e => {
    if (e.target.files.length === 0) {
      return;
    }

    if (beforeUpload(e.target.files[0])) {
      getBase64(e.target.files[0], img => {
        axios.defaults.headers.common = {
          Authorization: user.token,
        };
        axios
      .put(`${config.api.host}/users`,
        {profilepicture: img},
      )
      .then(async (res) => {
        axios.get(`${config.api.host}/users/${user.id}`)
          .then(async res => {
            let userdata = res.data.data.user
            dispatch({
              type: 'SET_USER',
              payload: {
                token: user.token,
                ...userdata,
              },
            });
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 401) {
              localStorage.removeItem('token');
              window.location.reload(false);
            }
          })
      })
      .catch((error) => {
        alert("Änderungen konnten nicht gespeichert werden");
      });
      });
    }
  }


  return (
    <Box>
      <Box marginTop={4} paddingBottom={4}>
        <Container sx={{ paddingTop: '0px!important' }}>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: "common.white" }}
          >
            {t('AccountSettings')}
            {/* Account settings */}
          </Typography>
          <Typography variant="h6" sx={{ color: "common.white" }}>
            {t('AccountSettings_Subline')}
            {/* Change account information and settings */}
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={"0 !important"} marginTop={-12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card sx={{ boxShadow: 10, marginBottom:10 }}>
              <List
                disablePadding
                sx={{
                  display: { xs: "inline-flex", md: "flex" },
                  flexDirection: { xs: "row", md: "column" },
                  overflow: "auto",
                  flexWrap: "nowrap",
                  width: "100%",
                  paddingY: { xs: 3, md: 4 },
                  paddingX: { xs: 4, md: 0 },
                }}
              >
                {pages.map((item) => (
                  <ListItem
                    key={item.id}
                    component={Link}
                    to={item.href}
                    disableGutters
                    sx={{
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 3 },
                      borderLeft: {
                        xs: "none",
                        md: "2px solid transparent",
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : "transparent",
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color={
                        activeLink === item.href
                          ? "text.primary"
                          : '#777E91'
                      }
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Card>
            <UserCardWithBackgroundLogged showInput={showInput} handleChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ boxShadow: 10, padding: 4 }}>{children}</Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
