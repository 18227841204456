import React, { useEffect, useState } from "react";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// Yup
import * as Yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import locationIcon from '@iconify/icons-carbon/location';
import { createFilterOptions } from '@mui/material/Autocomplete';
import DatePicker from '@mui/lab/DatePicker';

/* import TextareaAutosize from '@mui/material/TextareaAutosize'; */
import { LoadingButton } from '@mui/lab';

import Page from "../components/Page";
import Layout from "../../../layouts";
import { useGlobalContext } from "../../../stores/global";
import moment from "moment";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, MenuItem, Select } from "@mui/material";
import SVGIcon from "../../../components/icons.element";
import { useRequest } from "../../../hooks";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import config from "../../../config";
import SuccessMsg from "../../../components/success/SuccessMsg";
import ErrorMsg from "../../../components/errors/ErrorMsg";
import { tag_user } from "../../../mocks/tag_user";
import { Image, Iconify, SearchNotFound } from "../../../components";
const filter = createFilterOptions();

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />; */

const languages = [
  'Deutsch',
  'English',
  'Español',
  'Italiano',
  'Français',
  "Russkiy",
  'Português',
  'Mandarin',
  'Hindi'
];

const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

const jobCategories = [
  'Design',
  'Marketing',
  'Management',
  'Healthcare',
  'Development',
  'Engineering',
  'HR & Recruiting',
  'Garage services',
  'Sport and leisure',
  'Computing and ICT',
  'Financial services',
  'Design, arts and crafts',
  'Construction and building',
  'Social sciences and religion',
  'Science, mathematics and statistics',
  'Transport, distribution and logistics',
];

const FormSchema = Yup.object().shape({
  first_name: Yup
    .string()
    .trim()
    .required("Please specify your first name")
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name"),
});

const General = () => {

  const { user, dispatch } = useGlobalContext();

  const [tags, setTags] = useState([])

  const [categories, setCategories] = useState([])
  const [categoriesKey, setCategoriesKey] = useState([])
  const [titles, setTitles] = useState([])

  useEffect(() => {
    tag_user.map((item) => {
      item._t_category = t(item.category);
      item.name = t(item.name);
      item.title = t(item.title);
    });

    let tags = tag_user.filter((item) => item.category);

    let categoriesKey = tags.map((item, index) => {
      if ((user.tag_user || "").includes(item.id)) {
        tags[index].mainActive = true;
      }
      return item.category;
    });

    let categories = tags.map((item, index) => {
      if ((user.tag_user || "").includes(item.id)) {
        tags[index].mainActive = true;
      }
      return item._t_category;
    });

    let titles = tags.map((item, index) => {
      return item.title;
    });

    setTags(tags);
    categories = categories.filter(
      (item, index) => categories.indexOf(item) === index
    );
    titles = titles.filter(
      (item, index) => titles.indexOf(item) === index
    );
    categoriesKey = categoriesKey.filter(
      (item, index) => categoriesKey.indexOf(item) === index
    );
    console.log("CATEGORIES KEY", categoriesKey);
    setCategories(categories);
    setTitles(titles);
    setCategoriesKey(categoriesKey);
  }, [])

  const { t } = useTranslation();

  const [location, setLocation] = useState(user?.country ? { label: user.country } : { label: "Germany" });
  const handleChangeLocation = (keyword) => {
    setLocation(keyword);
  };

  const [branche, setBranche] = useState(user.branche || "");
  const handleChangeBranche = (keyword) => {
    setBranche(keyword);
  };

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      description: user.description,
      birthday: moment(user.birthday).format("YYYY-MM-DD"),
      gender: user.gender,
      branche: user.branche,
      job: user.job,
      company: user.company,
      phone_mobile: user.phone_mobile,
      phone_business: user.phone_business,
      street: user.street,
      postcode: user.postcode,
      city: user.city,
      country: user.country || "Germany",
      social_linkedin: user.social_linkedin,
      social_github: user.social_github,
      social_twitter: user.social_twitter,
      social_instagram: user.social_instagram,
      social_facebook: user.social_facebook,
      social_tiktok: user.social_tiktok,
    },
  });

  const setChip = (index) => {
    let mainActive = tags.filter((tag) => tag.mainActive);
    let newArr = [...tags]; // copying the old datas array
    if (!newArr[index].mainActive) {
      newArr[index].mainActive = true; // replace e.target.value with whatever you want to change it to
      setTags(newArr);
    } else {
      if (newArr[index].mainActive) {
        newArr[index].mainActive = false; // replace e.target.value with whatever you want to change it to
        setTags(newArr);
      } else {
        setOpen(true);
        setMsg("Sie können nur fünfzehn auswählen");
      }
    }
  };

  const [languagesUser, setLanguagesUser] = useState(user.languages ? user.languages.split(",") : [])
  function setValue(value) {
    console.log(value);
    setLanguagesUser(value)
  }

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const onSubmit = (data) => {
    let body = {}
    Object.keys(data).map(function (key, index) {
      if (data[key] !== undefined) {
        body[key] = data[key]
      }
    });
    body.country = location?.label || "Germany";
    body.branche = branche;
    if (languagesUser.length > 0) {
      body["languages"] = languagesUser.toString()
    }

    let active = tags.filter((tag) => tag.mainActive);
    if (active.length !== 0) {
      body["tag_user"] = tags
        .filter((tag) => tag.mainActive)
        .map((tag) => tag.id)
        .toString()
    }
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .put(`${config.api.host}/users`,
        body
      )
      .then(async (res) => {
        setOpenSuccess(true);
        setMsgSuccess("Daten erfolgreich aktualisiert");
        axios.get(`${config.api.host}/users/${user.id}`)
          .then(async res => {
            let userdata = res.data.data.user
            dispatch({
              type: 'SET_USER',
              payload: {
                token: user.token,
                ...userdata,
              },
            });
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 401) {
              localStorage.removeItem('token');
              window.location.reload(false);
            }
          })
      })
      .catch((error) => {
        setOpen(true);
        setMsg("Änderungen konnten nicht gespeichert werden");
      });
  };

  const [customTagsUser, setCustomTagsUser] = useState([])

  const getCustomTagsUser = () => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios.get(`${config.api.host}/taglist/new_tag_user/${user.id}`)
      .then(async res => {
        setCustomTagsUser(res.data.tagList)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getCustomTagsUser();
  }, [])

  const updateCustomTags = (id_tag) => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios.put(`${config.api.host}/taglist/new_tag_user/${id_tag}`)
      .then(async res => {
        //search in customTagsUser and update with negate select and update customTagsUser
        let newCustomTagsUser = customTagsUser.map((tag) => {
          if (tag.id === id_tag) {
            tag.selected = !tag.selected
          }
          return tag
        })
        setCustomTagsUser(newCustomTagsUser)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [addNewTagUserModal, setAddNewTagUserModal] = useState(false);

  const handleCloseNewTagUserModal = () => {
    setAddNewTagUserModal(false);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleOpenNewTagUserModal = (category) => {
    setAddNewTagUserModal(true);
    console.log(category);
    setSelectedCategory(category)
  };

  const handleAddNewTagUserModal = (tag) => {
    let body = {
      user_id: user.id,
      tag_name: tag,
      category_name: selectedCategory,
      selected: true
    };
    axios.defaults.headers.common = {
      Authorization: user.token,
    };

    axios
      .post(`${config.api.host}/taglist/new_tag_user/`, body)
      .then(async (res) => {
        //refecth tags
        getCustomTagsUser();
        setAddNewTagUserModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <Layout>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            {t('Headline_Change')}
          </Typography>
          <Typography variant={"subtitle2"} color={'#777E91'}>
            {t('New_Subline_Change_1')}{" "}
            <Link color={"primary"} href={"/company-terms"} underline={"none"}>
              {t('New_Subline_Change_2')}
            </Link>{" "}
            {t('New_Subline_Change_3')}
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant={"title"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Headline_PersonalData')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('FirstName')} *
                </Typography>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('LastName')} *
                </Typography>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      /* label="Last name *" */
                      variant="outlined"
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('E-Mail')} *
                  {/* Email */}
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="E-Mail *" */
                      variant="outlined"
                      value={user.email}
                      disabled
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Password')} *
                  {/* Passwort * */}
                </Typography>
                <Controller
                  name="passwort"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Passwort" */
                      value={"*********"}
                      disabled
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('ShortDescription')}
                  {/* Deine Kurzbeschreibung */}
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Kurz über dich *" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  value={languagesUser}
                  options={languages}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Languages')}
                      /* label="Which language you know?" */
                      placeholder="More?"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Birthday')} *
                </Typography>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      label={t('Birthday')}
                      renderInput={(params) => <TextField
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true, required: true }}
                        {...params}
                        error={Boolean(error)}
                        helperText={error?.message} />}
                      inputFormat="dd.MM.yyyy"
                    />
                  )}
                />



              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Gender')}
                  {/* Geschlecht */}
                </Typography>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      fullWidth
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    >
                      <MenuItem value={"male"}>Männlich</MenuItem>
                      <MenuItem value={"female"}>Weiblich</MenuItem>
                      <MenuItem value={"ternary"}>Divers</MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Industry')}
                  {/* Branche */}
                </Typography>
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  autoHighlight
                  options={jobCategories}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }
                    return filtered;
                  }}
                  value={branche}
                  onChange={(event, newValue) => {
                    //check if include Add and remove it
                    if (typeof newValue === 'string') {
                      if (newValue.includes("Add")) {
                        newValue = newValue.replace('Add "', "");
                        newValue = newValue.replace('"', "");
                      }
                      setBranche(newValue);
                    }
                  }}
                  noOptionsText={<SearchNotFound keyword={branche} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Branche"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'search',
                      }}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);
                    return (
                      <Box component="li" {...props}>
                        {parts.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              ...(part.highlight && {
                                fontWeight: 'fontWeightBold',
                              }),
                            }}
                          >
                            {part.text}
                          </Box>
                        ))}
                      </Box>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Profession')}
                  {/* Beruf */}
                </Typography>
                <Controller
                  name="job"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Beruf *" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Company')}
                  {/* Beruf */}
                </Typography>
                <Controller
                  name="company"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Beruf *" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>

                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Deine Tags
                </Typography>
                <Box
                  sx={{
                    display: "block",
                    margin: "30px",
                    height: { md: "400px", xs: "300px" },
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {categories.map((category, index) => {
                    return (
                      <div key={index}>
                        <span style={{ fontSize: "1.2rem", color: "#000" }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 2,
                              marginBottom: 2,
                            }}
                          >
                            {titles[index]}
                          </Box>
                          <div
                            style={{
                              margin: "10px",
                              backgroundColor: "white",
                              padding: "15px",
                            }}
                          >
                            {tags.map((tag, index2) => {
                              if (tag._t_category === category)
                                return (
                                  <Chip
                                    onClick={() => setChip(index2)}
                                    sx={{
                                      color: "common.white",
                                      opacity: tag.mainActive ? "1" : "0.4",
                                      backgroundColor:
                                        tag._t_category === t("Category_FoodAndDrinks") ?
                                          "#EAAA08" :
                                          tag._t_category === t("Category_Recreation") ?
                                            "#669F2A" :
                                            tag._t_category === t("Category_Sports") ?
                                              "#06AED4" :
                                              "#4E5BA6",
                                      '&:hover': { backgroundColor: 'red', },
                                      color: "#FCFCFD",
                                      borderRadius: "360px",
                                      padding: "8px 8px",
                                      fontWeight: '600',
                                      fontSize: '0.875rem',
                                    }}
                                    key={tag.id}
                                    label={tag.name}
                                  />
                                );
                            })}
                            {customTagsUser.map((tag, index2) => {
                              console.log("tag.category_name", tag);
                              console.log("category", categoriesKey[index]);
                              if (tag.category_name === categoriesKey[index])
                                return (
                                  <Chip
                                    onClick={() => updateCustomTags(tag.id)}
                                    sx={{
                                      color: "common.white",
                                      opacity: tag.selected ? "1" : "0.4",
                                      backgroundColor:
                                        tag.category_name === "Category_FoodAndDrinks" ?
                                          "#EAAA08" :
                                          tag.category_name === "Category_Recreation" ?
                                            "#669F2A" :
                                            tag.category_name === "Category_Sports" ?
                                              "#06AED4" :
                                              "#4E5BA6",
                                      '&:hover': { backgroundColor: 'red', },
                                      color: "#FCFCFD",
                                      borderRadius: "360px",
                                      padding: "8px 8px",
                                      fontWeight: '600',
                                      fontSize: '0.875rem',
                                    }}
                                    key={tag.id}
                                    label={tag.tag_name}
                                  />
                                );
                            })}
                            <Chip
                              onClick={() => handleOpenNewTagUserModal(categoriesKey[index])}
                              sx={{
                                color: "common.white",
                                backgroundColor: "#D0D5DD",
                                border: "1px solid #D0D5DD",
                                '&:hover': { backgroundColor: 'red', },
                                color: "white",
                                borderRadius: "360px",
                                padding: "8px 8px",
                                fontWeight: '600',
                                fontSize: '0.875rem',
                              }}
                              label={t('Add_NewTag')}
                            />
                          </div>
                        </span>
                      </div>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant={"title"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Contact')}
                  {/* Kontakt */}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Phone1')}
                  {/* Telefon */}
                </Typography>
                <Controller
                  name="phone_mobile"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Telefon" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Phone2')}
                  {/* Telefon 2 */}
                </Typography>
                <Controller
                  name="phone_business"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Telefon 2" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Street')}
                  {/* Straße */}
                </Typography>
                <Controller
                  name="street"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Adresse" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('PostalCode')}
                  {/* PLZ */}
                </Typography>
                <Controller
                  name="postcode"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="PLZ" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('City')}
                  {/* Stadt */}
                </Typography>
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Stadt" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('Country')}
                  {/* Land */}
                </Typography>

                <Autocomplete
                  autoHighlight
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  filterOptions={createFilterOptions({
                    stringify: (option) => option.label + option.code,
                  })}
                  value={location}
                  onChange={(event, value) => handleChangeLocation(value)}
                  noOptionsText={<SearchNotFound keyword={location?.label} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder=""
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'search',
                      }}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);
                    return (
                      <Box component="li" {...props}>
                        <Image
                          alt="flag country"
                          src={`https://flagcdn.com/${option.code.toLowerCase()}.svg`}
                          sx={{
                            mr: 1,
                            width: 24,
                            height: 24,
                            flexShrink: 0,
                            borderRadius: '50%',
                          }}
                        />

                        {parts.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              ...(part.highlight && {
                                fontWeight: 'fontWeightBold',
                              }),
                            }}
                          >
                            {part.text}
                          </Box>
                        ))}
                      </Box>
                    );
                  }}
                />


              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant={"title"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('New_Onboarding_Step5_Headline')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  LinkedIn
                </Typography>
                <Controller
                  name="social_linkedin"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="LinkedIn" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Github
                </Typography>
                <Controller
                  name="social_github"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Github" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Instagram
                </Typography>
                <Controller
                  name="social_instagram"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Instagram" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Twitter
                </Typography>
                <Controller
                  name="social_twitter"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Twitter" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  TikTok
                </Typography>
                <Controller
                  name="social_tiktok"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="TikTok" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Facebook
                </Typography>
                <Controller
                  name="social_facebook"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      /* label="Facebook" */
                      variant="outlined"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      {t('New_BillingInformation_1')}{" "}
                      <Link
                        color={"primary"}
                        href={"/account-billing"}
                        underline={"none"}
                      >
                        {t('New_BillingInformation_2')}
                      </Link>
                    </Typography>
                  </Box>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {t('SaveButton')}
                    {/* Save */}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
        <ErrorMsg open={open} msg={msg} bottom={true} handleClose={handleClose} />
        <SuccessMsg
          open={openSuccess}
          msg={msgSuccess}
          bottom={true}
          handleClose={handleCloseSuccess}
        />
        <ModalAddNewUserTag open={addNewTagUserModal} handleClose={handleCloseNewTagUserModal} handleAddNewTag={handleAddNewTagUserModal} />
      </Page>
    </Layout>
  );
};


function ModalAddNewUserTag(props) {
  const { open, handleClose, handleAddNewTag } = props;
  const [tag, setTag] = useState("");
  const [tagError, setTagError] = useState(false);
  const [tagErrorMessage, setTagErrorMessage] = useState("");

  const handleAddTag = () => {
    if (tag === "") {
      setTagError(true);
      setTagErrorMessage("Please enter a tag");
    } else {
      setTagError(false);
      setTagErrorMessage("");
      handleAddNewTag(tag);
      setTag("");
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          marginBottom: 2,
        }}
        id="alert-dialog-title">{"Add New Tag"}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-basic"
          label="Tag"
          variant="outlined"
          fullWidth
          error={tagError}
          helperText={tagErrorMessage}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddTag} color="primary" autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default General;
