import { NumericFormat } from 'react-number-format';
import styled from "@emotion/styled";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ErrorScreen,
  Iconify,
  Image,
  LoadingScreen,
  Page,
  TextMaxLine,
} from "../../../components";
import config from "../../../config";
import { useRequest } from "../../../hooks";
import Layout from "../../../layouts";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NumberFormat from "react-number-format";
import SVGIcon from "../../../components/icons.element";
import { TimePicker } from "@mui/lab";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ImageUploading from "react-images-uploading";
import ErrorMsg from "../../../components/errors/ErrorMsg";
import SuccessMsg from "../../../components/success/SuccessMsg";
import { useGlobalContext } from "../../../stores/global";
import { tag_list } from "../../../mocks/tag_list";
import { useTranslation } from "react-i18next";
import UploadFile from "../../../assets/images/uploadFile.svg";
import { fCurrency } from "../../../utils/formatNumber";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

mapboxgl.accessToken = config.mapbox.access_token;

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      style={{ fontSize: 16 }}
      thousandSeparator
      isNumericString
      prefix="€"
    />
  );
});

export default function UpdateWorkspace() {
  const { t } = useTranslation();

  let params = useParams();
  const { user } = useGlobalContext();

  //Fetch information workplace detail
  const { data: workplace, error: workplaceError } = useRequest({
    url: params.id ? `/office/workplace/${params.id}` : "",
  });

  //1
  const [type, setType] = useState("desk");
  //2
  const [latitude, setLatitude] = useState(48.137108);
  const [longitude, setLongitude] = useState(11.575382);
  const [address, setAddress] = useState(null);
  const [controlGeodecoder, setControlGeodecoder] = useState(true);
  //refs
  const geocoder = useRef();
  const mapContainer = useRef();
  //map
  var popup = new mapboxgl.Popup();
  const [map, setMap] = useState(null);
  var workspaceMarker = null;
  const [mapElement, setMapElement] = useState(<div ref={mapContainer} />);
  var popupCheckPin = `<div className={"officePopup"}>
      <div className={"popupContent"}>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            textAlign: "center",
            color: "#51555E",
            marginBottom: 0,
          }}
        >
          Passt dieser Pin? Du kannst ihn auch manuell einfach verschieben,
          damit man z.b. den Eingang besser findet.
        </p>
      </div>
    </div>`;

  useEffect(() => {
    if (workplace && controlGeodecoder) {
      //setMarker
      const el = document.createElement("div");
      el.className = "officeMarker";
      workspaceMarker = new mapboxgl.Marker(el, { draggable: true });

      workspaceMarker.on("dragend", (e) => {
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target._lngLat.lng},${e.target._lngLat.lat}.json?access_token=${config.mapbox.access_token}&language=de`
          )
          .then((res) => {
            setLatitude(e.target._lngLat.lat);
            setLongitude(e.target._lngLat.lng);
            setAddress(res.data.features[0].place_name);
            document.querySelector(".geocoder input").value =
              res.data.features[0].place_name;
            popupCheckPin = `
          <div className="officePopup">
            <div className={"popupContent"}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#51555E",
                  marginBottom: 0,
                }}
              >
                ${res.data.features[0].place_name}
              </p>
            </div>
          </div>
        `;
            workspaceMarker.setPopup(
              new mapboxgl.Popup().setHTML(popupCheckPin)
            );
            workspaceMarker.togglePopup();
          });
      });
      workspaceMarker.setLngLat([
        workplace.data.office.longitude,
        workplace.data.office.latitude,
      ]);
      //setMap
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/xsinister/ckt6eauig2x7h17o55mghtd22",
        center: [
          workplace.data.office.longitude,
          workplace.data.office.latitude,
        ],
        zoom: 13,
        antialias: true,
        bearing: -17.6,
        pitch: 45,
      });
      setMap(map);

      const mapboxGeocoder = new MapboxGeocoder({
        accessToken: config.mapbox.access_token,
        mapboxgl: mapboxgl,
        marker: false,
      });
      mapboxGeocoder.on("result", (result) => {
        setLatitude(result.result.center[1]);
        setLongitude(result.result.center[0]);
        setAddress(result.result.place_name);
        document.querySelector(".geocoder input").value =
          result.result.place_name;
        workspaceMarker.setLngLat([
          result.result.center[0],
          result.result.center[1],
        ]);
        workspaceMarker.setPopup(new mapboxgl.Popup().setHTML(popupCheckPin));
        workspaceMarker.togglePopup();
      });
      mapboxGeocoder.setLanguage("de");
      mapboxGeocoder.setTypes("place,poi,address");
      geocoder.current.appendChild(mapboxGeocoder.onAdd(map));
      document.querySelector(".geocoder input").value =
        workplace.data.office.address;

      workspaceMarker.addTo(map);
      if (workplace.data.office.address) setControlGeodecoder(false);
    }
  }, [workplace, controlGeodecoder]);

  //3
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState("12");
  const [description, setDescription] = useState("12");
  const [amount, setAmount] = useState("1");
  const [extraPercent, setExtraPercent] = useState(25);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleChangePercent = (event) => {
    setExtraPercent(event.target.value);
  };

  const [categoriesWorkplace, setCategoriesWorkplace] = useState([]);
  const [selectedCategoriesWorkplace, setSelectedCategoriesWorkplace] =
    useState([]);
  const getCategoriesWorkplace = () => {
    axios
      .get(`${config.api.host}/office/workplace_categories`)
      .then(function (response) {
        console.log(response.data.data);
        setCategoriesWorkplace(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setValueCategoriesWorkplace = (value) => {
    console.log(value);
    let arr = value.map((item) => item.id);
    console.log(arr);
    setSelectedCategoriesWorkplace(arr);
  };

  useEffect(() => {
    getCategoriesWorkplace();
  }, []);

  //4
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const setChip = (index) => {
    let mainActive = tags.filter((tag) => tag.mainActive);
    let newArr = [...tags]; // copying the old datas array
    if (!newArr[index].mainActive) {
      newArr[index].mainActive = true; // replace e.target.value with whatever you want to change it to
      setTags(newArr);
    } else {
      if (newArr[index].mainActive) {
        newArr[index].mainActive = false; // replace e.target.value with whatever you want to change it to
        setTags(newArr);
      } else {
        setOpen(true);
        setMsg("Sie können nur fünfzehn auswählen");
      }
    }
  };

  //5
  const [openings, setOpenings] = useState([
    {
      type: "MO",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "DI",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "MI",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "DO",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "FR",
      open: true,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "SA",
      open: false,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
    {
      type: "SO",
      open: false,
      from: "08-00",
      to: "17-00",
      dateValueFrom: new Date("2020-01-01 09:00"),
      dateValueTo: new Date("2020-01-01 18:00"),
    },
  ]);

  const changeOpeningsFrom = (index, value) => {
    let newArr = [...openings];
    newArr[index].from = moment(value).format("HH:mm").replace(":", "-");
    newArr[index].dateValueFrom = value;
    //if index is equal to 0 change all arra from to value
    if (index === 0) {
      newArr.map((item, index) => {
        if (index !== 0) {
          item.from = moment(value).format("HH:mm").replace(":", "-");
          item.dateValueFrom = value;
        }
      });
    }
    setOpenings(newArr);
  };

  const changeOpeningsTo = (index, value) => {
    let newArr = [...openings];
    newArr[index].to = moment(value).format("HH:mm").replace(":", "-");
    newArr[index].dateValueTo = value;
    //if index is equal to 0 change all arra from to value
    if (index === 0) {
      newArr.map((item, index) => {
        if (index !== 0) {
          item.to = moment(value).format("HH:mm").replace(":", "-");
          item.dateValueTo = value;
        }
      });
    }
    setOpenings(newArr);
  };

  const setOpenOpenings = (index, value) => {
    let newArr = [...openings];
    newArr[index].open = value;
    setOpenings(newArr);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  //6
  const [images, setImages] = useState([]);
  const [imageList, setImagesList] = useState([]);
  const maxNumber = 5;

  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    let json = [];
    await Promise.all(
      imageList.map((item) => {
        json.push({
          id: uuidv4(),
          data: item.data_url,
        });
      })
    );
    console.log(imageList);
    setImagesList(json);
    setImages(imageList);
  };

  const deleteAll = () => {
    setImagesList([]);
  };

  const deleteOne = (index) => {
    let newarr = [...imageList];
    newarr.splice(index, 1);
    setImagesList(newarr);
  };

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  //put default values
  const [onlyOneTime, setOnlyOneTime] = useState(true);
  useEffect(() => {
    if (workplace && onlyOneTime) {
      setOnlyOneTime(false);
      let data = workplace.data;
      console.log(data);
      setType(data.type);
      setLatitude(data.office.latitude);
      setLongitude(data.office.longitude);
      setAddress(data.office.address);
      setTitle(data.title);
      setPrice(data.price);
      setAmount(data.amount);
      setExtraPercent(data.extra_percent);
      setDescription(data.description);
      setSelectedCategoriesWorkplace(data.categories.map((item) => item.id));

      let tags = tag_list.filter((item) => item.category);

      let categories = tags.map((item, index) => {
        if ((data.tag_list || "").includes(item.id)) {
          tags[index].mainActive = true;
        }
        return item.category;
      });
      setTags(tags);
      categories = categories.filter(
        (item, index) => categories.indexOf(item) === index
      );
      setCategories(categories);

      setOpenings([
        {
          type: "MO",
          open: data.openedMonday,
          from: data.openedMondayFrom,
          to: data.openedMondayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedMondayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedMondayTo.replace("-", ":")
          ),
        },
        {
          type: "DI",
          open: data.openedTuesday,
          from: data.openedTuesdayFrom,
          to: data.openedTuesdayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedTuesdayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedTuesdayTo.replace("-", ":")
          ),
        },
        {
          type: "MI",
          open: data.openedWednesday,
          from: data.openedWednesdayFrom,
          to: data.openedWednesdayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedWednesdayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedWednesdayTo.replace("-", ":")
          ),
        },
        {
          type: "DO",
          open: data.openedThursday,
          from: data.openedThursdayFrom,
          to: data.openedThursdayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedThursdayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedThursdayTo.replace("-", ":")
          ),
        },
        {
          type: "FR",
          open: data.openedFriday,
          from: data.openedFridayFrom,
          to: data.openedFridayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedFridayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedFridayTo.replace("-", ":")
          ),
        },
        {
          type: "SA",
          open: data.openedSaturday,
          from: data.openedSaturdayFrom,
          to: data.openedSaturdayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedSaturdayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedSaturdayTo.replace("-", ":")
          ),
        },
        {
          type: "SO",
          open: data.openedSunday,
          from: data.openedSundayFrom,
          to: data.openedSundayTo,
          dateValueFrom: new Date(
            "2020-01-01 " + data.openedSundayFrom.replace("-", ":")
          ),
          dateValueTo: new Date(
            "2020-01-01 " + data.openedSundayTo.replace("-", ":")
          ),
        },
      ]);
      let imagesApi = [];
      let imageListApi = [];
      let i = 0;
      console.log(workplace.imageList.length);
      while (workplace.imageList.length > i) {
        toDataUrl(config.api.host + workplace.imageList[i].data, (data) => {
          imagesApi.push({
            data_url: data,
          });
          imageListApi.push({
            id: uuidv4(),
            data: data,
          });
          console.log(imagesApi);
          setImages(imagesApi);
          setImagesList(imageListApi);
        });
        i = i + 1;
      }
    }
  }, [workplace, onlyOneTime]);

  //Function to update
  const updateType = (data) => {
    let checkValidation = true;

    if (data.hasOwnProperty("latitude")) {
      if (!(address && latitude && longitude)) {
        setOpen(true);
        setMsg("Adresse erforderlich");
        checkValidation = false;
      }
    }

    if (data.hasOwnProperty("title")) {
      if (title && price && description.length > 0 && amount > 0) {
        //setStep(4);
      } else {
        checkValidation = false;
        setOpen(true);
        let msg = "";
        if (!title) {
          msg += "| Titel ist erforderlich |";
        }
        if (!price) {
          msg += "| Preis ist erforderlich |";
        }
        if (description.length === 0) {
          msg += "| Die Beschreibung ist erforderlich |";
        }
        if (amount <= 0) {
          msg += "| Die Anzahl ist erforderlich |";
        }
        setMsg(msg);
      }
    }

    if (data.hasOwnProperty("tag_list")) {
      let active = tags.filter((tag) => tag.mainActive);
      if (active.length === 0) {
        checkValidation = false;
        setOpen(true);
        setMsg("Tags sind erforderlich");
      }
    }

    if (data.hasOwnProperty("openings")) {
      let open = openings.filter((opens) => opens.open);
      if (open.length === 0) {
        checkValidation = false;
        setOpen(true);
        setMsg("Openings sind erforderlich");
      }
    }

    if (data.hasOwnProperty("imageList")) {
      let image = images.filter((image) => image.data_url);
      if (image.length === 0) {
        checkValidation = false;
        setOpen(true);
        setMsg("Ein Bild ist erforderlich");
      }
    }

    if (checkValidation) {
      axios.defaults.headers.common = {
        Authorization: user.token,
      };
      console.log({
        ...data,
        id: workplace.id,
      });
      axios
        .put(`${config.api.host}/host/office/workplace`, {
          ...data,
          id: workplace.data.id,
        })
        .then(async (res) => {
          setOpenSuccess(true);
          setMsgSuccess("Änderungen erfolgreich gespeichert");
        })
        .catch((error) => {
          setOpen(true);
          setMsg("Änderungen konnten nicht gespeichert werden");
        });
    }
  };

  function previewWorkplace() {
    return (
      <Card>
        {imageList.length > 0 && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                pt: 1.5,
                pl: 2,
                pr: 1.5,
                top: 0,
                width: 1,
                zIndex: 9,
                position: "absolute",
              }}
            >
              <Stack
                spacing={0.5}
                direction="row"
                sx={{
                  px: 1,
                  borderRadius: 0.75,
                  typography: "subtitle2",
                  bgcolor: "#FD5854",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                }}
              >
                €{fCurrency(price)}
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "common.white" }}
                >
                  / {t("Day")}
                </Typography>
              </Stack>
            </Stack>

            <Image alt={title} src={imageList[0]["data"]} ratio="4/3" />
          </>
        )}

        <Box
          sx={{
            p: 2.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <TextMaxLine variant="h6">{title || "No title"}</TextMaxLine>
            <Typography
              variant="body2"
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              {user.first_name}{" "}
            </Typography>
          </Box>
        </Box>

        <Divider />

        {address && (
          <Box
            sx={{
              p: 2.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Tooltip
                title={address.split(",")[1] + ", " + address.split(",")[2]}
              >
                <Typography
                  variant="body3"
                  sx={{ color: "#777E91" }}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {address.split(",")[1] + ", " + address.split(",")[2]}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Card>
    );
  }

  if (workplaceError) {
    return <ErrorScreen />;
  }

  if (!workplace) {
    return <LoadingScreen />;
  }

  return (
    <Layout>
      <Page title="Detail Workspace">
        <RootStyle>
          <Container>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{
                py: { xs: 8, md: 10 },
              }}
            >
              <Box marginBottom={1}>
                <Typography fontWeight={700} variant={"h3"} align={"center"}>
                  {workplace?.data?.title}
                </Typography>
              </Box>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={8}>
                <Box sx={{ marginY: "25px" }}>
                  <Card>
                    <CardContent>
                      <Typography sx={{ fontSize: 36, fontWeight: "bold" }}>
                        Galerie
                      </Typography>
                      <Box sx={{ margin: { md: "1% 30px", xs: 0 } }}>
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <>
                              <Box
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                sx={{
                                  width: "100%",
                                  height: "200px",
                                  backgroundColor: "#F4F5F6",
                                  fontSize: "3rem",
                                  cursor: "pointer",
                                }}
                                onClick={onImageUpload}
                                onDrop={onImageUpload}
                                {...dragProps}
                              >
                                {/*put image and text in the center*/}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    height: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    <img src={UploadFile} alt="upload" />
                                    <Typography
                                      fontSize={14}
                                      sx={{ color: "#777E90" }}
                                    >
                                      PNG, JPG, WEBP. Max 5Mb.
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Grid
                                container
                                sx={{ margin: "30px 50px" }}
                                spacing={2}
                              >
                                {imageList.map((image, index) => (
                                  <Grid item xs={12} md={4} key={index}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        width: "35%",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "20px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          src={image["data_url"]}
                                          alt=""
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <Button
                                          onClick={() => onImageUpdate(index)}
                                          variant="outlined"
                                        >
                                          {t("ButtonUpdate")}
                                        </Button>
                                        <Button
                                          onClick={() => onImageRemove(index)}
                                          variant="outlined"
                                        >
                                          {t("ButtonRemove")}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          )}
                        </ImageUploading>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginY: "10px",
                      }}
                    >
                      <Button
                        onClick={() => updateType({ imageList: imageList })}
                        variant="contained"
                      >
                        Update
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
                <Box sx={{ marginY: "25px" }}>
                  <Card>
                    <CardContent>
                      <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                        {t("EditWorkspace_Type")}
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          margin: "3% 0%",
                          width: "100%",
                          rowGap: { xs: 1, md: 1 },
                          columnGap: 2,
                          gridTemplateColumns: {
                            xs: "repeat(2, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(2, 1fr)",
                          },
                        }}
                      >
                        {/* Create radio buttons with title and radio in the right */}

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("desk")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat1")}
                          </Typography>
                          <Radio
                            checked={type === "desk"}
                            onChange={() => setType("desk")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("meetingroom")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat2")}
                          </Typography>
                          <Radio
                            checked={type === "meetingroom"}
                            onChange={() => setType("meetingroom")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("makerspace")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat3")}
                          </Typography>
                          <Radio
                            checked={type === "makerspace"}
                            onChange={() => setType("makerspace")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("studio")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat4")}
                          </Typography>
                          <Radio
                            checked={type === "studio"}
                            onChange={() => setType("studio")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("misc")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat5")}
                          </Typography>
                          <Radio
                            checked={type === "misc"}
                            onChange={() => setType("misc")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #E5E5E5",
                            cursor: "pointer",
                            "&:hover": {
                              border: "1px solid #000",
                            },
                          }}
                          onClick={() => setType("event")}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            sx={{ color: "#000" }}
                          >
                            {t("Create_Workspace_Cat6")}
                          </Typography>
                          <Radio
                            checked={type === "event"}
                            onChange={() => setType("event")}
                            value={type}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginY: "10px",
                      }}
                    >
                      <Button
                        onClick={() => updateType({ type: type })}
                        variant="contained"
                      >
                        {t("ButtonUpdate")}
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                {previewWorkplace()}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={8}>
                <Box sx={{ marginY: "25px" }}>
                  <Card>
                    <CardContent>
                      <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                        {t("EditWorkspace_Address")}
                      </Typography>
                      <div>
                        <div className="create__office-step3-map">
                          <div
                            ref={geocoder}
                            id="geocoder"
                            className={"geocoder"}
                          />
                        </div>
                      </div>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginY: "10px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          updateType({
                            latitude: latitude,
                            longitude: longitude,
                            address: address,
                          })
                        }
                        variant="contained"
                      >
                        {t("ButtonUpdate")}
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
                <Box sx={{ marginY: "25px" }}>
                  <Card>
                    <CardContent>
                      <div>
                        <Box>
                          <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                            Title
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            sx={{
                              fontSize: { md: "1.6rem", xs: "1rem" },
                              width: "100%",
                              backgroundColor: "white",
                              color: "black",
                              borderRadius: "15px",
                            }}
                            variant="outlined"
                            value={title}
                            inputProps={{
                              style: {
                                fontSize: 16,
                                backgroundColor: "white !important",
                              },
                            }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                            onInput={handleChangeTitle}
                          />
                        </Box>
                      </div>
                      <div style={{ marginTop: "25px" }}>
                        <Box>
                          <Typography
                            sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}
                          >
                            {t("WorkspacePricing2")}
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            sx={{
                              fontSize: { md: "1.6rem", xs: "1rem" },
                              width: "100%",
                              backgroundColor: "white",
                              color: "black",
                              borderRadius: "15px",
                            }}
                            variant="outlined"
                            value={price}
                            onChange={handleChangePrice}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                          />
                        </Box>
                      </div>
                      <div style={{ marginTop: "25px", display: "none" }}>
                        <Box>
                          <Typography
                            sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}
                          >
                            Kategorien:
                          </Typography>
                        </Box>
                        <Box>
                          <Autocomplete
                            value={categoriesWorkplace.filter((option) =>
                              selectedCategoriesWorkplace.includes(option.id)
                            )}
                            sx={{
                              fontSize: { md: "1.6rem", xs: "1rem" },
                              width: "100%",
                              backgroundColor: "white",
                              color: "black",
                              borderRadius: "15px",
                            }}
                            variant="outlined"
                            multiple
                            id="checkboxes-tags-demo"
                            options={categoriesWorkplace}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={
                                    selected ||
                                    selectedCategoriesWorkplace.includes(
                                      option.id
                                    )
                                  }
                                />
                                {option.name}
                              </li>
                            )}
                            onChange={(event, value) =>
                              setValueCategoriesWorkplace(value)
                            } // prints the selected value
                            renderInput={(params) => (
                              <TextField {...params} label="Categories" />
                            )}
                          />
                        </Box>
                      </div>
                      <Box sx={{ marginTop: 2 }}>
                        <Typography
                          fontSize={"14px"}
                          variant={"body2"}
                          sx={{ color: "#777E90", marginBottom: 2 }}
                        >
                          Number of travelers allowed
                        </Typography>
                        <TextField
                          type={"number"}
                          sx={{
                            fontSize: { md: "1.6rem", xs: "1rem" },
                            width: "100%",
                            backgroundColor: "white",
                            color: "black",
                            borderRadius: "15px",
                          }}
                          label={"Amount"}
                          variant="outlined"
                          value={amount}
                          inputProps={{ style: { fontSize: 16 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                          onInput={handleChangeAmount}
                          /* label="Name des workspace" */
                        />
                      </Box>
                      <Box sx={{ marginTop: 2 }}>
                        <Typography
                          fontSize={"14px"}
                          variant={"body2"}
                          sx={{ color: "#777E90", marginBottom: 2 }}
                        >
                          Extra price for additional user
                        </Typography>
                        <Select
                          sx={{
                            width: "100%",
                            backgroundColor: "white",
                            color: "black",
                            borderRadius: "15px",
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={extraPercent}
                          onChange={handleChangePercent}
                        >
                          <MenuItem value={25}>25%</MenuItem>
                          <MenuItem value={35}>35%</MenuItem>
                          <MenuItem value={45}>45%</MenuItem>
                        </Select>
                      </Box>
                      <div style={{ marginTop: "25px" }}>
                        <Box>
                          <Typography
                            sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}
                          >
                            {t("WorkspaceDescription1")}
                          </Typography>
                        </Box>
                        <Box>
                          {/* text area for description */}
                          <TextField
                            multiline
                            rows={5}
                            value={description}
                            sx={{
                              fontSize: { md: "1.6rem", xs: "1rem" },
                              width: "100%",
                              backgroundColor: "white",
                              color: "black",
                              borderRadius: "15px",
                            }}
                            variant="outlined"
                            inputProps={{ style: { fontSize: 16 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 16 } }} // font size of input label
                            onInput={handleChangeDescription}
                            /* label="Name des workspace" */
                          />
                        </Box>
                      </div>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginY: "10px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          updateType({
                            title: title,
                            price: price,
                            description: description,
                            amount: amount,
                            extra_percent: extraPercent,
                          })
                        }
                        variant="contained"
                      >
                        {t("ButtonUpdate")}
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: "50%",
                    display: { md: "block", xs: "none" },
                    width: "100%",
                  }}
                >
                  <div
                    className={"map-visible-create"}
                    style={{ width: "100%", height: 500 }}
                  >
                    {mapElement}
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ marginY: "25px" }}>
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    Tags
                  </Typography>
                  <Box
                    sx={{
                      display: "block",
                      marginTop: "25px",
                      border: "1px solid #777E91",
                      borderRadius: "15px",
                      padding: "15px",
                    }}
                  >
                    {categories.map((category, index) => {
                      return (
                        <div key={index}>
                          <span
                            style={{ fontSize: "1.2rem", color: "#777E90" }}
                          >
                            {category.slice(0, 1).toUpperCase() +
                              category.slice(1)}
                            <div
                              style={{
                                margin: "10px",
                                backgroundColor: "white",
                                padding: "15px",
                              }}
                            >
                              {tags.map((tag, index) => {
                                if (tag.category === category)
                                  return (
                                    <Chip
                                      onClick={() => setChip(index)}
                                      sx={{
                                        color: tag.mainActive
                                          ? "white"
                                          : "#23262F",
                                        opacity: tag.mainActive ? "1" : "0.8",
                                        backgroundColor: tag.mainActive
                                          ? "#FB5A55"
                                          : "#E6E8EC",
                                        padding: "8px",
                                        margin: "5px 5px",
                                        textTransform: "none",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                      key={tag.id}
                                      icon={
                                        <Iconify
                                          icon={tag.icon}
                                          sx={{
                                            width: 18,
                                            height: 18,
                                            color: tag.mainActive
                                              ? "white !important"
                                              : "#000 !important",
                                          }}
                                        />
                                      }
                                      label={tag.name}
                                    />
                                  );
                              })}
                            </div>
                          </span>
                        </div>
                      );
                    })}
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginY: "10px",
                  }}
                >
                  <Button
                    onClick={() =>
                      updateType({
                        tag_list: tags
                          .filter((tag) => tag.mainActive)
                          .map((tag) => tag.id)
                          .toString(),
                      })
                    }
                    variant="contained"
                  >
                    {t("ButtonUpdate")}
                  </Button>
                </CardActions>
              </Card>
            </Box>
            <Box sx={{ marginY: "25px" }}>
              <Card>
                <CardContent>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    {t("EditWorkspace_Hours")}
                  </Typography>
                  <Box sx={{ margin: "20px auto" }}>
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#F2F4F7",
                        borderRadius: "15px",
                        border: "1px solid #777E91",
                      }}
                    >
                      {openings.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            gap: "5",
                            margin: "20px 0",
                          }}
                          key={item.type}
                        >
                          <Box
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "20px",
                              padding: "10px 20px",
                            }}
                          >
                            {item.type}
                            <Switch
                              onClick={(e) => {
                                setOpenOpenings(index, e.target.checked);
                              }}
                              checked={item.open}
                            />
                          </Box>
                          <Box>
                            <TimePicker
                              label={t("OpeningHours_From")}
                              ampm={false}
                              value={item.dateValueFrom}
                              onChange={(newValue) => {
                                changeOpeningsFrom(index, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    backgroundColor: "white",
                                    minWidth: "370px",
                                    borderRadius: "10px",
                                    border: "1px solid #777E91",
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: item.open
                                        ? "white"
                                        : "#EAECF0",
                                    },
                                  }}
                                  {...params}
                                />
                              )}
                            />
                          </Box>
                          <Box>
                            <TimePicker
                              ampm={false}
                              label={t("OpeningHours_To")}
                              value={item.dateValueTo}
                              onChange={(newValue) => {
                                changeOpeningsTo(index, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    border: "1px solid #777E91",
                                    minWidth: "370px",
                                    //change color filled input
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: item.open
                                        ? "white"
                                        : "#EAECF0",
                                    },
                                  }}
                                  {...params}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginY: "10px",
                  }}
                >
                  <Button
                    onClick={() =>
                      updateType({
                        openings: openings,
                      })
                    }
                    variant="contained"
                  >
                    {t("ButtonUpdate")}
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Container>
          <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
          <SuccessMsg
            open={openSuccess}
            msg={msgSuccess}
            handleClose={handleCloseSuccess}
          />
        </RootStyle>
      </Page>
    </Layout>
  );
}
