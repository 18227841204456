import ReactGA from 'react-ga4';
import config from './config';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function GlobalHistory() {
  let location = useLocation();

  ReactGA.initialize(config.googleAnalytics);
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
  });
  console.log(location.pathname + location.search);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
    console.log(location.pathname + location.search);
  }, [location]);

  return null;
}
