import React from "react";
import { Link as LinkReact } from "react-router-dom";
// @mui
import { styled } from '@mui/material/styles';
import { Link, Stack, Divider, /* Container, */ Typography } from '@mui/material';
// layouts
/* import Layout from '../../layouts'; */
// components
import { Page, Logo } from '../../components';
import { AuthWithSocial, AuthCarousel, RegisterForm } from '../../sections/auth';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    overflow: 'hidden',
    height: '100vh',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 2.5),
  overflowY: 'scroll',
  [theme.breakpoints.up('md')]: {
    maxWidth: 480,
    padding: theme.spacing(8, 10),
  },
}));

// ----------------------------------------------------------------------

export default function RegisterIllustrationPage() {
  const { t } = useTranslation();
  return (
    <Page title="Register">
      <RootStyle>
        <ContentStyle>
          <Logo sx={{ display: { xs: 'block', md: 'inline-block' } }} />

          <Stack
            sx={{
              pb: 5,
              pt: { xs: 5, md: 3 },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography variant="h4" paragraph>
              Kostenlos registrieren bei twimyo
            </Typography>
            <Typography variant="body2" sx={{ color: '#777E91' }}>
              Du hast bereits einen Account?
              <Link component={LinkReact} to={'/login'} variant="subtitle2" color="primary">
                {''} Jetzt einloggen
              </Link>
            </Typography>
          </Stack>

          <AuthWithSocial />

          <Divider sx={{ py: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              {t('Or')}
            </Typography>
          </Divider>

          <RegisterForm />
        </ContentStyle>

        <AuthCarousel title={`Manage Your Workspace \n More Effectively`} />
      </RootStyle>
    </Page>
  );
}

