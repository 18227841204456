import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const FaqContent = ({ items }) => {
  const theme = useTheme();

  

  const [expanded, setExpanded] = useState(-1);
  return (
    <Box sx={{ width: { sx: "80%", md: "60%" } }}>
      <Box marginBottom={6}>
        <Box>
          <Box>
            {items.map((item, i) => (
              <>
                <Box
                  component={Accordion}
                  key={i}
                  padding={1}
                  borderBottom={"1px solid #E6E8EC"}
                  borderRadius={`${theme.spacing(1)} !important`}
                  sx={{
                    '&::before': {
                      display: 'none',
                    },
                  }}
                  onClick={() => { setExpanded(i) }}
                >
                  <Box
                    component={AccordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`panel1a-header--${i}`}
                  >
                    <Typography marginRight={5} color={expanded === i ? "#FB5A55" : "#777E90"} fontWeight={600}>{(i + 1 < 10 ? "0" : "") + (i + 1)}</Typography>

                    <Typography fontWeight={600}>{item.title}</Typography>
                  </Box>

                  <AccordionDetails>
                    <Typography paddingX={6} align={"justify"} sx={{ whiteSpace: 'pre-line' }} color='#777E91'>{item.subtitle}</Typography>
                  </AccordionDetails>
                </Box>
              </>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaqContent;
