import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from "./components/Container";
import { Content } from './components';
import { ContactCard } from './components';
import Layout from "../../layouts";
import { Page } from "../../components";

const CompanyInformation = () => {
  return (
    <Layout>
      <Page title="imprint">
      <Container>
        <Box boxShadow={4} borderRadius={2}>
          <Box bgcolor="#3998d1" borderRadius={2}>
            <Container paddingX={{ xs: 2, sm: 4 }}>
              <Typography
                variant={'h4'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: '#fff',
                }}
              >
                Impressum
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: '#fff',
                }}
              >
                Letzte Änderung am <strong>23.06.2022</strong>
              </Typography>
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              width={1}
              marginBottom={-1.1}
            >
              <path
                fill="#ffffff"
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
          <Container
            paddingTop={'0 !important'}
            paddingX={{ xs: 2, sm: 4 }}
            position={'relative'}
            top={0}
          >
            <Box
              component={Grid}
              container
              spacing={4}
              flexDirection={{ xs: 'column-reverse', md: 'row' }}
            >
              <Grid item xs={12} md={9}>
                <Content />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box position={'sticky'} top={88} className={'sticky'}>
                  <ContactCard />
                </Box>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
      </Page>
    </Layout>
  );
};

export default CompanyInformation;
