// @mui
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import { Iconify } from '../../components';
import Searchbar from '../../layouts/Searchbar';
//
import supportHero from './../../assets/images/support_hero.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({

  backgroundColor: theme.palette.background.neutral,

}));

// ----------------------------------------------------------------------

export default function BannerContact() {
  const { t } = useTranslation();
  //create a banner with image background and title with subtitle and input search in the center
  return (
    <RootStyle>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '90vh',
          p: 0
        }}
      >
        <Box
          component="img"
          alt="banner"
          src={supportHero}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            display: { xs: 'none', md: 'block' }
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 9,
            textAlign: 'center',
            color: '#000',
            top: "40%",
            left: 0,
          }}
        >
          <Typography variant="h1" sx={{ mb: 1 }}>
            Support
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.72, px: "30%" }}>
            {t('Support_MainText')}.
          </Typography>
          <Box sx={{ maxWidth: 480, margin: 'auto', mt: 2 }}>
            {/*           <TextField
            fullWidth
            sx={{ 
              mb: 1 ,
              backgroundColor: '#fff',
              borderRadius: 50,
              border: 0,
              boxShadow: 5,
              //add padding to input filled
              '& .MuiFilledInput-root': {
                padding: '5px 6px',
                borderRadius: 50,
              },
              '& .MuiFilledInput-input': {
                padding: '5px 6px',
                borderRadius: 50,
              },

            }}
            size="small"
            placeholder={'Suche nach deine Fragen.'}
            InputProps={{
              endAdornment: (
                <Box
                  sx={
                    {
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 360,
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      cursor: 'pointer',
                      padding: 1,
                    }
                  }
                >
                  <Iconify icon="bx:bx-search" width={20} height={20} />
                </Box>
              ),

            }}
          /> */}
          </Box>

        </Box>
      </Box>
    </RootStyle>
  );
}
