import React from "react";
import Page from "../../components/Page";
import Layout from "../../layouts";
import CitiesTabs from "../../sections/@travel/citiesTabs/citiesTabs";

const Cities = () => {
  return (
    <Layout>
      <Page>
        <CitiesTabs />;
      </Page>
    </Layout>
  )
};

export default Cities;