import PropTypes from 'prop-types';
import { useState } from 'react';
// icons
import contentDeliveryNetwork from '@iconify/icons-carbon/content-delivery-network';
// @mui
import { MenuItem, Box, Popover } from '@mui/material';
// components
import { Iconify } from '../components';
import { IconButtonAnimate } from '../components/animate';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../stores/global';
import mixpanel from 'mixpanel-browser';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Deutsch',
    value: 'de',
    icon: 'https://raw.githubusercontent.com/lipis/flag-icons/refs/heads/main/flags/1x1/de.svg',
  },
  {
    label: 'English',
    value: 'en',
    icon: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
  }
  /*   {
      label: 'French',
      value: 'fr',
      icon: 'https://zone-assets-api.vercel.app/assets/icons/flags/ic_flag_fr.svg',
    }, */
];

// ----------------------------------------------------------------------

LanguagePopover.propTypes = {
  sx: PropTypes.object,
};

export default function LanguagePopover({ sx, isDesktop }) {
  //get current language
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const [open, setOpen] = useState(null);

  const { user } = useGlobalContext();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  const handleChangeLang = (newLang) => {
    mixpanel.track(
      'Language changed',
      {
        language: newLang,
        user: user?.email || 'guest'
      }
    );
    handleClose();
    setCurrentLang(newLang);
    i18n.changeLanguage(newLang);
    if (window.location.pathname === '/' || window.location.pathname.includes("/workspace/")) {
      window.location.href = window.location.href;
    }
  };

  return (
    <>
      <IconButtonAnimate color="inherit" onClick={handleOpen} sx={sx}>
        <Iconify icon={contentDeliveryNetwork} sx={{ width: 20, height: 20 }} />
        {isDesktop && <span style={{ fontSize: "16px", marginLeft: "5px", marginTop: "3px" }}>
          {t("LanguagePopover")}
        </span>}
      </IconButtonAnimate>



      <Popover
        open={Boolean(open)}
        onClose={handleClose}
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { px: 1, width: 220 },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang}
            onClick={() => handleChangeLang(option.value)}
            sx={{ my: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={option.icon}
              sx={{ borderRadius: '50%', width: 28, height: 28, objectFit: 'cover', mr: 1 }}
            />

            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
