import React from "react";
import { Link as LinkReact } from "react-router-dom";
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Stack, Divider, Container, Typography } from '@mui/material';
// layouts
/* import Layout from '../../layouts'; */
// components
import { Page, /* Image, */ Logo } from '../../components';
import { AuthWithSocial, LoginForm, AuthCarousel } from '../../sections/auth';
import { useTranslation } from "react-i18next";


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    overflow: 'hidden',
    height: '100vh',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 2.5),
  overflowY: 'scroll',
  [theme.breakpoints.up('md')]: {
    maxWidth: 480,
    padding: theme.spacing(2, 10),
  },
}));

// ----------------------------------------------------------------------

export default function LoginIllustrationPage() {
  const { t } = useTranslation();
  return (
    <Page title="Login">
      <RootStyle>
        <ContentStyle>
          <Logo sx={{ display: { xs: 'block', md: 'inline-block' } }} />
          <Stack
            sx={{
              pb: 3,
              pt: { xs: 3, md: 0 },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography variant="h4" paragraph>
              Einloggen bei twimyo
            </Typography>
            <Typography variant="body2" sx={{ color: '#777E91' }}>
              Du bist neu hier?
              <Link component={LinkReact} to={'/signup'} variant="subtitle2" color="primary">
                {''} Registriere dich jetzt
              </Link>
            </Typography>
          </Stack>

          <AuthWithSocial />

          <Divider sx={{ py: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              {t('Or')}
            </Typography>
          </Divider>
          <LoginForm />
        </ContentStyle>

        <AuthCarousel title={t('LoginPage_Text')} />
      </RootStyle>
    </Page>
  );
}
