import { useState } from 'react';
import { Link as LinkReact } from "react-router-dom";
import Typed from "react-typed";
// icons
/* import playIcon from '@iconify/icons-carbon/play'; */
import chevronRight from '@iconify/icons-carbon/chevron-right';
// @mui
/*import {  styled, alpha,  useTheme } from '@mui/material/styles';*/
import { Typography, Stack, /* Container, */ Box, /* Grid, */ Button } from '@mui/material';
// utils
/* import cssStyles from '../../../utils/cssStyles'; */
/* import { fShortenNumber } from '../../../utils/formatNumber'; */
// utils
/* import { _brands } from '../../../../_data/mock'; */
// assets
/* import { CareerHeroIllustration } from '../../../assets/illustrations'; */
/* import HeaderVideo from '../../../assets/videos/Header-Animation-01.mp4' */
// components
import { Iconify, /* BgOverlay, */ Image, /* TextIconLabel, PlayerWithButton */ } from '../../../components';
import background_2 from './../../../assets/images/background_2.png';
import { useTranslation } from 'react-i18next';
/* import { FabButtonAnimate } from '../../../components/animate'; */
/* import { TravelTourBarFilters } from '../../../sections/@travel'; */
//
/* import { CareerJobKeywordFilter, CareerJobLocationsFilter } from '../filters'; */

// ----------------------------------------------------------------------

/* const RootStyle = styled(Stack)(({ theme }) => ({
  ...cssStyles(theme).bgImage(),
  overflow: 'hidden',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(26),
  },
})); */

/* const BarStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  '& .MuiAutocomplete-root': {
    '& .MuiFilledInput-root': {
      height: '48px !important',
      backgroundColor: 'transparent !important',
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})); */

// ----------------------------------------------------------------------

/* const video = `https://www.dropbox.com/s/8hvvci7grn524v6/twimyoTrailerEinschieben720.mp4?dl=0`; */

export default function HaveSpaceHero() {
  /* const theme = useTheme(); */
  /*   const [openVideo, setOpenVideo] = useState(false); */
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    filterKeyword: null,
    filterLocation: null,
  });

  const handleChangeKeyword = (keyword) => {
    setFilters({
      ...filters,
      filterKeyword: keyword,
    });
  };

  const handleChangeLocation = (keyword) => {
    setFilters({
      ...filters,
      filterLocation: keyword,
    });
  };

  /*   const handleOpenVideo = () => {
      setOpenVideo(true);
    };
  
    const handleCloseVideo = () => {
      setOpenVideo(false);
    }; */

  return (
    <Box sx={{ minHeight: { xs: '100%', md: '75vh' }, position: 'relative', paddingX: "5%" }}>
      <Box
        sx={{
          display: 'flex',
          textAlign: { xs: "center", md: 'left' },
          position: 'relative',
          color: 'common.white',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <Stack
          alignItems="left"
          sx={{
            zIndex: 9,
            py: { xs: 10, md: 0 },
            paddingLeft: { xs: 0, md: "6%" },
            paddingRight: { xs: 0, md: "30%" },
            width: { xs: '100%', md: '80%' },
            position: { md: 'absolute' },
          }}
        >
          <Typography variant="h1" sx={{ maxWidth: 800, lineHeight: '1.1' }} >
            {t("HaveSpace_Hero_MainHeadline1")}
            <br />
            {t("HaveSpace_Hero_MainHeadline2")}
            {/* <Box component="span" sx={{ color: 'primary.main' }}>Office</Box> */}
            {/*             <Typography
              color={"primary"}
              component={"span"}
              variant={"inherit"}
              sx={{
                background: `linear-gradient(180deg, transparent 82%, ${alpha(
                  theme.palette.secondary.main,
                  0.3
                )} 0%)`,
              }}
            >
              <Typed
                strings={["Caring."]}
                typeSpeed={80}
                loop={true}
              />
            </Typography> */}
          </Typography>
          <Box
            alignItems="center"
            sx={{
              my: 2,
              '& svg': {
                mr: 1,
                width: 24,
                height: 24,
                color: 'primary.main',
              },
            }}
          >
            <Typography sx={{ maxWidth: 820, fontSize: '22px', fontWeight: '400' }}>
              {t("HaveSpace_Hero_Subline")}
            </Typography>
          </Box>

          {/* <Stack spacing={1}> */}
          {/*             <Typography variant="h2" sx={{ lineHeight: '1.1' }} >
              Die Welt ist mein {''}
              <Box component="span" sx={{ color: 'primary.main' }}>Workspace</Box>
            </Typography> */}
          {/* <Typography sx={{ color: 'grey.500' }}> */}
          {/*             <Typography sx={{ fontSize: '18px', color: '#3F3F3F' }}>
              Bei Twimyo kann man jederzeit einen passenden Ort zum Arbeiten findest und dich dabei mit anderen Leuten vernetzen kannst. Ob am Schreibtisch, im Studio, oder ...! - dein perfektes Work-Life-Match findest du hier! Bald weltweit.
            </Typography> */}
          {/* </Stack> */}

          <Button
            sx={{
              width: { xs: '70%', md: '50%', lg: '45%' },
              marginTop: '10px',
              marginBottom: '10px',
              marginX: { xs: 'auto', md: '0' },
              padding: '16px 24px',
              borderRadius: '90px',
              backgroundColor: '#F5465D',
              fontSize: { xs: "0.8rem", sm: "1rem", md: "16px", lg: "1rem", xxl: "2rem" }
            }}
            component={LinkReact}
            to='/host/workspaces'
            variant="contained"
          >
            {t("HaveSpace_CreateWorkspace_Button")}
          </Button>

          {/*  <Stack spacing={3} alignItems="center" direction={{ xs: 'column', md: 'row' }}> */}

          {/*                   <TextIconLabel
                    icon={
                      <FabButtonAnimate color="secondary" sx={{ mr: 2 }}>
                        <Iconify icon={playIcon} />
                      </FabButtonAnimate>
                    }
                    value="Video ansehen"
                    onClick={handleOpenVideo}
                    justifyContent={{ xs: 'center', sm: 'unset' }}
                    sx={{
                      cursor: 'pointer',
                      typography: 'h6',
                      '&:hover': { opacity: 0.72 },
                    }}
                  /> */}
          {/* </Stack> */}
          {/* <TravelTourBarFilters sx={{ py: { xs: 3, md: 8 } }} /> */}
        </Stack>
        {/* Background */}
        <Box
          sx={{
            width: 1,
            height: 1,
            position: {
              xs: 'absolute',
              md: 'relative',
            },
            borderRadius: "35px"
          }}
        >
          <Image
            alt="Munich Life"
            src={background_2}
            sx={{
              height: { xs: 1, md: '70vh' },
              borderRadius: "35px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
