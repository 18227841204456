import PropTypes from 'prop-types';
import Slider from 'react-slick';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
// components
import { Image, BgOverlay, CarouselDots } from '../../../../components';
// Images
import bannerRegisterComplete from "../../../../assets/images/bannerRegisterComplete.png";
import LogoTwimyoRegisterComplete from "../../../../assets/images/LogoTwimyoRegisterComplete.png";
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        width: "25%",
        flexGrow: 1,
        display: 'block',
        position: 'relative',
    },
}));

// ----------------------------------------------------------------------

CompleteCarousel.propTypes = {
    title: PropTypes.string,
};

export default function CompleteCarousel({ title }) {
    const theme = useTheme();
    const texts = [
        {
            msg: `Damals, als ich noch freiberuflich tätig war, hätte ich die Gesellschaft gut gebrauchen können. Ich war ein guter Designer, hatte aber niemanden, mit dem ich das teilen konnte.`,
            author: `Cameron Leonard`,
            job: `Founder of Lorem Ipsumm`,
        },
        {
            msg: `Damals, als ich noch freiberuflich tätig war, hätte ich die Gesellschaft gut gebrauchen können. Ich war ein guter Designer, hatte aber niemanden, mit dem ich das teilen konnte.`,
            author: `Johannes, 35 Jahre`,
            job: `Grafikdesigner`,
        },
        {
            msg: `Damals, als ich noch freiberuflich tätig war, hätte ich die Gesellschaft gut gebrauchen können. Ich war ein guter Designer, hatte aber niemanden, mit dem ich das teilen konnte.`,
            author: `Johannes, 35 Jahre`,
            job: `Grafikdesigner`,
        }
    ];

    const carouselSettings = {
        autoplaySpeed: 5000,
        fade: true,
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: Boolean(theme.direction === 'rtl'),
        ...CarouselDots({
            rounded: true,
            sx: {
                mr: 100,
                zIndex: 9,
                bottom: 80,
            },
        }),
    };

    return (
        <RootStyle>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 9,
                    px: 10,
                    py: 5,
                }}
            >
                <Image src={LogoTwimyoRegisterComplete} alt="logo" />
            </Box>
            <Typography
                variant="h3"
                sx={{
                    p: 10,
                    bottom: "55%",
                    zIndex: 10,
                    position: 'absolute',
                    color: 'common.white',
                }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 300,
                    zIndex: 10,
                    px: 10,
                    width: "100%",
                    height: "10%",
                }}
            >
                <Slider {...carouselSettings}
                >
                    {texts.map((img) => (
                        <Card
                            key={img.author}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                p: 3,
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 16,
                                    fontWeight: 800,
                                }}
                            >
                                {img.msg}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontWeight: '700px',
                                    fontSize: '1rem',
                                    mt: 2,
                                }}
                            >
                                {img.author}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'left',
                                    mt: 2,
                                }}
                            >
                                {img.job}
                            </Typography>
                        </Card>
                    ))}
                </Slider>
            </Box>

            {/* <BgOverlay /> */}
            <Box sx={{
                width: "100%",
                height: 1,
                backgroundColor: "rgba(32, 32, 32, 0.482951)",
            }}>
                <Image
                    alt='Login-Signup'
                    src={bannerRegisterComplete}
                    sx={{ width: 1, height: '100vh', opacity: 0.6 }}
                />
            </Box>
        </RootStyle>
    );
}
