import PropTypes from "prop-types";
import Slider from "react-slick";
import { useRef, useState } from "react";
// icons
import quotesIcon from "@iconify/icons-carbon/quotes";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Container,
  Stack,
  Box,
  Avatar,
  Chip,
  Card,
  Button,
} from "@mui/material";
// components
import {
  /*  Image, */ Iconify,
  CarouselArrows,
  CarouselDots,
  Image,
} from "../../../components";
import { useTranslation } from "react-i18next";
import "./testimonials.css";
import { Link } from "react-router-dom";
import { ca } from "@mapbox/mapbox-gl-geocoder/lib/exceptions";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 15),
    padding: theme.spacing(0, 0, 10, 0),
  },
}));

// ----------------------------------------------------------------------

export default function Testimonials() {
  const { t } = useTranslation();
  const theme = useTheme();
  const carouselRef = useRef(null);

  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      avatar:
        "https://images.unsplash.com/photo-1491349174775-aaafddd81942?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80",
      name: "Lisa",
      job: "München",
      carreer: "Digital Marketing",
      profile_url: "/user/252dd8b4-6fde-483b-a14e-7978d06f2afe",
    },
    {
      id: 2,
      avatar:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1700&q=80",
      name: "Robert",
      job: "München",
      carreer: `${t('Testimonials_RobertJob')}`,
      profile_url: "/user/075ce649-f45b-4e95-b987-1aaee3f081c4",
      //review: `${t("Testimonial_Robert")}`,
    },
    {
      id: 3,
      avatar:
        "https://images.unsplash.com/photo-1617376431454-8195cf1fd668?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
      name: "Stephen & Liz",
      job: "München",
      carreer: `${t('Testimonials_StephenLiz')}`,
      profile_url: "/user/53dbe84b-9c20-49ff-86ed-1ca47f8da633",
      //review: `${t("Testimonial_Stephen&Liz")}`,
    },
  ]);

  const carouselSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container maxWidth={true} sx={{ position: "relative" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: { xs: "center", md: "unset" },
          }}
          spacing={3}
        >
          <Stack spacing={3} flexGrow={1}>

            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h2">{t('Testimonials_Headline')}</Typography>
              <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px", justifyContent: "space-between", alignItems: "center"}}>
{/*                 <Button className="secondButton" size="medium" sx={{ borderRadius: "50px", marginRight: "10px" }}>
                  {t("OurCustomers")}
                </Button> */}
                <Button
                  LinkComponent={Link}
                  to="/signup"
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ padding: "10px 25px !important", borderRadius: "50px" }}
                >
                  {t("RegisterNow")}
                </Button>
              </Box>
            </Box>
            <Typography sx={{ fontSize: "24px", color: '#777E91', marginTop: '12px!important', lineHeight: '1.30' }}>
              {t("Testimonials_Subline")}
            </Typography>
          </Stack>


        </Stack>

        <Box>
          <Slider ref={carouselRef} {...carouselSettings}>
            {testimonials.map((testimonial) => (
              <Box
                key={testimonial.id}
                sx={{
                  px: 2,
                  pt: { xs: 6, md: 4 },
                  pb: { xs: 4, md: 6 },
                }}
              >
                <TestimonialItem testimonial={testimonial} />
              </Box>
            ))}
          </Slider>
        </Box>
        <CarouselArrows
          onNext={handleNext}
          onPrevious={handlePrevious}
          sx={{
            display: "block",
          }}
        />
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

TestimonialItem.propTypes = {
  testimonial: PropTypes.shape({
    name: PropTypes.string,
    review: PropTypes.string,
  }),
};

function TestimonialItem({ testimonial }) {
  const { avatar, name, review, job, carreer,profile_url } = testimonial;

  return (
    <Card
      sx={{
        display: { sm: "flex" },
        boxShadow: (theme) => theme.customShadows.z16,
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        flexDirection: "column",
      }}
      component={Link}
      to={profile_url}
    >
      <Box sx={{ flexShrink: { sm: 0 }, position: "relative" }}>
        <Box>
          <Image alt={name} src={avatar} ratio="3/4" />
        </Box>
        <div className="box-testimonial">
          {review &&
            <Typography
              fontSize={{ xs: "1rem", xl: "1rem", xxl:"3rem" }}
              color={"white"}
              fontWeight={"500"}
              sx={{
                padding: "4px 20px",
                marginTop: "10px"
              }}
            >
              “{review.slice(0, 40) + "..."}”
            </Typography>
          }
          <Typography fontSize={{ xs: "0.9rem", xl: "1.4rem", xxl:"2.5rem" }} color={"white"} sx={{ padding: "3px 20px", marginY: "0px" }}>
            ★ ★ ★ ★ ★
          </Typography>
          <Typography fontSize={{ xs: "0.9rem", xl: "1.4rem", xxl:"2.5rem" }} color={"white"} sx={{ padding: "3px 20px", marginY: "0px" }}>
            {name}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: "0.7rem", xl: "0.8rem", xxl:"2rem" }}
            fontWeight={"500"}
            color={"white"}
            sx={{ padding: "2px 20px 0px 20px" }}
          >
            {job}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: "0.7rem", xl: "0.8rem" }}
            color={"white"}
            sx={{ padding: "2px 20px 8px 20px", marginBottom: "10px" }}
          >
            {carreer}
          </Typography>
        </div>
      </Box>
    </Card>
  );
}
