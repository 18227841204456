import PropTypes from 'prop-types';
// @mui
import { Pagination, Box } from '@mui/material';
// components
import { TourItemSkeleton } from '../../../components/skeleton';
//
import TravelTourItem from './TravelTourItem';
import { useEffect, useState } from 'react';
import { useRequest } from '../../../hooks';
import { useInRouterContext, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

TravelTourList.propTypes = {
  loading: PropTypes.bool,
  workplaces: PropTypes.any,
};

export default function TravelTourList({ workplaces, loading, handleChangePage, page, total }) {
  const [tagsUsers, setTagsUser] = useState([])
 
  const { data: tagsUsersData, error: tagsUsersError } = useRequest({
    url: `/taglist/user`,
  });

  useEffect(() => {
    setTagsUser(tagsUsersData?.tagList ? tagsUsersData?.tagList : [])
  }, [tagsUsersData])


  return (
    <>
      <Box
        sx={{
          display: 'grid',
          rowGap: { xs: 4, md: 5 },
          columnGap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          },
        }}
      >
        {(loading ? [...Array(8)] : workplaces).map((office, index) =>
          office ? office.workplaces.map(workplace => office.user && <TravelTourItem key={workplace.id} workplace={{ ...workplace, address: office.address, user: office.user }} tagsUsers={tagsUsers} />
          )
            : <TourItemSkeleton key={index} />)}
      </Box>

      <Pagination
        count={total}
        page={parseInt(page)}
        onChange={handleChangePage}
        color="primary"
        size="large"
        sx={{
          pt: 10,
          pb: { xs: 10, md: 15 },
          '& .MuiPagination-ul': {
            justifyContent: 'center',
          },
        }}
      />
    </>
  );
}
