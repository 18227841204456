import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import config from "../../../config";
import { tag_user } from "../../../mocks/tag_user";
// icons
import checkmarkIcon from '@iconify/icons-carbon/checkmark';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import calendarIcon from '@iconify/icons-carbon/calendar';
import translateIcon from '@iconify/icons-carbon/translate';
import userIcon from '@iconify/icons-carbon/user';
import timeIcon from '@iconify/icons-carbon/time';
// @mui
import { Typography, Stack, Box, Divider, Button } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
// utils
import { TextIconLabel, Iconify } from '../../../components';
import { useTranslation } from "react-i18next";
import { OfficeDescriptionDetails } from '.';

// ----------------------------------------------------------------------

TravelTourDetails.propTypes = {
  tour: PropTypes.shape({
    availableEnd: PropTypes.string,
    availableStart: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.string,
    highlights: PropTypes.array,
    includes: PropTypes.array,
    languages: PropTypes.array,
    location: PropTypes.string,
    program: PropTypes.array,
    tourGuide: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  }),
};

export default function TravelTourDetails({ tour, tags, categories }) {

  const { t } = useTranslation();

  const includes = [
    { label: 'Lounge', enabled: true },
    { label: 'Drucker', enabled: true },
    { label: 'Barrierefrei', enabled: true },
    { label: 'Meetingraum', enabled: true },
    { label: 'ÖPNV', enabled: true },
    { label: 'Programmierung', enabled: false },
    { label: 'Lunch', enabled: false },
    { label: 'WLAN', enabled: false },
    { label: 'Kaffeemaschine', enabled: false },
    { label: 'Küche', enabled: false },
  ]

  return (
    <Stack spacing={5}>


 {/*      <section>
        <OfficeDescriptionDetails
          workspaces={tour}
          tagListOffice={tags}
          categories={categories}
        />
      </section> */}

      {/*       <Divider sx={{ borderStyle: 'dashed', my: 5 }} /> */}

      {/* -- Tour Description -- */}
      {/*       <section>
        <Typography variant="h4" paragraph>
          Beschreibung des workPlace
        </Typography>
        <Typography>{tour.data.office.description}</Typography>
      </section> */}

      {/* -- Tour Includes -- */}
      {/*       <section>
        <Typography variant="h4" paragraph>
          Büro beinhaltet 
        </Typography>

        <Box
          sx={{
            display: 'grid',
            rowGap: 2,
            columnGap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
          }}
        >
          {includes.map((option) => (
            <TextIconLabel
              key={option.label}
              icon={
                <Iconify
                  icon={checkmarkIcon}
                  sx={{
                    mr: 2,
                    width: 20,
                    height: 20,
                    color: 'primary.main',
                    ...(!option.enabled && { color: 'currentColor' }),
                  }}
                />
              }
              value={option.label}
              sx={{
                ...(!option.enabled && { color: 'text.disabled' }),
              }}
            />
          ))}
        </Box>
      </section> */}

    </Stack>
  );
}

// ----------------------------------------------------------------------

OverviewItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  text: PropTypes.string,
};

function OverviewItem({ icon, label, text = '-' }) {
  return (
    <TextIconLabel
      spacing={1.5}
      alignItems="flex-start"
      icon={icon}
      value={
        <Stack spacing={0.5}>
          <Typography variant="body2">{label}</Typography>
          <Typography sx={{ color: '#777E91' }}>{text}</Typography>
        </Stack>
      }
      sx={{ '& svg': { width: 24, height: 24 } }}
    />
  );
}

// ----------------------------------------------------------------------

HighlightItem.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};

function HighlightItem({ label, text }) {
  return (
    <Stack spacing={1}>
      <Typography
        variant="h6"
        sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}
      >
        <Box
          component="span"
          sx={{ width: 12, height: 2, borderRadius: 1, bgcolor: 'currentColor', mr: 1.5 }}
        />
        {label}
      </Typography>
      <Typography>{text}</Typography>
    </Stack>
  );
}
