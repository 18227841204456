import ThemeProvider from '../src/theme';
import './App.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Main } from "./Main";
import GlobalHistory from './useTrackerGoogle';
function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider>
        <GlobalHistory />
        <Main />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
