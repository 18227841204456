// icons
/* import logoFacebook from '@iconify/icons-carbon/logo-facebook';
import logoGithub from '@iconify/icons-carbon/logo-github'; */
// @mui
import { Stack, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// components
import { Image, /* Iconify */ } from '../../components';
import GoogleLogo from '../../assets/images/google-logo.svg';
import LinkedInLogo from '../../assets/images/linkedin-logo.svg';
import axios from 'axios';
import config from '../../config';
import ErrorMsg from '../../components/errors/ErrorMsg';
import { useGlobalContext } from '../../stores/global';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { dispatch } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ 'type': 'code', 'code': code }, '*')
      window.close();
    }
    window.addEventListener('message', handlePostMessage);

    window.gapi.load('auth2', () => {
      let auth2 = window.gapi.auth2.init({
        client_id: config.google.clientId,
        cookiepolicy: 'single_host_origin'
      })
      auth2.attachClickHandler(document.getElementById('google-login'), {},
        (googleUser) => {
          handleThirdPartyLogin(googleUser.getAuthResponse().id_token, "google");
        }
      );
    });
  }, []);

  const getCodeFromWindowURL = url => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const handlePostMessage = event => {
    if (event.data.type === 'code') {
      const { code } = event.data;
      console.log(code);
      handleThirdPartyLogin(code);

    }
  };

  const showPopup = (type) => {
    if (type === 'linkedIn') {
      let { clientId, redirectUrl, oauthUrl, scope, state } = config.linkedin;
      oauthUrl = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
      const width = 450,
        height = 730,
        left = window.screen.width / 2 - width / 2,
        top = window.screen.height / 2 - height / 2;
      window.open(
        oauthUrl,
        'Linkedin',
        'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
      );
    }
  };


  const handleThirdPartyLogin = (code, activeThirdParty = 'linkedin') => {
    let authUrl = null
    if (activeThirdParty === 'linkedin') {
      authUrl = `${config.api.host}/auth/linkedin?code=${code}`
    }
    if (activeThirdParty === 'google') {
      authUrl = `${config.api.host}/auth/google?code=${code}`
    }
    axios.get(authUrl).then(res => {
      let data = res.data;
      axios.defaults.headers.common = {
        "Authorization": data.token,
      };
      axios.get(`${config.api.host}/users/${data.id}`)
        .then(async res => {
          let userdata = res.data.data.user
          dispatch({
            type: 'SET_USER',
            payload: {
              token: data.token,
              ...userdata,
            },
          });
          window.open('/complete-register/1', '_self')
        })
        .catch(error => {
          setOpen(true);
          setMsg("Ein unerwarteter Fehler ist aufgetreten");
          if (error.response.status === 401) {
            localStorage.clear();
          }
        })
    }).catch(error => {
      console.log(error)
      setOpen(true);
      setMsg("Ungültige Anmeldeinformationen");
    })
  }

  const onSignIn = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  return (<>
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        size="large"
        color="inherit"
        variant="contained"
        id={"google-login"}
        sx={{ color: '#296887', bgcolor: 'grey.5008', '&:hover': { bgcolor: 'grey.50024' } }}
      >
        <Image
          alt="google icon"
          src={GoogleLogo}
          sx={{ width: 24, height: 24, marginX: 1 }}
        />
        <Typography
          sx={{
            textTransform: 'capitalize',
            fontSize: '0.9rem',
            fontWeight: '600'
          }}>
          MIT GOOGLE REGISTRIEREN
        </Typography>
      </Button>

      {/*
      <Button
        color="inherit"
        fullWidth
        variant="contained"
        size="large"
        sx={{ bgcolor: 'grey.5008', '&:hover': { bgcolor: 'grey.50024' } }}
      >
        <Iconify icon={logoGithub} sx={{ color: 'text.primary', width: 24, height: 24 }} />
      </Button> */}

    </Stack>
    <Stack sx={{ marginY: 1 }} direction="row" spacing={2}>
      <Button
        fullWidth
        size="large"
        color="inherit"
        variant="contained"
        onClick={() => showPopup('linkedIn')}
        sx={{ color: '#296887', bgcolor: 'grey.5008', '&:hover': { bgcolor: 'grey.50024' } }}
      >
        <Image
          alt="google icon"
          src={LinkedInLogo}
          sx={{ width: 24, height: 24, marginX: 1 }}
        />
                <Typography
          sx={{
            textTransform: 'capitalize',
            fontSize: '0.9rem',
            fontWeight: '600'
          }}>
          MIT LINKEDIN REGISTRIEREN
        </Typography>
      </Button>

      {/*
      <Button
        color="inherit"
        fullWidth
        variant="contained"
        size="large"
        sx={{ bgcolor: 'grey.5008', '&:hover': { bgcolor: 'grey.50024' } }}
      >
        <Iconify icon={logoGithub} sx={{ color: 'text.primary', width: 24, height: 24 }} />
      </Button> */}

    </Stack>
    <ErrorMsg open={open} msg={msg} handleClose={handleClose} />
  </>
  );
}
