import React, { useEffect, useMemo, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./styles.css";
import axios from "axios";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const useOptions = () => {
  const options =
    //add styles mui input and add border with padding
    {
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          //add border with padding
          border: "1px solid #ccc",
          padding: "10px",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  return options;
};

export default function CheckoutForm({
  user,
  workplaceId,
  priceSale,
  clientSecret,
  fromDate,
  toDate,
  totalGuests,
  setIsLoading,
  submit,
  setSubmit,
  email,
  days,
  getValues,
}) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const navigate = useNavigate();

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (submit) {
      handleSubmit();
    }
  }, [submit, stripe]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    let message = getValues("message");
    let timeArrive = getValues("timeArrive");
    //hour with am or pm
    timeArrive = moment(timeArrive).format("HH:mm");
    console.log("timeArrive", timeArrive);
    console.log("message", message);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //check if range of dates are available
    const { data } = await axios.post(
      `${config.api.host}/booking/check_range_date_available`,
      {
        start_date: fromDate,
        end_date: toDate,
        workplace_id: workplaceId,
      }
    );
    console.log(data);
    if (!data.success) {
      setMessage(data.message);
    } else {
      //Need to save information of user
      const result = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              //get cardholder name
              name: user.first_name + " " + user.last_name,
              email: email,
              phone: user.phone_mobile,
              address: {
                line1: user.street,
                city: user.city,
                postal_code: user.postcode,
                country: user.country && user.country.slice(0, 2),
              },
            },
          },
          return_url: window.location.href,
        },
        { handleActions: false }
      );

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (result.error) {
        setMessage(result.error.message);
      } else {
        console.log(result);
        if (result.paymentIntent.status === "succeeded") {
          axios.defaults.headers.common = {
            Authorization: user.token,
          };
          axios
            .post(`${config.api.host}/booking/create`, {
              stripe_id: result.paymentIntent.id,
              workplace: workplaceId,
              amount: totalGuests,
              time: [fromDate, toDate],
              days: days,
              message: message,
              timeArrive: timeArrive,
            })
            .then(async (res) => {
              setMessage("Payment succeeded!");
              console.log(res);
              navigate(`/checkout/complete?booking=${res.data.data.id}`);
            })
            .catch((error) => {
              setMessage("An unexpected error occurred.");
            });
        } else {
          if (result.paymentIntent.status === "requires_action") {
            var iframe = document.createElement("iframe");
            iframe.src = result.paymentIntent.next_action.redirect_to_url.url;
            iframe.width = 600;
            iframe.height = 400;
            // render the iframe
            document.getElementById("iframe").appendChild(iframe);
          } else {
            setMessage("An unexpected error occurred.");
          }
        }
      }
    }

    setIsLoading(false);
    setSubmit(false);
  };

  const on3DSComplete = () => {
    // Hide the 3DS UI
    let message = getValues("message");
    let timeArrive = getValues("timeArrive");
    //hour with am or pm
    timeArrive = moment(timeArrive).format("HH:mm");
    console.log("timeArrive", timeArrive);
    console.log("message", message);

    document.getElementById("iframe").innerHTML = "";

    // Check the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(function (result) {
      if (result.error) {
        // PaymentIntent client secret was invalid
        setMessage(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // Show your customer that the payment has succeeded
          axios.defaults.headers.common = {
            Authorization: user.token,
          };
          axios
            .post(`${config.api.host}/booking/create`, {
              stripe_id: result.paymentIntent.id,
              workplace: workplaceId,
              amount: totalGuests,
              time: [fromDate, toDate],
              days: days,
              message: message,
              timeArrive: timeArrive,
            })
            .then(async (res) => {
              setMessage("Payment succeeded!");
              console.log(res);
              navigate(`/checkout/complete?booking=${res.data.data.id}`);
            })
            .catch((error) => {
              setMessage("An unexpected error occurred.");
            });
        } else if (result.paymentIntent.status === "requires_payment_method") {
          // Authentication failed, prompt the customer to enter another payment method
          setMessage(
            "Authentication failed, prompt the customer to enter another payment method"
          );
        }
      }
    });
  };

  window.addEventListener(
    "message",
    function (ev) {
      if (ev.data === "3DS-authentication-complete") {
        on3DSComplete();
      }
    },
    false
  );

  return (
    <form
      className="form-pay"
      id="payment-form"
      onSubmit={handleSubmit}
      style={{ marginTop: "15px" }}
    >
      <Grid marginTop={5} container spacing={2}>
        <Grid item xs={12}>
          <label
            htmlFor="email"
            style={{
              color: "#B1B5C4",
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {t("Booking_CardNumber")}
          </label>
          <Box
            sx={{
              width: "100%",
              height: "40px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <CardNumberElement
              options={options}
              onChange={(e) => {
                setMessage(null);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <label
            htmlFor="email"
            style={{
              color: "#B1B5C4",
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {t("Booking_CardHolder")}
          </label>
          <TextField fullWidth label={t("CardHolder_Name")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <label
            style={{
              color: "#B1B5C4",
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {t("Booking_ExpirationDate")}
          </label>
          <Box
            sx={{
              width: "100%",
              height: "40px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <CardExpiryElement options={options} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <label
            style={{
              color: "#B1B5C4",
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {t("Booking_CVC")}
          </label>
          <Box
            sx={{
              width: "100%",
              height: "40px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <CardCvcElement options={options} />
          </Box>
        </Grid>
      </Grid>
      {/* Show any error or success messages */}

      {message && (
        <Box
          sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "15px",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            borderColor: "red",
          }}
          id="payment-message"
        >
          {message}
        </Box>
      )}
      <div id="iframe"></div>
    </form>
  );
}
