import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery, Grid } from "@mui/material";

import Container from "../companyInformation/components/Container";
import { MyBookingsCards, SidebarDetails } from "./components";
// layouts
import Layout from "../../layouts";
// components
import { ErrorScreen, LoadingScreen, Page } from "../../components";
import { useRequest } from "../../hooks";
import MyFavoritesCards from "./components/MyFavoritesCards";

const MyFavorites = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { data: favorites, error: favoritesError } = useRequest({
    url: "/office/favorite/list",
  });

  if (favoritesError) {
    return <ErrorScreen />;
  }

  if (!favorites) {
    return <LoadingScreen />;
  }


  return (
    <Layout>
      <Page>
        <Box bgcolor={"alternate.main"}>
          <Container>
            <Grid sx={{ margin: '50px 0 30px 0' }} container spacing={isMd ? 4 : 0}>
              <Grid item xs={12} md={12}>
                <MyFavoritesCards favorites={favorites.favoriteWorkplaces} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Page>
    </Layout>
  );
};

export default MyFavorites;
