import { useRef, useState } from 'react';
// icons
import playIcon from '@iconify/icons-carbon/play';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Container, Stack, Box, Card } from '@mui/material';
// hooks
import { useBoundingClientRect, useResponsive } from '../../../hooks';
// components
import { /* SvgIconStyle,  */Image, TextIconLabel, Iconify, PlayerWithButton } from '../../../components';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7, 0),
  },
}));

const video = `https://www.dropbox.com/s/8hvvci7grn524v6/twimyoTrailerEinschieben720.mp4?dl=0`;

// ----------------------------------------------------------------------

export default function TravelLandingIntroduce() {
  const { t } = useTranslation();

  const isDesktop = useResponsive('up', 'md');

  const containerRef = useRef(null);
  const container = useBoundingClientRect(containerRef);

  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  const offsetLeft = container && container.left + 20;

  return (
    <>
      <RootStyle>
        {/*          <Container ref={containerRef}>
          <Stack
            spacing={3}
            sx={{
              maxWidth: 480,
              mx: { xs: 'auto', md: 'unset' },
              textAlign: { xs: 'center', md: 'unset' },
            }}
          >
            <Typography variant="h2">Entdecke die Welt als dein Office</Typography>
            <Typography sx={{ color: '#777E91' }}>
              Cras ultricies mi eu turpis hendrerit fringilla. Nulla consequat massa quis enim.
            </Typography>
          </Stack>
        </Container> */}

        <Box
          sx={{
            position: 'relative',
            my: { xs: 5, md: 0 },
            ml: { md: `${offsetLeft}px` },
          }}
        >
          <Card
            sx={{
              p: 5,
              m: 5,
              top: 0,
              left: 0,
              zIndex: 9,
              position: 'absolute',
              maxWidth: { sm: 360 },
              right: { xs: 0, sm: 'unset' },
              bottom: { xs: 0, sm: 'unset' },
              textAlign: { xs: 'center', sm: 'unset' },
            }}
          >
            {/*             <Typography variant="overline" sx={{ color: 'text.disabled' }}>
              Device
            </Typography> */}
            <Typography variant="h3" sx={{ mt: 2, mb: 3 }}>
              {t('VideoTitle_Headline')}
            </Typography>
            <TextIconLabel
              icon={<Iconify icon={playIcon} sx={{ width: 24, height: 24, mr: 1 }} />}
              value={t('VideoTitle_Subline')}
              /* value="Video ansehen" */
              onClick={handleOpenVideo}
              justifyContent={{ xs: 'center', sm: 'unset' }}
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                typography: 'subtitle1',
                '&:hover': { opacity: 0.72 },
              }}
            />
          </Card>
          <Image
            alt="cover"
            src={'https://zone-assets-api.vercel.app/assets/images/travel/travel_hero_4.jpg'}
            width={1600}
            height={isDesktop ? 900 : 1600}
            ratio={isDesktop ? '16/9' : '1/1'}
          />
        </Box>

        <Container sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'grid',
              gap: { xs: 8, md: 3 },
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
              },
            }}
          >
          </Box>
        </Container>
      </RootStyle>

      <PlayerWithButton open={openVideo} onClose={handleCloseVideo} videoPath={video} />
    </>
  );
}
