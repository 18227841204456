import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Stack,
  Card,
  /* Button, */ Divider,
  Chip,
  IconButton,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
// hooks
import { /* useBoundingClientRect, */ useRequest } from "../../../hooks";
// components
import {
  CarouselArrows,
  Image,
  /* Iconify, SvgIconStyle, IconButtonAnimate, Label,  */ TextMaxLine,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
/* import moment from "moment"; */

// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SVGIcon from "../../../components/icons.element";
import { useTranslation } from "react-i18next";
import { tag_user } from "../../../mocks/tag_user";
/* import BeATraveler from "../../../assets/images/TravelerPic.jpg"; */
import config from "../../../config";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 15),
    padding: theme.spacing(10, 0),
  },
}));

// ----------------------------------------------------------------------

export default function ExploreNearby({
  customIcon, // Set icon right
  onNext,
  onPrevious,
  children,
  ...other
}) {
  const { t } = useTranslation();

  const [places, setPlaces] = useState([]);

  const { data: workplacesData, error: workplacesError } = useRequest({
    url: `/office/workplaces`,
  });

  useEffect(() => {
    setPlaces(workplacesData?.data ? workplacesData?.data.slice(0, 9) : [])
  }, [workplacesData]);

  const theme = useTheme();
  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "flex-end" }}
          sx={{
            textAlign: "center",
            marginBottom: "50px",
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">{t('Headline_Close_to_you')}</Typography>
            <Typography sx={{ fontSize: "24px", color: '#777E91', marginTop: '12px!important' }}>
              {t('Subline_Close_to_you')}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {places.map((place) =>
            <Box
              key={place.id}
              sx={{
                width: { xs: "100%", sm: "45%", md: "23%", xl: "18%" },
                px: 2,
                pt: { xs: 6, md: 4 },
                pb: { xs: 4, md: 6 },
              }}
            >
              <PlaceItem place={{ ...place, imageList: place.images }} />
            </Box>
          )

          }
        </Box>
      </Container>
    </RootStyle>
  );
}

function PlaceItem({ place }) {
  const {
    title,
    imageList,
  } = place;

  const { t } = useTranslation();
  return (
    <Card
      sx={{
        display: { sm: "flex" },
        boxShadow: (theme) => theme.customShadows.z16,
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <Box sx={{ flexShrink: { sm: 0 }, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <Chip
            sx={{
              color: "#777E91",
              backgroundColor: "#F4F5F6",
              padding: "1px",
              textTransform: "uppercase",
              fontSize: "12px",
              borderRadius: "40px",
              marginLeft: "5px",
              fontWeight: "bold",
            }}
            label={"1480"}
          />
        </div>
        <Box sx={{ padding: "40px 0px 15px", display: "flex", justifyContent: "center" }}>
          <img
            alt={title}
            src={config.api.host + (imageList[0].data || imageList[0])}
            width="80px"
            height="80px"
            style={{ borderRadius: "50%" }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0 10px",
          alignItems: "center",
        }}
      >
        <TextMaxLine variant="body1" line={1}>
          <strong>
            {title.slice(0, 14) + (title.length > 14 ? "..." : "")}
          </strong>
        </TextMaxLine>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "5px 20px",
          alignItems: "center",
        }}
      >
        <TextMaxLine variant="body2" color='#777E91' line={2}>
          {t("Category_Distance")}
        </TextMaxLine>
      </Box>
    </Card>
  );
}
