import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link as LinkReact } from "react-router-dom";
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import LogoIcon from '../assets/logos/twimyo-logo-gradient.svg';


// ----------------------------------------------------------------------

Logo.propTypes = {
  isSimple: PropTypes.bool,
  onDark: PropTypes.bool,
  sx: PropTypes.object,
};

function Logo({ onDark = false, isSimple = false, sx }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const PRIMARY_MAIN = theme.palette.primary.main;
  const LIGHT_COLOR = theme.palette.common.white;
  const DARK_COLOR = theme.palette.grey[800];

  return (
    <LinkReact to="/">
      <Box
        sx={{
          width: isSimple ? 64 : 75,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {isSimple ? (
          <img style={{height: '105px'}} src={LogoIcon} alt="Twimyo" />
        ) : (
          <img style={{height: '105px'}} src={LogoIcon} alt="Twimyo" />
        )}
      </Box>
    </LinkReact>
  );
}

export default memo(Logo);
