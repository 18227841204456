import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'ALLGEMEINE GESCHÄFTS- UND NUTZUNGSBEDINGUNGEN',
    description: `für die Nutzung der Dienste der TWIMYO-Plattform 
    der`,
  },
  {
    title: `TWIMYO GmbH
    Garmischer Str. 4, 80339, München, Deutschland`,
    description: `eingetragen im Handelsregister des Amtsgerichts München unter HRB 266199, 
    USt.-Identifikationsnummer  DE345951435
  (nachfolgend „TWIMYO“)`,
  },
  {
    title: `A) ALLGEMEINE VORSCHRIFTEN
    1. DEFINITIONEN UND GELTUNGSBEREICH`,
    description: `1.1 „Local“ im Sinne dieser AGB ist Unternehmer im Sinne des § 14 BGB, nämlich natürliche oder juristische Personen oder rechtsfähige Personengesellschaften, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen oder gewerblichen beruflichen Tätigkeit handeln.
    
    1.2 „Nomad“ steht für eine natürliche Person, die die TWIMYO-Plattform gewerblich oder privat nutzt, um Arbeitsorte (nachfolgend „workplaces“) zu suchen und zu buchen.
    
    1.3 „Kunde“ bezeichnet jeden Local und Nomad, der sich auf der TWIMYO-Plattform registriert und ein Nutzerprofil angelegt hat.
    
    1.4 „Workspace“ ist ein auf der TWIMYO-Plattform näher spezifizierter Einzelarbeitsplätz oder Geschäftsräum, der Nomads von Locals über die TWIMYO-Plattform zur Buchung/Miete angeboten wird.
    
    1.5 Den Angeboten von TWIMYO und dem Vertragsverhältnis zwischen dem Local oder Nomad und TWIMYO über die Nutzung der Dienste der von TWIMYO unter www.twimyo.com angebotenen und bereitgestellten Plattform inklusive aller Unterseiten (nachfolgend „TWIMYO-Plattform“) sowie für die Erbringung sämtlicher sonstiger Leistungen von TWIMYO über die TWIMYO-Plattform oder im Zusammenhang mit der TWIMYO-Plattform liegen ausschließlich diese Allgemeinen Geschäfts- und Nutzungsbedingungen (nachfolgend „AGB“) in ihrer jeweils gültigen Form zugrunde.
    
    1.6 Allgemeine oder sonstige Geschäftsbedingungen des jeweiligen Locals gelten nur, wenn und soweit ihre Gültigkeit von TWIMYO ausdrücklich und schriftlich anerkannt wird. Von diesen AGB abweichende Geschäftsbedingungen kommen auch dann nicht zur Anwendung, wenn TWIMYO Leistungen trotz Kenntnis dieser abweichenden Geschäftsbedingungen erbringt, ohne diesen ausdrücklich zu widersprechen.
    
    1.7 Im Rahmen einer laufenden Geschäftsbeziehung haben diese AGB nach erstmaliger wirksamer Einbeziehung auch dann Gültigkeit, wenn sich TWIMYO in den Folgegeschäften nicht jeweils ausdrücklich auf diese beruft.`,
  },
  {
    title: `2.VERTRAGSGEGENSTAND UND GRUNDLAGEN DER NUTZUNG DER TWIMYO-PLATTFORM`,
    description: `2.1 Die TWIMYO-Plattform ist eine Online-Vermittlungsplattform, die es Locals ermöglicht, Workplaces und gegebenenfalls die damit im Zusammenhang stehende Ausstattung zu inserieren, und Nomad ermöglicht, angebotene Workplaces direkt vom Local zu mieten.

    2.2 TWIMYO vermittelt ausschließlich Workplaces Dritter und bietet selbst keine Workplaces an. Bucht ein Nomad einen Workspace über die TWIMYO-Plattform und bestätigt der Local die Buchung, kommt der Mietvertrag direkt zwischen Local und Nomad zustande. TWIMYO ist keine Partei dieses über die TWIMYO-Plattform vermittelten Vertrags und ist an dem Vertrag auch anderweitig nicht beteiligt.
    
    2.3 Darstellung, Reihenfolge und Platzierung von Inhalten und Inseraten auf der TWIMYO-Plattform kann von TWIMYO frei bestimmt werden, hängt jedoch üblicherweise von den jeweiligen Suchkriterien ab. Die Reihenfolge, in der Inserate in den Suchergebnissen auf der TWIMYO-Plattform angezeigt werden, hängt von einer Vielzahl von Faktoren ab, darunter diesen Hauptparametern: 
    
    - Suchparameter des Nomads (z. B. Arbeitszeit und -dauer, Preisspanne);
    - Merkmale des Inserats (z. B. Preis, Verfügbarkeit im Kalender, Anzahl und Qualität der Bilder, Bewertungen, Art des Local-Services, Status des Locals, Alter des Inserats, durchschnittliche Beliebtheit bei Nomads);
    - Buchungserfahrung des Nomads (z. B. Kundenservice und Stornierungshistorie des Locals, Einfachheit der Buchung);
    - Anforderungen des Locals (z. B. Mindest- oder Höchstzahl an Tagen, Buchungsfrist); und
    - Vorlieben des Nomads (z. B. frühere Buchungen, gespeicherte Inserate, Standort, von dem aus der Nomad sucht).
    
    2.4 Die TWIMYO-Plattform bietet die Möglichkeit, einen individualisierten Account zur Nutzung der TWIMYO-Plattform anzulegen (nachfolgend „Nutzerprofil“) und sich dadurch auf der TWIMYO-Plattform zu registrieren. 
    
    a) Das Nutzerprofil stellt die Identität dar, mit der der Kunde innerhalb der TWIMYO-Plattform und in den Anwendungen des Dienstes auftritt und dargestellt wird. Die Registrierung und Erstellung des Nutzerprofils ist kostenlos. TWIMYO hat das Recht, eine Registrierung abzulehnen oder ein Nutzerprofil zu sperren, insb. wenn gegen diese AGB verstoßen wird.
    
    b) Der Kunde sichert zu, dass sämtliche von ihm während des Registrierungsprozess angegebenen Daten wahr und vollständig sind. Der Kunde ist verpflichtet, die Daten hinsichtlich aller von ihm genutzten Anwendungen während der gesamten Vertragslaufzeit vollständig und wahr zu halten. 
    
    c) Die Registrierung steht nur Personen zur Verfügung, die volljährig (mind. 18 Jahre alt) und uneingeschränkt geschäftsfähig, sowie juristischen Personen sind. 
    
    d) Für den Fall, dass eine natürliche Person für eine juristische Person tätig wird, sichert diese Person zu, bevollmächtigt zu sein, um im Namen der juristischen Person tätig zu werden. Der Kunde wird TWIMYO auf Anfrage die gemäß dieser Ziffer zugesicherten Angaben nachweisen.
    
    e) Jeder Local und jeder Nomad darf nur ein Nutzerprofil anlegen. Der Kunde darf die Nutzung des eigenen Nutzerprofils Dritten nicht gestatten. Der Kunde ist verpflichtet, das von ihm gewählte Passwort geheim zu halten. Die Sicherung und Aufbewahrung der Zugangsdaten, insbesondere des Passworts, zur TWIMYO-Plattform obliegt der ausschließlichen Verantwortung des Kunden. TWIMYO wird das Passwort nicht an Dritte weitergeben, nur für den Anmeldevorgang benutzen und den Kunden – vom Anmeldevorgang abgesehen – zu keinem Zeitpunkt nach dem Passwort fragen. 
    
    f) TWIMYO kann technisch nicht mit Sicherheit feststellen, ob ein auf der TWIMYO-Plattform registrierter Benutzer tatsächlich diejenige Person oder dasjenige Unternehmen darstellt oder vertritt, die bzw. das der Nutzer angibt. TWIMYO übernimmt deswegen keine Gewähr für die tatsächliche Identität eines Nutzers.
    
    2.5 Kunden ist es untersagt,
    
    a) Handlungen vorzunehmen, die geeignet sind, die Funktionalität der TWIMYO-Plattform zu beeinträchtigen, insbesondere diese übermäßig zu belasten;
    b) Handlungen im Zusammenhang mit der TWIMYO-Plattform vorzunehmen, die Blockieren, Überschreiben, Modifizieren, Kopieren, soweit dies nicht für die ordnungsgemäße Nutzung der TWIMYO-Plattform erforderlich ist; und/oder
    c) Inhalte der TWIMYO-Plattform oder von anderen Kunden zu verbreiten oder öffentlich wiederzugeben, es sei denn die Verbreitung oder öffentliche Wiedergabe ist im Rahmen der jeweiligen Anwendung der TWIMYO-Plattform vorgesehen oder hat der anderen Nutzung der vorgenannten Handlungen zugestimmt.`,
  },
  {
    title: `3. ÄNDERUNGEN DER LEISTUNGEN DER TWIMYO-PLATTFORM`,
    description: `3.1 TWIMYO behält sich vor, die TWIMYO-Plattform sowie die darauf angebotenen Leistungen zu ändern oder abweichende Leistungen anzubieten, sofern dies nicht für den Kunden unzumutbar ist.

    3.2 TWIMYO behält sich ferner vor, dass die TWIMYO-Plattform gemäß vorstehender Regelung zu modifizieren, wenn und soweit
    
    a) TWIMYO verpflichtet ist, die Übereinstimmung der über die TWIMYO-Plattform angebotenen Leistungen mit dem auf die Leistungen anwendbaren Recht herzustellen, insbesondere wenn sich die geltende Rechtslage ändert;
    b) TWIMYO damit einem gegen TWIMYO gerichteten Gerichtsurteil oder einer Entscheidung einer Behörde nachkommt;
    c) die jeweilige Modifizierung notwendig ist, um Sicherheitslücken zu schließen; oder
    d) die Modifizierung rein technischer oder prozessualer Natur ohne wesentliche Auswirkungen für den Kunden ist.`,
  },
  {
    title: `4. HAFTUNG`,
    description: `4.1 Im Fall von Vorsatz und grober Fahrlässigkeit haftet TWIMYO unbeschränkt. 

    4.2 Im Fall von einfacher Fahrlässigkeit haftet TWIMYO nur
    
    a) für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie
    
    b) für Schäden aus der Verletzung einer wesentlichen Vertragspflicht, d.h. einer Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf (Kardinalpflicht); in diesem Fall ist die Haftung von TWIMYO jedoch auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens begrenzt.
    
    4.3 Die sich aus dem Vorstehenden ergebenden Beschränkungen gelten nicht für den Fall, dass TWIMYO einen Mangel arglistig verschwiegen hat oder eine Garantie für die Beschaffenheit der Sache übernommen hat sowie für Ansprüche des Kunden nach dem Produkthaftungsgesetz.`,
  },
  {
    title: `5. BUCHUNG, ÄNDERUNG UND STORNIERUNG`,
    description: `5.1 Möchte ein Nomad einen Workplace mieten und bestätigt seine Buchung auf der TWIMYO-Plattform, so gilt diese Buchung als ein für den Nomad verbindliches Angebot, den Workspace vom Local zu den auf der Plattform angegebenen Bedingungen (insb. Mietgegenstand, Preis, Mietzeitpunkt und -dauer) mieten zu wollen. An dieses Angebot ist der Nomad ab zwölf Stunden vor Beginn des Mietzeitpunkts gebunden. Wenn ein Nomad eine Buchung stornieren möchte, richtet sich der Betrag, der zurückerstattet wird, grundsätzlich nach den Stornierungsbedingungen des Locals, die für diese Buchung gelten.

    5.2 Ein Mietvertrag über den Workplace kommt durch Annahme des auf den Abschluss eines Mietvertrags gerichteten Angebots durch den Local zustande. Über die Annahme des Angebots und damit über das Zustandekommen des für den Local sowie den Nomad verbindlichen Mietvertrags werden der Local und der Nomad per E-Mail sowie über die TWIMYO-Plattform informiert.
    
    5.3 Wenn ein Nomad eine Buchung ändern möchte, so muss er seine Buchung gemäß 5.1 stornieren und eine neue Buchung vornehmen. 
    
    5.4 Die Stornierung eines abgeschlossenen Mietvertrags kann nur über die TWIMYO-Plattform zu den im Inserat angegebenen Stornierungsbedingungen erfolgen. Etwas anderes gilt nur, soweit es sich beim Nomad um einen Verbraucher handelt, der gemäß untenstehender Widerrufsbelehrung zum Widerruf berechtigt ist.`,
  },
  {
    title: `6. ENTGELTE UND ZAHLUNGSABWICKLUNG`,
    description: `6.1 Die Entgelte für die Miete der Workspaces sind auf der TWIMYO-Plattform jeweils inklusive gesetzlicher Umsatzsteuer in Höhe von 19 % angegeben. Die Mietentgelte für Workspaces sind auf der TWIMYO-Plattform entweder pro Stunde oder pro Tag angegeben.  
    6.2 Für die Bereitstellung und die Nutzung der TWIMYO-Plattform erhebt TWIMYO vom Local TWIMYO-Service Gebühren in Höhe zwischen 10% und 20% der vom Nomad zu entrichtende Entgelte einer Buchung über die TWIMYO-Plattform. Die TWIMYO-Service Gebühren werden mit Zahlung des Entgelts durch den Nomad an den Local fällig und durch TWIMYO bzw. den Zahlungsdienstleister einbehalten. Über die TWIMYO Service Gebühren stellt TWIMYO dem Local eine Rechnung.
    
    6.3 Die Abwicklung der Zahlungen in Zusammenhang mit den über die TWIMYO-Plattform abgeschlossenen Verträgen erfolgt über den Zahlungsdienstleister Stripe.
    
    a) Kunden haben zur Eingehung eines Vertrags über die TWIMYO-Plattform bei Stripe einen Account zu erstellen und ein gültiges Zahlungsmittel zu hinterlegen. Die Nutzungsbedingungen des Stripe sind unter folgendem Link verfügbar: https://stripe.com/en-de/legal. 
    
    b) Der Nomad ermächtigt Stripe die fälligen Entgelte für die Miete sowie die TWIMYO-Service Gebühren vom Nomad einzuziehen. Die Entgelte für die Miete werden dem User direkt im Anschluss an die Mietzeit belastet. Storniert der Nomad eine Buchung, werden dem Nomad die in den Stornierungsbedingungen genannten Entgelte in Rechnung gestellt. Stripe wird dem Nomad nach Abschluss der Transaktion eine Rechnung ausstellen.
    
    c) Die dem Nomad belasteten Entgelte werden dem Local üblicherweise innerhalb des Kalendermonats, in dem das Entgelt vom Nomad eingezogen wurde, abzüglich der TWIMYO-Service Gebühren auf das von ihm bei Stripe angegebene Zahlungsmittel ausbezahlt. Sollte der Einzug des Entgelts vom Nomad innerhalb der letzten 7 Tage eines Kalendermonats stattgefunden haben, kann die Auszahlung an den Local am Ende des darauffolgenden Kalendermonats erfolgen.
    
    d) Local und Nomad erkennen an, dass die Zahlungsansprüche in Zusammenhang mit dem Mietverhältnis ausschließlich im Verhältnis zwischen Nomad und Local bestehen und TWIMYO nicht für Zahlung und/oder Rückgewähr der Entgelte einsteht oder haftet.`,
  },
  {
    title: `7. LAUFZEITEN, BEENDIGUNG DES VERTRAGS`,
    description: `7.1 Das Vertragsverhältnis über die Nutzung der TWIMYO-Plattform mittels Nutzerkonto und zugehörigem Nutzerprofil wird auf unbestimmte Zeit geschlossen. Beide Seiten können das Vertragsverhältnis mit einer Frist von einem Monat zum Monatsende durch Kündigung in Textform und über die Kündigungsfunktion im Nutzerkonto der TWIMYO-Plattform beenden. Frühestens nach Ablauf Nach der Beendigung des Vertrags sperrt TWIMYO den Zugang zu dem Nutzerkonto des Kunden und löscht das vom Kunden angelegte Nutzerprofil sowie die vom Nutzer hinterlegten Inhalte und Daten im gesetzlich erforderlichen bzw. zulässigen Maße. 

    7.2 Das Recht zur Kündigung aus wichtigem Grund bleibt von der vorstehenden Regelung unberührt. Beide Parteien sind bei Vorliegen eines wichtigen Grunds berechtigt, das Vertragsverhältnis jederzeit ohne Einhaltung von Frist zu kündigen. Ein wichtiger Grund liegt für TWIMYO insbesondere vor, wenn
    
    i) der Kunde gesetzliche Vorschriften nicht einhält;
    ii) der Kunde gegen seine vertraglichen Pflichten aus diesen AGB verstößt;
    iii) der Kunde einen oder mehrere andere Kunden schädigt; oder
    iv) der Kunde für Vereinigungen oder Gemeinschaften, oder deren Aktivitäten oder Methoden wirbt, die von Sicherheits- oder Jugendschutzbehörden beobachtet werden.`,
  },
  {
    title: `8. VERANTWORTLICHKEITEN UND VERFÜGBARKEIT`,
    description: `8.1 TWIMYO übernimmt keine Verantwortung für die von den Kunden der TWIMYO-Plattform bereitgestellten Inserate, Inhalte, Daten oder Informationen sowie für Inhalte auf verlinkten externen Websites. TWIMYO gewährleistet insbesondere nicht, dass diese Inhalte wahr sind, einen bestimmten Zweck erfüllen oder einem solchen Zweck dienen können.

    8.2 TWIMYO stellt den Kunden lediglich die TWIMYO-Plattform mit den dort von TWIMYO angebotenen Inseraten, Leistungen und Inhalten zur Verfügung. An der etwaigen Kommunikation von Kunden untereinander sowie von Kunden untereinander ausgetauschten Informationen beteiligt sich TWIMYO grundsätzlich inhaltlich nicht. Sofern Kunden über oder unter Verwendung der TWIMYO-Plattform untereinander Verträge schließen, ist TWIMYO hieran nicht beteiligt und wird nicht Vertragspartner. Ausschließlich die Kunden sind für die Erfüllung und Abwicklung der untereinander geschlossenen Verträge verantwortlich, TWIMYO haftet insbesondere nicht für etwaige Pflichtverletzungen der Nutzer aus diesen untereinander geschlossenen Verträgen. 
    
    8.3 Hinsichtlich Inserate, Daten, Informationen oder sonstigen Inhalten der Kunden, die aufgrund der Einstellungen des Kunden oder gemäß dieser AGB oder Datenschutzbestimmungen von TWIMYO öffentlich verfügbar sind, trifft TWIMYO keine Verpflichtung, eine etwaige Speicherung solcher Daten, Informationen oder sonstigen Inhalten des Nutzers zu unterbinden. 
    
    8.4 Der Kunde erkennt an, dass eine 100-prozentige Verfügbarkeit der TWIMYO- Plattform nicht gewährleistet werden kann. TWIMYO bemüht sich jedoch, die TWIMYO-Plattform möglichst unterbrechungsfrei verfügbar zu halten. Insbesondere notwendige Wartungs- oder Instandhaltungsarbeiten, Sicherheitsbelange (z.B. Cyber Attacks), Kapazitätsengpässe oder -überschreitungen oder Ereignisse, die außerhalb der Sphäre von TWIMYO liegen (z.B. Stromausfälle etc.), können zu Störungen oder vorübergehenden Ausfällen der TWIMYO-Plattform führen.`,
  },
  {
    title: `B)BESONDERE BESTIMMUNGEN FÜR Local`,
    description: `1. Der Local räumt TWIMYO das Recht ein, die vom Local hochgeladenen und veröffentlichten Inhalte auf der TWIMYO-Plattform zu nutzen. Der Local garantiert, dass er das vorgenannte Nutzungsrecht einräumen kann und stellt TWIMYO von etwaigen Ansprüchen Dritter in diesem Zusammenhang auf erstes Anfordern frei.

    2. Die Einstellung eines Inserats ist als Aufforderung an Nomads zu verstehen, ein auf den Abschluss eines Mietvertrags gerichtetes Angebot abzugeben. Nimmt der Local ein solches Angebot an, kommt ein verbindlicher Vertrag mit dem Nomad zu den in dem Inserat festgelegten Bestimmungen zustande. Ein Vertrag kommt zwischen Nomad und dem Local zustande.
    
    3. Local sind verpflichtet,
    
    a) ausschließlich wahre und nicht irreführende Angaben zu machen sowie Klarnamen, die Angaben zu seiner Person oder seinem Unternehmen ggf. mit Rechtsform sowie entsprechend der registerrechtlichen Eintragung und keine Pseudonyme oder Künstlernamen zu verwenden;
    b) hinsichtlich des Workspace vollständige (d.h. insb. alle in der TWIMYO-Plattform abgefragten Informationen zum Service) und wahrheitsgemäße Angaben zu machen und alle Mängel, Einschränkungen und für den Nomad relevanten Umstände offenzulegen;
    c) bei der Nutzung der TWIMYO-Plattform und sämtlicher ihrer Anwendungen und Inhalte geltendes Recht sowie sämtliche Rechte Dritter zu beachten. Es ist dem Local insbesondere Folgendes untersagt:
    oVerwendung beleidigender oder verleumderischer Inhalte; 
    oVerwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger oder Jugendschutzgesetze verletzende Inhalte; 
    ounzumutbare Belästigungen anderer Nutzer, insbesondere durch Spam (vgl. § 7 Gesetz gegen den unlauteren Wettbewerb UWG);
    oVerwendung von gesetzlich (z.B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu berechtigt zu sein, oder Bewerbung, Angebot und/oder Vertrieb von gesetzlich geschützten Waren oder Dienstleistungen, ebenfalls ohne dazu berechtigt zu sein; oder
    oVornahme oder Förderung wettbewerbswidriger Handlungen, einschließlich progressiver Kundenwerbung (wie Ketten-, Schneeball- oder Pyramidensysteme);
    d) sicherzustellen, dass die öffentliche Wiedergabe eines von ihm in seinem Nutzerprofil verwendeten Profilfotos nach geltendem Recht erlaubt ist und ihm alle hierfür notwendigen Nutzungsrechte zustehen.
    
    4. Soweit der Local einen Nomad über die auf der TWIMYO-Plattform bereitgestellten Bewertungsfunktion bewertet, stellt die Bewertung die subjektive Darstellung des jeweiligen Local dar. Der Local ist verpflichtet in den Bewertungen ausschließlich wahre Angaben zu machen. Zudem dürfen Bewertungen weder beleidigend noch wahrheitswidrig diskreditierend sein. Bewertungen werden vor Veröffentlichung durch TWIMYO nicht auf Fairness und Richtigkeit geprüft. Sollte es über eine Bewertung Unstimmigkeiten geben, wird TWIMYO versuchen, die Unstimmigkeiten aufzulösen und eine Einigung zu erzielen. Zudem behält sich TWIMYO das Recht vor, Bewertungen zu löschen, insb. im Fall von Beleidigungen oder unsachgemäßen Darstellungen.
    
    5. TWIMYO ist nicht verpflichtet, vom Nutzer bereitgestellte Daten oder Informationen anderen Nutzern zur Verfügung zu stellen, wenn diese Daten oder Informationen nicht im Einklang mit den unmittelbar vorstehenden Regelungen stehen. TWIMYO ist außerdem berechtigt, solche nicht den AGB in Einklang stehenden Daten oder Informationen ohne vorherige Ankündigung gegenüber dem Nutzer zu entfernen.`,
  },
  {
    title: `BESONDERE BESTIMMUNGEN FÜR Nomad`,
    description: `1. Die Buchung über TWIMYO-Plattform stellt ein Angebot an den Local dar, einen Mietvertrag zu den im Inserat genannten Bestimmungen einzugehen, insb. Preis, Mietzeitpunkt, -objekt und -dauer. Nimmt der Local, oder ein von ihm freigegebener automatisierter Prozess dieses Angebot an, kommt zwischen Local und Nomad ein Mietvertrag zustande. 

    2. Wenn Nomads nach dem vorgesehenen Check-out-Zeitpunkt weiterhin in den Büroräumen verbleiben, hat der Local das Recht, Sie in Übereinstimmung mit geltendem Recht zum Verlassen der Büroräume aufzufordern, einschließlich der Geltendmachung von Mehrkosten, die dem Local durch Ihren längeren Aufenthalt entstehen.“
    
    3. Beschädigt der Nomad den Workspace in einer von ihm zu vertretende Weise, hat eine über die vertragsgemäß zu erwartende Verschlechterung des Workspace zu vertreten oder hinterlässt der Nomad den Workspace in verschmutztem oder verunreinigtem Zustand, so ist er dem Local zum Ersatz des entstanden Schadens (einschließlich Ersatz für den Aufwand für die Reinigung durch den Local oder durch die von ihm eingesetzten Dritten) verpflichtet.
    
    4. Soweit Nomad die über die TWIMYO-Plattform gemieteten Workspaces und/oder Local über die auf der TWIMYO-Plattform bereitgestellten Bewertungsfunktion bewerten, stellt die Bewertung die subjektive Darstellung des jeweiligen Kunden dar. Der Nomad ist verpflichtet in den Bewertungen ausschließlich wahre Angaben zu machen. Zudem dürfen Bewertungen weder beleidigend noch wahrheitswidrig diskreditierend sein. Bewertungen werden vor Veröffentlichung durch TWIMYO nicht auf Fairness und Richtigkeit geprüft. Sollte es über eine Bewertung Unstimmigkeiten geben, wird TWIMYO versuchen, die Unstimmigkeiten aufzulösen und eine Einigung zu erzielen. Zudem behält sich TWIMYO das Recht vor, Bewertungen zu löschen, insb. im Fall von Beleidigungen oder unsachgemäßen Darstellungen.`,
  },
  {
    title: `D) WIDERRUFSBELEHRUNG FÜR VERBRAUCHER UND STREITBEILEGUNG`,
  },
  {
    title: `E) SCHLUSSBESTIMMUNGEN `,
    description: `1. DATENSCHUTZKLAUSEL

    Personenbezogene Daten aus dem Vertrag werden nur im Einklang mit den Datenschutzbestimmungen genutzt. 
    
    2. GEHEIMHALTUNG
    
    Der Kunde stellt sicher, dass sämtliche ihm im Zusammenhang mit der Geschäftsbeziehung mit TWIMYO bekannt gewordenen oder übermittelten vertraulichen Informationen (Geschäfts- und Betriebsgeheimnisse) streng geheim gehalten werden. Im Gegenzug stellt TWIMYO sicher, dass sämtliche vom Kunden als vertraulichen eingestuften Informationen ebenfalls streng vertraulich behandelt werden und an Dritte nur weitergeben werden, sofern und soweit dies für die bestellten Lieferungen oder zu erbringenden Leistungen durch TWIMYO erforderlich ist. 
    
    3. GERICHTSSTAND
    
    Ausschließlicher Gerichtsstand für Streitigkeiten im Zusammenhang mit den zwischen TWIMYO und dem Kunden geschlossenen Verträgen ist, soweit gesetzlich zulässig, München. Unbeschadet dessen, bleibt TWIMYO zur Erhebung der Klage am allgemeinen Gerichtsstand des Kunden berechtigt.
    
    4. SONSTIGE BESTIMMUNGEN
    
    4.1 Die zwischen den Kunden und TWIMYO geschlossenen Verträge unterliegen ausschließlich dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechtsübereinkommens (CISG).
    
    4.2 Die Bestimmungen dieser AGB bleiben im Zweifel auch bei rechtlicher Unwirksamkeit einzelner Bestimmungen in ihren übrigen Teilen verbindlich. Die Parteien verpflichten sich, unwirksame Bestimmungen durch Regelungen zu ersetzen, die dem angestrebten wirtschaftlichen Erfolg möglichst nahekommen. Entsprechendes gilt für eventuell bestehende Vertragslücken.
    
    4.3 Änderungen oder Ergänzungen dieser AGB sowie bestätigter Aufträge bedürfen der Schriftform. Dies gilt auch für etwaige Änderungen dieser Schriftformklausel.`,
  },

];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
          whiteSpace: 'pre-line'
        }}
      >
        {title}
      </Typography>
      <Typography align={"justify"} sx={{whiteSpace: 'pre-line'}} component={'p'} color={'#777E91'}>
        {description}
      </Typography>
    </Box>
  );
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
