import React, { useState } from "react";
// @mui
import { Grid, Container, Typography, Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FaqContent from "./HaveFaqContent";
import HaveFaqContent from "./HaveFaqContent";

// ----------------------------------------------------------------------

export default function HaveSpaceFaqPage() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(t('FAQ_General'));
  const [index, setIndex] = useState(0);
  const categories = [
    `${t('FAQ_General')}`,
    "Compliance",
  ];
  const [items, setItems] = useState([
    {
      title: `${t('FAQ_Local_Advantages_Headline')}`,
      /* title: "Vorteile, wenn due deine Workspace bei twimyo anbieten", */
      subtitle: `${t('FAQ_Local_Advantages')}

      ${t('FAQ_Local_Advantages2')}`,
    },
    {
      title: `${t('FAQ_Local_Service_Headline')}`,
      /* title: "Was beinhaltet unser Service?", */
      subtitle: `${t('FAQ_Local_Service')}`,
    },
    {
      title: `${t('FAQ_Local_Cost_Headline')}`,
      /* title: "Wieviel kostet es?", */
      subtitle: `${t('FAQ_Local_Cost')}`,
    },
    {
      title: `${t('FAQ_Local_Workspace_Headline')}`,
      /* title: "Was ist ein Workplace?", */
      subtitle: `${t('FAQ_Local_Workspace')}

      ${t('FAQ_Local_Workspace2')}`,
    },
    {
      title: `${t('FAQ_Local_Local_Headline')}`,
      /* title: "Was ist ein Local?", */
      subtitle: `${t('FAQ_Local_Local')}`,
    },
    {
      title: `${t('FAQ_Local_Traveler_Headline')}`,
      /* title: "Wieviel kostet es?", */
      subtitle: `${t('FAQ_Local_Traveler')}`,
    },
  ])

  const handleChangeCategory = (event, newValue) => {
    setSelected(newValue);
    if (newValue === `${t('FAQ_General')}`) {
      setItems([
        {
          title: `${t('FAQ_Local_Advantages_Headline')}`,
          /* title: "Vorteile, wenn due deine Workspace bei twimyo anbieten", */
          subtitle: `${t('FAQ_Local_Advantages')}
    
          ${t('FAQ_Local_Advantages2')}`,
        },
        {
          title: `${t('FAQ_Local_Service_Headline')}`,
          /* title: "Was beinhaltet unser Service?", */
          subtitle: `${t('FAQ_Local_Service')}`,
        },
        {
          title: `${t('FAQ_Local_Cost_Headline')}`,
          /* title: "Wieviel kostet es?", */
          subtitle: `${t('FAQ_Local_Cost')}`,
        },
        {
          title: `${t('FAQ_Local_Workspace_Headline')}`,
          /* title: "Was ist ein Workplace?", */
          subtitle: `${t('FAQ_Local_Workspace')}
    
          ${t('FAQ_Local_Workspace2')}`,
        },
        {
          title: `${t('FAQ_Local_Local_Headline')}`,
          /* title: "Was ist ein Local?", */
          subtitle: `${t('FAQ_Local_Local')}`,
        },
        {
          title: `${t('FAQ_Local_Traveler_Headline')}`,
          /* title: "Wieviel kostet es?", */
          subtitle: `${t('FAQ_Local_Traveler')}`,
        },
      ]);
    } else {
      setItems([
        {
          title: `${t('FAQ_Compliance1')}`,
          subtitle: `${t('FAQ_Compliance2')}`,
        },
        {
          title: `${t('FAQ_Compliance3')}`,
          subtitle: `${t('FAQ_Compliance4')}
          
          ${t('FAQ_Compliance4_1')}`,
        },
        {
          title: `${t('FAQ_Compliance5')}`,
          subtitle: `${t('FAQ_Compliance6')}`,
        },
        {
          title: `${t('FAQ_Compliance7')}`,
          subtitle: `${t('FAQ_Compliance8')}`,
        },
        {
          title: `${t('FAQ_Compliance9')}`,
          subtitle: `${t('FAQ_Compliance10')}`,
        },
        {
          title: `${t('FAQ_Compliance11')}`,
          subtitle: `${t('FAQ_Compliance12')}`,
        },
      ])
    }
  };

  return (
    <Container sx={{
      marginY: "40px",
    }}>
      <Box
        container
        justifyContent="center"
        sx={{
          paddingTop: { xs: 8, md: 10 },
          paddingBottom: { xs: 5, md: 5 },
        }}
      >
        <Box display={"block"} marginBottom={8}>
          {/*         <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}> */}
          <Typography fontWeight={700} variant={"h2"} align={"center"}>
            {/* Make the world you office */}
            {t("Frequently_Asked_Questions")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "50px" }}>
          <Tabs
            value={selected}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            onChange={handleChangeCategory}
          >
            {categories.map((category, indexTab) => (
              <Tab
                sx={{
                  backgroundColor:
                    index === indexTab ? "#353945 !important" : "transparent",
                  color:
                    index === indexTab
                      ? "#FCFCFD !important"
                      : "#777E91 !important",
                  borderRadius: index === indexTab ? "15px" : "0px",
                  marginRight: "25px !important",
                  paddingX: index === indexTab ? "20px !important" : "0px !important",
                }}
                onClick={() => setIndex(indexTab)}
                key={category}
                value={category}
                label={category}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <HaveFaqContent items={items} />
        </Box>
      </Box>
    </Container>
  );
}
