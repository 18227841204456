import React, { useEffect, useState } from "react";
// Yup
import * as Yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import valid from "card-validator";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { LoadingButton } from '@mui/lab';

import Page from "../components/Page";
import Layout from "../../../layouts";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import { useGlobalContext } from "../../../stores/global";
import axios from "axios";
import config from "../../../config";

const FormSchema = Yup.object().shape({
  cardNumber: Yup
    .string()
    .test(
      "test-number",
      "Credit card number is invalid",
      (value) => valid.number(value).isValid
    ),
  name: Yup.string().trim().required("Please specify your name on the card"),
  date: Yup
    .string()
    .typeError("Not a valid expiration date. Example: MM/YY")
    .max(5, "Not a valid expiration date. Example: MM/YY")
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      "Not a valid expiration date. Example: MM/YY"
    )
    .required("Expiration date is required"),
  zip: Yup
    .string()
    .trim()
    .min(2, "Please enter a valid zip")
    .max(8, "Please enter a valid zip")
    .required("Please specify the billing zip code"),
  cvv: Yup
    .string()
    .trim()
    .matches(/^\d+$/, "Not a valid CVV. Should contain only numbers")
    .min(3, "Please enter a valid cvv")
    .max(3, "Please enter a valid cvv")
    .required("Please specify your card cvv"),
});

const Billing = () => {
  const { t } = useTranslation();
  const { user, dispatch } = useGlobalContext();

  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const [isSubmitting2, setIsSubmitting2] = useState(false)
  const goToRegisterLink = () => {
    setIsSubmitting1(true)
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/stripe/create-register-link`)
      .then(async (response) => {
        axios.get(`${config.api.host}/users/${user.id}`)
          .then(async res => {
            let userdata = res.data.data.user
            dispatch({
              type: 'SET_USER',
              payload: {
                token: user.token,
                ...userdata,
              },
            });
            setIsSubmitting1(false)
            window.open(response.data.accountLink.url, '_blank')
          })
          .catch(error => {
            console.log(error)
            setIsSubmitting1(false)
            if (error.response.status === 401) {
              localStorage.removeItem('token');
              window.location.reload(false);
            }
          })
      })
      .catch((error) => {
        setIsSubmitting1(false)
        alert("Ein unerwarteter Fehler ist aufgetreten");
      });
  }

  const [completeData, setCompleteData] = useState(false)
  useEffect(() => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/stripe/create-profile-link`)
      .then(async (response) => {
        setCompleteData(true)
      })
      .catch((error) => {
        setCompleteData(false)
      });
  }, [])

  const goToProfileLink = () => {
    setIsSubmitting2(true)
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/stripe/create-profile-link`)
      .then(async (response) => {
        setIsSubmitting2(false)
        console.log(response)
        window.open(response.data.loginLink.url, '_blank');
      })
      .catch((error) => {
        setIsSubmitting2(false)
        alert("Sie müssen Ihre Daten vervollständigen");
      });
  }

  return (
    <Layout>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            {t('ChangeCard_Headline')}
            {/* Change your card data */}
          </Typography>
          <Typography
            variant={"subtitle2"}
            color={'#777E91'}
            gutterBottom
          >
            {t('ChangeCard_Explanation1')}
            {/* Please be informed that we do not share any sensitive information
            such as your bank card data with any third party agencies and
            companies. */}
          </Typography>
          <Typography variant={"subtitle2"} color={'#777E91'}>
            {t('New_ChangeCard_Explanation_1')}
            {/*  Please read our */}{" "}
            <Link color={"primary"} href={"/company-terms"} underline={"none"}>
              {t('New_ChangeCard_Explanation_2')}
            </Link>{" "}
            {t('New_ChangeCard_Explanation_3')}
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
            <Typography variant={"h6"} gutterBottom fontWeight={700}>
              {t('Billing_Stripe1')}
            </Typography>
            <LoadingButton
              onClick={goToRegisterLink}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting1}
            >
              {t('Go_Button')}
            </LoadingButton>
          </Box>
          {user.stripe_id && completeData && (
            <>
              <Box paddingY={4}>
                <Divider />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                <Typography variant={"h6"} gutterBottom fontWeight={700}>
                  {t('RecentTransactions')}
                </Typography>
                <LoadingButton
                  onClick={goToProfileLink}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting2}
                >
                  {t('Go_Button')}

                </LoadingButton>
              </Box>
            </>)
          }
        </Box>
      </Page>
    </Layout>
  );
};

export default Billing;
