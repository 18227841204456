import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: `Purpose`,
    description: `Menschen, die arbeiten können, wo sie möchten und mit wem sie möchten: Mit twimyo treiben wir die Gestaltung einer solchen Arbeitswelt maßgeblich voran. Unser passioniertes, internationales Team hat sich einem gemeinsamen Zweck verschrieben: Alle Menschen sollen sich ihren Lebens- und Arbeitsort selbstbestimmt aussuchen können.`,
  },
  {
    title: `Vision`,
    description: `Wir streben danach die Arbeit mit- und beieinander zu demokratisieren und in das nächste Zeitalter zu katapultieren. Dabei wollen wir sämtliche bereits bestehenden Ressourcen nutzen und für alle Menschen verfügbar machen. Mit twimyo verfolgen wir das Ziel das umfassendste soziale Netzwerk für NEW WORK und flexible Arbeitswelten zu gestalten.`,
  },
  {
    title: `Mission`,
    description: `twimyo bringt zusammen, was zusammengehört: Über eine Buchungs-Plattform, die Menschen und ihre Interessen im wahren Leben miteinander vernetzt. Ob engverwandte Projekte, Ideen, Know-how oder Freizeitaktivitäten: Wir geben Gleichgesinnten rund um den Globus die Möglichkeit, sich zu finden. Dabei bieten wir Reisenden eine Heimat und Daheimgebliebenen die Welt.`,
  },
  {
    title: `Team`,
    description: `Wir verstehen uns als Teil und Gestalter einer neuen Arbeitswelt. Jeder aus unserem Team zeichnet sich durch Umtriebigkeit und Entdeckerfreude aus.`,
  },
  {
    title: `Gregor Gebhardt | CEO`,
    description: `Als Gründer und Vorstandsvorsitzender der Friendsfactory AG hat er 2004 bereits das Thema Büro-Gemeinschaft in Deutschland groß gemacht. Nun geht er den nächsten Schritt – er ist heute der Erfinder und Gründer von Twimyo.`,
  },
  {
    title: `Benjamin Stromberg | COO`,
    description: ``,
  },
  {
    title: `Mauricio Parada | CTO`,
    description: ``,
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
          whiteSpace: 'pre-line'
        }}
      >
        {title}
      </Typography>
      <Typography align={"justify"} sx={{ whiteSpace: 'pre-line' }} component={'p'} color={'#777E91'}>
        {description}
      </Typography>
    </Box>
  );
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
