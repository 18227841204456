import { Link } from "react-router-dom";

import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Button, Typography } from '@mui/material';
// layouts
import Layout from "../../layouts";
// components
import { Page, Image } from '../../components';
import { MotionContainer, varBounce } from '../../components/animate';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(10, 2.5),
  [theme.breakpoints.up('sm')]: {
    height: '110vh',
  },
}));

// ----------------------------------------------------------------------

export default function PageNotFound() {

  const { t } = useTranslation();
  return (
    <Layout disabledFooter>
      <MotionContainer>
        <Page title="404 Page Not Found">
          <RootStyle>
            <Stack alignItems="center" sx={{ maxWidth: 480 }}>
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  Page Not Found!
                </Typography>
              </m.div>
              <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
                Be sure to check your spelling.
              </Typography>

              <m.div variants={varBounce().in}>
                <Image
                  alt="404"
                  src="https://zone-assets-api.vercel.app/assets/illustrations/illustration_404.svg"
                  sx={{ maxWidth: 320, my: { xs: 6, sm: 8 } }}
                />
              </m.div>

              <Button component={Link} to={'/'} variant="contained">
                {t('Platform_Maintenance_Button')}
              </Button>

            </Stack>
          </RootStyle>
        </Page>
      </MotionContainer>
    </Layout>
  );
}
