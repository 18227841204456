import PropTypes from 'prop-types';
import { Link as LinkReact } from "react-router-dom";

// @mui
import { Link, Stack, AppBar, Divider, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import { Logo } from '../../components';
//
import LanguagePopover from '../LanguagePopover';
import { ToolbarStyle, ToolbarShadowStyle } from './HeaderToolbarStyle';

// ----------------------------------------------------------------------

HeaderSimple.propTypes = {
  transparent: PropTypes.bool,
};

export default function HeaderSimple({ transparent }) {
  const isScrolling = useOffSetTop(96);

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle disableGutters transparent={transparent} scrolling={isScrolling} sx={{ px: 2 }}>
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo onDark={transparent && !isScrolling} />

          <Stack
            direction="row"
            alignItems="center"
            divider={<Divider orientation="vertical" sx={{ height: 24 }} />}
            spacing={2.5}
          >
            <LanguagePopover
              sx={{
                ...(isScrolling && { color: 'text.primary' }),
              }}
            />

            <Link
              to={"/contact-us"}
              component={LinkReact}
              color="inherit"
              variant="body2"
              sx={{
                fontWeight: 'fontWeightMedium',
                ...(isScrolling && { color: 'text.primary' }),
              }}
            >
              Support
            </Link>
          </Stack>
        </Container>
      </ToolbarStyle>

      {isScrolling && <ToolbarShadowStyle />}
    </AppBar>
  );
}
