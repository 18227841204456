import React from 'react';
import Box from '@mui/material/Box';
import Container from '../../pages/companyInformation/components/Container';
import { Content, Footer, Headline } from './components';
import Layout from "../../layouts";
import { Page } from "../../components";

const Faq = () => {
  return (
    <Layout>
      <Page title="Company Terms">
      <Box>
        <Box
          sx={{
            backgroundColor: '#f7faff',
            backgroundImage: `linear-gradient(120deg, #ffffff 0%, #f7faff 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container>
            <Headline />
          </Container>
        </Box>
        <Container maxWidth={800}>
          <Content />
        </Container>
      </Box>
      </Page>
    </Layout>
  );
};

export default Faq;
