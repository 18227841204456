import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Container from "./Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../stores/global";
import { /* Chip, */ Chip, Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import config from "../../../config";
import { tag_user } from "../../../mocks/tag_user";
import SVGIcon from "../../../components/icons.element";
import moment from "moment";

//images
import house from "../../../assets/images/house.svg";
import comment from "../../../assets/images/comment.svg";
import route from "../../../assets/images/route.svg";
const DescriptionUser = () => {
  const { t } = useTranslation();
  //get params id from url
  const { id } = useParams();

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const theme = useTheme();

  const [userData, setUserData] = useState({});

  const [tags, setTags] = useState([])

  const navigate = useNavigate();
  //get user by id with useEffect
  useEffect(() => {
    tag_user.map((item) => {
      item.category = t(item.category);
      item.name = t(item.name);
    });
    axios.get(`${config.api.host}/users/${id}`).then(res => {
      setUserData(res.data.data.user);
      let user = res.data.data.user;
      let tags = tag_user.filter((item) => (user.tag_user || "").includes(item.id));
      setTags(tags);
    });
  }, []);

  const [customTagsUser, setCustomTagsUser] = useState([])

  const getCustomTagsUser = () => {
    axios.get(`${config.api.host}/taglist/new_tag_user/${id}`)
      .then(async res => {
        //filter by selected == true
        let tags = res.data.tagList.filter((item) => item.selected == true);
        setCustomTagsUser(tags)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getCustomTagsUser();
  }, [userData])

  return (
    <Box paddingTop={4} paddingBottom={0} paddingLeft={6}>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          fontWeight={600}
          sx={{ color: "#777E91" }}
        >
          {t("About")} {userData?.first_name}
        </Typography>
      </Box>

      <Box marginBottom={4}>
        <Typography
          variant="body2"
          color="#777E90"
        >
          {userData?.description}
        </Typography>
      </Box>
      <Box marginBottom={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: { xs: "50%", md: "40%", lg: "30%" } }}>
          <img src={house} alt="house" width="20px" height="20px" />
          <Typography marginLeft={2} color="#777E90">
            Workspace in
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={500} color="#23262F">
            {userData?.city}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: { xs: "50%", md: "40%", lg: "30%" } }}>
          <img src={comment} alt="comment" width="20px" height="20px" />
          <Typography marginLeft={2} color="#777E90">
            {t("Speaks")}
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={500} color="#23262F">
            {userData?.languages ? userData?.languages.split(",").map((item, index) => {
              return <span key={index}>{(index !== 0 ? ", " : "") + t(item)}</span>
            }) : "Deutsch"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
        {tags.map((item, index) => (
          <Button
            onClick={() => { navigate(`/users/${item.id}`) }}
            key={index}
            sx={{
              backgroundColor:
                item.category === t("Category_FoodAndDrinks") ?
                  "#EAAA08" :
                  item.category === t("Category_Recreation") ?
                    "#669F2A" :
                    item.category === t("Category_Sports") ?
                      "#06AED4" :
                      "#4E5BA6",
              color: "#FCFCFD",
              borderRadius: "360px",
              padding: "8px 15px",
            }}>
            {item.name}
          </Button>
        ))}
        {customTagsUser.map((item, index) => (
          <Button
            key={index}
            sx={{
              backgroundColor:
                item.category_name === "Category_FoodAndDrinks" ?
                  "#EAAA08" :
                  item.category_name === "Category_Recreation" ?
                    "#669F2A" :
                    item.category_name === "Category_Sports" ?
                      "#06AED4" :
                      "#4E5BA6",
              color: "#FCFCFD",
              borderRadius: "360px",
              padding: "8px 15px",
            }}>
            {item.tag_name}
          </Button>
        ))}
      </Box>
    </Box >

  );
};

export default DescriptionUser;
