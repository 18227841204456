import { NumericFormat } from 'react-number-format';
import React, { useEffect, useState } from "react";
// layouts
import Layout from "../../layouts";
// components
import {
  Breadcrumbs,
  ErrorScreen,
  /* FavoriteButton, */
  Iconify,
  Image,
  LoadingScreen,
  Page,
  TextMaxLine,
  TourItemSkeleton,
} from "../../components";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  /*Divider  , Grid */
  Grid,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRequest } from "../../hooks";
import { fCurrency } from "../../utils/formatNumber";
import config from "../../config";
import { Link, useNavigate } from "react-router-dom";
import deleteIcon from "@iconify/icons-carbon/delete";
import editIcon from "@iconify/icons-carbon/edit";
import { Delete } from "./delete/Delete";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../stores/global";
import verified from "../../assets/images/check.svg";
import SVGIcon from "../../components/icons.element";
import directionStraightRight from "@iconify/icons-carbon/chevron-left";
import plusSquare from "../../assets/images/plus-square.svg";
import Illustration_Card from "../../assets/images/Illustration_Card.svg";
import icon_pay from "../../assets/images/icon_pay.svg";
import mail01 from "../../assets/images/mail01.svg";
import axios from "axios";

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingTop: 96,
  },
}));

export default function MeineWorkspaces() {
  const { t } = useTranslation();
  //get user
  const { user, dispatch } = useGlobalContext();

  const [workplaces, setWorkplaces] = useState([]);
  const {
    data: listWorkplaces = [],
    error,
    mutate,
  } = useRequest({
    url: `/office/getAllWorkplacesWithOutOffice`,
  });

  useEffect(() => {
    if (listWorkplaces?.data) setWorkplaces(listWorkplaces.data);
  }, [listWorkplaces]);

  const [bankName, setBankName] = useState("");
  useEffect(() => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/users/billing/account`)
      .then((res) => {
        console.log("res_bank",res);
        setBankName(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const goToRegisterLink = () => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/stripe/create-register-link`)
      .then(async (response) => {
        axios
          .get(`${config.api.host}/users/${user.id}`)
          .then(async (res) => {
            let userdata = res.data.data.user;
            dispatch({
              type: "SET_USER",
              payload: {
                token: user.token,
                ...userdata,
              },
            });
            window.location.href = response.data.accountLink.url;
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
              localStorage.removeItem("token");
              window.location.reload(false);
            }
          });
      })
      .catch((error) => {
        alert("Ein unerwarteter Fehler ist aufgetreten");
      });
  };

  if (error) {
    return <ErrorScreen />;
  }

  if (!listWorkplaces) {
    return <LoadingScreen />;
  }
  return (
    <Layout>
      <Page title="Workspaces">
        <RootStyle>
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  window.history.back();
                }}
                startIcon={
                  <Iconify
                    icon={directionStraightRight}
                    sx={{ width: 16, height: 16, color: "black" }}
                  />
                }
                sx={{
                  display: { xs: "none", md: "inline-flex" },
                  color: "black",
                  fontWeight: "700",
                  border: "2px solid #E6E8EC",
                  borderRadius: "90px",
                  textTransform: "none",
                }}
              >
                {t("Button_GoBack")}
              </Button>
              <Breadcrumbs
                sx={{ color: "#B1B5C4", fontWeight: "700" }}
                links={[
                  { name: "Home", href: "/" },
                  {
                    name: `${t("YourWorkspaces_Overview")}`,
                    href: "/host/workspaces",
                  },
                ]}
              />
            </Box>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              sx={{
                py: { xs: 3, md: 5 },
                ml: 1,
              }}
            >
              <Box marginBottom={1}>
                <Typography fontWeight={800} variant={"h2"} align={"left"}>
                  {t("YourWorkspaces_Overview")}
                  {/* Deine Workspaces */}
                </Typography>
              </Box>
            </Grid>

            <Box
              sx={{
                display: "grid",
                rowGap: { xs: 4, md: 5 },
                columnGap: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
                marginBottom: 3,
              }}
            >
              <AddItem />
              {(!workplaces ? [...Array(6)] : workplaces).map(
                (workplace, index) => {
                  return workplace?.id ? (
                    <WorkplaceItem
                      key={workplace.id}
                      workplace={workplace}
                      mutate={mutate}
                    />
                  ) : (
                    <TourItemSkeleton key={index} />
                  );
                }
              )}
            </Box>

            {/*create alert warning if user has no stripe_id*/}
            {!user?.stripe_id ? (
              <Grid
                container
                spacing={3}
                justifyContent="center"
                sx={{
                  py: { xs: 8, md: 10 },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F4F5F6",
                    padding: 4,
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <img src={Illustration_Card} alt="Illustration_Card" />
                    </Box>
                    <Card sx={{ width: "100%", borderRadius: "15px" }}>
                      <Box
                        sx={{
                          mb: 3,
                          p: 3,
                        }}
                      >
                        <Typography
                          fontWeight={800}
                          variant={"h5"}
                          align={"left"}
                        >
                          Zahlungsmethode
                        </Typography>
                        <Typography
                          fontWeight={400}
                          align={"left"}
                          sx={{ color: "#B1B5C4" }}
                        >
                          Damit Ihre Arbeitsbereiche angezeigt werden, müssen
                          Sie Ihr Stripe-Konto aktivieren, um Zahlungen zu
                          erhalten
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 2,
                          p: 2,
                          m: 2,
                          border: "1px solid #E6E8EC",
                          borderRadius: "15px",
                        }}
                      >
                        <img src={icon_pay} alt="plusSquare" />
                        <Box>
                          <Button
                            variant="outlined"
                            onClick={goToRegisterLink}
                            sx={{
                              color: "black",
                              backgroundColor: "#FFF",
                              fontWeight: "700",
                              border: "2px solid #E6E8EC",
                            }}
                          >
                            Stripe Konto verknüpfen
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid
                container
                spacing={3}
                width="100% !important"
                justifyContent="center"
                sx={{
                  py: { xs: 8, md: 10 },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F4F5F6",
                    padding: 4,
                    borderRadius: "15px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <img src={Illustration_Card} alt="Illustration_Card" />
                    </Box>
                    <Card sx={{ width: "100%", borderRadius: "15px" }}>
                      <Box
                        sx={{
                          p: 3,
                        }}
                      >
                        <Typography
                          fontWeight={800}
                          variant={"h5"}
                          align={"left"}
                        >
                          Zahlungsmethode
                        </Typography>
                        <Typography
                          fontWeight={400}
                          align={"left"}
                          sx={{ color: "#B1B5C4" }}
                        >
                          {t("PaymentInfo_YourWorkspaces")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: { xs: "block", md: "flex" },
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          gap: 2,
                          p: 2,
                          m: 2,
                          border: "1px solid #E6E8EC",
                          borderRadius: "15px",
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "block", md: "flex" },
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 2,
                          }}
                        >
                          <img src={icon_pay} alt="plusSquare" />
                          <Box>
                            <Typography fontWeight={800} align={"left"}>
                              Bank: {bankName}
                            </Typography>
                            <Typography
                              fontWeight={400}
                              align={"left"}
                              display="flex"
                              alignItems="center"
                              gap={1}
                              sx={{ color: "#B1B5C4", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                            >
                              <img src={mail01} alt="plusSquare" />
                              {user?.email}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Button
                            variant="outlined"
                            target="_blank"
                            onClick={goToRegisterLink}
                            sx={{
                              color: "black",
                              backgroundColor: "#FFF",
                              fontWeight: "700",
                              border: "2px solid #E6E8EC",
                            }}
                          >
                            Anpassen
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Box>
              </Grid>
            )}
            <>
              <Pagination
                count={1}
                color="primary"
                size="large"
                sx={{
                  pt: 10,
                  pb: { xs: 10, md: 15 },
                  "& .MuiPagination-ul": {
                    justifyContent: "center",
                  },
                }}
              />
            </>
          </Container>
        </RootStyle>
      </Page>
    </Layout>
  );
}

function WorkplaceItem({ workplace, mutate }) {
  const { t } = useTranslation();
  const { id, title, address, price, priceSale = 0, images } = workplace;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    mutate();
    setOpen(false);
  };
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pt: 1.5,
          pl: 2,
          pr: 1.5,
          top: 0,
          width: 1,
          zIndex: 9,
          position: "absolute",
        }}
      >
        <Stack
          spacing={0.5}
          direction="row"
          sx={{
            px: 1,
            borderRadius: 0.75,
            typography: "subtitle2",
            bgcolor: "#FD5854",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          }}
        >
          {priceSale > 0 && (
            <Box
              sx={{
                color: "grey.500",
                textDecoration: "line-through",
                mr: 0.5,
              }}
            >
              €{fCurrency(priceSale)} / {t("Day")}
            </Box>
          )}
          €{fCurrency(price)}
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "common.white" }}
          >
            / {t("Day")}
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", gap: "10%" }}>
          <Box
            component={Link}
            to={`/host/workspaces/update/${id}`}
            onClick={() => handleOpen()}
            sx={{
              paddingX: "5px",
              paddingTop: "5px",
              bgcolor: "#FFCC00",
              borderRadius: 0.75,
              cursor: "pointer",
            }}
          >
            <Iconify
              color={"white"}
              icon={editIcon}
              sx={{ width: 20, height: 20 }}
            />
          </Box>
          <Box
            onClick={() => handleOpen()}
            sx={{
              paddingX: "5px",
              paddingTop: "5px",
              bgcolor: "red",
              borderRadius: 0.75,
              cursor: "pointer",
            }}
          >
            <Iconify
              color={"white"}
              icon={deleteIcon}
              sx={{ width: 20, height: 20 }}
            />
          </Box>
        </Box>
      </Stack>
      <Link to={`/workspace/${id}`}>
        <Image
          alt={title}
          src={config.api.host + (images[0].data || images[0])}
          ratio="4/3"
        />
      </Link>
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <TextMaxLine variant="h6">{title}</TextMaxLine>
          <Typography
            variant="body2"
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            {workplace.user.first_name}{" "}
            {workplace.user.stripe_id && (
              <img src={verified} alt="verified" width="20px" />
            )}{" "}
            {/* {user.last_name} */}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Tooltip title={address.split(",")[1] + ", " + address.split(",")[2]}>
            <Typography
              variant="body3"
              sx={{ color: "#777E91" }}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {address.split(",")[1] + ", " + address.split(",")[2]}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SVGIcon type={"star"} height={18} width={18} color={"orange"} />
          <Typography variant="body2">{workplace.rating || 0}</Typography>
        </Box>
      </Box>
      <Delete open={open} handleClose={handleClose} id={id} />
    </Card>
  );
}

function AddItem() {
  const { t } = useTranslation();
  const { user } = useGlobalContext();
  const navigate = useNavigate();

  const handleClick = () => {
    if (user) {
      navigate("/host/workspaces/create");
    } else {
      navigate("/login");
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
        bgcolor: "#EAECF0",
        cursor: "pointer",
        border: "1px solid #D0D5DD",
      }}
      onClick={handleClick}
    >
      <img
        style={{ marginBottom: "15px" }}
        src={plusSquare}
        alt="add"
        width="50px"
      />
      <Typography
        textAlign={"center"}
        fontWeight={"bold"}
        sx={{ color: "#000" }}
      >
        {t("Add_Workspace1")}
        <br></br>
        {t("Add_Workspace2")}
      </Typography>
    </Card>
  );
}
