import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Stack, Paper, Button, Rating, TextField, Typography, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useGlobalContext } from '../../stores/global';
import config from '../../config';

// ----------------------------------------------------------------------

const RootStyle = styled((props) => <Paper variant="outlined" {...props} />)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  rating: Yup.mixed().required('Rating is required'),
  review: Yup.string().required('Review is required'),
});

ReviewElearningForm.propTypes = {
  onClose: PropTypes.func,
};

export default function ReviewElearningForm({ onClose, workplace, mutate }) {
  //import translate
  const { t } = useTranslation();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      rating: null,
      review: '',
    },
  });

  const { user } = useGlobalContext()
  const onSubmit = async (data) => {
    //fecth with axios
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .post(`${config.api.host}/booking/review/create`, {
        text: data.review,
        rating: Number(data.rating),
        booking_id: workplace.bookings.id,
      },
      )
      .then((response) => {
        mutate()
        reset();
        onClose();
      })
      .catch((error) => {
        alert(error);
      });

  };

  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5}>
          <div>
            <Typography variant="h3" paragraph>
              Review
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Your rating about this tour:
            </Typography>
            <Controller
              name="rating"
              control={control}
              render={({ field }) => <Rating {...field} value={Number(field.value)} />}
            />
            {Boolean(errors.rating) && (
              <FormHelperText sx={{ px: 2 }} error>
                {errors.rating?.message}
              </FormHelperText>
            )}
          </div>

          <Controller
            name="review"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                fullWidth
                rows={3}
                label="Review *"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ width: 1 }}
          >
            <Button variant="outlined" onClick={onClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Post Review
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </RootStyle>
  );
}
