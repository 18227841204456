import PropTypes from 'prop-types';
// icons
import checkmarkFilled from '@iconify/icons-carbon/checkmark-filled';
// @mui
import { Stack, Typography, Avatar, Button, Box, Paper } from '@mui/material';
// components
import { SocialsButton, RatingLabel, Iconify } from '../../../components';
import { Link } from 'react-router-dom';
import SVGIcon from "../../../components/icons.element";
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

TravelTourGuideInfo.propTypes = {
  tourGuide: PropTypes.shape({
    about: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    quotes: PropTypes.string,
    ratings: PropTypes.number,
    reviews: PropTypes.number,
    socialLinks: PropTypes.string,
    verified: PropTypes.bool,
  }),
};

export default function TravelTourGuideInfo({ tour, ratingData }) {
  const { t } = useTranslation();

  console.log("ratingData", ratingData);
  return (
    <Paper variant="outlined" sx={{ borderRadius: 2 }}>
      <Stack alignItems="center" sx={{ textAlign: 'center', p: 5 }}>
        <Box sx={{
          position: 'relative',
          color: 'inherit',
          textDecoration: 'inherit'
        }}
          component={Link} to={'/user/' + tour.data.user.id}>
          {true && (
            <>
              <Typography variant="h4">Dein Local</Typography>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  bottom: 4,
                  right: 4,
                  zIndex: 9,
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: 'background.default',
                }}
              >
                <Iconify
                  icon={checkmarkFilled}
                  sx={{
                    width: 1,
                    height: 1,
                    color: 'success.main',
                  }}
                />
              </Box>
            </>
          )}

          <Avatar src={tour.data.user.profilepicture} sx={{ width: 96, height: 96 }} />
        </Box>

        <Stack spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">
            {tour.data.user.first_name}
          </Typography>
          {/*           <RatingLabel ratings={ratingData?.data?.rating} reviews={ratingData?.data?.reviews || 0} fontSize={12} /> */}
          <Box
            component={Link} to={'/user/' + tour.data.user.id + "?rating=true"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              marginBottom: 5,
              position: 'relative',
              color: 'inherit',
              textDecoration: 'inherit'
            }}
          >
            <SVGIcon type={"star"} height={18} width={18} color={"orange"} />
            <Typography fontWeight={600}>
              {ratingData?.data?.rating}
            </Typography>
            <Typography variant="body2">
              ({ratingData?.data?.reviews} {t("Reviews")})
            </Typography>
          </Box>
        </Stack>

        <Typography variant="body2" sx={{ color: '#777E91' }}>
          {tour.data.user.description}
        </Typography>

        <Stack alignItems="center" sx={{ my: 2.5 }}>
          <SocialsButton initialColor links={{}} />
        </Stack>
      </Stack>
    </Paper >
  );
}
