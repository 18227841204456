import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography align={"justify"} sx={{ whiteSpace: 'pre-line' }} color='#777E91'>{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Content = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Ich habe einen Workplace'}
          items={[
            {
              title: `${t('FAQ_Local_Advantages_Headline')}`,
              /* title: "Vorteile, wenn due deine Workspace bei twimyo anbieten", */
              subtitle: `${t('FAQ_Local_Advantages')}

              ${t('FAQ_Local_Advantages2')}`,
            },
            {
              title: `${t('FAQ_Local_Service_Headline')}`,
              /* title: "Was beinhaltet unser Service?", */
              subtitle: `${t('FAQ_Local_Service')}`,
            },
            {
              title: `${t('FAQ_Local_Cost_Headline')}`,
              /* title: "Wieviel kostet es?", */
              subtitle:`${t('FAQ_Local_Cost')}`,
            },
            {
              title: `${t('FAQ_Local_Workspace_Headline')}`,
              /* title: "Was ist ein Workplace?", */
              subtitle:`${t('FAQ_Local_Workspace')}

              ${t('FAQ_Local_Workspace2')}`,
            },
            {
              title: `${t('FAQ_Local_Local_Headline')}`,
              /* title: "Was ist ein Local?", */
              subtitle:`${t('FAQ_Local_Local')}`,
            },
            {
              title: `${t('FAQ_Local_Traveler_Headline')}`,
              /* title: "Wieviel kostet es?", */
              subtitle:`${t('FAQ_Local_Traveler')}`,
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Ich suche einen Workplace'}
          items={[
            {
              title: "Welche Vorteile erwarten dich bei twimyo?",
              subtitle:
                "Wir geben dir die Möglichkeit Arbeit und Leben selbstbestimmt zu kombinieren und dabei inspirierende Menschen und einzigartige Orte entdecken kannst. Mit twimyo wirst du Teil einer weltweiten Community. Welche exklusiven Vorteile dich bei einer Premium Membership erwarten, erfährst du hier: contact@twimyo.com",
            },
            {
              title: "Unser Leistungsversprechen vs anderen Plattformen",
              subtitle:
                `Uns geht es um die Menschen dahinter. Ob engverwandte Projekte, Ideen, Know-how, Interessen oder Freizeitaktivitäten: Wir möchten Gleichgesinnten rund um den Globus die Möglichkeit geben, sich zu finden. 
              
              Genauso wichtig ist uns die nachhaltige Nutzung bereits bestehender Räume, anstatt neue Flächen zu erwerben, die aufwändig kuratiert werden müssen. `,
            },
            {
              title: "Was ist ein Workplace?",
              subtitle:
                `Bei einem Workspace kann es sich um jeglichen Ort handeln, an dem potenziell gearbeitet werden kann: Ob Bürotisch, Meetingraum, Studio, Garage, Café, Atelier oder Gartentisch. Wir setzen hier keine Grenzen. Ein Tisch ist dabei üblicherweise das Minimum. :) 
          
              Welche Merkmale der entsprechende Workspace aufweist und ob er deinen Bedürfnissen entspricht, erfährst du im jeweiligen Inserat.`,
            },
            {
              title: "Was ist ein Traveler?",
              subtitle:
                "Traveler sind bei twimyo alle Menschen, die mobil arbeiten können und wollen (oder müssen). Dies schließt erfahrene Co-Worker, digitale Nomaden, Home Officers, Freelancer und Urlauber mit ein.",
            },
            {
              title: "Was ist ein Local?",
              subtitle:
                `Ein Local ist die Person, die hinter einem Workspace steht. Zunächst ist sie die Urherber:in des Inserats und gleichzeitig deine Ansprechpartner:in virtuell und vor Ort. Der Local kann repräsentativ für ein Unternehmen sein, wie eine Agentur, ein Start-up, ein größerer Unternehmenstandort, aber auch Studios, Ateliers oder Geschäfte. Neben den Informationen zum Workspace haben Locals eigene Profile, in denen sie sich beschreiben können. Dadurch ist es möglich den passenden Workspace nicht nur nach materiellen Kriterien zu bestimmen. Gleichzeitig ist es uns ein Anliegen Neuankömmlinge und verwurzelte Menschen zusammenzubringen. 
          
              Übrigens kann jede Nutzer:in unserer Plattform ein Local werden, sobald sie ein Inserat veröffentlicht und dadurch ein Nebeneinkommen generieren.`,
            },
            {
              title: "Wieviel kostet es?",
              subtitle:
                "Die Preise für die tageweise Nutzung der Workspaces werden vom Local selbst festgelegt. Wir geben eine Empfehlung ab, die insbesondere auf dem Standort und der Ausstattung basiert. Soll ein Workspace für mehrere Wochen oder Monate gebucht werden, so fällt üblicherweise der Preis.",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
