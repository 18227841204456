import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, TextField, Container, Typography } from '@mui/material';
import { useState } from 'react';
import config from '../../config';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string().required('Email is required').email('That is not an email'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

export default function ElearningContactForm() {
  const { t } = useTranslation();

  const [state, setState] = useState(null);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      fullName: '',
      subject: '',
      email: '',
      message: '',
    },
  });

  const onSubmit = async (data) => {
    //send post data to /support_email
    //then reset form
    //then set state to success

    fetch(`${config.api.host}/personas/support_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setState('Email send successfully');
        reset();
      })
      .catch((error) => {
        setState('Error to send Email');
      });

  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Stack
        spacing={2}
        sx={{
          mb: 5,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography variant="h3">
          {t("Support_SecondHeadline")}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          {t("Support_Subline")}
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5} alignItems="flex-start">
          <Controller
            name="fullName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Support_FullName")}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Email_Information")}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="subject"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Support_Topic")}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="message"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                rows={4}
                label={t("Chat_Inbox")}
                error={Boolean(error)}
                helperText={error?.message}
                sx={{ pb: 2.5 }}
              />
            )}
          />
          {state && <Container maxWidth="sm" sx={{ mt: 5 }}>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
              {state}
            </Typography>
          </Container>}
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              mx: { xs: 'auto !important', md: 'unset !important' },
              background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`
            }}
          >
            {t("Support_Send")}
          </LoadingButton>
        </Stack>
      </form>
      {/*show message*/}

    </Grid>
  );
}
