const localStorage = window.localStorage;
const USER_KEY = 'twimyo_user';
const VERSION_KEY = 'twimyo_version';

export function setVersion(version) {
  window.localStorage.setItem(VERSION_KEY, JSON.stringify(version));
}

export function getVersion() {
  try {
    return JSON.parse(localStorage.getItem(VERSION_KEY) || null);
  } catch (err) {
    return 0;
  }
}

export function getUser() {
  try {
    const user = JSON.parse(localStorage.getItem(USER_KEY) || null);
    return user?.token ? (user) : undefined;
  } catch (err) {
    return undefined;
  }
}

export function setUser(data) {
  window.localStorage.setItem(USER_KEY, JSON.stringify(data));
}

export function removeUser() {
  window.localStorage.removeItem(USER_KEY);
}
