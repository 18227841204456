/* eslint-disable react/no-unescaped-entities */
import { Link, Link as LinkReact, useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
// icons
import cubeIcon from '@iconify/icons-carbon/cube';
import eventsIcon from '@iconify/icons-carbon/events';
import packageIcon from '@iconify/icons-carbon/package';
import receiptIcon from '@iconify/icons-carbon/receipt';
import calendarIcon from '@iconify/icons-carbon/calendar';
import purchaseIcon from '@iconify/icons-carbon/purchase';
import chevronLeft from '@iconify/icons-carbon/chevron-left';
// @mui
import { Typography, Stack, Avatar, Divider, Button } from '@mui/material';
// @utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import { RatingLabel, TextIconLabel, Iconify } from '../../../../components';
import imageCheckout from '../../../../assets/images/have-space.jpg';

import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../../stores/global";
import { LoadingButton } from "@mui/lab";
import config from "../../../../config";
import axios from "axios";


SidebarDetails.propTypes = {
  tour: PropTypes.shape({
    price: PropTypes.number,
    ratings: PropTypes.number,
    reviews: PropTypes.number,
    slug: PropTypes.string,
    tourGuide: PropTypes.shape({
      name: PropTypes.string,
      picture: PropTypes.string,
    }),
  }),
};

export default function SidebarDetails({ booking, setOpenDetail }) {
  const { t } = useTranslation();
  const ratings = 3.5;
  const reviews = 350;
  const price = booking.booking.price;
  console.log(booking);
  const days = -1 * moment(booking.booking.from).diff(moment(booking.booking.to), 'days') + 1;
  const { user, dispatch } = useGlobalContext();

  const [isSubmitting2, setIsSubmitting2] = useState(false)

  const goToProfileLink = () => {
    setIsSubmitting2(true)
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .get(`${config.api.host}/stripe/create-profile-link`)
      .then(async (response) => {
        setIsSubmitting2(false)
        console.log(response)
        window.location.href = response.data.loginLink.url;
      })
      .catch((error) => {
        setIsSubmitting2(false)
        alert("Sie müssen Ihre Daten vervollständigen");
      });
  }


  return (
    <Stack spacing={5}>
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Stack spacing={2}>
          <Typography variant="h5">{booking.workplace.title} - {days} {t("Days")}</Typography>
          <RatingLabel ratings={0} reviews={0} />
        </Stack>

        <Stack component={Link} to={'/user/' + booking.user.id}
          sx={{ textDecoration: 'none', color: 'text.primary' }}
          direction="row" alignItems="center" spacing={1.5}>
          <Avatar src={booking.user.profilepicture} />
          <div>
            <Typography variant="body3" sx={{ color: 'text.disabled' }}>
              Eigentum erstellt von
            </Typography>
            <Typography variant="subtitle2">  {booking.user.first_name} - {booking.user.last_name}  </Typography>
          </div>
        </Stack>
      </Stack>

      <Stack
        spacing={3}
        sx={{
          p: 5,
          borderRadius: 2,
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h5">Booking Details</Typography>

        <LineItem
          icon={<Iconify icon={calendarIcon} />}
          label="From: "
          value={fDate(booking.booking.from)}
        />
        <LineItem
          icon={<Iconify icon={calendarIcon} />}
          label="To: "
          value={fDate(booking.booking.to)}
        />
        <LineItem icon={<Iconify icon={eventsIcon} />} label={t("Guests")} value={booking.booking.amount + " guest"} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <LineItem icon={<Iconify icon={cubeIcon} />} label="Booking code" value={booking.booking.id.slice(0, 8)} />
        <LineItem icon={<Iconify icon={receiptIcon} />} label="Total" value={"€" + fCurrency(booking.booking.price)} />
        <LineItem icon={<Iconify icon={purchaseIcon} />} label="Payment method"  value={booking.paymentMethod.card.brand} />
      </Stack>

      <Stack spacing={2.5} direction={{ xs: 'column', md: 'row' }} justifyContent="center">
        <Button
          variant="outlined"
          size="large"
          color="inherit"
          startIcon={<Iconify icon={chevronLeft} />}
          component={Link}
          to={"/workspace/" + booking.workplace.id}
        >
          {setOpenDetail ? "Close" : "Go to Workspace"}
        </Button>
        <LoadingButton onClick={goToProfileLink}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting2}
          startIcon={<Iconify icon={packageIcon} />}>
          {t("Download_Invoice")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

// ----------------------------------------------------------------------

LineItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
};

function LineItem({ icon, label, value }) {
  return (
    <TextIconLabel
      icon={icon}
      value={
        <>
          {label}
          <Typography
            variant="subtitle2"
            sx={{ color: 'text.primary', flexGrow: 1, textAlign: 'right' }}
          >
            {value}
          </Typography>
        </>
      }
      sx={{
        color: 'text.disabled',
        '& svg': { mr: 1, width: 24, height: 24 },
      }}
    />
  );
}
