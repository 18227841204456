import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Autocomplete, InputAdornment, InputLabel, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
// components
import { Image, Iconify } from '../../../components';
import { InputStyle } from './TravelTourBarFilters';
import { useTranslation } from 'react-i18next';
import location from '../../../assets/images/location.svg';
import { useEffect, useState } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  width: '100%',
  '& .MuiAutocomplete-root': {
    '& .MuiFilledInput-root': {
      padding: '0 12px',
    },
  },
}));

/* const workspaceCategories = [
  { name: 'München' },
  { name: 'Berlin' },
  { name: 'Frankfurt' },
  { name: 'Wien' },
  { name: 'Salzburg' },
  { name: 'Zürich' },
  { name: 'Basel' },
];
 */
// ----------------------------------------------------------------------

export default function TravelTourFilterLocation({ setType, type }) {
  const { t } = useTranslation();

  const workspaceCategories = [
    { name: `${t('Create_Workspace_Cat1')}`, type: "desk" },
    { name: `${t('Create_Workspace_Cat3')}`, type: "office" },
    { name: `${t('Create_Workspace_Cat2')}`, type: "meetingroom" },
    { name: `${t('Create_Workspace_Cat4')}`, type: "studio" },
    { name: `${t('Create_Workspace_Cat6')}`, type: "misc" },
    { name: `${t('Create_Workspace_Cat5')}`, type: "event" },
  ];

  const changeValue = (event, value) => {
    console.log(value);
    console.log(event);
    setType(value);
  };

  const [defaultValue, setDefaultValue] = useState();
  useEffect(() => {
    let option = workspaceCategories.find((item) => item.type === type);
    setDefaultValue(option);
  }, [type]);
  return (
    <RootStyle>
      <Box display="flex" alignItems="flex-start" justifyContent="center" gap={1} sx={{ width: "100% !important" }}>
        <Box sx={{ pt: "3px" }}>
          <Image alt={"location"} src={location} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography fontSize={{ xs: "auto", xxl: "1.6rem" }} sx={{ fontWeight: 800, lineHeight: '1.1', px: "12px" }} > Location </Typography>
          <Autocomplete
            autoHighlight
            sx={{ fontSize: { xs: "auto", xxl: "1.6rem" } }}
            options={workspaceCategories}
            getOptionLabel={(option) => option.name}
            filterOptions={createFilterOptions({
              stringify: (option) => option.name + option.name,
            })}
            onChange={(event, value) => changeValue(event, value?.type || "")}
            popupIcon={""}
            renderInput={(params) => (
              <>
                {/* <InputLabel sx={{ fontWeight: "bold", color: "#000", flexShrink: 0, ml: 1, mb: 0.3 }} >
              {t('MainSearch_Where')} */}
                {/* Was möchtest du gerne tun? */}
                {/* </InputLabel> */}
                <InputStyle
                  {...params}
                  variant="filled"
                  placeholder={defaultValue ? defaultValue.name : t('MainSearch_Where2')}
                  /* placeholder={type ? type : t('MainSearch_Where2')} */
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: 'search',
                  }}
                />
              </>
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <Box component="li" {...props}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                    >
                      {part.text}
                    </Box>
                  ))}
                </Box>
              );
            }}
          />
        </Box>
      </Box>
    </RootStyle>
  );
}
