import React, { useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { syncUser } from '../../helpers/asyncActions';
import { useGlobalContext } from '../../stores/global';


export default function ProtectedRoute({
  redirectPath = '/',
  children,
}) {
  const { user } = useGlobalContext();

  useEffect(() => {
    syncUser()
  }, [])
  
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;

}
