/*create modal review with react hook form and mui*/
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField, Modal, Box, Typography } from '@mui/material';
import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGlobalContext } from '../../stores/global';
import axios from 'axios';
import config from '../../config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

const ModalReview = ({
  open,
  setOpen,
  reviewUser,
  reviewWorkplace,
  reviewBooking,
  userData
}) => {
  const { user } = useGlobalContext();
  const [rating, setRating] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    if (data.comment) {
      let body = {
        review_rating: rating,
        review_text: data.comment,
        workplace_id: reviewWorkplace,
        user_id: reviewUser,
        booking_id: reviewBooking,
      };
      console.log(body);
      if (user) {
        axios.defaults.headers.common = {
          Authorization: user.token,
        };
        axios
          .post(`${config.api.host}/users/create/review`, body)
          .then((res) => {
            console.log(res);
            if(res.data.success){
                //clear query params and close modal
                window.location.href = "/";
            }else{
                alert("You can't review this booking")
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        alert('You must be logged in to leave a review');
      }
    } else {
      alert('Please write a comment');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goToUser = () => {
    window.open(`/user/${reviewUser}`, '_blank');
  }

  const RatingStyled = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFD700',
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography sx={{ mb: 2 }} variant='h5' component='h2' gutterBottom>
          Review a Traveler <span style={{textDecoration:"underline", cursor:"pointer"}} onClick={goToUser}>({userData})</span>
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/*show rating*/}
          <Box sx={{ mb: 2 }}>
            <Typography variant='h6' component='h2' gutterBottom>
              Rating
            </Typography>
            <RatingStyled
              name='rating'
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          {/*show comment*/}
          <Box sx={{ mb: 2 }}>
            <Typography variant='h6' component='h2' gutterBottom>
              Comment
            </Typography>
            <TextField
              id='outlined-multiline-static'
              label='Comment'
              multiline
              rows={4}
              sx={{ width: '100%' }}
              variant='outlined'
              {...register('comment')}
            />
          </Box>
          {/*show button*/}
          <Box sx={{ mb: 2 }}>
            <Button
              type='submit'
              variant='contained'
              sx={{ backgroundColor: 'red', color: 'white' }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalReview;
