const config = {
    api: {
        host: "/api",
        dev: "/api"
    },
    googleAnalytics: 'G-LXEF8MBLJ4',
    linkedin: {
        clientId: '78i456csce1vgm',
        redirectUrl: 'https://twimyo.com/login',
        oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
        scope: 'r_liteprofile%20r_emailaddress',
        state: 'M24bbr9ZF4conXuKUs40oWQ9a'
    },
    microsoft: {
        clientId: '30194b14-2355-45a1-bf3d-649773e25c18',
        redirectUrl: 'https://twimyo.com/login',
        oauthUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&response_mode=query',
        scope: 'user.read',
        state: 'Fyrkpdhy1a3I8NQoSFHidyGT2'
    },
    google: {
        clientId: '223845696173-qhl9aft7sa2tvtcabh3dor53k1vtsujr.apps.googleusercontent.com'
    },
    mapbox: {
        access_token: 'pk.eyJ1IjoieHNpbmlzdGVyIiwiYSI6ImNrc3lld3FnbjJqamsycW9kY3huY21vMmQifQ.1TXpivgONs-5o7IXWU_77Q'
    },
    mix_panel: 'aa49fca7ab791950a5f45f8305de2a19',
};
export default config;

// LAYOUT
// ----------------------------------------------------------------------
export const HEADER_MOBILE_HEIGHT = 64;
export const HEADER_DESKTOP_HEIGHT = 96;
