import React, { useState } from "react";
// Yup
import * as Yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, TextField, IconButton, InputAdornment, Button, Checkbox } from '@mui/material';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LoadingButton } from '@mui/lab';
// icons
import viewIcon from '@iconify/icons-carbon/view';
import viewOff from '@iconify/icons-carbon/view-off';

import Page from "../components/Page";
import Layout from "../../../layouts";
import { Iconify } from '../../../components';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ErrorMsg from "../../../components/errors/ErrorMsg";
import SuccessMsg from "../../../components/success/SuccessMsg";
import config from "../../../config";
import { useGlobalContext } from "../../../stores/global";
import { CheckBox } from "@mui/icons-material";
import { logout } from "../../../helpers/asyncActions";

const FormSchema = Yup.object().shape({
  newPassword: Yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  repeatPassword: Yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8")
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const Security = () => {
  const { t } = useTranslation();
  const { user, dispatch } = useGlobalContext();

  const [showPassword, setShowPassword] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const onSubmit = (data) => {
    console.log(data)
    if(data.currentPassword === ""){
      data.currentPassword = "social";
    }
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .put(`${config.api.host}/users`,
        data
      )
      .then(async (res) => {
        setOpenSuccess(true);
        setMsgSuccess("Passwort erfolgreich aktualisiert");
        reset();
      })
      .catch((error) => {
        setOpen(true);
        setMsg(error.response.data.message || "Änderungen konnten nicht gespeichert werden");
      });
  };

  const deleteAccount = () => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .delete(`${config.api.host}/users/${user.id}`)
      .then(async (res) => {
        setOpenSuccess(true);
        setMsgSuccess("Account erfolgreich gelöscht");
        //logout
        logout(dispatch);

        reset();
      })
      .catch((error) => {
        setOpen(true);
        setMsg(error.response.data.message || "Änderungen konnten nicht gespeichert werden");
      });
  };

  return (
    <Layout>
      <Page>
        <Box>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <Typography variant="h6" fontWeight={700}>
              {t('ChangePassword_Headline')}
            </Typography>
            {/*             <Button
              size={"large"}
              variant={"outlined"}
              sx={{ marginTop: { xs: 2, md: 0 } }}
            >
              Log out
            </Button> */}
          </Box>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('CurrentPassword')} ( If you created your account with a social network, leave this field empty )
                  {/* Current password */}
                </Typography>
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('CurrentPassword')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              <Iconify icon={showPassword ? viewIcon : viewOff} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('NewPassword')}
                  {/* New password */}
                </Typography>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('NewPassword')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              <Iconify icon={showPassword ? viewIcon : viewOff} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  {t('RepeatPassword')}
                  {/* Repeat password */}
                </Typography>
                <Controller
                  name="repeatPassword"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('RepeatPassword')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              <Iconify icon={showPassword ? viewIcon : viewOff} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/*               <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      Show your email to 
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid> */}
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      {t('PasswordSave')}
                      {/* Don't forget to save the password change */}{" "}
                      {/*                       <Link
                        color={"primary"}
                        href={"/account-notifications"}
                        underline={"none"}
                      >
                        notification settings.
                      </Link> */}
                    </Typography>
                  </Box>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {t('SaveButton')}
                    {/* Save */}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
          {/* Create delete button account */}
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              margin={"20px auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  {t('DeleteAccount2')}
                </Typography>
              </Box>
              <Box
                sx={
                  {
                    display: !showDeleteButton ? "flex" : "none",
                    alignItems: "center",
                    gap: 1,
                  }
                }
              >
                <FormControlLabel
                  value={showDeleteButton}
                  onChange={() => setShowDeleteButton(!showDeleteButton)}
                  control={<Checkbox />}
                  label={t('DeleteAccount3')} />
              </Box>
              <Button
                size={"large"}
                variant={"outlined"}
                sx={{ marginTop: { xs: 2, md: 0 }, display: showDeleteButton ? "flex" : "none" }}
                onClick={() => deleteAccount()}
              >
                {t('DeleteAccount_Button')}
              </Button>
            </Box>
          </Grid>
        </Box>
        <ErrorMsg open={open} msg={msg} bottom={true} handleClose={handleClose} />
        <SuccessMsg
          open={openSuccess}
          msg={msgSuccess}
          bottom={true}
          handleClose={handleCloseSuccess}
        />
      </Page >
    </Layout >
  );
};

export default Security;
