import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import config from "../../../../config";
import { fCurrency } from "../../../../utils/formatNumber";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const MyFavoritesCards = ({ favorites }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h6"} gutterBottom>
            {t('Menu_MyFavorites')}
            {/* My favorites */}
          </Typography>
          <Typography color={'#777E91'}>
            {t('MyFavorites_Subline')}
            {/* All the workspaces where you have been sharing with Locals */}
          </Typography>
        </Box>
        {/*         <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginLeft={2}
          >
            View all
          </Box>
        </Box> */}
      </Box>
      <Grid container spacing={4}>
        {favorites && favorites.map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              sx={{ backgroundImage: "none", bgcolor: "transparent" }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: "30%" },
                  "& .lazy-load-image-loaded": {
                    height: 1,
                    display: "flex !important",
                  },
                }}
              >
                <Box
                  component={LazyLoadImage}
                  height={1}
                  width={1}
                  src={config.api.host + item.workplace.imageList[0].data}
                  alt="..."
                  effect="blur"
                  sx={{
                    objectFit: "cover",
                    maxHeight: 200,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  width: { xs: 1, md: "70%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontWeight={700}
                  sx={{ textTransform: "uppercase" }}
                >
                  {item.workplace.title}
                </Typography>
                <Box marginY={1}>
                  <Typography
                    /* variant={"caption"} */
                    color={'#777E91'}
                    component={"i"}
                  >
                    {item.user.first_name} - {item.user.last_name}
                  </Typography>
                </Box>
                <Typography color='#777E91'>
                  €{fCurrency(item.workplace.price)}
                </Typography>
                <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    component={Link}
                    to={`/workspace/${item.workplace.id}`}
                    endIcon={
                      <Box
                        component={"svg"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Details
                  </Button>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyFavoritesCards;

