import { Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax
import config from '../../../config';
import './Map.scss';
import { useTranslation } from "react-i18next";

mapboxgl.accessToken = config.mapbox.access_token

export default function Map({ workplace }) {

  const { t } = useTranslation();

  const mapContainer = useRef()

  let map = null
  let marker = null
  const [mapElement, setMapElement] = useState(<div style={{ height: '100%' }} ref={mapContainer} />)

  useEffect(() => {
    const el = document.createElement('div');
    el.className = 'officeMarker';
    marker = new mapboxgl.Marker(el);
    map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/xsinister/ckt6eauig2x7h17o55mghtd22',
      center: [workplace.data.office.longitude, workplace.data.office.latitude],
      zoom: 16,
      antialias: true,
      bearing: -17.6,
      pitch: 45,
    });
    marker.setLngLat([
      workplace.data.office.longitude,
      workplace.data.office.latitude
    ]);
    marker.addTo(map);
    map.on('load', () => {
      map.addSource('places', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      map.addLayer({
        'id': 'place-labels',
        'type': 'symbol',
        'source': 'places',
        'minzoom': 5,
        'layout': {
          'text-variable-anchor': ['top'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'icon-image': ['get', 'icon'],
          'icon-size': 0.5,
          'text-size': 12,
          'text-padding': 15
        },
        'paint': {
          'icon-color': '#FD6A56',
          'text-color': '#8f8b8a',
          'text-halo-color': '#fafafa',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        }
      });
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.on('mouseenter', 'place-labels', (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.title;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    map.on('mouseleave', 'place-labels', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });

  }, [])

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <section>
          <Typography variant="h4" paragraph>
            {t("Neighborhood_WorkspaceOverview")}
          </Typography>
          <div className={"map-visible"} style={{ width: '100%', height: 450 }}>
            {
              mapElement
            }
          </div>
        </section>
      </Grid>
    </Grid>
  )
}
