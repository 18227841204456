import React, { useEffect, useRef, useState } from "react";
import UserCardWithBackground from "../../sections/publicProfile/UserCardWithBackground";
import Layout from "../../layouts";
import { Box, Card, Collapse, Container, Grid, List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import background_profile from "../../assets/images/background_profile.png";
import DescriptionUser from "../../sections/publicProfile/DescriptionUser";
import VideoZone from "../../sections/@travel/videoZone/videoZone";
import ProfileGallery from "../../sections/@travel/tours/ProfileGallery";
import { useGlobalContext } from "../../stores/global";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { ErrorScreen, LoadingScreen } from "../../components";
import { ReviewForm, ReviewTravelToolbar, ReviewTravelTourList } from "../../sections/reviews";
import { ReviewUserToolbar, ReviewUserTourList } from "../../sections/reviews/users";
import TravelTourItem from "../../sections/@travel/tours/TravelTourItem";
import { useRequest } from "../../hooks";

const PublicProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const { user } = useGlobalContext();
  const [openReview, setOpenReview] = useState(false);
  const [sort, setSort] = useState("latest");

  const { data: workplacesData, error: workplacesError } = useRequest({
    url: `/office/workspace_user/${id}`,
  });

  const [workplaces, setWorkplaces] = useState([]);

  //get rating of user
  const { data: ratingData, error: ratingError } = useRequest({
    url: `/users/get/review/rating/${id}`,
  });

  //get comments of user
  const { data: commentsData, error: commentsError } = useRequest({
    url: `/users/get/review/comments/${id}?page=1&limit=5`,
  });

  useEffect(() => {
    if (workplacesData?.data) {
      setWorkplaces(workplacesData?.data);
    }
  }, [workplacesData]);


  //loader
  const [loading, setLoading] = useState(false);
  //error
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (user) {
      axios.defaults.headers.common = {
        Authorization: user.token,
      };
    }
    axios.get(`${config.api.host}/users/${id}`).then(res => {
      setUserData(res.data.data.user);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      setError(true);
    })
  }, []);

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const myRef = useRef(null)
  //get query params
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    //check if rating == "true" then open review form
    console.log(urlParams.get("rating"));
    if (urlParams.get("rating") == "true") {
      let sw = false;
      setTimeout(() => {
        if (myRef.current) {
          myRef.current.scrollIntoView();
          //clean query params
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }, 1500);
    }
  }, [urlParams]);

  if (error) {
    return <ErrorScreen />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Layout>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }} paddingTop={9} paddingBottom={0}>
          <img src={userData.titlepicture || background_profile} alt="background_profile" width={"90%"} height={"500px"} style={{ borderRadius: "25px", marginTop: "25px", objectFit: "cover" }} />
        </Box>
        <Container maxWidth paddingTop={"0 !important"} sx={{ width: "80%" }}>
          <Grid container spacing={4}>
            <Grid sx={{ marginTop: "-150px !important" }} item xs={12} md={4}>
              <Card sx={{ boxShadow: 3 }}>
                <UserCardWithBackground ratingData={ratingData} />
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <DescriptionUser />
              <ProfileGallery />
            </Grid>
          </Grid>

        </Container>
        {workplaces && workplaces.length > 0 && (
          <Container my={3} maxWidth sx={{ width: "80%" }}>
            <Grid container spacing={4}>
              <Grid sx={{ marginTop: "-150px !important" }} item xs={12} md={4} />
              <Grid item xs={12} md={8}>
                <Typography paddingTop={4} paddingBottom={0} paddingLeft={6} variant="h3"
                  sx={{ fontWeight: "600", marginTop: "50px", textAlign: "left", color: "#777E91", fontSize: "1.5rem!important" }}>
                  {t('WorkspacesOf')} {userData.first_name}
                </Typography>
                <Box
                  paddingTop={4} paddingBottom={0} paddingLeft={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 3,
                    flexWrap: "wrap",
                    marginY: 3,
                  }}
                >
                  {workplaces.map((workplace) => {
                    return (
                      <Box
                        key={workplace.id}
                        sx={{
                          width: { xs: "100%", sm: "45%", lg: "45%" },
                        }}
                      >
                        <TravelTourItem
                          key={workplace.id}
                          workplace={{
                            ...workplace,
                            address: workplace.office.address,
                            imageList: workplace.images,
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
        <Box marginTop={10}>
          <VideoZone />
        </Box>
        <Container ref={myRef} sx={{ marginY: "100px" }}>
          <ReviewUserToolbar
            totalReview={commentsData?.data ? commentsData?.data.length : 0}
            onOpenReview={() => setOpenReview(!openReview)}
            sort={sort}
            onChangeSort={handleChangeSort}
          />
          <ReviewUserTourList reviews={commentsData?.data || []} />
        </Container>
      </Box>
    </Layout >
  )
};

export default PublicProfile;