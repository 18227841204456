import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// icons
/* import timeIcon from '@iconify/icons-carbon/time'; */
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Tooltip,
  Avatar,
  ListItem,
  Chip,
} from "@mui/material";
// utils
import { fCurrency } from "../../../utils/formatNumber";
// components
import {
  Image,
  /*   Iconify,
    RatingLabel, */
  TextMaxLine,
  TextIconLabel,
  FavoriteButton,
  Iconify,
} from "../../../components";
import config from "../../../config";
import { Link } from "react-router-dom";
import SVGIcon from "../../../components/icons.element";
import "./TravelTourItem.css";
import { tag_list } from "../../../mocks/tag_list";
import { useTranslation } from "react-i18next";
import verified from "../../../assets/images/check.svg";
import { useGlobalContext } from "../../../stores/global";
import axios from "axios";
// ----------------------------------------------------------------------

export default function TravelTourItem({ workplace }) {
  const { t } = useTranslation();
  const {
    id,
    title,
    address,
    price,
    priceSale = 0,
    imageList,
  } = workplace;
  const [favorite, setFavorite] = useState(workplace.favorite);
  const { user } = useGlobalContext();
  const handleChangeFavorite = (event) => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    if (event.target.checked) {
      axios.post(`${config.api.host}/office/favorite/${id}`, {})
        .then((res) => {
          setFavorite(true);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      axios.delete(`${config.api.host}/office/favorite/${id}`, {})
        .then((res) => {
          setFavorite(false);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  console.log("list workplaces:", workplace);
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pt: 1.5,
          pl: 2,
          pr: 1.5,
          top: 0,
          width: 1,
          zIndex: 9,
          position: "absolute",
        }}
      >
        <Stack
          spacing={0.5}
          direction="row"
          sx={{
            px: 1,
            borderRadius: 0.75,
            typography: "subtitle2",
            bgcolor: "#FD5854",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          }}
        >
          {priceSale > 0 && (
            <Box
              sx={{
                color: "grey.500",
                textDecoration: "line-through",
                mr: 0.5,
              }}
            >
              €{fCurrency(priceSale)} / {t("Day")}
            </Box>
          )}
          €{fCurrency(price)}
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "common.white" }}
          >
            / {t("Day")}
          </Typography>
        </Stack>
        {user && (
          <FavoriteButton
            checked={favorite}
            onChange={handleChangeFavorite}
            sx={{ color: "common.white" }}
          />
        )}
      </Stack>
      <Link to={`/workspace/${id}`}>
        <Image
          alt={title}
          src={config.api.host + (imageList[0].data || imageList[0])}
          ratio="4/3"
        />
      </Link>
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <TextMaxLine variant="h6">{title}</TextMaxLine>
          <Typography variant="body2" display={"flex"} alignItems={"center"} gap={1}>
            {workplace?.user?.first_name} {workplace?.user?.stripe_id && <img src={verified} alt="verified" width="20px" />} {/* {user.last_name} */}
          </Typography>
        </Box>
        <TextIconLabel
          icon={
            <Link to={"/user/" + workplace.user.id}>
              <Avatar src={workplace?.user?.profilepicture} />
            </Link>
          }
        />
      </Box>

      <Divider />

      <Box
        sx={{
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Tooltip title={address.split(",")[1] + ", " + address.split(",")[2]}>
            <Typography
              variant="body3"
              sx={{ color: '#777E91' }}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {address.split(",")[1] + ", " + address.split(",")[2]}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SVGIcon type={"star"} height={18} width={18} color={"orange"} />
          <Typography variant="body2">{workplace.rating || 0}</Typography>
        </Box>
      </Box>
    </Card>
  );
}
