import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function ErrorMsg({ msg, open, handleClose, bottom }) {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: bottom ? "bottom" : "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
