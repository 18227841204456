/* import PropTypes from 'prop-types'; */
import { /* useRef, useEffect, */ useState } from 'react';
import getDaysInMonth from 'date-fns/getDaysInMonth';
// icons
/* import departureIcon from '@iconify/icons-carbon/departure'; */
import calendarIcon from '@iconify/icons-carbon/calendar';
// @mui
/* import PickersDay from '@mui/lab/PickersDay';
import DatePicker from '@mui/lab/DatePicker';
import CalendarPickerSkeleton from '@mui/lab/CalendarPickerSkeleton'; */
import { /* Badge */Button, Box, InputAdornment, InputLabel } from '@mui/material';
//
import { Iconify } from '../../../components';
/* import { InputStyle } from './TravelTourBarFilters'; */

import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import "./calendar_double.css";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import axios from 'axios';
import config from '../../../config';

// ----------------------------------------------------------------------

/* const INITIAL_DATE = new Date(); */

export default function TravelTourFilterTime({ setTotalPrice, sx, noWhite, changeDates, defaultFromDate,
  defaultToDate, workspace }) {
  const { t } = useTranslation();

  const [fromDate, setFromDate] = useState(defaultFromDate ? new Date(defaultFromDate) : moment().add(1, 'days').toDate());
  const [toDate, setToDate] = useState(defaultToDate ? new Date(defaultToDate) : moment().add(2, 'days').toDate());

  //message date error
  const [dateError, setDateError] = useState("");
  const handleEvent = (event, picker) => {
    //check if from date is before to date plus 1 day
    if (moment(picker.startDate._d.toISOString()).isAfter(moment())) {
      setFromDate(picker.startDate._d.toISOString());
      setToDate(picker.endDate._d.toISOString());
      console.log("end", picker.startDate);
      console.log("start", picker.endDate);
      console.log("ENTRO")
      changeDates(picker.startDate._d.toISOString(), picker.endDate._d.toISOString())
      if (setTotalPrice) {
        let startDate = moment(picker.startDate._d.toISOString());
        let endDate = moment(picker.endDate._d.toISOString());
        let mondays = 0;
        let tuesdays = 0;
        let wednesdays = 0;
        let thursdays = 0;
        let fridays = 0;
        let saturdays = 0;
        let sundays = 0;
        while (startDate.isSameOrBefore(endDate)) {
          if (startDate.day() === 1) {
            mondays++;
          }
          if (startDate.day() === 2) {
            tuesdays++;
          }
          if (startDate.day() === 3) {
            wednesdays++;
          }
          if (startDate.day() === 4) {
            thursdays++;
          }
          if (startDate.day() === 5) {
            fridays++;
          }
          if (startDate.day() === 6) {
            saturdays++;
          }
          if (startDate.day() === 0) {
            sundays++;
          }
          startDate.add(1, 'days');
        }
        //subtract the days that the workspace is not open
        let days = 0;
        if (workspace.openedMonday) {
          days += mondays;
        }
        if (workspace.openedTuesday) {
          days += tuesdays;
        }
        if (workspace.openedWednesday) {
          days += wednesdays;
        }
        if (workspace.openedThursday) {
          days += thursdays;
        }
        if (workspace.openedFriday) {
          days += fridays;
        }
        if (workspace.openedSaturday) {
          days += saturdays;
        }
        if (workspace.openedSunday) {
          days += sundays;
        }
        console.log(days);
        setDateError("");
        setTotalPrice(days);
      }
    } else {
      setDateError("The date must be after today");
    }
  };

  const [datesNotValid, setDatesNotValid] = useState([]);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (workspace) {
      let dates = [];
      axios.post(config.api.host + "/booking/check_range_date_not_available", {
        workplace_id: workspace.id,
      }).then((res) => {
        res.data.notAvailableDates.forEach((date) => {
          dates.push(date);
        });
        console.log(dates)
        setDatesNotValid(dates);
        setInit(true);
      });
    }
  }, [workspace]);



  return (
    <>
      {init && (
        <DateRangePicker
          alwaysShowCalendars={true}
          onEvent={handleEvent}
          initialSettings={{
            startDate: fromDate,
            endDate: toDate,
            isInvalidDate: function (date) {
              return datesNotValid.includes(moment(date).format("DD-MM-YYYY")) || moment(date).isBefore(moment());
            },
            locale: {
              format: "DD.MM.YYYY",
              separator: " - ",
              applyLabel: t("Apply"),
              cancelLabel: t("Cancel"),
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              weekLabel: "W",
              daysOfWeek: [t("ShortWeek_Su"), t("ShortWeek_Mo"), t("ShortWeek_Tu"), t("ShortWeek_We"), t("ShortWeek_Tu"), t("ShortWeek_Fr"), t("ShortWeek_Sa")],
              monthNames: [
                t("Months_Jan"),
                t("Months_Feb"),
                t("Months_Mar"),
                t("Months_Apr"),
                t("Months_May"),
                t("Months_Jun"),
                t("Months_Jul"),
                t("Months_Aug"),
                t("Months_Sep"),
                t("Months_Oct"),
                t("Months_Nov"),
                t("Months_Dec"),
              ],
              firstDay: 1,
            },
          }}
        >
          <Box sx={{ width: 1, ...sx }}>
            {/* <InputLabel sx={{ fontWeight: "bold", color: "#000", flexShrink: 0, ml: 1, mb: 0.3 }} >
          {t('MainSearch_When')} */}
            {/* Wann? */}
            {/* </InputLabel> */}
            <InputAdornment position="start" style={{ height: "2em", padding: "10px" }}>
              <Iconify
                icon={calendarIcon}
                sx={{ width: 24, height: 24, color: noWhite ? 'text.disabled' : '#ffffff', flexShrink: 0, mr: 1 }}
              />
              <Button sx={{
                backgroundColor: "transparent",
                border: 0,
                padding: "10px",
                color: noWhite ? 'text.disabled' : '#ffffff',
                fontWeight: "bold",
                fontSize: '14px',
                //wrap text
                whiteSpace: "normal",
                width: "100%",
                '&:hover': { backgroundColor: 'transparent', },
              }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box>
                    <Box>{t('MainSearch_When2')}</Box>
                    <Box>
                      {moment(fromDate).format("DD.MM.YYYY")}
                    </Box>
                  </Box>
                  <Box>
                    <Box>{t('MainSearch_When3')}</Box>
                    <Box>
                      {moment(toDate).format("DD.MM.YYYY")}
                    </Box>
                  </Box>
                </Box>
              </Button>
            </InputAdornment>
          </Box>
        </DateRangePicker >
      )}
      <div style={{ color: "red", textAlign: "center" }}>{dateError}</div>
    </>
  );
}
