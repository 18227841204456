import PropTypes from 'prop-types';
// @mui
import { Box, Pagination } from '@mui/material';
//
import ReviewTravelTourItem from './ReviewTravelTourItem';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

ReviewTravelTourList.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default function ReviewTravelTourList({ workplace }) {
  const { t } = useTranslation();

  return (
    <>
      {workplace.allReviews.map((review) => {
        const hasReply = false;

        return (
          <Box key={review.review.id}>
            <ReviewTravelTourItem
              name={review.user.first_name}
              avatarUrl={review.user.profilepicture}
              postedAt={review.review.review_date}
              message={review.review.review_text}
              rating={review.review.review_rating}
            />
          </Box>
        );
      })}
      {console.log(workplace.allReviews.length)}
      {workplace.allReviews.length == 0 && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          {t('NoReviews')}
        </Box>
      )}

      <Pagination
        count={1}
        color="primary"
        size="large"
        sx={{
          pt: 5,
          pb: { xs: 10, md: 15 },
          '& .MuiPagination-ul': {
            justifyContent: 'center',
          },
        }}
      />
    </>
  );
}
