import PropTypes from "prop-types";
import { useRef, useState } from "react";
import Slider from "react-slick";
/* import { m } from 'framer-motion'; */
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  /* Grid, */ Container,
  Typography,
  Stack,
  Card /* Button  */,
} from "@mui/material";
// hooks
/* import { useBoundingClientRect } from '../../../hooks'; */
// components
import {
  CarouselArrows,
  Image,
  /* Iconify, SvgIconStyle, IconButtonAnimate, */ Label,
} from "../../../components";
/* import { varHover, varTranHover } from '../../../components/animate'; */
import { Link } from "react-router-dom";
import moment from "moment";
// icons
/* import directionStraightRight from '@iconify/icons-carbon/direction-straight-right'; */
import munich from './../../../assets/images/munchen_pic.png';
import berlin from './../../../assets/images/berlin_pic.png';
import hamburg from './../../../assets/images/hamburg_pic.png';
import frankfurt from './../../../assets/images/frankfurt_pic.png';

import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

SliderCities.propTypes = {
  children: PropTypes.node,
  customIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(7, 0),
  [theme.breakpoints.up("md")]: {
    position: "relative",
    padding: theme.spacing(8, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SliderCities({
  customIcon, // Set icon right
  onNext,
  onPrevious,
  children,
  ...other
}) {
  const { t } = useTranslation();

  const [cities, setCities] = useState([
    {
      name: "Hamburg",
      pathname: "/city/hamburg",
      img: `${hamburg}`,
      quantity: "1,234",
    },
    {
      name: "München",
      pathname: "/city/münchen",
      img: `${munich}`,
      quantity: "1,875",
    },
    {
      name: "Berlin",
      pathname: "/city/berlin",
      img: `${berlin}`,
      quantity: "657",
    },
    {
      name: "Frankfurt",
      pathname: "/city/frankfurt",
      img: `${frankfurt}`,
      quantity: "489",
    },
    {
      name: "Barcelona",
      pathname: "/city/münchen",
      img: "https://images.unsplash.com/photo-1539037116277-4db20889f2d4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
      quantity: "78",
    }
    /* {
      name: "Hong Kong",
      pathname: "/city/münchen",
      img: "https://images.unsplash.com/photo-1507941097613-9f2157b69235?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1713&q=80",
    },
    {
      name: "Dublin",
      pathname: "/city/münchen",
      img: "https://images.unsplash.com/photo-1605969353711-234dea348ce1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
    }, */
    /* {
      name: "Paris",
      pathname: "/city/münchen",
      img: "https://images.unsplash.com/photo-1531210483974-4f8c1f33fd35?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
    },
    {
      name: "Miami",
      pathname: "/city/münchen",
      img: "https://images.unsplash.com/photo-1546709344-8be01dfbc721?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2089&q=80",
    }, */
  ]);

  const containerRef = useRef(null);

  const theme = useTheme();

  const carouselRef = useRef(null);

  const carouselSettings = {
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "baseline" }}
          sx={{
            textAlign: { xs: "center", md: "unset" },
          }}
        >
          <Stack spacing={3} flexGrow={1}>
            <Typography variant="h2">
              {/* Entdecke Neues */}
              {t("CityOverview")}
            </Typography>

            <Typography sx={{ fontSize: "24px", color: "#777E91", marginTop: '12px!important' }}>
              {t('CityOverview_Subline')}
            </Typography>
          </Stack>
          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{
              display: { xs: "none", md: "block" },
            }}
          />
        </Stack>

        <Box
          sx={{
            position: "relative",
            ml: { md: -2 },
            width: { md: "calc(100% + 32px)" },
          }}
        >
          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{
              "& .arrow": {
                display: { md: "none" },
                "& button, &button:hover": {
                  bgcolor: "common.white",
                  color: "text.primary",
                },
              },
            }}
          >
            <Slider ref={carouselRef} {...carouselSettings}>
              {cities.map((city) => (
                <Box
                  key={city.name}
                  sx={{
                    px: 2,
                    pt: { xs: 6, md: 6 },
                    pb: { xs: 6, md: 6 },
                  }}
                >
                  <CityItem city={city} />
                </Box>
              ))}
            </Slider>
          </CarouselArrows>
        </Box>
      </Container>
    </RootStyle>
  );
}

function CityItem({ city }) {
  const { pathname, search, img, name, quantity } = city;
  return (
    <Link
      to={{
        pathname: pathname,
      }}
    >
      <Card
        sx={{
          display: { sm: "flex" },
          boxShadow: (theme) => theme.customShadows.z16,
          "&:hover": {
            boxShadow: (theme) => theme.customShadows.z24,
          },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexShrink: { sm: 0 } }}>
          <Image alt={"img-city"} src={img} ratio="1/1" />
        </Box>
      </Card>
      <Box sx={{ marginY: "15px" }}>
        <Typography fontWeight={500} sx={{ fontSize: "18px", color: "black" }}>{name}</Typography>
        <Typography sx={{ fontSize: "14px", color: "#777E91" }}>{quantity}</Typography>
      </Box>
    </Link>
  );
}
