import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// next
/* import { useRouter } from 'next/router'; */
// routes
/* import Routes from '../../../src/routes'; */
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Stack,
  Divider,
  Container,
  Typography,
  TextField,
  Button,
} from "@mui/material";
// hooks
import { useRequest } from "../../hooks";
// config
import config, {
  HEADER_MOBILE_HEIGHT,
  HEADER_DESKTOP_HEIGHT,
} from "../../config";
// layouts
import Layout from "../../layouts";
// components
import { Page, ErrorScreen, LoadingScreen } from "../../components";
//
import {
  TravelCheckOutSummary,
  TravelCheckOutPaymentForm,
  TravelCheckOutShippingForm,
  TravelTourFilterTime,
  TravelTourFilterGuests,
} from "../../sections/@travel";

// _data, delete later, just to show on netlify
import { add, sub } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../stores/global";
//STRIPE
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./stripe/CheckoutForm";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DateTimePicker, LoadingButton, TimePicker } from "@mui/lab";

// ----------------------------------------------------------------------

const LabelStepStyle = styled((props) => (
  <Typography
    variant="h4"
    {...props}
    sx={{
      mb: 3,
      display: "flex",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        mr: 1.5,
        width: 28,
        height: 28,
        display: "flex",
        typography: "h6",
        borderRadius: "50%",
        alignItems: "center",
        bgcolor: "primary.main",
        justifyContent: "center",
        color: "primary.contrastText",
      }}
    >
      {props.step}
    </Box>
    {props.title}
  </Typography>
))({});

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: HEADER_MOBILE_HEIGHT,
  [theme.breakpoints.up("md")]: {
    paddingTop: HEADER_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  billingAddress: Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    fullAddress: Yup.string().required("Full address is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("PLZ is required"),
    city: Yup.string().required("Stadt is required"),
  }),
  paymentMethods: Yup.object().shape({
    card: Yup.object().when("methods", {
      is: "credit_card",
      then: Yup.object({
        cardNumber: Yup.string().required("Card number is required"),
        cardHolder: Yup.string().required("Card holder is required"),
        expirationDate: Yup.string().required("Expiration date is required"),
        ccv: Yup.string().required("CCV is required"),
      }),
    }),
  }),
});

const stripePromise = loadStripe(
  "pk_live_51J85MbGlY3sQ0wEOYt3cGVznJeIGhBuKKEh0mWaVU7xvxDBfqq4XCoDxk94qElmrnFjeIwV1Vd9qy3azQW6yfaTS00F39Lw4fL"
);

export default function Checkout() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [sameBilling, setSameBilling] = useState(false);
  const [departureDay, setDepartureDay] = useState(new Date());

  const { user } = useGlobalContext();

  const handleChangeSameBilling = (event) => {
    setSameBilling(event.target.checked);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("workspace");
  const [priceSale, setPriceSale] = useState(searchParams.get("price"));
  const [days, setDays] = useState(searchParams.get("days"));

  const [guests, setGuests] = useState({
    adults: parseInt(searchParams.get("guests")),
    children: 0,
  });
  const [fromDate, setFromDate] = useState(searchParams.get("dateFrom"));
  const [toDate, setToDate] = useState(searchParams.get("dateTo"));
  const { data: workplace, error: workplaceError } = useRequest({
    url: id ? `/office/workplace/${id}` : "",
  });
  const [timeArrive, setTimeArrive] = useState(null);

  const calculateTotalPrice = (days) => {
    setDays(days);
    if (totalGuests > workplace?.data.amount) {
      let temp_price = price * days;
      console.log("percent", workplace?.data.extra_percent);
      let percent = workplace?.data.extra_percent / 100;
      let extra_price = temp_price * percent;
      console.log("extra_price", extra_price);
      console.log("amount", (totalGuests - workplace?.data.amount));
      let total_price = temp_price + (extra_price * (totalGuests - workplace?.data.amount));
      setPriceSale(total_price);
      createNewSecret(total_price);
    } else {
      setPriceSale(price * (days))
      createNewSecret(price * days);
    }
  }

  useEffect(() => {
    let totalGuests = guests.adults + guests.children;
    console.log("totalGuests", totalGuests);
    console.log("workplace?.amount", workplace?.data.amount);
    if (totalGuests > workplace?.data.amount) {
      let temp_price = price * days;
      console.log("percent", workplace?.data.extra_percent);
      let percent = workplace?.data.extra_percent / 100;
      let extra_price = temp_price * percent;
      console.log("extra_price", extra_price);
      console.log("amount", (totalGuests - workplace?.data.amount));
      let total_price = temp_price + (extra_price * (totalGuests - workplace?.data.amount));
      setPriceSale(total_price);
      createNewSecret(total_price);
    } else {
      setPriceSale(price * (days))
      createNewSecret(price * days);
    }
  }, [guests])

  const createNewSecret = async (priceSale) => {
    axios.defaults.headers.common = {
      Authorization: user.token,
    };
    axios
      .post(`${config.api.host}/stripe/create-payment-intent`, {
        items: {
          id: workplace.data.id,
          totalPrice: priceSale,
          stripe_id: workplace?.data?.user?.stripe_id,
        },
      })
      .then((res) => {
        setClientSecret(res.data.clientSecret);
        console.log(res.data.clientSecret);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const changeDates = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };

  //Stripe
  const [clientSecret, setClientSecret] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (workplace?.data && !init) {
      axios.defaults.headers.common = {
        Authorization: user.token,
      };
      axios
        .post(`${config.api.host}/stripe/create-payment-intent`, {
          items: {
            id: workplace.data.id,
            totalPrice: priceSale,
            stripe_id: workplace?.data?.user?.stripe_id,
          },
        })
        .then((res) => {
          setClientSecret(res.data.clientSecret);
          console.log(res.data.clientSecret);
        })
        .catch((err) => {
          alert(err);
        });
      setInit(true);
    }
  }, [workplace, init]);

  const appearance = {
    theme: "flat",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const {
    reset,
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(FormSchema),
    defaultValues: {
      billingAddress: {
        firstName: user.first_name,
        lastName: user.last_name,
        fullAddress: "",
        country: "",
        zipCode: "",
        city: user?.city ? user?.city : "",
      },
      paymentMethods: {
        methods: "paypal",
        card: {
          cardNumber: "",
          cardHolder: "",
          expirationDate: "",
          ccv: "",
        },
      },
    },
  });

  if (workplaceError) {
    return <ErrorScreen />;
  }

  if (!workplace) {
    return <LoadingScreen />;
  }

  const { price } = workplace?.data;
  const totalGuests = guests.adults + guests.children;

  const onSubmit = async (data) => {
    console.log(data);
  };

  /*   if (error) {
    return <ErrorScreen />;
  }

  if (!tour) {
    return null;
  } */

  return (
    <Layout>
      <Page title="Checkout">
        <RootStyle>
          <Container
            sx={{
              mt: { xs: 8, md: 10 },
              mb: { xs: 8, md: 15 },
            }}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} md={7}>
                <Stack spacing={5}>
                  <Typography variant="h2">
                    {t("Booking_Confirmation")}
                  </Typography>

                  <Divider />

                  <section>
                    <Typography variant="h4">
                      {t("Booking_Duration")}
                    </Typography>
                    <Grid marginTop={5} container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TravelTourFilterTime
                          noWhite={true}
                          setTotalPrice={calculateTotalPrice}
                          changeDates={changeDates}
                          workspace={workplace.data}
                          defaultFromDate={fromDate}
                          defaultToDate={toDate}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TravelTourFilterGuests
                          guests={guests}
                          setGuests={setGuests}
                        />
                      </Grid>
                      {/* add input dateTime picker mui with title date to arrive */}

                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <Controller
                          name="timeArrive"
                          control={control}
                          render={({ field }) => (
                            <TimePicker
                              ampm={false}
                              label={t("ETA")}
                              {...field}
                              renderInput={(params) => (
                                <TextField sx={{ width: "100%" }} {...params} />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </section>
                  <section>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label
                          htmlFor="email"
                          style={{
                            color: "#B1B5C4",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          {t("SavedBillingInfo")}
                        </label>
                        <TextField
                          fullWidth
                          defaultValue={user.email}
                          label="Email"
                        />
                      </Grid>
                    </Grid>
                  </section>
                  <Divider />

                  <section>
                    <Typography variant="h4">
                      {t("Booking_CreditCard")}
                    </Typography>

                    <section>
                      {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutForm
                            clientSecret={clientSecret}
                            user={user}
                            workplaceId={workplace.data.id}
                            priceSale={priceSale}
                            fromDate={fromDate}
                            toDate={toDate}
                            totalGuests={totalGuests}
                            setIsLoading={setIsLoading}
                            submit={submit}
                            setSubmit={setSubmit}
                            email={user.email}
                            days={days}
                            getValues={getValues}
                          />
                        </Elements>
                      )}
                    </section>
                  </section>
                  <section>
                    <Typography variant="h4">
                      {t("Booking_MessageHost")}
                    </Typography>
                    {/*Textarea with placeholder and button Complete Booking*/}
                    <Grid marginTop={5} container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="message"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label={t("MessageExample")}
                              {...field}
                              error={Boolean(field.error)}
                              helperText={field.error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid marginTop={5} container spacing={2}>
                      <Grid item xs={12}>
                        <LoadingButton
                          onClick={() => setSubmit(true)}
                          type="submit"
                          size="large"
                          variant="contained"
                          loading={isSubmitting || isLoading}
                        >
                          {t("Complete_Booking")}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </section>
                </Stack>
              </Grid>

              <Grid item xs={12} md={5}>
                <TravelCheckOutSummary
                  workspace={workplace}
                  guests={guests}
                  setGuests={setGuests}
                  setTotalPrice={calculateTotalPrice}
                  changeDates={changeDates}
                  priceSale={priceSale}
                  isSubmitting={isSubmitting}
                  fromDate={fromDate}
                  toDate={toDate}
                  setSubmit={setSubmit}
                  isLoading={isLoading}
                  days={days}
                />
              </Grid>
            </Grid>
          </Container>
        </RootStyle>
      </Page>
    </Layout>
  );
}
