/* import PropTypes from "prop-types";
import Slider from "react-slick"; */
import { useRef, useState } from "react";
// icons
import quotesIcon from "@iconify/icons-carbon/quotes";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Container,
  Stack,
  Box,
  Avatar,
  Chip,
  Card,
  Button,
} from "@mui/material";
// components
import {
  /*  Image, */ Iconify,
  /*   CarouselArrows,
    CarouselDots, */
  Image,
  PlayerWithButton,
  TextIconLabel,
} from "../../../components";
import { useTranslation } from "react-i18next";
import playFilledAlt from '@iconify/icons-carbon/play-filled-alt';
import { useResponsive } from "../../../hooks";
/* import videoPicture from "../../../assets/images/videoPicGirls.png"; */
import iconTwimyo from "../../../assets/images/logoOrange.svg";
import { Link } from "react-router-dom";


// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(5, 0),
  borderRadius: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 15),
    padding: theme.spacing(2, 0),
  },
}));

// ----------------------------------------------------------------------

export default function VideoZone() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };
  const isDesktop = useResponsive('up', 'md');
  const video = "/assets/videos/twimyoTrailerEinschieben720.mp4";

  return (
    <RootStyle>
      <Container maxWidth={true} sx={{ position: "relative" }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ md: "center" }}
          sx={{
            textAlign: { xs: "center", md: "unset" },
            marginBottom: "80px"
          }}
          spacing={3}
        >
          <Stack spacing={0} flexGrow={1}>
            <Typography fontSize={{ xs: "1.0rem", lg: "2.1rem" }} fontWeight={800}>
              {t("Video_Subline")}
            </Typography>
          </Stack>

          <Box component={Link} to={"/needspace"}
            sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                padding: "10px 25px !important",
                borderRadius: "50px",
                background: `linear-gradient(100.84deg, #73759E 0%, #EF4F5B 48.44%, #E61B6F 100%)`
              }}
            >
              {t("Button_BookNow")}
            </Button>
          </Box>
        </Stack>

        <Box
          height={600}
          maxHeight={{ xs: "170px", sm:"250px", md: "420px", lg: "600px", xl: 900 }}
          width={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box height={1} width={1}>
            <video
              width="100%"
              height="100%"
              controls
              style={{
                boxShadow: theme.shadows[4],
                borderRadius: theme.shape.borderRadius * 2
              }}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>

      </Container>
      <PlayerWithButton open={openVideo} onClose={handleCloseVideo} videoPath={video} />
    </RootStyle>
  );
}