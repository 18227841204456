/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
/* import Button from "@mui/material/Button"; */
import config from "../../../../config";
import { useTranslation } from 'react-i18next';
import { Divider, FormControl, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { padding } from "@mui/system";
import moment from "moment";
import { useGlobalContext } from "../../../../stores/global";

const mock = [
  {
    image: "https://assets.maccarianagency.com/backgrounds/img13.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Newyork Office Apple Inc.",
    author: {
      name: "Clara Bertoletti",
    },
    date: "15.7.22",
  },
  {
    image: "https://assets.maccarianagency.com/backgrounds/img14.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Workspace in Paris",
    author: {
      name: "Jhon Anderson",
    },
    date: "12.12.22",
  },
  {
    image: "https://assets.maccarianagency.com/backgrounds/img15.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Barcelona Workspace",
    author: {
      name: "Clara Bertoletti",
    },
    date: "12.12.22",
  },
  {
    image: "https://assets.maccarianagency.com/backgrounds/img16.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Workspace in India",
    author: {
      name: "Jhon Anderson",
    },
    date: "12.12.22",
  },
];

const SidebarMessages = ({ bookings, setSelectBooking }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //get logged user
  const { user } = useGlobalContext();
  return (
    <Box sx={{ width: { xs: '90%', md: '105%' } }} component={Card} variant={"outlined"} padding={2}>
      <Typography
        variant="h6"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
          paddingY: 2,
          borderBottom: "1px solid #ccc",
        }}
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        <Box>{t("Chat_RoomDetails")}</Box>
        <Box style={{ backgroundColor: "#FFF4ED", color: "#FB5A55", padding: "2px", fontSize: "14px" }}>{bookings.length}</Box>
        {/* Last Chats */}
      </Typography>
      {/*mui search bar input with icon*/}
      <TextField
        sx={{
          width: '100%',
          marginBottom: 2,
        }}
        placeholder={t('Chat_Search')}
        InputProps={{
          sx: {
            background: "white",
            border: "1px solid #777E90",
            '& input': {
              padding: 1,
            }
          },
          startAdornment: (
            <InputAdornment sx={{ marginBottom: "15px" }} position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Grid container spacing={2} sx={{ overflowY: "scroll", height: "80vh" }} >
        {bookings && bookings.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              onClick={() => setSelectBooking(i)}
              component={Card}
              width={1}
              height={"auto"}
              boxShadow={0}
              sx={{ backgroundImage: "none", bgcolor: user.id === item.user.id ? "#D3EEDC" : "transparent", cursor: "pointer", padding: "10px", borderRadius: "10px" }}
            >
              <Box
                sx={{
                  display: "flex !important",
                  alignItems: "center",
                  gap: 1,
                  width: 1,
                  "& .lazy-load-image-loaded": {
                    height: 1,
                    display: "flex !important",
                  },
                }}
              >
                <Box
                  component={LazyLoadImage}
                  height={"50px"}
                  width={"50px"}
                  src={user.id !== item.user.id ? item.user?.profilepicture : item.userRequest?.profilepicture}
                  alt="..."
                  sx={{
                    borderRadius: "360px",
                    objectFit: "cover",
                  }}
                />

                <CardContent
                  sx={{ padding: 1, "&:last-child": { paddingBottom: 1 }, width: "100%" }}
                >
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}>
                    <Typography fontWeight={500}
                      sx={{
                        fontSize: "14px",
                        color: '#344054!important',
                      }}
                    >
                      {user.id !== item.user.id ? item.user?.first_name : item.userRequest?.first_name}
                    </Typography>
                    <Typography
                      color={'#777E91'}
                      sx={{
                        fontSize: "12px"
                      }}
                    >
                      Start Date: {moment(item.booking.from).fromNow()}
                    </Typography>
                  </Box>
                  <Box marginY={1 / 4}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: '#667085!important',
                      }}
                      fontWeight={400}
                      component={"i"}
                    >
                      {item.workplace.title}
                    </Typography>
                  </Box>
                </CardContent>
              </Box>
              <Box
                sx={{
                  width: 1,
                }}
              >
                <Box marginY={1}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                    fontWeight={400}
                    color={'#777E91'}
                  >
                    {item.workplace.address}
                  </Typography>
                </Box>
                {item?.lastMessage?.message && (
                  <Box marginY={1}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                      }}
                      color={'#667085'}
                      fontWeight={400}
                    >
                      "{item.lastMessage.message}" - {moment(item.lastMessage.created_at).fromNow()}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Divider />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SidebarMessages;
