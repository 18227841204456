import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Hinweis zur verantwortlichen Stelle',
    description: `Twimyo GmbH
    Garmischer Straße 4
    80339 München
    `,
  },
  {
    title: 'E-Mail: contact@twimyo.com',
  },
  {
    title: 'Registergericht: Amtsgericht München',
    description: `Registernummer: HRB 266199
    Geschäftsführer: Gregor Gebhardt
    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE345951435`,
  },
  {
    title: 'EU-Streitschlichtung',
    description: `Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/odr`,
  },
  {
    title: 'Verbraucher­streit­beilegung/Universal­schlichtungs­stelle',
    description: `Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.`,
  },
  {
    title: 'Urheberrecht',
    description: `Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    Quelle: https://www.e-recht24.de`,
  },  
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography align={"justify"} sx={{whiteSpace: 'pre-line'}} component={'p'} color={'#777E91'}>
        {description}
      </Typography>
    </Box>
  );
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
